import React, { useState } from "react";
import axios from "axios";
import form_styles from "../styles/form.module.css";
import button_styles from "../styles/buttons.module.css";
import Notification from "./notification";
import Eye from "../assets/icons/eye.svg";
import EyeOff from "../assets/icons/eye-off.svg";

const LoginForm = ({ gympass, changeFormState }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [dataset, setDataset] = useState({
    email: "",
    password: "",
    gympass,
  });
  const [show, setShow] = useState(false);
  const [details, setDetails] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const sucessMessage = "";
  const [error, setError] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const onSubmittingFailure = () => {
    setShow(true);
  };

  const onSubmittingSuccess = () => {
    setShow(true);
  };

  async function signupAction(values) {
    axios.defaults.headers.common = {
      "X-API-Key": "zZ3g94or.xd7X3wcVbimaLZnGTSvuIqMbeSUj7qSv",
    };
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/gympass/signup`,
      {
        method: "POST",
        body: JSON.stringify(values),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    const data = await response.json();
    const status = await response.status;
    return { data, status };
  }

  const enablingStatus = (enablerObject) => {
    if (
      enablerObject.email !== "" &&
      enablerObject.password !== "" &&
      enablerObject.password.length >= 8
    ) {
      return true;
    }
    return false;
  };

  // Login form request
  const send = async () => {
    if (enablingStatus(dataset)) {
      setSubmitting(true);
      const { data, status } = await signupAction(dataset);
      if (status === 200) {
        const { redirect_url } = data;
        setSubmitting(false);
        onSubmittingSuccess();
        setTimeout(() => (window.location.href = redirect_url), 500);
      } else if (status === 400) {
        const { details } = data;
        setDetails(details);
        setSubmitting(false);
        onSubmittingFailure();
        setError(true);
      } else {
        setDetails("Something goes wrong, please try again");
        setSubmitting(false);
        onSubmittingFailure();
        setError(true);
      }
    } else {
      onSubmittingFailure();
      setError(false);
    }
  };

  const displayToastBG = () => {
    if (!enablingStatus(dataset)) {
      return "error-toast";
    }

    if (enablingStatus(dataset) && !error) {
      return "success-toast";
    }

    if (error) {
      return "error-toast";
    }
  };

  const displayMessage = () => {
    if (!enablingStatus(dataset)) {
      return "Failed to login, please fill with the required fields";
    }

    if (enablingStatus(dataset) && !error) {
      return "Successfully logged in for the Gympass";
    }

    if (error) {
      return details !== ""
        ? details
        : "Something wrong with your credentials, please try again or contact to our support.";
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        justifyContent: "flex-start",
        margin: "16px 0 0",
      }}
    >
      <div className={form_styles.form_column}>
        <label>Email</label>
        <input
          type="email"
          placeholder="Email address"
          className={form_styles.application_input}
          value={dataset.email}
          onChange={(e) => {
            e.target.value.localeCompare(dataset.email) !== 0
              ? setDataset({ ...dataset, email: e.target.value })
              : setDataset({ ...dataset, email: "" });
          }}
        />
      </div>
      <div className={form_styles.form_column}>
        <label>Password</label>
        <input
          type={showPassword ? "text" : "password"}
          placeholder="Password"
          value={dataset.password}
          className={form_styles.application_input}
          onChange={(e) => {
            e.target.value.localeCompare(dataset.password) !== 0
              ? setDataset({ ...dataset, password: e.target.value })
              : setDataset({ ...dataset, password: "" });
          }}
        />
        <div
          style={{
            position: "absolute",
            right: 0,
            bottom: 16,
            cursor: "pointer",
          }}
        >
          {showPassword ? (
            <img
              width={20}
              height={20}
              src={Eye}
              onClick={() => setShowPassword(!showPassword)}
              alt="eye"
            />
          ) : (
            <img
              width={20}
              height={20}
              src={EyeOff}
              onClick={() => setShowPassword(!showPassword)}
              alt="eye off"
            />
          )}
        </div>
      </div>

      <div
        className="row"
        style={{ marginTop: 32, display: "flex", justifyContent: "flex-start" }}
      >
        {submitting ? (
          <div className={form_styles.loader} style={{ margin: "0 auto" }} />
        ) : (
          <button
            className={button_styles.black}
            style={{ width: 320 }}
            onClick={() => send()}
          >
            <p style={{ margin: 0, textTransform: "uppercase" }}>Login</p>
          </button>
        )}
      </div>

      <div
        className="row"
        style={{
          margin: "26px 0",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <span style={{ fontSize: 13, lineHeight: "130%", color: "#737373" }}>
          {`Read our  `}
          <a
            href="/privacy-policy"
            target="_blank"
            rel="noreferrer"
            style={{ color: "#737373" }}
          >
            privacy policy
          </a>
        </span>

        <p
          style={{
            color: "#737373",
            fontSize: 14,
            lineHeight: "120%",
            letterSpacing: 2,
            textTransform: "uppercase",
            fontWeight: 600,
            cursor: "pointer",
          }}
          onClick={() => changeFormState(true)}
        >
          Sign Up
        </p>
      </div>

      {/* Error Message */}
      <Notification
        open={show}
        onClose={() => {
          setShow(false);
        }}
        type={displayToastBG()}
        message={displayMessage()}
        timeout={3000}
      />
      {/* Success Message */}
      <Notification
        open={showSuccess}
        onClose={() => {
          setShowSuccess(false);
        }}
        type="success-toast"
        message={sucessMessage}
        timeout={3000}
      />
    </div>
  );
};

export default LoginForm;
