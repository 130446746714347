import React from "react";
import ChevronRight from "../../assets/icons/chevron-right-dark.svg";
import ChevronLeft from "../../assets/icons/chevron-left-dark.svg";

function Arrows({ prevSlide, nextSlide, isVideoSlider }) {
  return (
    <div className={isVideoSlider ? null : "arrows"}>
      <span className="prev" onClick={prevSlide}>
        <img src={ChevronLeft} alt="left arrow" height={32} width={32} />
      </span>
      <span className="next" onClick={nextSlide}>
        <img src={ChevronRight} alt="right arrow" height={32} width={32} />
      </span>
    </div>
  );
}

export default Arrows;
