import React, { useState } from "react";
import Arrows from "./common/Arrows";
import home_styles from "../styles/home.module.css";

const MobileFeaturesSlider = ({ content }) => {
  const tabSource = content.sort((a, b) => a.id - b.id);
  const [activeIndex, setActiveIndex] = useState(0);
  const len = tabSource.length - 1;
  return (
    <section
      className={`${home_styles.show_for_mobile} ${home_styles.tab_screen}`}
      style={{ margin: "-80px auto 120px" }}
    >
      <div className={`${home_styles.landing_banner_content}`}>
        <div
          className={` ${home_styles.tab_content}`}
          style={{ flexDirection: "column", justifyContent: "center" }}
        >
          <div className={home_styles.image_slider_gympass}>
            <div className={home_styles.device_frame}>
              <img
                width={296}
                height={600}
                src={
                  tabSource.length > 0
                    ? tabSource[activeIndex].tabImage["url"]
                    : ""
                }
                alt={
                  tabSource.length > 0
                    ? tabSource[activeIndex].tabImage["name"]
                    : ""
                }
              />
            </div>
          </div>

          {tabSource.length > 1 && (
            <Arrows
              prevSlide={() => {
                setActiveIndex(activeIndex < 1 ? len : activeIndex - 1);
              }}
              nextSlide={() => {
                setActiveIndex(activeIndex === len ? 0 : activeIndex + 1);
              }}
            />
          )}

          <div className="all-dots">
            {tabSource.map((slide, index) => (
              <span
                key={index}
                className={`${activeIndex === index ? `active_dot` : `dot`}`}
              ></span>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default MobileFeaturesSlider;
