import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { chunk } from "../js/chunks";
// Components
import Footer from "../components/common/Footer";
import Header from "../components/common/Header";
import TabLayout from "../components/common/TabLayout";
// Icons
import alerDarkIcon from "../assets/icons/alert-dark.svg";
// Actions
import { history } from "../index";
import { tabs } from "../js/static";
import { createNotification, fetchWildCoaches, setWildCoaches } from "../store/app/actions";

const Coaches = (props) => {
    const { location } = props;
    const dispatch = useDispatch();
    const [wild_coaches, set_wild_coaches] = useState([]);
    let { user, coaches } = useSelector((state) => state.app);
    let [username, set_username] = useState('');
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        if (user) {
            let { first_name } = user;
            set_username(first_name);
        }
    }, [user]);

    const resetFields = () => {
        set_wild_coaches([]);
        dispatch(setWildCoaches([]));
    }
    const onSuccess = () => setLoading(false);
    const onError = (error) => dispatch(createNotification("error-toast", error));

    useEffect(() => {
        if (coaches && coaches.length > 0) {
            set_wild_coaches(chunk(coaches, 3));
        }
    }, [coaches]);

    useEffect(() => {
        setLoading(true);
        resetFields();
        window.analytics.page();
        dispatch(fetchWildCoaches(onSuccess, onError));
    }, []);


    return (
        <div className="_container">
            <header className='desktop home_background_banner'>
                <div className='banner_image home_background_banner_image' />
                <div className='_after'>
                    <Header location={location} />
                    <div className="desktop flex justifyCenter alignCenter w-100 h-100" style={{ margin: '96px 0 0' }}>
                        <h1 style={{ color: '#FFF', fontSize: 72 }}>{`Hi, ${username}`}</h1>
                    </div>

                    <div className="mobile flex justifyCenter alignCenter w-100 h-100" style={{ margin: '72px 0 0' }}>
                        <h3 className="mobile" style={{ color: '#FFF', fontSize: 32 }}>{`Hi, ${username}`}</h3>
                    </div>
                </div>
            </header>

            <header className='mobile home_background_banner'>
                <Header location={location} />
                <div className="flex justifyCenter alignCenter w-100 h-100" style={{ margin: '96px 0 0' }}>
                    <h3 style={{ color: '#000', fontSize: 32 }}>{`Hi, ${username}`}</h3>
                </div>
            </header>

            <div className="main_content_area flex justifyCenter alignCenter w-100 h-100">
                <div className="content_area flex column justifyStart alignCenter w-100">

                    {/* tabs */}
                    <TabLayout tabs={tabs} index={1} />

                    {/* loading */}
                    {loading && (
                        <div className="flex column justifyCenter alignCenter" style={{ margin: '64px 0' }}>
                            <div className="custom_loader" />
                            <p style={{ marginTop: 32 }}>
                                {`Loading, please wait…`}
                            </p>
                        </div>
                    )}

                    {/* Wild Coaches (1- desktop, 2- mobile) */}
                    <div className="desktop flex column justifyCenter alignCenter w-100" style={{ margin: "24px 0 0" }}>
                        {wild_coaches.map((coaches_list, index) => (
                            <div className="flex justifyCenter alignCenter w-100" key={index + 1}
                                style={{ margin: "24px 0 0" }}>
                                {coaches_list && coaches_list.length > 0 && coaches_list.map((coach, sub_index) => (
                                    <div className="coaches_flex_container flex column justifyStart alignStart relative pointer" key={sub_index + 1} onClick={() => history.push(`/coach?id=${coach['pk']}`)} >

                                        <div className="flex column justifyCenter alignCenter w-100" style={{ padding: '8px 16px 16px' }}>
                                            <h2 style={{ textAlign: 'center', paddingTop: 16 }}>{`${coach['name']}`}</h2>
                                            <p style={{ fontSize: 14, textAlign: 'center' }}>{`${coach.short_title}`}</p>
                                        </div>
                                        <div className="flex justifyCenter alignCenter w-100">
                                            <img src={coach['profile_img_url'] || 'https://images.unsplash.com/photo-1608138404239-d2f557515ecb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80'} alt="plan_image" style={{ borderBottomLeftRadius: 8, borderBottomRightRadius: 8 }} />
                                        </div>
                                        <div className="flex justifyCenter alignCenter w-100" style={{ position: 'absolute', bottom: 8, marginTop: 8 }}>
                                            <p style={{ fontSize: 14, color: '#FFF' }}>{`${coach.total_plans > 1 ? `${coach.total_plans} plans available` : `${coach.total_plans} plan available`}`}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>

                    <div className="mobile flex column justifyStart alignCenter w-100" style={{ marginTop: 24 }}>
                        {coaches && coaches.length > 0 && coaches.map((coach, index) => (
                            <div className="coaches_flex_container flex column justifyStart alignStart relative pointer" key={index + 1}
                                onClick={() => history.push(`/coach?id=${coach['pk']}`)} >
                                <div className="flex alignCenter absolute" style={{
                                    left: '50%', transform: 'translate(-50%)', bottom: 0
                                }}>
                                    <img
                                        src={coach['profile_img_url'] || 'https://images.unsplash.com/photo-1608138404239-d2f557515ecb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80'}
                                        alt="plan_image"
                                    />
                                </div>
                                <div className="flex column justifyCenter alignCenter absolute w-100" style={{ top: 8 }}>
                                    <h2 style={{ textAlign: 'center', paddingTop: 16 }}>{`${coach['name']}`}</h2>
                                    <div className="flex alignCenter" style={{ marginTop: 8 }}>
                                        <p style={{ fontSize: 14 }}>{`${coach.position} @ ${coach.coach_type}`}</p>
                                    </div>
                                </div>
                                <div className="flex justifyCenter alignCenter w-100" style={{ position: 'absolute', bottom: 8, marginTop: 8 }}>
                                    <p style={{ fontSize: 14, color: '#FFF' }}>{`${coach.total_plans > 1 ? `${coach.total_plans} plans available` : `${coach.total_plans} plan available`}`}</p>
                                </div>
                            </div>
                        ))}
                    </div>

                    {!loading && coaches.length === 0 && wild_coaches.length === 0 && (
                        <div className="flex justifyCenter alignCenter w-100" style={{ margin: "24px 0 0" }}>
                            <img src={alerDarkIcon} alt="dark_alert_icon" height={28} width={28} />
                            <p style={{ color: '#000', marginLeft: 8 }}>No coaches available yet</p>
                        </div>
                    )}
                </div>
            </div>
            <Footer />
        </div >
    );
};

export default Coaches;
