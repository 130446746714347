import React from "react";
import ArrowLeft from "../assets/icons/back-arrow-light.svg";
import { history } from "../index";

const PageNotFound = () => (
  <div className="flex column justifyCenter alignCenter h-100" style={{ minHeight: "100vh", background: "#010101" }}>
    <div className="desktop flex column justifyCenter alignCenter w-100"
      style={{
        backgroundColor: "#ffffff",
        height: 376,
        borderRadius: 12,
        textAlign: "center",
        width: "40vw",
        padding: "0px 40px",
      }}
    >
      <h2>  WRONG PLACE </h2>
      <p
        style={{
          padding: 0,
          margin: "24px 0",
          color: "#010101",
          fontWeight: "normal",
          fontSize: 22,
        }}
      >
        Not sure how you got here but this place doesn&apos;t exist
      </p>
      <div className="btn-back" type="button" onClick={() => history.push("/")}>
        <img src={ArrowLeft} alt="back-arrow" style={{ marginRight: "0.5em" }} />
        Back
      </div>
    </div>

    <div className="mobile flex column justifyCenter alignCenter w-100"
      style={{
        backgroundColor: "#ffffff",
        height: 320,
        borderRadius: 12,
        textAlign: "center",
        width: "90%",
        padding: "0px 40px",
      }}
    >
      <h3 className="mobile">  WRONG PLACE </h3>
      <p
        style={{
          padding: 0,
          margin: "24px 0",
          color: "#010101",
          fontWeight: "normal",
          fontSize: 20,
          lineHeight: 1.5
        }}
      >
        Not sure how you got here but this place doesn&apos;t exist
      </p>
      <div className="btn-back" type="button" onClick={() => history.push("/")}>
        <img src={ArrowLeft} alt="back-arrow" style={{ marginRight: "0.5em" }} />
        Back
      </div>
    </div>
  </div>
);
export default PageNotFound;
