
// detect OS
export function getOS() {
  var userAgent = window.navigator.userAgent,
    platform = window.navigator.platform,
    macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
    windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
    iosPlatforms = ["iPhone", "iPad", "iPod"],
    OS = null;
  if (macosPlatforms.indexOf(platform) !== -1) {
    OS = "Mac OS";
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    OS = "iOS";
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    OS = "Windows";
  } else if (/Android/.test(userAgent)) {
    OS = "Android";
  } else if (/Linux/.test(platform)) {
    OS = 'Linux';
  } else {
    OS = "Windows";
  }
  return OS;
}

const AndroidAppLink =
  "https://play.google.com/store/apps/details?id=com.wildai.wild";
const IOSAppLink = "https://apps.apple.com/us/app/wild-ai/id1482294997";
export const loadAppUrl = () => {
  let OS = getOS();
  if (OS === "Windows" || OS === "Android" || OS === "Linux") {
    window.open(AndroidAppLink, "_blank");
  } else if (OS === "Mac OS" || OS === "iOS") {
    window.open(IOSAppLink, "_blank");
  }
}
