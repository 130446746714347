import React from "react";
import home_styles from "../styles/home.module.css";
import Menarche from "../assets/icons/menarche.svg";
import Run from "../assets/icons/run.svg";
import Cycle from "../assets/icons/cycle.svg";
import SwapHoriz from "../assets/icons/swap_horiz.svg";

const WildFeatures = () => {
  return (
    <section className={home_styles.gympass_features_content}>
      <h2>Wild.AI Features</h2>

      <div className={home_styles.features_column}>
        <div className={home_styles.features_row}>
          <div className={home_styles.feature_box}>
            <div className={home_styles.feature_image_container}>
              <img width={46.75} height={46.75} src={Menarche} alt="menarche" />
            </div>
            <p>
              Wild.AI supports active females with a menstrual cycle, on birth
              control, in perimenopause or postmenopause.
            </p>
          </div>
          <div className={home_styles.feature_box}>
            <div className={home_styles.feature_image_container}>
              <img width={46.75} height={46.75} src={Run} alt="run" />
            </div>
            <p>
              Use the recommendations in Wild.AI to support your existing
              training.
            </p>
          </div>
        </div>
        <div className={home_styles.features_row}>
          <div className={home_styles.feature_box}>
            <div className={home_styles.feature_image_container}>
              <img width={46.75} height={46.75} src={Cycle} alt="cycle" />
            </div>
            <p>
              Use one of our intermediate training plans for running with a
              cycle-sync option.
            </p>
          </div>
          <div className={home_styles.feature_box}>
            <div className={home_styles.feature_image_container}>
              <img
                width={46.75}
                height={46.75}
                src={SwapHoriz}
                alt="swap horiz"
              />
            </div>
            <p>
              Sync Wild.AI with your TrainingPeaks (Premium), Garmin or Strava
              accounts. Oura ring is coming soon.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WildFeatures;
