import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { chunk } from "../js/chunks";
// Components
import Header from "../components/common/Header";
import Footer from "../components/common/Footer";
// Icons
import greenTickIcon from "../assets/icons/green-tick.svg";
import BcakArrow from "../assets/icons/back-arrow-dark.svg";
import badgeIcon from "../assets/icons/plan-badge.svg";
import timerIcon from "../assets/icons/timer-light.svg";
// Action
import { history } from "../index";
import { fetchWildCoach, setWildCoach } from "../store/app/actions";

const Coach = (props) => {
    const { location } = props;
    const dispatch = useDispatch();
    let { user, coach } = useSelector((state) => state.app);
    let [username, set_username] = useState('');
    let [url_params, set_url_params] = useState(null);
    let [error_message, set_error_message] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (user) {
            let { first_name } = user;
            set_username(first_name);
        }
    }, [user]);
    const [pro_plans, set_pro_plans] = useState([]);
    const onSuccess = () => setLoading(false);
    const onError = (error) => {
        setLoading(false);
        set_error_message(error);
    };

    useEffect(() => {
        if (coach && Object.keys(coach).length > 0) {
            if (coach['plans'] && coach['plans'].length > 0) {
                set_pro_plans(chunk(coach['plans'], 3));
            }
        }
    }, [coach]);

    useEffect(() => {
        if (location) {
            window.analytics.page();
            const { search } = location;
            const params = JSON.parse('{"' + decodeURI(search).replace(/\?/g, '').replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
            set_url_params(params);
            setLoading(true);
            dispatch(setWildCoach({}));
            dispatch(fetchWildCoach(params['id'], onSuccess, onError))
        }
    }, []);

    return (
        <div className="_container">
            <header className='desktop home_background_banner'>
                <div className='banner_image home_background_banner_image' />
                <div className='_after'>
                    <Header location={location} />
                    <div className="desktop flex justifyCenter alignCenter w-100 h-100" style={{ margin: '96px 0 0' }}>
                        <h1 style={{ color: '#FFF', fontSize: 72 }}>{`Hi, ${username}`}</h1>
                    </div>

                    <div className="mobile flex justifyCenter alignCenter w-100 h-100" style={{ margin: '72px 0 0' }}>
                        <h3 className="mobile" style={{ color: '#FFF', fontSize: 32 }}>{`Hi, ${username}`}</h3>
                    </div>
                </div>
            </header>

            <header className='mobile home_background_banner'>
                <Header location={location} />
                <div className="flex justifyCenter alignCenter w-100 h-100" style={{ margin: '96px 0 0' }}>
                    <h3 style={{ color: '#000', fontSize: 32 }}>{`Hi, ${username}`}</h3>
                </div>
            </header>

            <div className="main_content_area flex justifyCenter alignCenter w-100 h-100">
                <div className="content_area flex column justifyStart alignCenter w-100">
                    {/* loading */}
                    {loading && (
                        <div className="flex column justifyCenter alignCenter" style={{ margin: '64px 0' }}>
                            <div className="custom_loader" />
                            <p style={{ marginTop: 32 }}>
                                {`Loading, please wait…`}
                            </p>
                        </div>
                    )}

                    {error_message !== '' && (
                        <div className="flex column justifyCenter alignCenter" style={{ margin: '64px 8px', textAlign: 'center' }}>
                            <h2>{`Sorry, we couldn’t find anything`}</h2>
                            <p style={{ margin: "16px 0 0" }}>  Try using another search term or contact support at support@wild.ai.  </p>
                        </div>
                    )}

                    {Object.keys(coach).length > 0 && (
                        <div className="flex column justifyCenter alignCenter w-100 coach_container" style={{ paddingTop: 32 }}>
                            <div className="mobile flex justifyStart alignCenter pointer w-100" style={{ marginBottom: 24 }} onClick={() => history.push("/coaches")}>
                                <img src={BcakArrow} alt="wild.ai logo" height={24} width={24} />
                                <p style={{ marginLeft: 8, textTransform: 'uppercase' }}> Back </p>
                            </div>

                            <h1 className="desktop">{coach['name']}</h1>
                            <h3 className="mobile">{coach['name']}</h3>
                            <p>{coach['short_title']}</p>
                            <div className="desktop" style={{ textAlign: 'center', width: "70%", margin: "24px 0 32px" }}>
                                <p>{coach['bio']}</p>
                            </div>
                            <div className="mobile" style={{ textAlign: 'center', margin: "24px 0 32px", padding: "0 16px" }}>
                                <p>{coach['bio']}</p>
                            </div>
                            <div className="flex alignCenter" style={{ margin: "0 0 32px" }}>
                                <img src={badgeIcon} alt="availability badge" height={18} width={18} />
                                <p style={{ marginLeft: 8 }}>{coach['total_plans'] > 1 ? `${coach['total_plans']} plans available` : `${coach['total_plans']} plan available`}</p>
                            </div>


                            {pro_plans && pro_plans.length > 0 && (
                                <div className="desktop flex column justifyStart alignCenter w-100" style={{ margin: "24px 0 0" }}>
                                    {pro_plans.map((plan_list, index) => (
                                        <div className="flex justifyCenter alignCenter w-100" key={index + 1}
                                            style={{ margin: "24px 0 0" }}>
                                            {plan_list && plan_list.length > 0 &&
                                                plan_list.map((plan, sub_index) => {
                                                    return (
                                                        <div className="flex_container flex column justifyStart alignStart relative pointer" key={sub_index + 1}
                                                            onClick={() => history.push(`/plan?id=${plan['pk']}&coaches=${true}&coach_id=${url_params['id']}`)}
                                                        >
                                                            <img src={plan['plan_image'] || 'https://images.unsplash.com/photo-1608138404239-d2f557515ecb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80'} alt="plan_image" height={260} width="100%" style={{ borderRadius: 16 }} />
                                                            {plan['user_pricing']['user_bought'] && (
                                                                <div className="flex absolute" style={{ top: 20, right: 12, zIndex: 100 }}>
                                                                    <p style={{ color: '#FFF', marginRight: 8 }}>Purchased</p>
                                                                    <img src={greenTickIcon} alt="green_tick_icon" height={22} width={22} />
                                                                </div>
                                                            )}
                                                            <div className="flex column justifyEnd alignStart absolute absolute_card">
                                                                <h4>{plan.name}</h4>
                                                                <div className="flex justifyStart" style={{ margin: "4px 0 12px" }}>
                                                                    <p style={{ fontSize: 14 }}>{`${plan['user_pricing'].user_price} ${plan['user_pricing'].currency}`}</p>
                                                                    <div className="flex alignCenter" style={{ marginLeft: 16 }}>
                                                                        <img src={timerIcon} alt="timer_icon" height={18} width={18} />
                                                                        <span style={{ marginLeft: 6 }}><p style={{ fontSize: 14 }}>{`${plan.plan_length > 1 ? `${plan.plan_length} weeks` : `${plan.plan_length} week`}`}</p></span>
                                                                    </div>
                                                                </div>
                                                                <div className="flex justifyCenter">
                                                                    <img src={plan['coach']['profile_img_url']} alt="coach_profile_image" height={36} width={36} style={{ borderRadius: '50%', border: "1px solid #fff" }} />
                                                                    <div className="flex column justifyStart" style={{ marginLeft: 12 }}>
                                                                        <p style={{ fontSize: 12 }}>{plan['coach']['name']}</p>
                                                                        <p style={{ fontSize: 12 }}>{plan['coach']['short_title']}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                        </div>
                                    ))}
                                </div>
                            )}

                            {pro_plans && pro_plans.length > 0 && (
                                <div className="mobile flex column justifyStart alignCenter w-100" style={{ marginTop: 24 }}>
                                    {coach['plans'] && coach['plans'].length > 0 && coach['plans'].map((plan, index) => {
                                        return (
                                            <div className="flex_container flex column justifyStart alignStart relative pointer" key={index + 1}
                                                onClick={() => history.push(`/plan?id=${plan['pk']}&coaches=${true}&coach_id=${url_params['id']}`)}>
                                                <img src={plan['plan_image'] || 'https://images.unsplash.com/photo-1608138404239-d2f557515ecb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80'} alt="plan_image" height={220} width="100%" style={{ borderRadius: 24 }} />
                                                {plan['user_pricing']['user_bought'] && (
                                                    <div className="flex absolute" style={{ top: 20, right: 12, zIndex: 100 }}>
                                                        <p style={{ color: '#FFF', marginRight: 8 }}>Purchased</p>
                                                        <img src={greenTickIcon} alt="green_tick_icon" height={22} width={22} />
                                                    </div>
                                                )}
                                                <div className="flex column justifyEnd alignStart absolute absolute_card">
                                                    <h4>{plan.name}</h4>
                                                    <div className="flex justifyStart" style={{ margin: "4px 0 12px" }}>
                                                        <p style={{ fontSize: 14 }}>{`${plan['user_pricing'].user_price} ${plan['user_pricing'].currency}`}</p>
                                                        <div className="flex alignCenter" style={{ marginLeft: 16 }}>
                                                            <img src={timerIcon} alt="timer_icon" height={18} width={18} />
                                                            <span style={{ marginLeft: 6 }}><p style={{ fontSize: 14 }}>{`${plan.plan_length > 1 ? `${plan.plan_length} weeks` : `${plan.plan_length} week`}`}</p></span>
                                                        </div>
                                                    </div>
                                                    <div className="flex justifyCenter">
                                                        <img src={plan['coach']['profile_img_url']} alt="coach_profile_image" height={36} width={36} style={{ borderRadius: '50%', border: "1px solid #fff" }} />
                                                        <div className="flex column justifyStart" style={{ marginLeft: 12 }}>
                                                            <p style={{ fontSize: 12 }}>{plan['coach']['name']}</p>
                                                            <p style={{ fontSize: 12 }}>{plan['coach']['short_title']}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
            <Footer />
        </div >
    );
};

export default Coach;
