import React, { useEffect, useState } from "react";
import main_styles from "../styles/main.module.css";
import background_banners from "../styles/banners.module.css";
// Common
import LogoFooter from "../components/normal_footer";
// Components
import BannerWithMobileFeaturesSlider from "../components/banner-with-mobile-features-slider";
import LifeStageSupport from "../components/life-stage-support";
import AccessFormCard from "../components/AccessFormCard";
import MobileFeaturesSlider from "../components/mobile-feature-slider";
import WildFeatures from "../components/WildFeatures";
import AES256 from "aes-everywhere";
import Notification from "../components/notification";

const JoinGympass = () => {
  const [isSignup, setSignup] = useState(true);
  const [showError, setShowError] = useState(false);
  const [gympass, setGympass] = useState("");
  const [first_name, setFirstName] = useState("");
  const [tabs, setTabs] = useState([]);
  const [lifeStageSupport, setLifeStageSupport] = useState([]);

  const fetchValues = async () => {
    try {
      const [tabsValue, lifeStageSupportValue] = await Promise.all([
        fetchAPI("/tabs"),
        fetchAPI("/life-stage-supports"),
      ]);
      setTabs(tabsValue);
      setLifeStageSupport(lifeStageSupportValue);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchValues();
  }, []);

  useEffect(() => {
    const currentURL = window.location.href;
    const decodedURL = decodeURIComponent(currentURL);
    const url = new URL(decodedURL);
    const params = new URLSearchParams(url.search);

    let gympassValue = params.get("gympass").replace(/\s/g, "+");
    let first_name_value = params.get("first_name").replace(/\s/g, "+");
    try {
      const decryptedFirstNameValue = AES256.decrypt(
        first_name_value,
        "AES_Password_!21Prod"
      );
      setFirstName(decryptedFirstNameValue);
    } catch {
      setShowError(true);
    }

    try {
      const decryptedGympassValue = AES256.decrypt(
        gympassValue,
        "AES_Password_!21Prod"
      );
      setGympass(decryptedGympassValue);
    } catch {
      setShowError(true);
    }
  }, []);

  async function fetchAPI(path) {
    const requestUrl = `https://strapi.wild.ai${path}`;
    const response = await fetch(requestUrl);
    const data = await response.json();
    return data;
  }

  return (
    <div className={main_styles.container}>
      <main
        className={`${background_banners.main} ${background_banners.gympasspage_banner}`}
      >
        <div className={background_banners.tiled} />
        <div
          className={`${background_banners.image} ${background_banners.gympasspage_background_image}`}
        />
        <div className={background_banners.after}>
          <BannerWithMobileFeaturesSlider
            content={tabs || []}
            name={first_name}
            showFormFor={(state) => setSignup(state)}
          />
        </div>
      </main>

      <MobileFeaturesSlider isGympass={true} content={tabs} />

      <WildFeatures />
      <LifeStageSupport content={lifeStageSupport} screenName="gympass" />
      {!showError && (
        <AccessFormCard
          gympass={gympass}
          firstName={first_name}
          isSignUp={isSignup}
          showFormFor={(state) => setSignup(state)}
        />
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          position: "relative",
          width: "100%",
          marginTop: 120,
        }}
      >
        <LogoFooter screenName="gympass" />
      </div>
      <Notification
        open={showError}
        onClose={() => {
          setShowError(true);
        }}
        type={"error-toast"}
        message={
          "Looks like you are not coming from Gympass. If you do please reach out to your account manager for more help."
        }
        timeout={3000}
      />
    </div>
  );
};

export default JoinGympass;
