import React from "react";

const CommonReportHeader = (props) => {

    return props && (
        <div className="flex column justifyStart w-100">
            <p className={!props.isSubtitleUnder ? "common_label" : "common_label_with_underline"}>{props.title}</p>
            <div className="w-100" style={{ borderBottom: !props.isSubtitleUnder ? "1px solid #000000" : "none" }}>
                <p className={!props.isSubtitleUnder ? "common_subtitle" : "common_subtitle_without_underline"}>
                    {props.replaceStatus ? props.subtitle.replace(/your/gi, 'her').replace(/you/gi, 'she') : props.subtitle}
                </p>
            </div>
        </div>

    );
}

export default CommonReportHeader;