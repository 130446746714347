import React, { useState, useEffect } from "react";
import CommonReportHeader from "../../common/CommonReportHeader";
import GraphMultiLegends from "../../common/GraphMultiLegends";
import FooterDescription from "../../common/FooterDescription";
import LinePattern from "../../../assets/icons/line-pattern.svg";
import EmptyGraph from "../../common/EmptyGraph";
import { menstrual_cycle_report, contraceptive_group_report, monthly_report } from "../../../js/static_report";



const LifestyleOverview = (props) => {
    let [legends, setLegends] = useState(null);
    let [content, setContent] = useState(null);
    let [lifestyle, setLifestyle] = useState(null);
    let [lifestyleData, setLifestyleData] = useState(null);
    let [checkinDesc, setCheckinDesc] = useState(null);
    let [show_empty_graph, set_show_empty_graph] = useState(false);

    const allEqualToWhite = (arr) => arr.every(v => v === '#FFFFFF');
    const allEqualToGrey = (arr) => arr.every(v => v === '#BFBFBF');

    useEffect(() => {
        if (props.lifestyle) {
            let { lifestyle: { legend, title, subtitle, data, top_checkin } } = props.lifestyle;
            if (data && Object.keys(data).length !== 0) {
                let data_values = Object.values(data);
                let is_empty = false;
                let phase_count = 0;
                let merge_arrays = [];

                if (data_values && data_values.length > 0) {
                    for (let i in data_values) {
                        let data_list = data_values[i];
                        phase_count = data_values[i].length;
                        if (data_list && data_list.length > 0) {
                            for (let j in data_list) {
                                let colors_list = Object.values(data_list[j]['data']);
                                merge_arrays = [...merge_arrays, ...colors_list];
                            }
                        }
                    }

                    if (merge_arrays && merge_arrays.length > 0) {
                        if (allEqualToWhite(merge_arrays)) {
                            is_empty = true;
                        } else if (allEqualToGrey(merge_arrays)) {
                            is_empty = true;
                        } else {
                            is_empty = false;
                        }
                    }

                    if (is_empty && !props.dashboard) {
                        set_show_empty_graph(true);
                        if (phase_count > 2) {
                            let { legend, title, subtitle, data, top_checkin } = menstrual_cycle_report['lifestyle'];
                            setLegends(legend);
                            setContent({
                                title: title || null,
                                subtitle: subtitle || null
                            });
                            setLifestyle(Object.keys(data));
                            setLifestyleData(Object.values(data));
                            setCheckinDesc(top_checkin || null);
                        } else if (phase_count === 2) {
                            let { legend, title, subtitle, data, top_checkin } = contraceptive_group_report['lifestyle'];
                            setLegends(legend);
                            setContent({
                                title: title || null,
                                subtitle: subtitle || null
                            });
                            setLifestyle(Object.keys(data));
                            setLifestyleData(Object.values(data));
                            setCheckinDesc(top_checkin || null);
                        } else if (phase_count === 1) {
                            let { legend, title, subtitle, data, top_checkin } = monthly_report['lifestyle'];
                            setLegends(legend);
                            setContent({
                                title: title || null,
                                subtitle: subtitle || null
                            });
                            setLifestyle(Object.keys(data));
                            setLifestyleData(Object.values(data));
                            setCheckinDesc(top_checkin || null);
                        }
                    } else if (is_empty && props.dashboard) {
                        setLegends(legend);
                        setContent({
                            title: title || null,
                            subtitle: subtitle || null
                        });
                        setLifestyle(Object.keys(data));
                        setLifestyleData(Object.values(data));
                        setCheckinDesc(top_checkin || null);
                    } else {
                        setLegends(legend);
                        setContent({
                            title: title || null,
                            subtitle: subtitle || null
                        });
                        setLifestyle(Object.keys(data));
                        setLifestyleData(Object.values(data));
                        setCheckinDesc(top_checkin || null);
                    }
                } else {
                    set_show_empty_graph(true);
                    let { legend, title, subtitle, data, top_checkin } = monthly_report['lifestyle'];
                    setLegends(legend);
                    setContent({
                        title: title || null,
                        subtitle: subtitle || null
                    });
                    setLifestyle(Object.keys(data));
                    setLifestyleData(Object.values(data));
                    setCheckinDesc(top_checkin || null);
                }
            }
        }
    }, [props.lifestyle]);

    const covertCycleDataDictionaryIntoArray = (dictionary, maxDays) => {
        let array = [];
        if (Object.keys(dictionary).length > 0) {
            for (var i = 1; i <= maxDays; i++) {
                array[i - 1] = dictionary[i] || "#ffffff";
            }
        } else {
            for (var j = 1; j <= maxDays; j++) {
                array[j - 1] = "#ffffff";
            }
        }
        return array;
    }

    return !props ? (
        <div className="flex column justifyStart alignStart w-100" style={{ margin: "40px 0 0" }}>
            <CommonReportHeader label="Lifestyle Check-in Overview" />
        </div>
    ) : legends && (
        <div className="flex column justifyStart alignStart relative w-100" style={{ margin: "40px 0 0" }}>
            {content && (<CommonReportHeader title={content.title} subtitle={content.subtitle} isSubtitleUnder={true} />)}

            {checkinDesc && <FooterDescription describe={checkinDesc} />}

            <EmptyGraph show={show_empty_graph} message="You're not tracking this yet. Add this check-in Wild AI to start tracking this metric!" />

            <div className="flex column justifyStart alignStart w-100 scrollable" style={{ paddingBottom: 24 }}>
                <div className="flex column justifyStart alignStart w-100">
                    {lifestyle && lifestyle.map((item, index) => {
                        return (
                            <div className="flex justifyStart alignCenter w-100" key={index + 1} style={{ marginTop: index === 0 ? 32 : 16, }}>
                                <p style={{ fontSize: 12, fontWeight: 700, minWidth: 84, maxWidth: 84, textAlign: 'left' }}>{item}</p>
                                <div className="flex justifyStart alignStart">
                                    {lifestyleData[index] && lifestyleData[index].map((symptomPhaseDetail, subIndex) => {
                                        return (
                                            <div className="flex justifyStart" key={subIndex + 1} style={{ minWidth: 84, marginLeft: 16 }}>
                                                {covertCycleDataDictionaryIntoArray(symptomPhaseDetail["data"], symptomPhaseDetail["max_days"]).map((color, colorIndex) => {
                                                    return (
                                                        <div key={colorIndex + 1}>
                                                            {color.toLowerCase() === "#ffffff" ? (
                                                                <img src={LinePattern} alt="line-pattern" style={{ margin: "4px 12px", height: props.monthly_status ? 28 : 20, width: props.monthly_status ? 28 : 20, borderRadius: 1.6 }} />
                                                            ) : (
                                                                <div style={{ margin: "4px 2px", background: color, height: props.monthly_status ? 28 : 20, width: props.monthly_status ? 28 : 20, borderRadius: 1.6 }} />
                                                            )}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )
                    })}

                    {lifestyle && lifestyle.map((item, index) => {
                        return index + 1 === 1 && (
                            <div className="flex justifyStart alignStart w-100" key={index + 1} style={{ marginTop: 16 }}>
                                <p style={{ fontSize: 12, minWidth: 84, maxWidth: 84, textAlign: 'left', marginTop: lifestyleData[index].length === 1 || lifestyleData[index].length === 2 ? 4 : 8 }}>{lifestyleData[index].length > 1 ? `Day of Phase` : `Day of Month`}</p>
                                <div className="flex justifyStart alignStart">
                                    {lifestyleData[index] && lifestyleData[index].map((symptomPhaseDetail, subIndex) => {
                                        return (
                                            <div className="flex column justifyStart" key={subIndex + 1} style={{ minWidth: 84, marginLeft: 16 }}>
                                                <div className="flex justifyStart">
                                                    {covertCycleDataDictionaryIntoArray(symptomPhaseDetail["data"], symptomPhaseDetail["max_days"]).map((color, colorIndex) => {
                                                        return (
                                                            <p key={colorIndex + 1} style={{ fontSize: lifestyleData[index].length === 1 || lifestyleData[index].length === 2 ? 10 : 14, width: props.monthly_status ? 28 : 20, margin: "4px 2px", textAlign: "center" }}>{colorIndex + 1}</p>
                                                        );
                                                    })}
                                                </div>
                                                {lifestyleData[index].length > 1 && (
                                                    <div className="flex justifyStart alignCenter w-100" style={{ marginTop: 16 }} >
                                                        <p style={{ fontSize: 12, fontWeight: 700, margin: "auto" }}>{symptomPhaseDetail.phase}</p>
                                                    </div>
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )
                    })}

                </div>
            </div>

            <GraphMultiLegends legend={legends} />
        </div>
    )
}


export default LifestyleOverview;