export const INITIALIZE = "app/INITIALIZE";
export const INITIALIZED = "app/INITIALIZED";
export const FETCH_USER = "app/FETCH_USER";
export const SET_USER = "app/SET_USER";
export const RECAPTCHA_STATUS = "app/RECAPTCHA_STATUS";
export const SET_RECAPTCHA_STATUS = "app/SET_RECAPTCHA_STATUS";
export const LOGOUT_USER = "app/LOGOUT_USER";
export const SET_ERROR = "app/ERROR";
export const SET_REDIRECT_TO = "app/SET_REDIRECT_TO";
export const SET_LOGGED_USER = "app/SET_LOGGED_USER";
export const LOGIN_MANUAL = "app/LOGIN_MANUAL";
export const LOGIN_GOOGLE = "app/LOGIN_GOOGLE";
export const LOGIN_APPLE = "app/LOGIN_APPLE";
export const LOGIN_AUTO = "app/LOGIN_AUTO";
export const FORGOT_PASSWORD = "app/FORGOT_PASSWORD";
export const CREATE_NOTIFICATION = "app/CREATE_NOTIFICATION";
export const REMOVE_NOTIFICATION = "app/REMOVE_NOTIFICATION";
// Tabs
export const FETCH_PRO_PLANS = "app/FETCH_PRO_PLANS";
export const SET_PRO_PLANS = "app/SET_PRO_PLANS";
export const FETCH_PLAN_DETAIL = "app/FETCH_PLAN_DETAIL";
export const SET_PLAN_DETAIL = "app/SET_PLAN_DETAIL";
export const FETCH_WILD_COACHES = "app/FETCH_WILD_COACHES";
export const SET_WILD_COACHES = "app/SET_WILD_COACHES";
export const FETCH_WILD_COACH = "app/FETCH_WILD_COACH";
export const SET_WILD_COACH = "app/SET_WILD_COACH";
export const FETCH_ATHLETE_REPORTS = "app/FETCH_ATHLETE_REPORTS";
export const SET_ATHLETE_REPORTS = "app/SET_ATHLETE_REPORTS";
// Promotion Codes
export const VALIDATE_PROMO_CODE = "app/VALIDATE_PROMO_CODE";
// Get Country Name
export const GET_COUNTRY_NAME = "app/GET_COUNTRY_NAME";

export const initialize = () => ({
  type: INITIALIZE,
  payload: {},
});

export const initialized = (isInitialized) => ({
  type: INITIALIZED,
  payload: {
    isInitialized,
  },
});

export const recaptchaValue = (value, onSuccess) => ({
  type: RECAPTCHA_STATUS,
  payload: { value, onSuccess },
});

export const setRecaptchaStatus = (status) => ({
  type: SET_RECAPTCHA_STATUS,
  payload: {
    status,
  },
});

export const fetchUser = () => ({
  type: FETCH_USER,
});

export const setUser = (user) => ({
  type: SET_USER,
  payload: { user },
});

export const logout = () => ({
  type: LOGOUT_USER,
});

export const setError = (error) => ({
  type: SET_ERROR,
  payload: { error },
});

export const setRedirectTo = (to) => ({
  type: SET_REDIRECT_TO,
  payload: { to },
});

export const setLoggedUser = (user, access, refresh) => ({
  type: SET_LOGGED_USER,
  payload: { user, access, refresh },
});

export const login = (form, success, failure) => ({
  type: LOGIN_MANUAL,
  payload: { form, success, failure },
});

export const googleLogin = (params, success, failure) => ({
  type: LOGIN_GOOGLE,
  payload: { params, success, failure },
});

export const appleLogin = (code, success, failure) => ({
  type: LOGIN_APPLE,
  payload: { code, success, failure },
});

export const loginAuto = (params, onError, redirect, url) => ({
  type: LOGIN_AUTO,
  payload: { params, onError, redirect, url },
});

export const forgotPassword = (email, successMessage, failure) => ({
  type: FORGOT_PASSWORD,
  payload: { email, successMessage, failure },
});

export const createNotification = (type, message, timeout = 6000) => ({
  type: CREATE_NOTIFICATION,
  payload: { type, message, open: true, timeout },
});

export const removeNotification = () => ({
  type: REMOVE_NOTIFICATION,
});


// Tabs fucn
export const fetchProPlans = (onSuccess, onError) => ({
  type: FETCH_PRO_PLANS,
  payload: { onSuccess, onError },
});
export const setProPlans = (payload) => ({
  type: SET_PRO_PLANS,
  payload,
});
export const fetchProPlan = (id, live_status, onSuccess, onError) => ({
  type: FETCH_PLAN_DETAIL,
  payload: { id, live_status, onSuccess, onError },
});
export const setProPlan = (payload) => ({
  type: SET_PLAN_DETAIL,
  payload,
});
export const fetchWildCoaches = (onSuccess, onError) => ({
  type: FETCH_WILD_COACHES,
  payload: { onSuccess, onError },
});
export const setWildCoaches = (payload) => ({
  type: SET_WILD_COACHES,
  payload,
});
export const fetchWildCoach = (id, onSuccess, onError) => ({
  type: FETCH_WILD_COACH,
  payload: { id, onSuccess, onError },
});
export const setWildCoach = (payload) => ({
  type: SET_WILD_COACH,
  payload,
});
export const fetchAthleteReports = (onSuccess, onError) => ({
  type: FETCH_ATHLETE_REPORTS,
  payload: { onSuccess, onError },
});
export const setAthleteReports = (payload) => ({
  type: SET_ATHLETE_REPORTS,
  payload,
});
export const validatePromoCode = (code, onSuccess, onError) => ({
  type: VALIDATE_PROMO_CODE,
  payload: { code, onSuccess, onError },
});

export const getCountryName = (onSuccess, onError) => ({
  type: GET_COUNTRY_NAME,
  payload: { onSuccess, onError },
})