import React, { useState, useEffect } from "react";
import logo from "../../assets/logo-dark.png";

const ReportHeader = (props) => {
    const [headers, setHeaders] = useState(null);
    useEffect(() => {
        if (props.header) {
            setHeaders(props.header || null);
        }
    }, [props]);

    return props.header && props.params && (
        <div className="flex column justifyStart alignStart w-100">
            <div className="flex justifyBetween alignCenter w-100">
                <div className="flex column alignStart">
                    <h1 className="title">{headers && headers.title}</h1>
                    <div className="flex alignCenter justifyStart cycle_date">
                        <p className="website">{headers && headers.cycle_start}</p>
                        <p className="seperator">-</p>
                        <p className="website">{headers && headers.cycle_end}</p>
                    </div>
                </div>

                <img src={logo} alt="wild.ai" height={72} width={64} />
            </div>
            {(props.params['pdf'] !== "true" || props.params['pdf'] === undefined) && (
                <div id="download_pdf_button"
                    onClick={() => props.print()}
                    className="black-button"
                    style={{
                        maxWidth: 180,
                        maxHeight: 40,
                        padding: "6px 0 0",
                        marginTop: 8,
                        cursor: "pointer"
                    }}>
                    <p className="report_download_button_text">
                        Download PDF
                    </p>
                </div>
            )}
        </div>
    );
};

export default ReportHeader;
