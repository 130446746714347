import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// Icons
import StarIcon from "../assets/icons/star-shop.svg";
// Components
import CustomHeader from "../components/common/CustomHeader";
import Footer from "../components/common/Footer";
import Header from "../components/common/Header";
import TabLayout from "../components/common/TabLayout";
// Actions
import { isString } from "lodash";
import { chunk } from "../js/chunks";
import { tabs } from "../js/static";
import { getCountryName } from "../store/app/actions";

const Product = (props) => {
    const { location } = props;
    let { user } = useSelector((state) => state.app);
    let [username, set_username] = useState('');
    let screen_width = window.screen.width;
    let [products_list, setProductsList] = useState(null);
    let [product, setProduct] = useState(null);
    let [items_listing, setItemsListing] = useState(null);
    let [country_name, setCountryName] = useState(null);
    const dispatch = useDispatch();
    let onError = (error) => console.log(error);
    let onSuccess = (response) => setCountryName(response['countryName']);

    const insert = (arr, index, newItem) => [
        ...arr.slice(0, index),
        newItem,
        ...arr.slice(index)
    ]
    console.log(country_name);
    useEffect(() => {
        if (country_name) {
            let { pathname } = location;
            var name = pathname.substring(pathname.lastIndexOf('/') + 1);
            let filter_product = products_list.filter((item) => item['name'] === name && item)[0];
            if (name === "Garmin") {
                if (country_name === "United States" || country_name === "United Kingdom" || country_name === "New Zealand") {
                    const results = insert(filter_product['products_list'], 0, {
                        image_url: "https://wild-blog-strapi.s3.amazonaws.com/Plan_White_Background_106ef109cf.png",
                        name: "",
                    });
                    filter_product['products_list'] = results;
                    setProduct(filter_product);
                    setItemsListing(chunk(filter_product['products_list'], 2));
                } else {
                    setProduct(filter_product);
                    setItemsListing(chunk(filter_product['products_list'], 2));
                }
            } else {
                setProduct(filter_product);
                setItemsListing(chunk(filter_product['products_list'], 2));
            }
        }
    }, [country_name]);


    useEffect(() => {
        if (user && products_list) {
            let { first_name } = user;
            set_username(first_name);
            const { pathname } = location;
            var name = pathname.substring(pathname.lastIndexOf('/') + 1);
            let filter_product = products_list.filter((item) => item['name'] === name && item)[0]
            setProduct(filter_product);
            setItemsListing(chunk(filter_product['products_list'], 2));
            dispatch(getCountryName(onSuccess, onError));
        }
    }, [user, products_list]);

    useEffect(() => {
        window.analytics.page();
        dispatch(getCountryName(onSuccess, onError));
        setProductsList([
            {
                id: 1,
                name: "Garmin",
                front_image_url: "https://wild-blog-strapi.s3.amazonaws.com/Garmin_0d9d72de66.svg",
                white_logo_url: "https://wild-blog-strapi.s3.amazonaws.com/Garmin_Logo_White_fc0387676d.svg",
                black_logo_url: "https://wild-blog-strapi.s3.amazonaws.com/Garmin_Logo_Black_89f0540785.svg",
                title: "Why connect with Garmin?",
                description1: "Garmin was one of our first ever integrations and we can’t recommend them enough! Understanding your body better allows you to be able to use your physiology to your advantage and Garmin has created some amazing wearables to help do just that! At Wild.AI what we love about Garmin watches, is that they are perfect for tracking such a range of activities from strength training, to swimming, rowing, cycling, running and more! Plus with the Wild.AI integration it pulls in the data from your Garmin watch to help deliver more accurate readiness scores, training session, nutrition and recovery recommendations. We are pleased to not only recommend them but have also been able to give a 25% discount off a range of Garmin watches to our community.",
                lifestyle_image_1: "https://wild-blog-strapi.s3.amazonaws.com/garmin_lifestyle_2_46f175dd8c.png",
                lifestyle_image_2: "https://wild-blog-strapi.s3.amazonaws.com/garmin_lifestyle_1_909b1d1803.png",
                discount: 25,
                buy_link: ["https://www.garmin.com/en-US/promos/ghwai4146/", "https://www.garmin.com/en-GB/promos/ht4E9psGB/", "https://www.garmin.com/en-NZ/c/promotions/30508/?cdncache=false"],
                products_list: [
                    {
                        image_url: "https://wild-blog-strapi.s3.amazonaws.com/Garmin_Fenix_6s_0d02f5b2da.png",
                        name: "Fēnix 6s"
                    },
                    {
                        image_url: "https://wild-blog-strapi.s3.amazonaws.com/Garmin_Venu_sq_bc4b3f9109.png",
                        name: "Venu sq."
                    },
                    {
                        image_url: "https://wild-blog-strapi.s3.amazonaws.com/Garmin_Venu_2_series_4ab7d7eb23.png",
                        name: "Venu 2 series"
                    },
                    {
                        image_url: "https://wild-blog-strapi.s3.amazonaws.com/Garmin_Forerunner_45_4d3a5a92cb.png",
                        name: "Forerunner 45"
                    },
                    {
                        image_url: "https://wild-blog-strapi.s3.amazonaws.com/Garmin_Forerunner_245_7f8cc45c1e.png",
                        name: "Forerunner 245"
                    },
                    {
                        image_url: "https://wild-blog-strapi.s3.amazonaws.com/Garmin_Forerunner_745_6f39b9788e.png",
                        name: "Forerunner 745"
                    },
                    {
                        image_url: "https://wild-blog-strapi.s3.amazonaws.com/Garmin_Forerunner_945_77001782b9.png",
                        name: "Forerunner 945"
                    }
                ]
            },
            {
                id: 2,
                name: "Oura",
                front_image_url: "https://wild-blog-strapi.s3.amazonaws.com/Oura_f630718025.svg",
                white_logo_url: "https://wild-blog-strapi.s3.amazonaws.com/Oura_Logo_White_553419b6a3.svg",
                black_logo_url: "https://wild-blog-strapi.s3.amazonaws.com/Oura_Logo_Black_495a23ae06.svg",
                title: "Why connect with Oura?",
                description1: "The Oura Ring is a Wild.AI favorite. What we love about the Oura Ring is its accuracy in tracking sleep habits and heart rate. Sleep is so important as it improves health, mood and energy levels, while heart rate information allows you to get a better understanding of your fitness level, heart health and emotional health. Through its tracking of exercise and with the Wild.AI integration you can pull in the data from Oura to have more accurate readiness scores, training session, nutrition and recovery recommendations. When it comes to helping you understand your patterns to improve your fitness, we love our Oura rings. We truly recommend an Oura Ring if you are looking for a wearable that helps track fitness levels, sleep quality and integrates easily with your Wild.AI app.",
                lifestyle_image_1: "https://wild-blog-strapi.s3.amazonaws.com/oura_lifestyle_2_601b57d077.png",
                lifestyle_image_2: "https://wild-blog-strapi.s3.amazonaws.com/oura_lifestyle_1_1112ae8744.png",
                discount: 0,
                buy_link: "https://ouraring.com/wildai",
                products_list: [
                    {
                        image_url: "https://wild-blog-strapi.s3.amazonaws.com/Oura_Black_e853b9f220.png",
                        name: "Oura Black"
                    },
                    {
                        image_url: "https://wild-blog-strapi.s3.amazonaws.com/Oura_Gold_d24ef5e45c.png",
                        name: "Oura Gold"
                    },
                    {
                        image_url: "https://wild-blog-strapi.s3.amazonaws.com/Oura_Stealth_28f1afcee3.png",
                        name: "Oura Stealth"
                    },
                    {
                        image_url: "https://wild-blog-strapi.s3.amazonaws.com/Oura_Rose_Gold_9e985988ea.png",
                        name: "Oura Rose Gold"
                    },
                    {
                        image_url: "https://wild-blog-strapi.s3.amazonaws.com/Oura_Heritage_31159e1106.png",
                        name: "Oura Heritage"
                    },
                    {
                        image_url: "https://wild-blog-strapi.s3.amazonaws.com/Oura_Silver_8b6997f80c.png",
                        name: "Oura Silver"
                    },
                ],
            },
            // {
            //     id: 3,
            //     name: "Hexxee",
            //     front_image_url: "https://wild-blog-strapi.s3.amazonaws.com/Hexxee_66ef79eddf.svg",
            //     white_logo_url: "https://wild-blog-strapi.s3.amazonaws.com/Hexxee_Logo_White_9bf150f294.svg",
            //     black_logo_url: "https://wild-blog-strapi.s3.amazonaws.com/Hexxee_Logo_Black_200aa71472.svg",
            //     title: "Why HEXXEE?",
            //     description1: "Train Like a Wild Woman!",
            //     description2: "We've teamed up with the funkiest sports sock company They've created DriFIT sport socks- purpose built for training and exercise with a funky twist. Express your personality, showcase your individuality and feel empowered during every workout. With our Wild.AI limited edition socks,celebrate the strength and power of women with all of our icons right on your socks:(.)(.), 🏋️‍♀️ 🔥🏄‍♀️🩸🚣‍♀️ and more.",
            //     lifestyle_image_1: "https://wild-blog-strapi.s3.amazonaws.com/hexxee_lifestyle_1_171cd52ae0.png",
            //     lifestyle_image_2: "https://wild-blog-strapi.s3.amazonaws.com/hexxee_lifestyle_2_edd9787d02.png",
            //     discount: 0,
            //     buy_link: "https://www.hexxee.com/collections/wild-ai",
            //     products_list: [
            //         {
            //             image_url: "https://wild-blog-strapi.s3.amazonaws.com/Hexxee_Wild_AI_Pro_Performance_f35cbc5469.png",
            //             name: "Wild.AI Pro Performance"
            //         },
            //         {
            //             image_url: "https://wild-blog-strapi.s3.amazonaws.com/Hexxee_x_Wild_AI_65b879f219.png",
            //             name: "X Wild.AI"
            //         }
            //     ]
            // },
        ]);
    }, []);

    return (
        <div className="_container">
            {/* header */}
            {screen_width > 992 ?
                <header className='home_background_banner'>
                    <div className='banner_image home_background_banner_image' />
                    <div className='_after'>
                        <Header location={location} />
                        <div className="desktop flex justifyCenter alignCenter w-100 h-100" style={{ margin: '96px 0 0' }}>
                            <h1 style={{ color: '#FFF', fontSize: 72 }}>{`Hi, ${username}`}</h1>
                        </div>
                    </div>
                </header>
                :
                <header className='home_background_banner'>
                    <CustomHeader location={location} />
                    <div className="flex justifyCenter alignCenter w-100 h-100">
                        <h3 style={{ color: '#000', fontSize: 32 }}>{`Hi, ${username}`}</h3>
                    </div>
                </header>
            }

            {/* Content area */}
            <div className="main_content_area flex justifyCenter alignCenter w-100 h-100">
                <div className="content_area flex column justifyStart alignCenter w-100">
                    {/* tabs */}
                    <TabLayout tabs={tabs} index={2} />
                    {/* Product detail */}
                    <div className="flex column justifyCenter alignCenter w-100" style={{ margin: screen_width > 992 ? "32px 0 0" : 0 }}>
                        {(product && screen_width <= 992) ?
                            // Mobile version
                            <div className="flex column justifyCenter alignCenter w-100">
                                <div className="flex column justifyCenter alignCenter w-100 relative">
                                    <img src={product['lifestyle_image_1']} alt={product['name']} height={170} width="100%" />
                                    <img className="absolute" style={{ left: '50%', bottom: -38, transform: 'translate(-50%, 0px)' }} src={product['black_logo_url']} alt={`${product['name']} logo`} height={76} width={220} />
                                </div>

                                <div className="flex column justifyStart alignCenter w-100">
                                    <div className="flex column" style={{ margin: "80px 0 40px", width: '96%' }}>
                                        <p style={{ fontSize: 20 }}>{product['title']}</p>
                                        <p style={{ fontSize: product['id'] === 3 ? 14 : 12, fontWeight: product['id'] === 3 ? 'bold' : 'normal', margin: "16px 0 0" }}>{product['description1']}</p>
                                        {product['description2'] ? <p style={{ fontSize: 12, margin: "4px 0 0" }}>{product['description2']}</p> : null}
                                    </div>
                                    <img src={product['lifestyle_image_2']} alt={product['name']} height="100%" width="100%" />
                                    {Array.isArray(product['buy_link']) ?
                                        <div className="black-button"
                                            onClick={() => {
                                                if (country_name === "United States") {
                                                    window.open(product['buy_link'][0], "_blank")
                                                } else if (country_name === "United Kingdom") {
                                                    window.open(product['buy_link'][1], "_blank")
                                                } else if (country_name === "New Zealand") {
                                                    window.open(product['buy_link'][2], "_blank");
                                                } else {
                                                    window.open(product['buy_link'][0], "_blank")
                                                }
                                            }}
                                            style={{ maxWidth: 440, margin: "44px 0 0" }}>
                                            <p style={{ fontSize: 12, lineHeight: 1.8, textTransform: 'uppercase' }}> Buy now</p>
                                        </div>
                                        : isString(product['buy_link']) ?
                                            <div className="black-button"
                                                onClick={() => window.open(product['buy_link'], "_blank")}
                                                style={{ maxWidth: 440, margin: "44px 0 0" }}>
                                                <p style={{ fontSize: 12, lineHeight: 1.8, textTransform: 'uppercase' }}> Buy now</p>
                                            </div>
                                            : null}
                                </div>
                                <div className="flex column justifyStart alignCenter relative w-100" style={{ margin: "32px 0 0" }}>
                                    {/* Discount Sticker */}
                                    {(product['products_list'].length > 2 && product['name'] === "Garmin" && product['discount'])
                                        ? (country_name === "United States" || country_name === "United Kingdom" || country_name === "New Zealand") ?
                                            <div className="absolute" style={{ top: 20, left: 0, zIndex: 100, transform: 'rotate(25deg)' }}>
                                                <div className="relative">
                                                    <img src={StarIcon} alt="start icon" height={170} width={170} />
                                                    <div className="absolute" style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                                        <p style={{ fontSize: 18, lineHeight: '150%', marginLeft: 12, textAlign: 'center', rotate: '-48deg', }}>{`Get your ${product['discount']}% off`}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            : null
                                        : (product['products_list'].length > 2 && product['name'] !== "Garmin" && product['discount']) ?
                                            <div className="relative">
                                                <img src={StarIcon} alt="start icon" height={160} width={160} />
                                                <div className="absolute" style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                                    <p style={{ fontSize: 16, lineHeight: '150%', marginLeft: 12, textAlign: 'center', rotate: '-30deg', }}>{`Get your ${product['discount']}% off`}</p>
                                                </div>
                                            </div>
                                            : (product['products_list'].length === 2 && product['discount']) ?
                                                <div className="relative">
                                                    <img src={StarIcon} alt="start icon" height={160} width={160} />
                                                    <div className="absolute" style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                                        <p style={{ fontSize: 16, lineHeight: '150%', marginLeft: 12, textAlign: 'center', rotate: '-30deg', }}>{`Get your ${product['discount']}% off`}</p>
                                                    </div>
                                                </div>
                                                : null}

                                    {/* Products listing */}
                                    {items_listing.map((item_list, index) => (
                                        <div key={index + 1} className="flex justifyBetween alignCenter w-100" style={{ margin: "16px 0" }}>
                                            {item_list.map((item, item_index) => (
                                                <div key={item_index + 1} className="flex column alignCenter justifyCenter"
                                                    onClick={() => {
                                                        if (Array.isArray(product['buy_link'])) {
                                                            if (country_name === "United States") {
                                                                window.open(product['buy_link'][0], "_blank")
                                                            } else if (country_name === "United Kingdom") {
                                                                window.open(product['buy_link'][1], "_blank")
                                                            } else if (country_name === "New Zealand") {
                                                                window.open(product['buy_link'][2], "_blank");
                                                            } else {
                                                                window.open(product['buy_link'][0], "_blank")
                                                            }
                                                        } else if (typeof product['buy_link'] === "string") {
                                                            window.open(product['buy_link'], "_blank")
                                                        }
                                                    }}
                                                    style={{ width: 170, cursor: product['buy_link'] ? "pointer" : null }}>
                                                    <img src={item['image_url']} alt={item['name']} height={142} width={142} />
                                                    <p>{item['name']}</p>
                                                </div>
                                            ))}
                                        </div>
                                    ))}

                                </div>
                            </div>
                            : (product && screen_width > 992) ?
                                // Desktop version
                                <div className="flex column justifyCenter alignCenter w-100" style={{ width: 970 }}>
                                    <div className="flex column justifyCenter alignCenter w-100 relative">
                                        <img src={product['lifestyle_image_1']} alt={product['name']} height={375} width={970} style={{ borderRadius: 4 }} />
                                        <div className="absolute" style={{ left: '50%', bottom: -51.75, transform: 'translate(-50%, 0px)' }}>
                                            <img src={product['black_logo_url']} alt={`${product['name']} logo`} height={screen_width <= 992 ? 83 : 103} width={screen_width <= 992 ? 240 : 300} />
                                        </div>
                                    </div>

                                    <div className="flex justifyBetween alignStart w-100" style={{ margin: "96px 0 0" }}>
                                        <div className="flex column justifyStart alignCenter" style={{ minWidth: 440, maxWidth: 440 }}>
                                            <div className="flex column" style={{ margin: "32px 0", width: 440 }}>
                                                <p style={{ fontSize: 22 }}>{product['title']}</p>
                                                <p style={{ fontSize: product['id'] === 3 ? 16 : 14, fontWeight: product['id'] === 3 ? 'bold' : 'normal', margin: "16px 0 0" }}>{product['description1']}</p>
                                                {product['description2'] ? <p style={{ fontSize: 14, margin: "4px 0 0" }}>{product['description2']}</p> : null}
                                            </div>
                                            <img src={product['lifestyle_image_2']} alt={product['name']} height="100%" width="100%" />

                                            {Array.isArray(product['buy_link']) ?
                                                <div className="black-button"
                                                    onClick={() => {
                                                        if (country_name === "United States") {
                                                            window.open(product['buy_link'][0], "_blank")
                                                        } else if (country_name === "United Kingdom") {
                                                            window.open(product['buy_link'][1], "_blank")
                                                        } else if (country_name === "New Zealand") {
                                                            window.open(product['buy_link'][2], "_blank");
                                                        } else {
                                                            window.open(product['buy_link'][0], "_blank")
                                                        }
                                                    }}
                                                    style={{ maxWidth: 440, margin: "44px 0 0" }}>
                                                    <p style={{ fontSize: 12, lineHeight: 1.8, textTransform: 'uppercase' }}> Buy now</p>
                                                </div> :
                                                typeof product['buy_link'] === 'string' ?
                                                    <div className="black-button"
                                                        onClick={() => window.open(product['buy_link'], "_blank")}
                                                        style={{ maxWidth: 440, margin: "44px 0 0" }}>
                                                        <p style={{ fontSize: 12, lineHeight: 1.8, textTransform: 'uppercase' }}> Buy now</p>
                                                    </div>
                                                    : null}
                                        </div>
                                        <div className="flex column justifyStart alignCenter relative" style={{ minWidth: 338, maxWidth: 340 }}>
                                            {/* Discount Sticker */}
                                            {(product['products_list'].length > 2 && product['name'] === "Garmin" && product['discount'])
                                                ? (country_name === "United States" || country_name === "United Kingdom" || country_name === "New Zealand") ?
                                                    <div className="absolute" style={{ top: 0, left: -24, zIndex: 100, transform: 'rotate(25deg)' }}>
                                                        <div className="relative">
                                                            <img src={StarIcon} alt="start icon" height={180} width={180} />
                                                            <div className="absolute" style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                                                <p style={{ fontSize: 22, lineHeight: '140%', rotate: '-48deg', textAlign: 'center' }}>{`Get your ${product['discount']}% off`}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : null
                                                : (product['products_list'].length > 2 && product['name'] !== "Garmin" && product['discount']) ?
                                                    <div className="relative">
                                                        <img src={StarIcon} alt="start icon" height={215} width={215} />
                                                        <div className="absolute" style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                                            <p style={{ fontSize: 26, lineHeight: '140%', rotate: '-48deg', textAlign: 'center' }}>{`Get your ${product['discount']}% off`}</p>
                                                        </div>
                                                    </div>
                                                    : (product['products_list'].length === 2 && product['discount']) ?
                                                        <div className="relative">
                                                            <img src={StarIcon} alt="start icon" height={215} width={215} />
                                                            <div className="absolute" style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                                                <p style={{ fontSize: 26, lineHeight: '140%', rotate: '-30deg', textAlign: 'center' }}>{`Get your ${product['discount']}% off`}</p>
                                                            </div>
                                                        </div>
                                                        : null}

                                            {/* Products listing */}
                                            {items_listing.map((item_list, index) => (
                                                <div key={index + 1} className="flex justifyBetween alignCenter w-100" style={{ margin: "16px 0" }}>
                                                    {item_list.map((item, item_index) => (
                                                        <div key={item_index + 1} className="flex column alignCenter justifyCenter"
                                                            onClick={() => {
                                                                if (Array.isArray(product['buy_link'])) {
                                                                    if (country_name === "United States") {
                                                                        window.open(product['buy_link'][0], "_blank")
                                                                    } else if (country_name === "United Kingdom") {
                                                                        window.open(product['buy_link'][1], "_blank")
                                                                    } else if (country_name === "New Zealand") {
                                                                        window.open(product['buy_link'][2], "_blank");
                                                                    } else {
                                                                        window.open(product['buy_link'][0], "_blank")
                                                                    }
                                                                } else if (typeof product['buy_link'] === "string") {
                                                                    window.open(product['buy_link'], "_blank")
                                                                }
                                                            }}
                                                            style={{ width: 170, cursor: product['buy_link'] ? "pointer" : null }}>
                                                            <img src={item['image_url']} alt={item['name']} height={142} width={142} />
                                                            <p>{item['name']}</p>
                                                        </div>
                                                    ))}
                                                </div>
                                            ))}

                                        </div>
                                    </div>
                                </div>
                                : null}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Product;
