import React, { useState, useEffect } from "react";
import { history } from "../..";

const TabLayout = ({ tabs, index }) => {
    const [tabIndex, setIndex] = useState(0);

    const checkIndex = (activeIndex) => {
        if (tabIndex === activeIndex) {
            return "3px solid #000000";
        }
        return "3px solid rgba(0,0,0,0.4)";
    };

    useEffect(() => {
        setIndex(index);
    }, [tabs]);

    const handleChange = (tab_id) => {
        setIndex(index - 1);
        if (tab_id === 1) {
            history.push('/plans');
        } else if (tab_id === 2) {
            history.push('/coaches');
        } else if (tab_id === 3) {
            history.push('/shop');
        } else if (tab_id === 4) {
            history.push('/reports');
        }
    };

    return (
        <div className="flex justifyCenter alignStart w-100" style={{ margin: "32px 0" }}>
            {tabs &&
                tabs.length > 0 &&
                tabs.map((tab, index) => (
                    <p className="editTab" key={`tab-${index}`}
                        onClick={() => handleChange(tab['id'])}
                        style={{ borderBottom: checkIndex(index) }}
                    >
                        {tab['name']}
                    </p>
                ))}
        </div>
    );
};

export default TabLayout;
