import React from "react";
import InfoIcon from "../../assets/icons/info_report.svg";


const EmptyGraph = (props) => {

    return props.show && (
        <div className="flex column justifyCenter alignCenter absolute h-100 w-100" style={{ top: 0, background: "rgba(255,255,255,0.7)", zIndex: 9999 }}>
            <div className="flex alignCenter justifyCenter" style={{ background: "#E5E5E5", borderRadius: 25, padding: "16px 24px", boxShadow: "0 0 10px rgb(0 0 0 / 25%)" }}>
                <img src={InfoIcon} alt="info_icon" height={24} width={24} style={{ marginRight: 8 }} />
                <p>{props.message}</p>
            </div>
        </div>
    )
}

export default EmptyGraph;