import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { useDispatch, useSelector } from "react-redux";
// Components
import CustomHeader from "../components/common/CustomHeader";
import Footer from "../components/common/Footer";
import Header from "../components/common/Header";
// Common icons
import DownArrowIcon from "../assets/icons/gray-down-arrow.svg";
import UpArrowIcon from "../assets/icons/gray-up-arrow.svg";
import GreenTickIcon from "../assets/icons/green-tick.svg";
// Training icons
import CalendarIcon from "../assets/icons/calendar.svg";
import SportTypeIcon from "../assets/icons/muscle.svg";
import TimerIcon from "../assets/icons/timer-gray.svg";
import TrainingLevelIcon from "../assets/icons/training-level.svg";
import WeightIcon from "../assets/icons/weight.svg";
// Action
import { history } from "../index";
import { getShortDescription } from "../js/chunks";
import { loadAppUrl } from "../js/get-wild";
import { fetchProPlan, setProPlan, validatePromoCode } from "../store/app/actions";

const Plan = (props) => {
    const { location } = props;
    const dispatch = useDispatch();
    let { user, plan } = useSelector((state) => state.app);
    let [username, set_username] = useState('');
    let [error_message, set_error_message] = useState('');
    const [loading, setLoading] = useState(true);
    let [show_preview, set_preview] = useState(true);
    const [left_content_height, set_left_content_height] = useState(0);
    const [scroll_height, set_scroll_height] = useState(-1);
    const [scroll_height_plan, set_scroll_height_plan] = useState(-1);
    const [left_distance, set_left_distance] = useState(0);
    const [position_sticky_top, set_position_sticky_top] = useState(false);
    const [position_sticky_bottom, set_position_sticky_bottom] = useState(false);
    const [isProUser, setProUserStatus] = useState(false);
    let [planId, setPlanId] = useState(null);
    let [sessions_list, setSessionList] = useState([]);
    let [showFullText, setShowFullText] = useState({
        length: 0,
        show_full: false,
        mobile_show_full: false,
    });
    let currency_list = [
        { prefix: "USD", symbol: '$' },
        { prefix: "EUR", symbol: '€' },
        { prefix: "JPY", symbol: '¥' },
        { prefix: "GBP", symbol: '£' },
    ];

    // find currency symbol
    const findSymbol = (name) => {
        const returning_obj = currency_list.find((item) => item['prefix'] === name && item);
        return returning_obj['symbol'];
    }

    useEffect(() => {
        if (plan && Object.keys(plan).length > 0) {
            setFlagsForSessions(plan['plan_details']);
            setShowFullText({
                length: plan['description'].length,
                show_full: plan['description'].length > 200 ? false : true,
            });
        }
    }, [plan]);

    // Set opening and closing flags for sessions list on init
    const setFlagsForSessions = (sessions_list) => {
        if (sessions_list && sessions_list.length > 0) {
            let flagList = sessions_list.map((item, index) => {
                return { ...item, open_list: index === 0 ? true : false }
            });
            setSessionList(flagList);
        }
    }
    // Close session list for specific index
    const closeListForIndex = (closing_index) => {
        let flagList = sessions_list.map((item, index) => {
            return { ...item, open_list: index === closing_index ? false : item['open_list'] }
        });
        setSessionList(flagList);
    }
    // Open session list for specific index
    const openListForIndex = (opening_index) => {
        let flagList = sessions_list.map((item, index) => {
            return { ...item, open_list: index === opening_index ? true : item['open_list'] }
        });
        setSessionList(flagList);
    }
    // set user onload
    useEffect(() => {
        if (user) {
            let { first_name } = user;
            set_username(first_name);
        }
    }, [user]);

    const findSessionsPerWeek = (sessions_list) => sessions_list.length;

    const onSuccess = () => setLoading(false);
    const onSuccessProUser = (response) => setProUserStatus(response['is_user_pro']);
    const onError = (error) => {
        setLoading(false);
        set_error_message(error);
    };

    function getPosition(element) {
        var xPosition = 0;
        var yPosition = 0;

        while (element) {
            xPosition += (element.offsetLeft - element.scrollLeft + element.clientLeft);
            yPosition += (element.offsetTop - element.scrollTop + element.clientTop);
            element = element.offsetParent;
        }

        return { x: xPosition, y: yPosition };
    }

    const handleScroll = () => {
        const position = window.pageYOffset;
        if (document.getElementById('side_content')) {
            let distance = getPosition(document.getElementById('side_content'));
            let height = position;
            set_left_distance(distance['x']);
            set_scroll_height(height);
        }

        if (document.getElementById('plan_content')) {
            const content_height = document.getElementById('plan_content').scrollHeight + 40;
            let height = content_height - position
            set_scroll_height_plan(height > 0 ? height : 0);
        }
    };

    useEffect(() => {
        if ((scroll_height === left_content_height || scroll_height > left_content_height) && scroll_height_plan !== 0) {
            set_position_sticky_top(true);
            set_position_sticky_bottom(false);
        } else if ((scroll_height === left_content_height || scroll_height > left_content_height) && scroll_height_plan === 0) {
            set_position_sticky_top(false);
            set_position_sticky_bottom(true);
        } else {
            set_position_sticky_top(false);
            set_position_sticky_bottom(false);
        }

    }, [scroll_height]);


    useEffect(() => {
        let left_content = getPosition(document.getElementById('side_content'));
        if (left_distance === 0) {
            set_left_content_height(left_content['y']);
        }
    });

    useEffect(() => {
        if (location) {
            window.analytics.page();
            dispatch(setProPlan({}));
            dispatch(validatePromoCode(null, onSuccessProUser, onError));
            const { search } = location;
            const params = JSON.parse('{"' + decodeURI(search).replace(/\?/g, '').replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
            dispatch(fetchProPlan(params['id'], params['live'] || false, onSuccess, onError));
            setPlanId(params['id']);
            window.addEventListener('scroll', handleScroll, { passive: true });
            return () => {
                window.removeEventListener('scroll', handleScroll);
            };
        }
    }, []);

    return (
        <div className="_container">
            {/* Desktop header */}
            <header className='desktop home_background_banner'>
                <div className='banner_image home_background_banner_image' />
                <div className='_after'>
                    <Header location={location} />
                    <div className="desktop flex justifyCenter alignCenter w-100 h-100" style={{ margin: '96px 0 0' }}>
                        <h1 style={{ color: '#FFF', fontSize: 72 }}>{`Hi, ${username}`}</h1>
                    </div>

                    <div className="mobile flex justifyCenter alignCenter w-100 h-100" style={{ margin: '72px 0 0' }}>
                        <h3 className="mobile" style={{ color: '#FFF', fontSize: 32 }}>{`Hi, ${username}`}</h3>
                    </div>
                </div>
            </header>

            {/* Mobile header */}
            <header className='mobile w-100'>
                <CustomHeader location={location} />
            </header>

            <div className="main_content_area flex justifyCenter alignCenter w-100 h-100">
                <div className="content_area flex column justifyStart alignCenter w-100 h-100">
                    {/* loading */}
                    {loading ? (
                        <div className="flex column justifyCenter alignCenter" style={{ margin: '64px 0' }}>
                            <div className="custom_loader" />
                            <p style={{ marginTop: 32 }}>
                                {`Loading, please wait…`}
                            </p>
                        </div>
                    ) : null}

                    {/* if dound error, throw error message */}
                    {error_message !== '' ? (
                        <div className="flex column justifyCenter alignCenter" style={{ margin: '64px 8px', textAlign: 'center' }}>
                            <h2>{`Sorry, we couldn’t find anything`}</h2>
                            <p style={{ margin: "16px 0 0" }}>  Try using another search term or contact support at support@wild.ai.  </p>
                        </div>
                    ) : null}

                    {/* Desktop version */}
                    {plan && Object.keys(plan).length > 0 && (
                        <div className="desktop flex justifyCenter alignStart w-100 h-100 relative" style={{ margin: "32px 0 0" }}>
                            {/* left */}
                            {(position_sticky_top || position_sticky_bottom) ? <div style={{ width: 360 }} /> : null}
                            <div id="side_content"
                                className={`flex column alignStart  ${(position_sticky_top || position_sticky_bottom) ? 'lock_sidebar_at_top_left' : ''}`}
                                style={{
                                    width: 360,
                                    left: (position_sticky_top || position_sticky_bottom) ? left_distance : 0
                                }}>
                                <img src={plan['coach']['profile_img_url']} alt="coach profile" height={140} width={140}
                                    style={{ borderRadius: '50%', border: '1px solid #FFF', padding: 2 }}
                                />
                                <div className="flex column justifyStart alignStart" style={{ margin: "16px 0 0 4px" }}>
                                    <h5>{plan['coach']['name']}</h5>
                                    <p style={{ fontWeight: 600 }}>{plan['coach']['short_title']}</p>
                                    {position_sticky_bottom ?
                                        <p style={{ margin: "16px 0 0", fontSize: 12, lineHeight: '170%' }}>{getShortDescription(plan['coach']['bio'], 120)}</p> :
                                        <p style={{ margin: "16px 0 0", fontSize: 12, lineHeight: '170%' }}>{plan['coach']['bio']}</p>
                                    }
                                </div>
                            </div>

                            {/* border */}
                            <div style={{ width: 2, margin: "0 48px", background: 'rgba(0,0,0, 0.4)', height: '100%' }} />
                            {/* right */}
                            <div id="plan_content" className="flex column alignStart w-100" style={{ maxWidth: 576 }}>
                                {/* Plan name + Author */}
                                <div className="flex column justifyStart w-100">
                                    {plan['user_pricing']['user_bought'] && (
                                        <div className="flex" style={{ marginBottom: 16 }}>
                                            <img src={GreenTickIcon} alt="green_tick_icon" height={24} width={24} />
                                            <p style={{ color: '#000000', marginLeft: 8 }}>Purchased</p>
                                        </div>
                                    )}
                                    <h1>{plan['name']}</h1>
                                    <p style={{ color: '#8F8F8F', margin: "8px 0 0", paddingBottom: 8, width: 360, borderBottom: '1px solid #000' }}>{`by ${plan['author']}`}</p>
                                </div>

                                {/* Icon with labels */}
                                <div className="flex alignCenter justifyBetween w-100" style={{ margin: "24px 0", width: 562 }}>
                                    {/* sport_type */}
                                    <div className="flex column alignCenter" style={{ width: 93 }}>
                                        <img src={SportTypeIcon} alt="sport_type" height={24} width={24} />
                                        <p style={{ color: '#8F8F8F', textAlign: 'center', margin: '8px 0 0', lineHeight: '120%' }}>{plan['sport_type']}</p>
                                    </div>

                                    {/* Place */}
                                    <div className="flex column alignCenter" style={{ width: 49 }}>
                                        <img src={WeightIcon} alt="sport_type" height={24} width={24} />
                                        <p style={{ color: '#8F8F8F', textAlign: 'center', margin: '8px 0 0', lineHeight: '120%' }}>{`Needs\n Gym`}</p>
                                    </div>

                                    {/* Level */}
                                    <div className="flex column alignCenter" style={{ width: 99 }}>
                                        <img src={TrainingLevelIcon} alt="sport_type" height={24} width={24} />
                                        <p style={{ color: '#8F8F8F', textAlign: 'center', margin: '8px 0 0', lineHeight: '120%' }}>{`Level ${plan['level']}`}</p>
                                    </div>

                                    {/* Duration */}
                                    <div className="flex column alignCenter" style={{ width: 62 }}>
                                        <img src={TimerIcon} alt="sport_type" height={24} width={24} />
                                        <p style={{ color: '#8F8F8F', textAlign: 'center', margin: '8px 0 0', lineHeight: '120%' }}>{`Duration ${plan['plan_length']}`}</p>
                                    </div>

                                    {/* Sessions per week */}
                                    <div className="flex column alignCenter" style={{ width: 71 }}>
                                        <img src={CalendarIcon} alt="sport_type" height={24} width={24} />
                                        <p style={{ color: '#8F8F8F', textAlign: 'center', margin: '8px 0 0', lineHeight: '120%' }}>{`${findSessionsPerWeek(plan['plan_details'][0]['sessions'])} Sessions per week`}</p>
                                    </div>

                                </div>

                                {/* Video preview */}
                                {show_preview ? (
                                    <div className="flex justifyCenter alignCenter relative w-100" style={{ height: 331, borderRadius: 12 }}>
                                        <img src={plan['image_url']} alt="plan_image" height={331} width={562} style={{ borderRadius: 12 }} />
                                        <div className="flex column justifyCenter alignCenter h-100 absolute w-100">
                                            <div className="custom_loader" />
                                        </div>
                                    </div>
                                ) : null}

                                {/* Loaded video */}
                                <ReactPlayer
                                    width={562}
                                    height={331}
                                    url={plan['promo_video']}
                                    controls={true}
                                    style={{ display: show_preview ? 'none' : 'block' }}
                                    onLoad={() => set_preview(false)}
                                />

                                {/* Plan description */}
                                {(plan && plan['description'].length <= 200) ?
                                    <p style={{ margin: "24px 0" }}>{plan['description']} </p>
                                    : showFullText['show_full'] ?
                                        <p style={{ margin: "24px 0" }}>{plan['description']}
                                            <span className="view_less_text" onClick={() => setShowFullText({ ...showFullText, show_full: false })}>{`View Less`}</span>
                                        </p>
                                        : <p style={{ margin: "24px 0" }}>{getShortDescription(plan['description'], 150)}
                                            <span className="view_more_text" onClick={() => setShowFullText({ ...showFullText, show_full: true })}>{`Read More`}</span>
                                        </p>}

                                {/*  View in app & Buy Plan buttons */}
                                {plan['user_pricing']['user_bought'] ? (
                                    <div className="flex justifyCenter alignCenter w-100 pointer"
                                        style={{ background: '#E5E5E5', padding: 24, textAlign: 'left', borderRadius: 12 }}
                                    >
                                        <p style={{ width: '89%' }}>You already bought that plan. To view it open the Wild app or download it
                                            <span className="pointer" style={{ textDecoration: 'underline' }} onClick={() => loadAppUrl()}> here</span>
                                        </p>
                                    </div>
                                ) : (
                                    <div className="flex justifyCenter alignCenter w-100 pointer"
                                        onClick={() => window.location.href = plan['user_pricing']['user_buy_url']}
                                        style={{ background: '#000', height: 56, textAlign: 'center', borderRadius: 60 }}
                                    >
                                        {isProUser ? (
                                            <p style={{ color: '#FFF', letterSpacing: 1 }}>
                                                <span style={{ textDecoration: 'line-through' }}> {`${findSymbol(plan['user_pricing']['currency'])}${plan['user_pricing']['price']}`}</span>
                                                <span style={{ margin: "0 8px" }}> {`${findSymbol(plan['user_pricing']['currency'])}${plan['user_pricing']['user_price']}`}</span>
                                                {` BUY PLAN`}
                                            </p>
                                        ) : (
                                            <p style={{ color: '#FFF', letterSpacing: 1 }}>
                                                <span style={{ margin: "0 8px" }}> {`${findSymbol(plan['user_pricing']['currency'])}${plan['user_pricing']['user_price']}`}</span>
                                                {` BUY PLAN`}
                                            </p>
                                        )}
                                    </div>
                                )}

                                {/* sessions list */}
                                {sessions_list && sessions_list.length > 0 && (
                                    <div className="flex column justifyCenter alignCenter w-100" style={{ margin: "24px 0 0" }}>
                                        {sessions_list.map((list_item, index) => (
                                            <div className="flex column justifyStart alignStart w-100" key={index + 1}
                                                style={{ margin: "28px 0 16px" }}>
                                                <div className="flex alignCenter justifyBetween w-100">
                                                    <p style={{ color: '#666668', fontWeight: 700 }}>{list_item['name']}</p>
                                                    {list_item['open_list'] ?
                                                        <img className="pointer" onClick={() => closeListForIndex(index)} src={UpArrowIcon} alt="upward arrow icon" height={28} width={28} />
                                                        :
                                                        <img className="pointer" onClick={() => openListForIndex(index)} src={DownArrowIcon} alt="upward arrow icon" height={28} width={28} />
                                                    }
                                                </div>
                                                {list_item['open_list'] && list_item['sessions'] && list_item['sessions'].length > 0 && list_item['sessions'].map((session, sub_index) => (
                                                    <div className="flex justifyStart alignCenter w-100" key={sub_index + 1}
                                                        style={{ background: '#FFFFFF', borderRadius: 8, padding: "14px 10px", margin: "16px 0 0", boxShadow: '0 0px 2px 0px rgb(0 0 0 / 35%)' }}>
                                                        <img src={session['image_url']} alt="session_image" height={60} width={64} style={{ borderRadius: 6 }} />
                                                        {(index === 0 && sub_index === 0) || (index === 0 && sub_index === 1) ? (
                                                            <div className="flex justifyBetween alignCenter w-100">
                                                                <div className="flex column justifyCenter alignStart" style={{ marginLeft: 16 }}>
                                                                    <p style={{ fontWeight: 500 }}>{session.name}</p>
                                                                    <div className="flex" style={{ marginTop: 3 }}>
                                                                        <p style={{ color: '#B0B0AD' }}>{`${session['session_intensity']} intensity`}</p>
                                                                        <span style={{ margin: "0 8px", color: '#B0B0AD' }}>{`-`}</span>
                                                                        <p style={{ color: '#B0B0AD' }}>{session['duration_minutes'] > 1 ? `${session['duration_minutes']} mins` : `${session['duration_minutes']} min`}</p>
                                                                    </div>
                                                                </div>

                                                                {plan['user_pricing']['user_bought'] ? null :
                                                                    <div className="flex justifyCenter alignCenter pointer"
                                                                        onClick={() => history.push(`/plan/session/${planId}`)}
                                                                        style={{ background: '#000000', borderRadius: 25, textAlign: 'center', height: 44, width: 140 }}>
                                                                        <p style={{ color: '#FFFFFF' }}>Explore for free</p>
                                                                    </div>
                                                                }
                                                            </div>
                                                        ) : (
                                                            <div className={`flex column justifyCenter alignStart w-100  ${!plan['user_pricing']['user_bought'] ? 'pointer' : null}`}
                                                                style={{ marginLeft: 16 }}>
                                                                <p style={{ fontWeight: 500 }}>{session.name}</p>
                                                                <div className="flex" style={{ marginTop: 3 }}>
                                                                    <p style={{ color: '#B0B0AD' }}>{`${session['session_intensity']} intensity`}</p>
                                                                    <span style={{ margin: "0 8px", color: '#B0B0AD' }}>{`-`}</span>
                                                                    <p style={{ color: '#B0B0AD' }}>{session['duration_minutes'] > 1 ? `${session['duration_minutes']} mins` : `${session['duration_minutes']} min`}</p>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    )}

                    {/* Mobile version */}
                    {plan && Object.keys(plan).length > 0 && (
                        <div className="mobile flex column alignStart w-100 relative" style={{ maxWidth: 576 }}>
                            {/* Plan details */}
                            <div className="flex column alignStart w-100" >
                                {/* Purchased status */}
                                {plan['user_pricing']['user_bought'] ? (
                                    <div className="flex" style={{ margin: "24px 0" }}>
                                        <img src={GreenTickIcon} alt="green_tick_icon" height={24} width={24} />
                                        <p style={{ color: '#000000', marginLeft: 8 }}>Purchased</p>
                                    </div>
                                ) : null}
                                {/* Plan name */}
                                <h2 className=" w-100" style={{ textAlign: 'center' }}>{plan['name']}</h2>
                                {/* Author */}
                                <p style={{ color: '#8F8F8F', textAlign: 'center', paddingBottom: 8, margin: "8px auto", width: '90%', borderBottom: '1px solid #000000', }}>{`by ${plan['author']}`}</p>
                                {/* Icon with labels */}
                                <div className="flex alignStart justifyCenter w-100" style={{ margin: "24px 0" }}>
                                    {/* sport_type */}
                                    <div className="flex column alignCenter" style={{ width: 80, }}>
                                        <img src={SportTypeIcon} alt="sport_type" height={24} width={24} />
                                        <p style={{ color: '#8F8F8F', fontSize: 10, textAlign: 'center', margin: '8px 0 0', lineHeight: '120%' }}>{plan['sport_type']}</p>
                                    </div>

                                    {/* Place */}
                                    <div className="flex column alignCenter" style={{ width: 44, margin: "0 8px" }}>
                                        <img src={WeightIcon} alt="sport_type" height={24} width={24} />
                                        <p style={{ color: '#8F8F8F', fontSize: 10, textAlign: 'center', margin: '8px 0 0', lineHeight: '120%' }}>{`Needs\n Gym`}</p>
                                    </div>

                                    {/* Level */}
                                    <div className="flex column alignCenter" style={{ width: 80 }}>
                                        <img src={TrainingLevelIcon} alt="sport_type" height={24} width={24} />
                                        <p style={{ color: '#8F8F8F', fontSize: 10, textAlign: 'center', margin: '8px 0 0', lineHeight: '120%' }}>{`Level ${plan['level']}`}</p>
                                    </div>

                                    {/* Duration */}
                                    <div className="flex column alignCenter" style={{ width: 60, margin: "0 8px" }}>
                                        <img src={TimerIcon} alt="sport_type" height={24} width={24} />
                                        <p style={{ color: '#8F8F8F', fontSize: 10, textAlign: 'center', margin: '8px 0 0', lineHeight: '120%' }}>{`Duration ${plan['plan_length']}`}</p>
                                    </div>

                                    {/* Sessions per week */}
                                    <div className="flex column alignCenter" style={{ width: 60 }}>
                                        <img src={CalendarIcon} alt="sport_type" height={24} width={24} />
                                        <p style={{ color: '#8F8F8F', fontSize: 10, textAlign: 'center', margin: '8px 0 0', lineHeight: '120%' }}>{`${findSessionsPerWeek(plan['plan_details'][0]['sessions'])} Sessions per week`}</p>
                                    </div>

                                </div>

                                {/* Video loading state preview */}
                                {show_preview ? (
                                    <div className="flex justifyCenter alignCenter relative w-100" style={{ height: 220, borderRadius: 12 }}>
                                        <img src={plan['image_url']} alt="plan_image" height={220} width="100%" style={{ borderRadius: 12 }} />
                                        <div className="flex column justifyCenter alignCenter h-100 absolute w-100">
                                            <div className="custom_loader" />
                                        </div>
                                    </div>
                                ) : null}

                                {/* Loaded video */}
                                <div className="w-100" style={{ maxWidth: 576 }}>
                                    <ReactPlayer
                                        width="100%"
                                        height={220}
                                        url={plan['promo_video']}
                                        controls={true}
                                        style={{ display: show_preview ? 'none' : 'block' }}
                                        onReady={() => set_preview(false)}
                                    />
                                </div>

                                {/* Plan description */}
                                {(plan && plan['description'].length <= 250) ?
                                    <p style={{ margin: "24px 0", fontSize: 14 }}>{plan['description']}</p>
                                    : showFullText['mobile_show_full'] ?
                                        <p style={{ margin: "24px 0", fontSize: 14 }}>{plan['description']}
                                            <span className="view_less_text" onClick={() => setShowFullText({ ...showFullText, mobile_show_full: false })}>{`View Less`}</span>
                                        </p> :
                                        <p style={{ margin: "24px 0", fontSize: 14 }}>{getShortDescription(plan['description'], 150)}
                                            <span className="view_more_text" onClick={() => setShowFullText({ ...showFullText, mobile_show_full: true })}>{`Read More`}</span>
                                        </p>}

                                {/* View in app or buy now button */}
                                {plan['user_pricing']['user_bought'] ? (
                                    <div className="pointer"
                                        onClick={() => window.location.href = plan['user_pricing']['app_deeplink']}
                                        style={{ width: '96%', maxWidth: 576, position: 'fixed', zIndex: 100, bottom: 12, background: '#000', padding: "14px 0px", textAlign: 'center', borderRadius: 60, }}>
                                        <p style={{ color: '#FFF', letterSpacing: 1 }}>{`VIEW IN WILD APP`}</p>
                                    </div>
                                ) : (
                                    <div className="pointer"
                                        onClick={() => window.location.href = plan['user_pricing']['user_buy_url']}
                                        style={{ width: '96%', maxWidth: 576, position: 'fixed', bottom: 12, zIndex: 100, background: '#000', padding: "14px 0px", textAlign: 'center', borderRadius: 60, }}>
                                        {isProUser ? (
                                            <p style={{ color: '#FFF', letterSpacing: 1 }}>
                                                <span style={{ textDecoration: 'line-through' }}> {`${findSymbol(plan['user_pricing']['currency'])}${plan['user_pricing']['price']}`}</span>
                                                <span style={{ margin: "0 8px" }}> {`${findSymbol(plan['user_pricing']['currency'])}${plan['user_pricing']['user_price']}`}</span>
                                                {` BUY PLAN`}
                                            </p>
                                        ) : (
                                            <p style={{ color: '#FFF', letterSpacing: 1 }}>
                                                <span style={{ margin: "0 8px" }}> {`${findSymbol(plan['user_pricing']['currency'])}${plan['user_pricing']['user_price']}`}</span>
                                                {` BUY PLAN`}
                                            </p>
                                        )}
                                    </div>
                                )}

                                {/* sessions list */}
                                {plan['plan_details'] && plan['plan_details'].length > 0 && (
                                    <div className="flex column justifyCenter alignCenter w-100">
                                        {plan['plan_details'].map((session_list, index) => (
                                            <div className="flex column justifyStart alignStart w-100" key={index + 1} style={{ margin: "20px 0" }}>
                                                <p style={{ color: '#666668', fontWeight: 700 }}>{session_list.name}</p>
                                                {session_list['sessions'] && session_list['sessions'].length > 0 && session_list['sessions'].map((session, sub_index) => (
                                                    <div className="flex justifyStart alignCenter w-100" key={sub_index + 1}
                                                        style={{ background: '#FFFFFF', borderRadius: 8, padding: "8px 6px", margin: "16px 0 0", boxShadow: '0 0px 2px 0px rgb(0 0 0 / 35%)' }}>

                                                        {(index === 0 && sub_index === 0) || (index === 0 && sub_index === 1) ?
                                                            <img src={session['image_url']} alt="session_image" height={plan['user_pricing']['user_bought'] ? 48 : 68} width={plan['user_pricing']['user_bought'] ? 52 : 56} style={{ borderRadius: 6 }} />
                                                            : <img src={session['image_url']} alt="session_image" height={48} width={52} style={{ borderRadius: 6 }} />
                                                        }
                                                        {(index === 0 && sub_index === 0) || (index === 0 && sub_index === 1) ? (
                                                            <div className="flex justifyBetween alignCenter w-100" style={{ marginLeft: 12 }}>
                                                                <div className="flex column justifyCenter alignStart">
                                                                    <p style={{ fontWeight: 500 }}>{session.name}</p>
                                                                    <div className="flex" style={{ marginTop: 3 }}>
                                                                        <p style={{ color: '#B0B0AD', fontSize: 12 }}>{`${session['session_intensity']} intensity`}</p>
                                                                        <span style={{ margin: "0 4px", color: '#B0B0AD' }}>{`-`}</span>
                                                                        <p style={{ color: '#B0B0AD', fontSize: 12 }}>{session['duration_minutes'] > 1 ? `${session['duration_minutes']} mins` : `${session['duration_minutes']} min`}</p>
                                                                    </div>
                                                                </div>
                                                                {plan['user_pricing']['user_bought'] ? null :
                                                                    <div className="flex justifyCenter alignCenter pointer" onClick={() => history.push(`/plan/session/${planId}`)}
                                                                        style={{ background: '#000000', borderRadius: 25, textAlign: 'center', height: 36, width: 140 }}>
                                                                        <p style={{ color: '#FFFFFF', fontSize: 12 }}>View for free</p>
                                                                    </div>
                                                                }
                                                            </div>
                                                        ) : (
                                                            <div className="flex column justifyCenter alignStart" style={{ marginLeft: 12 }}>
                                                                <p style={{ fontWeight: 500 }}>{session.name}</p>
                                                                <div className="flex" style={{ marginTop: 3 }}>
                                                                    <p style={{ color: '#B0B0AD', fontSize: 12 }}>{`${session['session_intensity']} intensity`}</p>
                                                                    <span style={{ margin: "0 4px", color: '#B0B0AD' }}>{`-`}</span>
                                                                    <p style={{ color: '#B0B0AD', fontSize: 12 }}>{session['duration_minutes'] > 1 ? `${session['duration_minutes']} mins` : `${session['duration_minutes']} min`}</p>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                            {/* Coach details */}
                            < div className="flex column alignStart" style={{ paddingTop: 28, marginTop: 8, borderTop: '2px solid rgba(0,0,0, 0.4)' }}>
                                <div className="flex alignCenter">
                                    {/* Coach image */}
                                    <img src={plan['coach']['profile_img_url']} alt="coach profile" height={72} width={72}
                                        style={{ borderRadius: '50%', border: '1px solid #FFF', padding: 2 }}
                                    />
                                    {/* Coach name + shot title */}
                                    <div className="flex column" style={{ marginLeft: 8 }}>
                                        <h6 style={{ margin: "8px 0 2px" }}>{plan['coach']['name']}</h6>
                                        <p style={{ fontSize: 14 }}>{plan['coach']['short_title']}</p>
                                    </div>
                                </div>
                                {/* Coach bio */}
                                <p style={{ margin: "16px 0 0" }}>{plan['coach']['bio']}</p>
                            </div>
                        </div>
                    )}

                </div>
            </div>

            <Footer />

        </div >
    );
};

export default Plan;
