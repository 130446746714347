import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import BcakArrow from "../../assets/icons/back-arrow-light.svg";
import WildLightLogo from "../../assets/logo-light.png";
import WildDarkLogo from "../../assets/logo-dark.png";
import LogoutIcon from "../../assets/icons/logout.svg";
import MenuIcon from "../../assets/icons/menu.svg";
import CrossIcon from "../../assets/icons/cross-light.svg";
// Actions
import { history } from "../../index";
import { logout } from "../../store/app/actions";
import { useHistory } from 'react-router-dom';


const Header = ({ location }) => {
    const dispatch = useDispatch();
    const [show_overlay, set_overlay] = useState(false);
    const { location: { search } } = useHistory();
    let { user } = useSelector((state) => state.app);
    let [user_detail, set_user_detail] = useState({});
    const [push_location, set_push_location] = useState("/plans");

    useEffect(() => {
        if (user) {
            set_user_detail(user);
        }
    }, [user]);

    useEffect(() => {
        if (search && search !== "") {
            const params = JSON.parse('{"' + decodeURI(search).replace(/\?/g, '').replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
            if (params['coaches']) {
                set_push_location(`/coach?id=${params['coach_id']}`)
            } else {
                set_push_location(`/plans`)
            }
        }
    }, [search]);

    return (
        <div id="header">
            <div className="desktop flex justifyBetween alignCenter w-100">
                {location['pathname'] !== '/login' && (location['pathname'] === '/plan' || location['pathname'] === '/coach' || location['pathname'].startsWith('/plan/session')) ? (
                    <div className="flex justifyStart alignCenter pointer" onClick={() => history.push(push_location)}>
                        <img src={BcakArrow} alt="back arrow icon" height={24} width={24} />
                        <p style={{ marginLeft: 8, color: '#FFF' }}> Back </p>
                    </div>
                ) : location['pathname'].startsWith('/shop/') ? (
                    <div className="flex justifyStart alignCenter pointer" onClick={() => history.goBack()}>
                        <img src={BcakArrow} alt="back arrow icon" height={28} width={28} />
                        <p style={{ marginLeft: 8, color: '#FFF' }}> Back </p>
                    </div>
                ) : <div />
                }
                <a className="desktop" href="https://wild.ai" target="_blank" rel="noreferrer">
                    <img src={WildLightLogo} alt="wild.ai logo" height={106.53} width={92} />
                </a>

                {location['pathname'] !== '/login' ? (
                    <p onClick={() => dispatch(logout())}>Logout</p>
                ) : <div />
                }

            </div>
            <div className="mobile flex justifyBetween alignCenter w-100">
                {location['pathname'] !== '/login' && user_detail && Object.keys(user_detail).length > 0 ? (
                    <img src={user_detail['profile_pic']['profile_img_url']} alt="wild.ai logo" height={44} width={44}
                        style={{ borderRadius: '50%' }}
                    />
                ) : <div />}

                {location['pathname'] !== '/login' && (
                    <a href="https://wild.ai" target="_blank" rel="noreferrer">
                        <img src={WildDarkLogo} alt="wild.ai logo" height={61} width={53.13} />
                    </a>
                )}
                {location['pathname'] === '/login' && (
                    <a href="https://wild.ai" target="_blank" rel="noreferrer" style={{ marginTop: 64 }}>
                        <img src={WildLightLogo} alt="wild.ai logo" height={106.53} width={92} />
                    </a>
                )}

                {location['pathname'] !== '/login' ? (
                    <img
                        width={18}
                        height={18}
                        className="mobile pointer"
                        src={MenuIcon}
                        alt="sign out icon"
                        style={{ width: "auto", cursor: "pointer" }}
                        loading="lazy"
                        onClick={() => set_overlay(!show_overlay)}
                    />
                ) : <div />}
            </div>

            {show_overlay && (
                <div className="dark_bg flex column alignCenter justifyStart"
                    style={{
                        position: 'fixed',
                        top: 0, left: 0,
                        minWidth: '100vw',
                        height: 210,
                        padding: "44px 16px"
                    }}>
                    <div className="flex justifyEnd w-100">
                        <img
                            width={28}
                            height={28}
                            className="mobile pointer"
                            src={CrossIcon}
                            alt="sign out icon"
                            style={{ width: "auto", cursor: "pointer" }}
                            loading="lazy"
                            onClick={() => set_overlay(!show_overlay)}
                        />
                    </div>


                    {/* Logout button */}
                    <div className="flex justifyCenter alignCenter" style={{ marginTop: 32 }}>
                        <div className="flex alignCenter pointer" onClick={() => dispatch(logout())}>
                            <img
                                width={28}
                                height={28}
                                className="mobile"
                                src={LogoutIcon}
                                alt="sign out icon"
                                style={{ width: "auto" }}
                                loading="lazy"
                                onClick={() => set_overlay(!show_overlay)}
                            />
                            <p style={{ color: '#FFF', marginLeft: 12, textTransform: 'uppercase' }}>Logout</p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Header;