import Axios from "axios";
import { push } from "connected-react-router";
import {
  call,
  delay,
  fork,
  put,
  race,
  spawn,
  takeEvery,
} from "redux-saga/effects";
import { history } from "../../index";
import api from "../../services/api/ApiService";
import {
  FETCH_ATHLETE_REPORTS,
  FETCH_PLAN_DETAIL,
  FETCH_PRO_PLANS,
  FETCH_USER,
  FETCH_WILD_COACH,
  FETCH_WILD_COACHES,
  GET_COUNTRY_NAME,
  INITIALIZE,
  LOGIN_APPLE,
  LOGIN_AUTO,
  LOGIN_GOOGLE,
  LOGIN_MANUAL,
  LOGOUT_USER,
  RECAPTCHA_STATUS,
  SET_LOGGED_USER,
  VALIDATE_PROMO_CODE,
  initialized,
  setAthleteReports,
  setProPlan,
  setProPlans,
  setRecaptchaStatus,
  setUser,
  setWildCoach,
  setWildCoaches
} from "./actions";

function getRefreshToken() {
  return String(window.localStorage.getItem("authRefreshToken"));
}

function setRefreshToken(token) {
  window.localStorage.setItem("authRefreshToken", token);
}

function removeRefreshToken() {
  window.localStorage.removeItem("authRefreshToken");
}

function reset() {
  window.localStorage.clear();
}

function refreshToken(token) {
  const resp = api.post("/api/jwt/token/refresh", { refresh: String(token) });
  return resp;
}

function setApiAuth(token) {
  api.defaults.headers.common.Authorization = `JWT ${token}`;
}

function unsetAuth() {
  api.defaults.headers.common.Authorization = null;
}

function retriveMe() {
  const resp = api.get("/user/settings/account");
  return resp;
}

function* getUser() {
  const { data } = yield call(retriveMe);
  yield put(setUser(data));
}

function* logout() {
  yield call(reset);
  yield call(unsetAuth);
  yield put(setUser(null));
  yield put(setRecaptchaStatus(false));
  yield put(push("/login"));
}

function* refreshLoop(refresher) {
  let userSignedOut;
  while (!userSignedOut) {
    const { expired } = yield race({
      expired: delay(3540000), // Wait 59 min before refreshing
    });

    // token expired first
    if (expired) {
      try {
        const {
          data: { access },
        } = yield call(refreshToken, refresher);
        yield call(setApiAuth, access);
      } catch (e) {
        console.log(e);
      }
    }
  }
}

function* setupAuth(access, refresh) {
  setApiAuth(access);
  setRefreshToken(refresh);
  yield spawn(refreshLoop, refresh);
}

function* initializeApp() {
  const token = yield call(getRefreshToken);
  if (token && token !== "null" && token !== "undefined") {
    try {
      const { data: { access } } = yield call(refreshToken, token);
      if (access) {
        yield call(setApiAuth, access);
        yield call(getUser);
        yield fork(refreshLoop, token);
      } else {
        yield call(removeRefreshToken);
      }
    } catch (e) {
      yield call(unsetAuth);
      yield call(removeRefreshToken);
    }
  }

  yield put(initialized(true));
}

function* setLoggedUser(user, access, refresh) {
  yield call(setupAuth, access, refresh);
  yield put(setUser(user));
}

function userProfile() {
  const response = api.get("/user/settings/account");
  return response;
}

function getTokens(username, password) {
  const resp = api.post("/api/jwt/token/auth", { username, password });
  return resp;
}

function* login({ payload: { form, success, failure } }) {
  try {
    let location = window.localStorage.hasOwnProperty("location");
    const { username, password } = form;
    const { data } = yield call(getTokens, username, password);
    const { access, refresh } = data;
    setApiAuth(access);
    const response = yield call(userProfile);
    yield call(setLoggedUser, response.data, access, refresh);
    yield call(success);
    const is_survey_done = window.localStorage.hasOwnProperty("survey");
    if (!is_survey_done) {
      window.localStorage.setItem("survey", false);
    }
    if (location) {
      history.push(`/wildpro/${window.localStorage.getItem('location')}`);
    }
  } catch (err) {
    if (err.response) {
      const { data: { details, detail } } = err.response;
      if (details) {
        yield call(failure, details);
      } else if (detail) {
        yield call(failure, detail);
      }
    }
  }
}


function getResult(params) {
  const resp = api.post("/google/auth", params);
  return resp;
}
function* googleLogin({ payload: { params, success, failure } }) {
  try {
    let location = window.localStorage.hasOwnProperty("location");
    const { data } = yield call(getResult, params);
    const { access_token, refresh_token } = data['jwt_pair'];
    setApiAuth(access_token);
    const response = yield call(userProfile);
    yield call(setLoggedUser, response.data, access_token, refresh_token);
    yield call(success);
    const is_survey_done = window.localStorage.hasOwnProperty("survey");
    if (!is_survey_done) {
      window.localStorage.setItem("survey", false);
    }
    if (location) {
      history.push(`/wildpro/${window.localStorage.getItem('location')}`);
    }
  } catch (err) {
    if (err.response) {
      const {
        data: { details, detail },
      } = err.response;
      if (details) {
        yield call(failure, details);
      } else if (detail) {
        yield call(failure, detail);
      }
    }
  }
}


function getAppleResult(code) {
  const resp = api.put("/apple/auth", {
    auth_code: code,
    redirect: true
  });
  return resp;
}
function* appleLogin({ payload: { code, success, failure } }) {
  try {
    let location = window.localStorage.hasOwnProperty("location");
    const { data } = yield call(getAppleResult, code);
    const { access_token, refresh_token } = data['jwt_pair'];
    setApiAuth(access_token);
    const response = yield call(userProfile);
    yield call(setLoggedUser, response.data, access_token, refresh_token);
    yield call(success);
    const is_survey_done = window.localStorage.hasOwnProperty("survey");
    if (!is_survey_done) {
      window.localStorage.setItem("survey", false);
    }
    if (location) {
      history.push(`/wildpro/${window.localStorage.getItem('location')}`);
    }
  } catch (err) {
    if (err.response) {
      const {
        data: { details, detail },
      } = err.response;
      if (details) {
        yield call(failure, details);
      } else if (detail) {
        yield call(failure, detail);
      }
    }
  }
}

const captchaRequest = async (token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/api/captcha_request`,
    {
      method: "post",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({ recaptcha: token, version: "v3" }),
    }
  );
  if (response.ok) {
    return await response.json();
  }
};

function* recaptcha({ payload: { value, onSuccess } }) {
  if (value !== null) {
    const data = yield call(captchaRequest, value);
    yield call(onSuccess);
    yield put(setRecaptchaStatus(data.success));
  }
}

function* loginAuto({ payload: { params, onError, redirect, url } }) {
  try {
    const { access_token, refresh_token } = params;
    setRefreshToken(refresh_token);
    setApiAuth(access_token);
    const response = yield call(userProfile);
    yield call(setLoggedUser, response.data, access_token, refresh_token);
    if (redirect) {
      history.push(url);
    }
  } catch (err) {
    if (err.response) {
      const { data: { details, detail }, status } = err.response;
      if (status === 403) {
        try {
          const { refresh_token } = params;
          const resp = yield call(refreshToken, refresh_token);
          const { data: { access, refresh }, status } = resp;
          if (status === 200) {
            setRefreshToken(refresh);
            if (access) {
              yield call(setApiAuth, access);
              yield call(getUser);
              yield fork(refreshLoop, refresh);
            }
          }
        } catch (error) {
          const { data: { detail }, status } = error.response;
          if (status === 401) {
            yield call(onError, detail);
          }
        }
      } else {
        if (details) {
          yield call(onError, details);
        } else if (detail) {
          yield call(onError, detail);
        }
      }
    }
  }
}

// Tabs
// plans
const getProPlans = async () => {
  const resp = api.get("/athlete/dashboard/blocks/coach_plans");
  return resp;
};

function* wildProPlans({ payload: { onSuccess, onError } }) {
  try {
    const { data: { coach_plans } } = yield call(getProPlans);
    yield put(setProPlans(coach_plans['data']));
    yield call(onSuccess);
  } catch (err) {
    if (err.response) {
      const { data: { details, detail } } = err.response;
      if (details) {
        yield call(onError, details);
      } else if (detail) {
        yield call(onError, detail);
      }
    }
  }
}
// plan
const getProPlan = async (id, live_status) => {
  let resp;
  if (live_status) {
    resp = api.get(`/training-plan/coach/${id}?live=${live_status}`);
  } else {
    resp = api.get(`/training-plan/coach/${id}`);
  }
  return resp;
};

function* wildProPlan({ payload: { id, live_status, onSuccess, onError } }) {
  try {
    const { data } = yield call(getProPlan, id, live_status);
    yield put(setProPlan(data));
    yield call(onSuccess);
  } catch (err) {
    if (err.response) {
      const { data: { details, detail } } = err.response;
      if (details) {
        yield call(onError, details);
      } else if (detail) {
        yield call(onError, detail);
      }
    }
  }
}
// coaches
const getWildCoaches = async () => {
  const resp = api.get("/athlete/dashboard/blocks/coaches");
  return resp;
};

function* wildCoaches({ payload: { onSuccess, onError } }) {
  try {
    const { data: { coaches } } = yield call(getWildCoaches);
    yield put(setWildCoaches(coaches['data']));
    yield call(onSuccess);
  } catch (err) {
    if (err.response) {
      const { data: { details, detail } } = err.response;
      if (details) {
        yield call(onError, details);
      } else if (detail) {
        yield call(onError, detail);
      }
    }
  }
}

// coaches
const getWildCoach = async (id) => {
  const resp = api.get(`/training-plans/coach/${id}`);
  return resp;
};

function* wildCoach({ payload: { id, onSuccess, onError } }) {
  try {
    const { data } = yield call(getWildCoach, id);
    yield put(setWildCoach(data));
    yield call(onSuccess);
  } catch (err) {
    if (err.response) {
      const { data: { details, detail } } = err.response;
      if (details) {
        yield call(onError, details);
      } else if (detail) {
        yield call(onError, detail);
      }
    }
  }
}
// reports
const getReports = async () => {
  const resp = api.get("/athlete/dashboard/blocks/reports");
  return resp;
};

function* athleteReports({ payload: { onSuccess, onError } }) {
  try {
    const { data: { reports } } = yield call(getReports);
    yield put(setAthleteReports(reports['data']));
    yield call(onSuccess);
  } catch (err) {
    if (err.response) {
      const { data: { details, detail } } = err.response;
      if (details) {
        yield call(onError, details);
      } else if (detail) {
        yield call(onError, detail);
      }
    }
  }
}


// promo code verification
const promoCodeVerification = async (code) => {
  const resp = api.get(`/user/pro_status?promo_code=${code}`);
  return resp;
};
function* validateCode({ payload: { code, onSuccess, onError } }) {
  try {
    const { data } = yield call(promoCodeVerification, code);
    yield call(onSuccess, data);
  } catch (err) {
    if (err.response) {
      const { data: { details, detail } } = err.response;
      if (details) {
        yield call(onError, details);
      } else if (detail) {
        yield call(onError, detail);
      }
    }
  }
}

const getCurrentIPDetails = async () => {
  const resp = Axios.get(`https://api.db-ip.com/v2/free/self`);
  return resp;
};

function* getCountry({ payload: { onSuccess, onError } }) {
  try {
    const { data } = yield call(getCurrentIPDetails);
    yield call(onSuccess, data);
  } catch (err) {
    if (err.response) {
      const { data: { details, detail } } = err.response;
      if (details) {
        yield call(onError, details);
      } else if (detail) {
        yield call(onError, detail);
      }
    }
  }
}

function* appSaga() {
  yield takeEvery(INITIALIZE, initializeApp);
  yield takeEvery(LOGOUT_USER, logout);
  yield takeEvery(FETCH_USER, getUser);
  yield takeEvery(SET_LOGGED_USER, setLoggedUser);
  yield takeEvery(LOGIN_MANUAL, login);
  yield takeEvery(LOGIN_GOOGLE, googleLogin);
  yield takeEvery(LOGIN_APPLE, appleLogin);
  yield takeEvery(LOGIN_AUTO, loginAuto);
  yield takeEvery(RECAPTCHA_STATUS, recaptcha);
  // tabs
  yield takeEvery(FETCH_PRO_PLANS, wildProPlans);
  yield takeEvery(FETCH_WILD_COACHES, wildCoaches);
  yield takeEvery(FETCH_ATHLETE_REPORTS, athleteReports);
  yield takeEvery(FETCH_PLAN_DETAIL, wildProPlan);
  yield takeEvery(FETCH_WILD_COACH, wildCoach);
  yield takeEvery(VALIDATE_PROMO_CODE, validateCode);
  yield takeEvery(GET_COUNTRY_NAME, getCountry);
}

export default appSaga;
