import React, { useState, useEffect } from "react";
import CommonReportHeader from "../../common/CommonReportHeader";
import JoinedDots from "../../../assets/icons/joined_dots.svg";
import Arrows from "../../../assets/icons/gray_ arrows.svg";
import EmptyGraph from "../../common/EmptyGraph";
import { menstrual_cycle_report, contraceptive_group_report, monthly_report } from "../../../js/static_report";
import moment from "moment";

const MorningCheckin = (props) => {
    let [legends, setLegends] = useState(null);
    let [graphs, setGraphs] = useState(null);
    let [content, setContent] = useState(null);
    let [phaseDetails, setPhaseDetails] = useState(null);
    let [axes_difference, set_axes_difference] = useState([]);
    let [axes_base, set_axes_base] = useState([]);
    let [lines_list, set_lines_axes] = useState([]);
    let [graph_widths, set_graph_widths] = useState([]);
    let [line_graph, set_line_graph] = useState([]);
    let [showTooltip, setShowTooltip] = useState(-1);
    let [unitsAndSymbols, setUnitsAndSymbols] = useState([]);
    let [max_days, set_max_days] = useState([]);
    let [show_empty_graph, set_show_empty_graph] = useState(false);

    const reverseyAxesArray = (graphs) => {
        for (let i in graphs) {
            graphs[i].y_axes = graphs[i].y_axes.reverse();
            const values = axes_calculator(graphs, i);
            set_axes_difference(values[0]);
            set_axes_base(values[1]);
        }
        return graphs;
    }

    const axes_calculator = (graphs, i) => {
        axes_difference[i] = graphs[i].y_axes[0] - graphs[i].y_axes[1]
        const length = graphs[i].y_axes.length;
        axes_base[i] = graphs[i].y_axes[length - 1] - (graphs[i].y_axes[0] - graphs[i].y_axes[1]);
        return [axes_difference, axes_base]
    }

    const getDaysDiffForIndexing = (start_date, end_date) => {
        const date1 = new Date(start_date);
        const date2 = new Date(end_date);
        function getDifferenceInDays(date1, date2) {
            const diffInMs = Math.abs(date2 - date1);
            return diffInMs / (1000 * 60 * 60 * 24);
        }
        return getDifferenceInDays(date1, date2) + 1;
    }

    const handleWithPhaseDate = (start_date, end_date, average_graph) => {
        let average_value = 0;
        Object.keys(average_graph).forEach(function (average_key) {
            if (average_key.valueOf() === start_date.valueOf() || average_key.valueOf() === end_date.valueOf()) {
                average_value = average_graph[average_key];
            }
        });
        return average_value;
    }

    let list = [];
    const setPhases = (index, phase_details, average_graph) => {
        var phase_values = [];
        for (var i = 0; i < phase_details.length; i++) {
            phase_values[i] = {
                days: phase_details[i].duration,
                average_value: handleWithPhaseDate(phase_details[i].start_date, phase_details[i].end_date, average_graph),
                start_date: phase_details[i].start_date,
                end_date: phase_details[i].end_date,
                name: phase_details[i].full_name,
                short_name: phase_details[i].short_name,
                value: phase_details[i].stats ? phase_details[i].stats["value"] : null,
                trend: phase_details[i].stats ? phase_details[i].stats["trend"] : null,
                trend_value: phase_details[i].stats ? phase_details[i].stats["trend_value"] : null,
                trend_color: phase_details[i].stats ? phase_details[i].stats["arrow_colour"] : null
            }
        }
        list[index] = phase_values;
        setPhaseDetails(list);
    }

    const calculateBarHeight = (value, base, diff) => {
        let val_diff = 0;
        if (base < 0 || base === 0) {
            if (Number.isInteger(value)) {
                val_diff = Math.round(value / diff) * 40;
            } else {
                val_diff = (value / diff) * 40;
            }
            return val_diff;
        } else {
            val_diff = value - base;
            if (Number.isInteger(value)) {
                val_diff = Math.round(val_diff / diff) * 40;
            } else {
                val_diff = (val_diff / diff) * 40;
            }
            return val_diff;
        }

    }

    const handleWithLineKeys = (start_date, end_date, line_key, index) => {
        let line_values = [];
        start_date = moment(start_date, 'DD-MM-YYYY').format('YYYY-MM-DD');
        end_date = moment(end_date, 'DD-MM-YYYY').format('YYYY-MM-DD');
        // start date check
        if (moment(line_key).isSame(start_date)) {
            line_values.push({
                id: index,
                day: getDaysDiffForIndexing(start_date, line_key),
            });
        }
        // between date check
        if (moment(line_key).isBetween(start_date, end_date)) {
            line_values.push({
                id: index,
                day: getDaysDiffForIndexing(start_date, line_key),
            });
        }
        // end date check
        if (moment(line_key).isSame(end_date)) {
            line_values.push({
                id: index,
                day: getDaysDiffForIndexing(start_date, line_key),
            });
        }
        return line_values;
    }

    const calculatePointDistance = (phaseLength, index, days) => {
        let distance = 0;
        if (index === 0) {
            distance = props.monthly_status ? 34 * days : 28 * days;
        } else if (index === 1) {
            distance = props.monthly_status ? 34 * days : 28 * days + 16;
        } else if (index === 2) {
            distance = props.monthly_status ? 34 * days : 28 * days + 32;
        } else if (index === 3) {
            distance = props.monthly_status ? 34 * days : 28 * days + 48;
        } else if (index === 4) {
            distance = props.monthly_status ? 34 * days : 28 * days + 64;
        }
        return distance;
    }

    const calculateMaxDays = (graph) => {
        let maxDays = 0;
        for (var i = 0; i < graph.phase_details.length; i++) {
            maxDays += graph.phase_details[i].duration;
        }
        return maxDays;
    }
    useEffect(() => {
        if (graphs && graphs.length > 0) {
            let start_date = null;
            let end_date = null;
            for (var g = 0; g < graphs.length; g++) {
                const resp = calculateMaxDays(graphs[g]);
                setPhases(g, graphs[g].phase_details, graphs[g].average_graph);
                unitsAndSymbols[g] = {
                    unit: graphs[g].unit,
                    symbol: graphs[g].symbol
                }
                setUnitsAndSymbols(unitsAndSymbols);
                start_date = moment(graphs[g].phase_details[0].start_date, 'DD-MM-YYYY').format('YYYY-MM-DD');
                const phase_details = graphs[g].phase_details;
                end_date = moment(graphs[g].phase_details[phase_details.length - 1].end_date, 'DD-MM-YYYY').format('YYYY-MM-DD');
                const days = getDaysDiffForIndexing(start_date, end_date);
                graph_widths[g] = days;
                set_graph_widths(graph_widths);
                const values = axes_calculator(graphs, g);
                let difference_between_axes = values[0];
                let base_value_of_the_axes = values[1];

                //  Set Lines Axes
                let linesArr = [];
                // eslint-disable-next-line
                Object.keys(graphs[g].line_graph).map((key, index) => {
                    let days_from_start_date_to_line_date = getDaysDiffForIndexing(start_date, key);
                    let value_of_index_from_phase_against_line_date;
                    let line_value = Object.values(graphs[g].line_graph)[index];
                    for (var i = 0; i < phase_details.length; i++) {
                        const values = handleWithLineKeys(phase_details[i].start_date, phase_details[i].end_date, key, i);
                        if (values && values.length > 0) {
                            value_of_index_from_phase_against_line_date = values[0].id;
                            break;
                        }
                    }

                    return (
                        linesArr[index] = {
                            x: calculatePointDistance(graphs[g].phase_details.length, value_of_index_from_phase_against_line_date, days_from_start_date_to_line_date),
                            y: calculateBarHeight(graphs[g].line_graph[key], base_value_of_the_axes[g], difference_between_axes[g]),
                            line_value,
                            date: key
                        }
                    )
                });

                line_graph[g] = linesArr;
                set_line_graph(line_graph);


                var concatenated_lines = [];
                for (var l = 0; l < linesArr.length - 1; l++) {
                    concatenated_lines[l] = {
                        x1: linesArr[l].x,
                        y1: linesArr[l].y,
                        x2: linesArr[l + 1].x,
                        y2: linesArr[l + 1].y,
                    }
                }

                lines_list[g] = concatenated_lines;
                set_lines_axes(lines_list);

                max_days.push(resp);
                set_max_days(max_days);
            }
        }
    }, [graphs]);

    function createLine(x1, y1, x2, y2) {
        let distance = Math.sqrt((x2 - x1) * (x2 - x1) + (y2 - y1) * (y2 - y1));
        let x_mid = (x1 + x2) / 2;
        let y_mid = (y1 + y2) / 2;
        let angel_in_radian = Math.atan((y2 - y1) / (x2 - x1));
        let angel_in_degree = (angel_in_radian * 180) / Math.PI;
        return [`${distance}px`, `${x_mid - (distance / 2)}px`, `${y_mid}px`, "rotate(" + (-1 * angel_in_degree) + "deg)"]
    }

    const getDates = (startDate, endDate) => {
        var dateArray = [];
        var currentDate = moment(startDate);
        var stopDate = moment(endDate);
        while (currentDate <= stopDate) {
            dateArray.push(moment(currentDate).format('YYYY-MM-DD'))
            currentDate = moment(currentDate).add(1, 'days');
        }
        return dateArray;
    }

    const allEqualToNull = (arr) => arr.every(v => v === true);

    useEffect(() => {
        if (props.morning) {
            let { morning: { title, subtitle, legend, graphs } } = props.morning;
            let is_empty = false;
            let phase_count = 0;
            let graphs_empty_state_collector = [];
            if (graphs && graphs.length > 0) {
                for (let i in graphs) {
                    const line_values = Object.values(graphs[i]['line_graph']);
                    phase_count = graphs[i]['phase_details'].length;
                    if (line_values.length > 0) {
                        graphs_empty_state_collector[i] = false;
                    } else {
                        graphs_empty_state_collector[i] = true;
                    }
                }
            }
            is_empty = allEqualToNull(graphs_empty_state_collector);
            if (is_empty && !props.dashboard) {
                set_show_empty_graph(true);
                if (phase_count > 2) {
                    let { title, subtitle, legend, graphs } = menstrual_cycle_report['morning'];
                    setLegends(legend);
                    setGraphs(reverseyAxesArray(graphs));
                    setContent({
                        title: title || null,
                        subtitle: subtitle || null
                    });
                } else if (phase_count === 2) {
                    let { title, subtitle, legend, graphs } = contraceptive_group_report['morning'];
                    setLegends(legend);
                    setGraphs(reverseyAxesArray(graphs));
                    setContent({
                        title: title || null,
                        subtitle: subtitle || null
                    });
                } else if (phase_count === 1 || phase_count === 0) {
                    let { title, subtitle, legend, graphs } = monthly_report['morning'];
                    setLegends(legend);
                    setGraphs(reverseyAxesArray(graphs));
                    setContent({
                        title: title || null,
                        subtitle: subtitle || null
                    });
                }
            } else if (!is_empty && phase_count === 0 && !props.dashboard) {
                set_show_empty_graph(true);
                let { title, subtitle, legend, graphs } = monthly_report['morning'];
                setLegends(legend);
                setGraphs(reverseyAxesArray(graphs));
                setContent({
                    title: title || null,
                    subtitle: subtitle || null
                });
            } else if (is_empty && props.dashboard) {
                setLegends(legend);
                setGraphs(reverseyAxesArray(graphs));
                setContent({
                    title: title || null,
                    subtitle: subtitle || null
                });
            } else {
                setLegends(legend);
                setGraphs(reverseyAxesArray(graphs));
                setContent({
                    title: title || null,
                    subtitle: subtitle || null
                });
            }
        }
    }, [props.morning]);


    return !props ? (
        <div className="flex column justifyStart alignStart w-100" style={{ margin: "40px 0 0" }}>
            <CommonReportHeader title="Morning Checkin Overview" subtitle={null} />
        </div>
    ) : (
        <div className="flex column justifyStart alignCenter relative w-100" style={{ margin: "40px 0 0" }}>
            <div className="flex justifyBetween alignStart w-100 morning_checkin_header">
                {content && (
                    <div className="flex column justifyStart w-100">
                        <p className="common_label" style={{ margin: 0 }}>{content.title}</p>
                        <p className="common_subtitle_without_underline" style={{ margin: "8px 0 0", padding: 0 }}>
                            {props.dashboard ? content.subtitle.replace(/your/gi, 'her').replace(/you/gi, 'she') : content.subtitle}
                        </p>
                    </div>
                )}

                <EmptyGraph show={show_empty_graph} message="No data to show! Complete your check-ins in Wild AI to see your data." />


                <div className="flex column justifyCenter alignStart morning_header">
                    {legends && legends.map((legend, index) => (
                        <div className="flex justifyBetween alignCenter" key={index + 1} style={{ margin: "8px 0 0" }}>
                            {legend.colour === "arrows" && <img src={Arrows} alt="joined_dots" height={24} />}
                            {legend.colour === "joined_dots" && <img src={JoinedDots} alt="joined_dots" height={24} />}
                            <p className="common_subtitle_without_underline" style={{ marginLeft: 12, padding: 0, fontSize: 14, lineHeight: 1.4 }}>{legend.text}</p>
                        </div>
                    ))}
                </div>
            </div>

            {graphs && graphs.map((graph, index) => (
                <>
                    <div key={index + 1} className="flex column justifyStart alignStart w-100  scrollable" style={{ paddingBottom: 24 }}>
                        <div id={`graph${index}`} className="flex column w-100"
                            style={{
                                position: "relative", margin: "24px 0",
                                minWidth: (props.monthly_status ? max_days[index] * 34 : max_days[index] * 28) + 80,
                            }}>
                            <h2 style={{ borderBottom: "2px solid #000000", marginBottom: 32, paddingBottom: 16 }}>{graph.name}</h2>
                            {/* axes */}
                            {graph.y_axes && graph.y_axes.map((axe, axeIndex) => {
                                return axe !== 0 && (
                                    <div key={axeIndex + 1} className="flex justifyStart alignstart w-100" style={{
                                        borderBottom: "1px solid #737373", borderTop: axeIndex === 0 ? "1px solid #737373" : 0,
                                        minWidth: (props.monthly_status ? max_days[index] * 34 : max_days[index] * 28) + 80,
                                        height: 40
                                    }}>
                                        <p style={{ color: "#737373", fontSize: 12 }}>{`${axe}`}</p>
                                    </div>
                                )
                            })}

                            <div className="flex" style={{
                                height: 240, position: "absolute", bottom: 0, left: 48
                            }}>
                                <div className="flex" style={{ position: "absolute", bottom: 0 }}>
                                    {phaseDetails && phaseDetails[index] && phaseDetails[index].length > 0 && phaseDetails[index].map((bar, bIndex) => {
                                        let average_start_date = moment(bar.start_date, 'DD-MM-YYYY').format('YYYY-MM-DD');
                                        let average_end_date = moment(bar.end_date, 'DD-MM-YYYY').format('YYYY-MM-DD');
                                        var daylist = getDates(average_start_date, average_end_date);
                                        return (
                                            <div key={bIndex + 1} className="flex alignEnd"
                                                style={{ position: "relative", marginLeft: bIndex === 0 ? 0 : 16 }}>
                                                {/* bar */}
                                                <div style={{
                                                    position: "relative", background: "rgba(191, 191, 191, 0.5)",
                                                    width: props.monthly_status ? 34 * bar.days : 28 * bar.days,
                                                    borderTopLeftRadius: 4,
                                                    borderTopRightRadius: 4,
                                                    height: calculateBarHeight(bar.average_value, axes_base[index], axes_difference[index])
                                                }}
                                                />

                                                {phaseDetails[index].length === 1 && (
                                                    <div className="flex " style={{ position: "absolute", bottom: -23, }}>
                                                        {daylist.map((item, itemIndex) => (
                                                            <div className="flex column alignEnd" style={{ width: props.monthly_status ? 34 : 28 }}>
                                                                <div key={itemIndex + 1} style={{ width: 1, height: 8, background: '#737373' }} />
                                                                <p style={{ fontSize: 8, marginTop: 4, }}>{moment(item).format("DD")}</p>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        )
                                    })}
                                </div>


                                {/* Circle */}
                                {line_graph[index] && line_graph[index].length > 0 && (
                                    <div className="flex" style={{ position: "relative" }}>
                                        {line_graph[index].map((line_axes, line_axes_index) => {
                                            return (
                                                <div className="flex alignCenter" key={`circle_${line_axes_index + 1}`}>
                                                    <div
                                                        style={{
                                                            position: "absolute",
                                                            left: line_axes.x - 8,
                                                            zIndex: 100,
                                                            background: "#FFFFFF",
                                                            width: 16,
                                                            height: 16,
                                                            bottom: line_axes.y - 8,
                                                            border: "2px solid #000000",
                                                            borderRadius: "50%"
                                                        }}
                                                        onMouseEnter={() => setShowTooltip(`graph_index_${index}_point_index_${line_axes_index}`)}
                                                        onMouseLeave={() => setShowTooltip(-1)}
                                                    />
                                                    {showTooltip === `graph_index_${index}_point_index_${line_axes_index}` && (
                                                        <div className="tooltip" style={{
                                                            left: line_axes.x - 54,
                                                            background: "#FFFFFF",
                                                            boxShadow: "0 0 2px rgba(0,0,0,0.3)",
                                                            marginBottom: line_axes.y + 24,
                                                            border: "0.5px solid rgba(0,0,0,0.5)",
                                                            width: 132,
                                                            zIndex: 1000
                                                        }}>
                                                            {`${moment(line_axes.date).format("DD MMM")}: ${Number.isInteger(line_axes.line_value) ? line_axes.line_value : line_axes.line_value.toFixed(1)} (${unitsAndSymbols[index].unit})`}
                                                        </div>
                                                    )}
                                                </div>
                                            )
                                        })}
                                    </div>
                                )}

                                {lines_list[index] && lines_list[index].length > 0 && (
                                    <div className="flex" style={{ position: "relative" }}>
                                        {lines_list[index].map((line_axes, line_axes_index) => {
                                            return (
                                                <div key={`line_${line_axes_index + 1}`} id={`graph_${index + 1}_line_${line_axes_index}`}
                                                    style={{
                                                        background: "#000000",
                                                        height: 2,
                                                        position: "absolute",
                                                        width: createLine(line_axes.x1, line_axes.y1, line_axes.x2, line_axes.y2)[0],
                                                        left: createLine(line_axes.x1, line_axes.y1, line_axes.x2, line_axes.y2)[1],
                                                        bottom: createLine(line_axes.x1, line_axes.y1, line_axes.x2, line_axes.y2)[2],
                                                        transform: createLine(line_axes.x1, line_axes.y1, line_axes.x2, line_axes.y2)[3]
                                                    }} />
                                            )
                                        })}
                                    </div>
                                )}

                            </div>
                        </div>


                        <div className="flex justifyStart alignStart w-100">
                            {phaseDetails && phaseDetails[index].length > 1 && phaseDetails[index].map((phase, pIndex) => (
                                <div key={pIndex + 1} className="flex justifyCenter alignCenter flex_wrap"
                                    style={{
                                        marginLeft: pIndex === 0 ? (phaseDetails[index].length === 2 ? 48 : 34) : 16,
                                        minWidth: props.monthly_status ? 34 * phase.days : 28 * phase.days,
                                        marginTop: 16,
                                        lineHeight: 2.5
                                    }}>
                                    <p style={{ color: "#000000", fontSize: 12 }}>{phase.name}</p>

                                    <div className="flex justifyCenter alignCenter">
                                        <h5 style={{ fontSize: 12, marginLeft: 6 }}>{phase.value}</h5>
                                        <div className="flex alignCenter">
                                            {phase.trend === "positive" && (
                                                <>
                                                    <div className="positive_arrow">
                                                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="24" height="24" viewBox="0 0 24 24">
                                                            <path d="M14,20H10V11L6.5,14.5L4.08,12.08L12,4.16L19.92,12.08L17.5,14.5L14,11V20Z" fill={phase.trend_color} />
                                                        </svg>
                                                    </div>
                                                    <p className="trend_value" style={{ marginLeft: 0, fontSize: 12 }}>{`+${phase.trend_value}`}</p>
                                                </>
                                            )}
                                            {phase.trend === "negative" && (
                                                <>
                                                    <div className="negative_arrow">
                                                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="24" height="24" viewBox="0 0 24 24">
                                                            <path d="M10,4H14V13L17.5,9.5L19.92,11.92L12,19.84L4.08,11.92L6.5,9.5L10,13V4Z" fill={phase.trend_color} />
                                                        </svg>
                                                    </div>
                                                    <p className="trend_value" style={{ marginLeft: 0, fontSize: 12 }}>{`${phase.trend_value}`}</p>
                                                </>
                                            )}
                                            {phase.trend === "neutral" && (
                                                <>
                                                    <div style={{ marginLeft: 4, width: 12, height: 2, backgroundColor: "#000000" }} />
                                                    <p className="trend_value" style={{ marginLeft: 0, fontSize: 12 }}>{`${phase.trend_value}`}</p>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </>
            ))
            }
        </div >
    )
}


export default MorningCheckin;