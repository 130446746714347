import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// libraries
import { GoogleReCaptchaProvider, GoogleReCaptcha } from "react-google-recaptcha-v3";
import AppleLogin from 'react-apple-login'
import { GoogleOAuthProvider, useGoogleLogin } from "@react-oauth/google";
// Icons
import AppleIcon from "../assets/icons/apple.svg";
import GoogleIcon from "../assets/icons/google.svg";
import MailIcon from "../assets/icons/mail.svg";
import EyeIcon from "../assets/icons/eye.svg";
import EyeOffIcon from "../assets/icons/eye-off.svg";
import closeIcon from "../assets/icons/cross-dark.svg";
import WildAILogoDark from "../assets/logo-dark.png";
import ForwardArrow from "../assets/icons/forward-arrow-light.svg";
// Components
import Header from "../components/common/Header";
import Footer from "../components/common/Footer";
import Notification from "../components/common/Notification";
// Actions
// Actions
import api from "../services/api/ApiService";
import { login, appleLogin, removeNotification, createNotification, googleLogin } from "../store/app/actions";
import { validateEmail } from "../js/validation";
import { getOS, loadAppUrl } from "../js/get-wild";
import ResetYourPassword from "./ResetPassword";

const SignIn = (props) => {
  const { notification } = useSelector((state) => state.app);
  let [show_manual_login_form, setShowManualLoginForm] = useState(false);
  let [show_signup_dialog, setShowSignupDialog] = useState(false);
  const [values, setValues] = useState({ username: '', password: '' });
  const [show_password, set_to_show_Password] = useState(false);
  const [errors, setErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [verified_captcha, set_verified_captcha] = useState(false);
  const [visible, setVisible] = useState(false);
  const [submitting_apple_auth, set_submitting_apple_auth] = useState(false);
  const [submitting_google_auth, set_submitting_google_auth] = useState(false);

  let screen_width = window.screen.width;
  const dispatch = useDispatch();
  const handleVerify = async (token) => {
    if (token !== null && token !== undefined) {
      if (!verified_captcha) {
        const response = await api.post(`/api/captcha_request`, { recaptcha: token, version: "v3" });
        if (response.status === 200) {
          set_verified_captcha(response.data.success);
        }
      }
    }
  };

  const enableStatus = (values) => {
    let status = false;
    if (values['username'] !== '' && values['password'] !== '') {
      status = true;
    }
    return status;
  }

  const getQRLink = () => {
    let OS = getOS();
    if (OS === "Windows" || OS === "Android" || OS === "Linux") {
      return "Play Store";
    } else if (OS === "Mac OS" || OS === "iOS") {
      return "App Store";
    }
  }

  const validateInputs = (e) => {
    if (e.target.value === "")
      setErrors({ ...errors, [e.target.name]: true });
    else setErrors({ ...errors, [e.target.name]: false });
  };

  const onError = (error) => {
    setSubmitting(false);
    dispatch(createNotification("error-toast", error));
  };

  const onSuccess = () => {
    setSubmitting(false);
    setValues({ username: '', password: '' });
  };

  const onAuthFailed = (error) => {
    dispatch(createNotification("error-toast", error));
    set_submitting_apple_auth(false);
    set_submitting_google_auth(false);
  }
  const onAuthSuccess = () => {
    set_submitting_apple_auth(false);
    set_submitting_google_auth(false);
  }

  const onGoogleError = (error) => dispatch(createNotification("error-toast", error));
  const onGoogleSuccess = async (response) => {
    set_submitting_google_auth(true);
    const { access_token } = response;
    const values = {
      access_token,
      // id_token: 'abcdefghijklmnopqrstuvwxyz0123456789'
    }
    dispatch(googleLogin(values, onAuthSuccess, onAuthFailed))
  };

  const GoogleButton = ({ onSuccess, onError }) => {
    const login = useGoogleLogin({
      flow: 'implicit', ux_mode: 'popup', onSuccess, onError, onNonOAuthError: onError,
      scope: [
        'https://www.googleapis.com/auth/userinfo.profile',
        'https://www.googleapis.com/auth/userinfo.email',
      ].join(' '),
    });

    const handleClick = () => login();

    return (!verified_captcha ?
      <div className='flex alignCenter justfyCenter login_round_box disabled_light_bg'>
        <img src={GoogleIcon} alt="google_icon" height={28} width={28} />
        <p style={{ cursor: 'not-allowed' }}>Sign in with Google</p>
      </div> :
      <div onClick={handleClick} className='flex alignCenter justfyCenter login_round_box light_bg'>
        {submitting_google_auth ? <div className="login_card_loader" /> : <img src={GoogleIcon} alt="google_icon" height={28} width={28} />}
        <p>{submitting_google_auth ? 'Processing ...' : 'Sign in with Google'}</p>
      </div>
    );
  };

  const onAppleCallback = (response) => {
    set_submitting_apple_auth(true);
    const { code } = response;
    dispatch(appleLogin(code, onAuthSuccess, onAuthFailed))
  };

  const onFinish = async () => {
    setSubmitting(true);
    dispatch(login(values, onSuccess, onError));
  };

  const onFinishForgetFailed = (error) => {
    setVisible(false);
    dispatch(createNotification("error-toast", error));
  };

  const onFinishForgetSuccess = (email) => {
    setVisible(false);
    dispatch(createNotification("success-toast", `An email with instructions to reset your password has been sent to ${email}`));
  }

  let location = { pathname: '/login' }

  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}>
      <GoogleReCaptcha onVerify={handleVerify} />
      <div className="_container">
        <header className='background_banner' style={{ minHeight: show_signup_dialog ? 992 : screen_width <= 768 ? '120vh' : '100vh' }}>
          <video autoPlay loop muted preload="auto" poster="https://media.wild.ai/assets/images/landing-video-thumbnail.png">
            <source src="https://media.wild.ai/assets/videos/wild_teaser.mp4" type="video/mp4" />
          </video>
          <div className='_after'>
            <Header location={location} />
            <div className="flex justifyCenter alignCenter w-100 h-100">
              <div className="flex column justifyCenter alignCenter social_login_container_size">
                {/* Apple Signin Button */}
                {(!show_manual_login_form && !show_signup_dialog) &&
                  <AppleLogin
                    render={renderProps => {
                      return !verified_captcha ? (
                        <div className='flex alignCenter justfyCenter login_round_box disabled_light_bg'>
                          <img src={AppleIcon} alt="google_icon" height={32} width={28} />
                          <p style={{ cursor: 'not-allowed' }}>Sign in with Apple</p>
                        </div>
                      ) : (
                        <div onClick={renderProps.onClick} className='flex alignCenter justfyCenter login_round_box dark_bg'>
                          {submitting_apple_auth ? <div className="login_card_loader" /> : <img src={AppleIcon} alt="google_icon" height={32} width={28} />}
                          <p>{submitting_apple_auth ? 'Processing ...' : 'Sign in with Apple'}</p>
                        </div>
                      )
                    }}
                    scope='email name'
                    callback={onAppleCallback}
                    clientId={`${process.env.REACT_APP_APPLE_OAUTH2_CLIENT_ID}`}
                    redirectURI={`${process.env.REACT_APP_SITE_URL}/login`}
                  />
                }

                {/* Google Signin Button */}
                {(!show_manual_login_form && !show_signup_dialog) &&
                  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID} onScriptLoadError={onGoogleError}>
                    <GoogleButton onSuccess={onGoogleSuccess} onError={onGoogleError} />
                  </GoogleOAuthProvider>
                }

                {/* Manual Signin Button */}
                {(!show_manual_login_form && !show_signup_dialog) &&
                  <div style={{ cursor: verified_captcha ? 'pointer' : 'not-allowed' }} onClick={() => verified_captcha ? setShowManualLoginForm(true) : null}
                    className={verified_captcha ? 'flex alignCenter justfyCenter login_round_box light_bg' : 'flex alignCenter justfyCenter login_round_box disabled_light_bg'}>
                    <img src={MailIcon} alt="mailbox_icon" height={28} width={28} />
                    <p style={{ cursor: verified_captcha ? 'pointer' : 'not-allowed' }}>Sign in with Email</p>
                  </div>
                }

                {(!show_manual_login_form && !show_signup_dialog) &&
                  <div onClick={() => setShowSignupDialog(true)}
                    style={{ border: '1px solid #FFF', borderRadius: 60, height: 54, color: '#FFF', width: 260, marginTop: 32, cursor: "pointer" }}
                    className='flex alignCenter justfyCenter'>
                    <p style={{ textAlign: 'center', width: '100%', textTransform: 'uppercase', letterSpacing: 1 }}>Create Account</p>
                  </div>
                }

                {(show_manual_login_form && !show_signup_dialog) && (
                  <div className="flex column justifyCenter alignCenter form_container login_form_container relative light_bg">
                    <div className="flex justifyEnd w-100" style={{ margin: "16px 32px 0 0" }}>
                      <img className="pointer" onClick={() => setShowManualLoginForm(false)} src={closeIcon} alt="close icon" height={20} width={20} />
                    </div>


                    <div className="input_form">
                      <h3 style={{ textAlign: 'center' }}>Log In</h3>
                      {/* email address */}
                      <div className="flex column justifyStart" style={{ height: 85 }}>
                        <div className="settings-form-item border-bottom">
                          <input
                            required
                            type="email"
                            name="email_address"
                            placeholder="Email address"
                            className="settings-value"
                            onBlur={validateInputs}
                            onKeyUp={(event) => {
                              if (event.keyCode === 13) {
                                setValues({ ...values, username: event.target.value.trim() })
                                onFinish();
                              }
                            }}
                            onChange={(event) => {
                              if (event.target.value.length > 0) {
                                setValues({ ...values, username: event.target.value.trim() })
                              } else {
                                setValues({ ...values, username: "" })
                              }
                            }}
                          />
                        </div>
                        {errors.email_address && !validateEmail(values['username']) && (<span style={{ color: '#EB5757', marginTop: 8, fontSize: 12 }}>Please enter valid email address.</span>)}
                      </div>
                      {/* password */}
                      <div className="flex column justifyStart" style={{ height: 85 }}>
                        <div className="settings-form-item border-bottom">
                          <input
                            required
                            name="password"
                            type={show_password ? "text" : "password"}
                            placeholder="Password"
                            className="settings-value password"
                            onBlur={validateInputs}
                            onKeyUp={(event) => {
                              if (event.keyCode === 13) {
                                setValues({ ...values, password: event.target.value.trim() })
                                onFinish();
                              }
                            }}
                            onChange={(event) => {
                              if (event.target.value.length > 0) {
                                setValues({ ...values, password: event.target.value.trim() })
                              } else {
                                setValues({ ...values, password: "" })
                              }
                            }}
                          />
                          {show_password ? (
                            <img className="absolute" src={EyeOffIcon} alt="eye_icon" height={18} width={18}
                              onClick={() => set_to_show_Password(!show_password)}
                              style={{ right: 0, bottom: 12 }}
                            />
                          ) : (
                            <img className="absolute" src={EyeIcon} alt="eye_icon" height={18} width={18}
                              onClick={() => set_to_show_Password(!show_password)}
                              style={{ right: 0, bottom: 12 }}
                            />
                          )}
                        </div>
                        {errors.password && (<span style={{ color: '#EB5757', marginTop: 8, fontSize: 12 }}>Please should be of minimum 6 characters.</span>)}
                      </div>

                      <p className="pointer" onClick={() => {
                        setShowManualLoginForm(false);
                        setVisible(true)
                      }}
                        style={{ fontWeight: 600, width: "max-content", textDecoration: 'underline' }}>
                        Forgot password?
                      </p>
                      {/* submit button */}
                      <div onClick={() => enableStatus(values) ? onFinish() : false}
                        className={enableStatus(values) ? "black-button" : "black-button-disable"}
                        style={{ marginTop: 24 }}>
                        {submitting ? (
                          <div className="flex justifyCenter alignCenter">
                            <div className="custom_button_loader" />
                          </div>
                        ) : (
                          <p
                            style={{
                              margin: 0,
                              color: "#ffffff",
                              fontSize: 12,
                              maxWidth: 320,
                              textTransform: 'uppercase',
                              lineHeight: "120%",
                              cursor: 'pointer'
                            }}
                          >
                            Sign In With Email
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {(!show_manual_login_form && show_signup_dialog) && (
                  <div className="flex column justifyCenter alignCenter w-100">
                    <div className="flex column justifyCenter alignCenter w-100 desktop"
                      style={{ background: "#fff", width: 468, padding: "16px 24px 32px", borderRadius: 8, margin: "0 auto" }}>

                      <div className="flex justifyEnd w-100" style={{ margin: "16px 32px 0 0" }}>
                        <img className="pointer" onClick={() => setShowSignupDialog(false)} src={closeIcon} alt="close icon" height={20} width={20} />
                      </div>
                      <img src={WildAILogoDark} alt="Wild.AI logo" height={106.53} width={92} />
                      <p style={{ fontSize: 27 }}>Wild.AI</p>
                      <p style={{ fontSize: 27, margin: '40px 0 8px', width: 300, textAlign: 'center', lineHeight: '140%' }}>{`You will be redirected to your ${getQRLink()}`}</p>
                      <p style={{ fontSize: 17, textAlign: 'center', width: 337 }}>Please come back here and sign in once you finish your onboarding.
                        We’ll wait here with your discount code! </p>
                      <div onClick={() => loadAppUrl()} id="continue_to_dashboard_button" className="black-button" style={{ maxWidth: 320, margin: "32px 0 0" }} >
                        <img src={ForwardArrow} alt="Wild.AI logo" height={24} width={24} style={{ marginRight: 24 }} />
                        <p>   Download Wild.AI </p>
                      </div>
                      <p className="flex justifyCenter alignCenter w-100 pointer"
                        onClick={() => {
                          setShowSignupDialog(false);
                          setShowManualLoginForm(true)
                        }}
                        style={{ textTransform: 'uppercase', margin: "24px 0 16px", fontWeight: 600 }}>Sign In</p>
                    </div>

                    <div className="flex column justifyCenter alignCenter w-100 mobile" style={{ background: "#fff", width: '96%', padding: "16px 24px 32px", borderRadius: 8, margin: "0 auto" }}>
                      <div className="flex justifyEnd w-100" style={{ margin: "16px 32px 0 0" }}>
                        <img className="pointer" onClick={() => setShowSignupDialog(false)} src={closeIcon} alt="close icon" height={20} width={20} />
                      </div>
                      <img src={WildAILogoDark} alt="Wild.AI logo" height={106.53} width={92} />
                      <p style={{ fontSize: 27 }}>Wild.AI</p>
                      <p style={{ fontSize: 27, margin: '40px 0 8px', width: 300, textAlign: 'center', lineHeight: '140%' }}>{`You will be redirected to your ${getQRLink()}`}</p>
                      <p style={{ fontSize: 17, textAlign: 'center', width: '94%' }}>We’ll do our best to keep your discount safe to be used after your onboarding; but keep your code handy, just in case. </p>
                      <div onClick={() => loadAppUrl()} id="continue_to_dashboard_button" className="black-button" style={{ maxWidth: 320, margin: "32px 0 0" }} >
                        <img src={ForwardArrow} alt="Wild.AI logo" height={24} width={24} style={{ marginRight: 24 }} />
                        <p>   Download Wild.AI </p>
                      </div>
                      <p className="flex justifyCenter alignCenter w-100 pointer"
                        onClick={() => {
                          setShowSignupDialog(false);
                          setShowManualLoginForm(true)
                        }}
                        style={{ textTransform: 'uppercase', margin: "24px 0 16px", fontWeight: 600 }}>Sign In</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </header>
        <Footer />
        <Notification {...notification} onClose={() => dispatch(removeNotification())} />
      </div >

      <ResetYourPassword
        show={visible}
        onCancel={() => setVisible(false)}
        onError={(message) => onFinishForgetFailed(message)}
        onSuccess={(message) => onFinishForgetSuccess(message)}
      />
    </GoogleReCaptchaProvider >
  );
};

export default SignIn;
