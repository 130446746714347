export const chunk = (myArray, chunk_size) => {
    var index = 0;
    var arrayLength = myArray.length;
    var tempArray = [];
    for (index = 0; index < arrayLength; index += chunk_size) {
        const myChunk = myArray.slice(index, index + chunk_size);
        tempArray.push(myChunk);
    }
    return tempArray;
};

export const getShortDescription = (desc, max_limit) => {
    if (!desc) {
        return "";
    } else {
        if (desc.length <= max_limit) {
            return desc;
        } else if (desc.length > max_limit) {
            return desc.slice(0, max_limit) + "... ";
        }
    }
};