import React, { useState, useEffect } from "react";
import CommonReportHeader from "../../common/CommonReportHeader";
import GraphLegends from "../../common/GraphLegends";
import ArrowDown from "../../../assets/icons/arrow-down.svg";
import ArrowUp from "../../../assets/icons/arrow-up.svg";
import LinePattern from "../../../assets/icons/line-pattern.svg";
import EmptyGraph from "../../common/EmptyGraph";
import { menstrual_cycle_report, contraceptive_group_report } from "../../../js/static_report";


const CycleCheckin = (props) => {
    let [cycleData, setCycleData] = useState(null);
    let [bullets, setBullets] = useState(null);
    let [legends, setLegends] = useState(null);
    let [cycleLengths, setCycleLengths] = useState(null);
    let [content, setContent] = useState(null);
    let [cycle_data_length, set_cycle_data_length] = useState(0);
    let [max_days, set_max_days] = useState(0);
    let [show_empty_graph, set_show_empty_graph] = useState(false);

    useEffect(() => {
        if (props.checkins) {
            let { cycle: { bullets, cycles_data, legend, cycle_lengths, title, subtitle } } = props.checkins;
            if (cycles_data && cycles_data.length > 0) {
                let cycle_data_length = cycles_data.length;
                let is_white = false;
                for (let i in cycles_data) {
                    if (cycles_data[i].data && cycles_data[i].data.length > 0) {
                        let data_list = Object.values(cycles_data[i].data);
                        for (let j in data_list) {
                            let color_list = Object.values(data_list[j].data);
                            if (color_list && color_list.length > 0) {
                                for (let k in color_list) {
                                    if (color_list[k] === "#FFFFFF") {
                                        is_white = true;
                                    }
                                }
                            }
                        }
                    }
                }

                if (is_white && !props.dashboard) {
                    if (cycle_data_length > 2) {
                        set_show_empty_graph(true);
                        let { bullets, cycles_data, legend, cycle_lengths, title, subtitle } = menstrual_cycle_report['cycle'];
                        setBullets(bullets);
                        setCycleData(cycles_data);
                        set_cycle_data_length(cycles_data.length);
                        setLegends(legend);
                        setCycleLengths(covertDictionaryIntoArray(cycle_lengths));
                        setContent({
                            title: title || null,
                            subtitle: subtitle || null
                        });
                    } else if (cycle_data_length === 2) {
                        set_show_empty_graph(true);
                        let { bullets, cycles_data, legend, cycle_lengths, title, subtitle } = contraceptive_group_report['cycle'];
                        setBullets(bullets);
                        setCycleData(cycles_data);
                        set_cycle_data_length(cycles_data.length);
                        setLegends(legend);
                        setCycleLengths(covertDictionaryIntoArray(cycle_lengths));
                        setContent({
                            title: title || null,
                            subtitle: subtitle || null
                        });
                    }
                } else if (is_white && props.dashboard) {
                    setBullets(bullets);
                    setCycleData(cycles_data);
                    set_cycle_data_length(cycles_data.length);
                    setLegends(legend);
                    setCycleLengths(covertDictionaryIntoArray(cycle_lengths));
                    setContent({
                        title: title || null,
                        subtitle: subtitle || null
                    });
                } else {
                    setBullets(bullets);
                    setCycleData(cycles_data);
                    set_cycle_data_length(cycles_data.length);
                    setLegends(legend);
                    setCycleLengths(covertDictionaryIntoArray(cycle_lengths));
                    setContent({
                        title: title || null,
                        subtitle: subtitle || null
                    });
                }
            }
        }
    }, [props.checkins]);

    const covertCycleDataDictionaryIntoArray = (dictionary, maxDays) => {
        let array = [];
        if (Object.keys(dictionary).length > 0) {
            for (var i = 1; i <= maxDays; i++) {
                array[i - 1] = dictionary[i] || "#ffffff";
            }
        } else {
            for (var j = 1; j <= maxDays; j++) {
                array[j - 1] = "#ffffff";
            }
        }
        return array;
    }

    const covertDictionaryIntoArray = (dictionary) => {
        let array = [];
        if (Object.keys(dictionary).length > 0) {
            for (let k in dictionary) {
                array.push({
                    key: k,
                    trend: dictionary[k].trend,
                    trend_value: dictionary[k].trend_value,
                    value: dictionary[k].value
                });
            }
        }
        return array;
    }

    const calculateMaxDays = (cycleData) => {
        let maxDays = 0;
        for (var i = 0; i < cycleData.length; i++) {
            maxDays += cycleData[i].max_days;
        }
        return maxDays;
    }

    useEffect(() => {
        if (cycleData) {
            const resp = calculateMaxDays(cycleData);
            set_max_days(resp);
        }
    }, [cycleData]);

    const calcHeightWidth = () => {
        let boxes_width = document.getElementById('rectangles_container');
        if (boxes_width) {
            boxes_width = boxes_width.offsetWidth;
            const calc = (boxes_width - (16 * cycle_data_length)) / max_days;
            return calc;
        }
    }

    return !props ? (
        <div className="flex column justifyStart alignStart w-100" style={{ margin: "40px 0 0" }}>
            <CommonReportHeader title="Cycle Check-in Overview" subtitle={null} />
        </div>
    ) : cycle_data_length > 0 && legends && (
        <div className="flex column justifyStart alignStart w-100" style={{ margin: "40px 0 0" }}>
            {content && (<CommonReportHeader header={props.headers || null} title={content.title} subtitle={content.subtitle} replaceStatus={props.dashboard} isSubtitleUnder={false} />)}

            <EmptyGraph show={show_empty_graph} message="No data to show! Complete your check-ins in Wild AI to see your data." />

            <div className="cycle_bullets_card">
                {bullets && bullets.map((item, bIndex) => (
                    <div key={bIndex + 1} className="flex column justifyStart alignStart bullet_container">
                        <div className="flex justifyStart alignCenter" >
                            <h3 className="cycle_days">{item.value} </h3>
                            {item.trend === "positive" && (
                                <>
                                    <img src={ArrowUp} alt="arrow-up" height={20} width={16} style={{ marginLeft: 8 }} />
                                    <p className="trend_value">{`+${item.trend_value}`}</p>
                                </>
                            )}
                            {item.trend === "negative" && (
                                <>
                                    <img src={ArrowDown} alt="arrow-down" height={20} width={16} style={{ marginLeft: 8 }} />
                                    <p className="trend_value">{`${item.trend_value}`}</p>
                                </>
                            )}
                            {item.trend === "neutral" && (
                                <>
                                    <div style={{ marginLeft: 8, width: 16, height: 2, backgroundColor: "#000000" }} />
                                    <p className="trend_value">{`${item.trend_value}`}</p>
                                </>
                            )}
                        </div>
                        <p className="bullet">
                            {props.dashboard ? item.text.replace(/your/gi, 'her').replace(/you/gi, 'she') : item.text}
                        </p>
                    </div>
                ))}
            </div>

            <div className="flex column justifyStart alignStart w-100 scrollable" style={{ paddingBottom: 24 }}>
                <div className="flex justifyStart alignStart w-100">
                    {/* Phases names */}
                    <div className="flex column" style={{ minWidth: 68, maxWidth: 68 }}>
                        <p style={{ fontSize: 12, marginBottom: 8 }}>{`Phase`}</p>
                        {cycleLengths && cycleLengths.map((length, clIndex) => (
                            <div key={`cycle_length_${clIndex + 1}`}
                                className="flex justifyStart alignCenter"
                                style={{ height: calcHeightWidth() - 3, margin: "4px 0" }} >
                                <p style={{ fontSize: 12, fontWeight: 700 }}>{length.key}</p>
                            </div>
                        ))}
                    </div>

                    {/* Cycle Charts */}
                    <div className="flex justifyStart alignStart" id="rectangles_container" style={{ width: 'calc(100% - 136px)' }}>
                        {cycleData && cycleData.map((cycle, cIndex) => (
                            <div key={`cycle_data${cIndex + 1}`} className="flex column" style={{ marginLeft: 16 }}>
                                {/* phase names */}
                                <p style={{ marginLeft: 2, marginBottom: 8, fontSize: 11, fontWeight: 700, }}>{cycle.phase} </p>

                                {/* phases data */}
                                <div className="flex column justifyStart">
                                    {cycle["data"] && cycle["data"].map((cycleDetail, subIndex) => {
                                        return (
                                            <div key={`cycle_detail_${subIndex + 1}`} className="flex justifyBetween">
                                                {covertCycleDataDictionaryIntoArray(cycleDetail["data"], cycle["max_days"]).map((color, colorIndex) => (
                                                    <>
                                                        {color.toLowerCase() === "#ffffff" ? (
                                                            <img key={colorIndex + 1} src={LinePattern} alt="line-pattern" style={{
                                                                margin: "4px 1.5px",
                                                                height: calcHeightWidth() - 3,
                                                                width: calcHeightWidth() - 3,
                                                                borderRadius: 1.6
                                                            }} />
                                                        ) : (
                                                            <div key={colorIndex + 1} style={{
                                                                margin: "4px 1.5px",
                                                                background: color,
                                                                height: calcHeightWidth() - 3,
                                                                width: calcHeightWidth() - 3,
                                                                borderRadius: 1.6
                                                            }} />
                                                        )}
                                                    </>
                                                )
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        ))}
                    </div>

                    {/* Cycle details */}
                    <div className="flex column" style={{ minWidth: 68, maxWidth: 68, margin: "24px 0 0 8px", }}>
                        {cycleLengths && cycleLengths.map((length, lIndex) => (
                            <div key={`length_${lIndex + 1}`} className="flex justifyStart alignCenter"
                                style={{
                                    height: calcHeightWidth() - 3,
                                    margin: "4px 0"
                                }} >
                                <p style={{ fontSize: 14, fontWeight: 700 }}>{length.value}</p>
                                {length.trend === "positive" && (
                                    <>
                                        <img src={ArrowUp} alt="arrow-up" height={24} width={16} style={{ marginLeft: 8, marginTop: -8 }} />
                                        <p style={{
                                            fontSize: 12,
                                            marginTop: -12,
                                            marginLeft: 4
                                        }}>{`+${length.trend_value}`}</p>
                                    </>
                                )}
                                {length.trend === "negative" && (
                                    <>
                                        <img src={ArrowDown} alt="arrow-down" height={24} width={16} style={{ marginLeft: 8 }} />
                                        <p style={{
                                            fontSize: 12,
                                            marginTop: -12,
                                            marginLeft: 4
                                        }}>{`${length.trend_value}`}</p>
                                    </>
                                )}
                                {length.trend === "neutral" && (
                                    <>
                                        <div style={{ marginLeft: 8, width: 16, height: 2, backgroundColor: "#000000" }} />
                                        <p style={{
                                            fontSize: 12,
                                            marginTop: -12,
                                            marginLeft: 4
                                        }}>{`${length.trend_value}`}</p>
                                    </>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
                <div className="flex justifyStart alignCenter w-100">
                    <p style={{ fontSize: 10, minWidth: 68, maxWidth: 68 }}>Day of Phase</p>
                    {cycleData && cycleData.map((cycle, cdIndex) => (
                        <div key={`cycle_${cdIndex + 1}`} className="flex column justifyStart" style={{ marginLeft: 16 }}>
                            {cycle["data"] && cycle["data"].map((cycleDetail, subIndex) => {
                                return subIndex + 1 === 1 && (
                                    <div key={`index_${subIndex + 1}`} className="flex justifyStart">
                                        {covertCycleDataDictionaryIntoArray(cycleDetail["data"], cycle["max_days"]).map((color, colorIndex) => {
                                            return (
                                                <p key={`color_${colorIndex + 1}`} style={{
                                                    fontSize: cycle_data_length === 2 ? 10 : 14,
                                                    width: calcHeightWidth() - 3,
                                                    margin: "4px 1.5px", textAlign: "center"
                                                }}>{colorIndex + 1}</p>
                                            );
                                        })}
                                    </div>
                                );
                            })}
                        </div>
                    ))}
                </div>

            </div>

            <GraphLegends legend={legends} />
        </div >
    )
}


export default CycleCheckin;