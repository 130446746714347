import React, { useState } from "react";
import closeIcon from "../assets/icons/cross-dark.svg";
import axios from "axios";
import { validateEmail } from "../js/validation";

const ResetYourPassword = (props) => {
    const [email_address, set_email_address] = useState('');
    const [submit, setSubmitting] = useState(false);
    const [errors, setErrors] = useState({});
    let [enable_status, setEnableStatus] = useState(false);
    const validateInputs = (e) => {
        if (e.target.value === "") {
            setErrors({ ...errors, [e.target.name]: true });
            setEnableStatus(false)
        } else if (e.target.value !== "" && !validateEmail(e.target.value)) {
            setErrors({ ...errors, [e.target.name]: true });
            setEnableStatus(false)
        }
        else {
            setErrors({ ...errors, [e.target.name]: false });
            setEnableStatus(true)
        }
    };

    const forgotEmailPassword = async (email_address) => {
        setSubmitting(true);
        const resp = await axios.post(`${process.env.REACT_APP_BASE_URL}/user/password/forgot/request/?email=${email_address}`);
        if (resp) {
            setSubmitting(false);
            const { data: { details }, status } = resp;
            if (status === 200) {
                props.onSuccess(email_address);
            } else {
                props.onError(details);
            }
        } else {
            props.onError(`Failed to submit reset password request. Please try again or contact to support support@wild.ai`);
        }
    }


    return props.show && (
        <div className="flex column alignCenter justifyCenter model_popup">
            <div className="forgot_password_container">
                <div className="flex justifyEnd w-100">
                    <img
                        src={closeIcon}
                        alt="close_icon"
                        style={{ height: 24, width: 24, cursor: "pointer" }}
                        onClick={() => { setSubmitting(false); props.onCancel() }}
                    />
                </div>

                <h3 style={{ textAlign: 'center', margin: "32px 0 16px" }}>Reset your password</h3>
                <div className="flex column" style={{ padding: 24 }}>
                    <div className="settings-form-item border-bottom">
                        <input
                            required
                            type="email"
                            name="email_address"
                            placeholder="Email address"
                            className="settings-value"
                            onBlur={validateInputs}
                            onKeyUp={(event) => {
                                if (event.keyCode === 13 && validateEmail(event.target.value.trim())) {
                                    forgotEmailPassword(event.target.value.trim());
                                }
                            }}
                            onChange={(event) => {
                                if (event.target.value.length > 0) {
                                    if (validateEmail(event.target.value.trim())) {
                                        setEnableStatus(true);
                                        set_email_address(event.target.value.trim());
                                    } else {
                                        setEnableStatus(false);
                                    }
                                }
                            }}
                        />
                    </div>
                    {errors.email_address && (<span style={{ color: '#EB5757', marginTop: 8, fontSize: 12 }}>Please enter valid email address.</span>)}
                </div>

                <div className={`flex alignCenter justifyCenter w-100 ${enable_status ? "black-button" : "black-button-disable"}`}
                    style={{
                        width: '90%',
                        margin: "0 auto",
                        cursor: enable_status ? 'pointer' : 'not-allowed'
                    }}
                    onClick={() => enable_status ? forgotEmailPassword(email_address) : null}
                >

                    {submit ? (
                        <div className="flex justifyCenter alignCenter">
                            <div className="custom_button_loader" />
                        </div>
                    ) : (
                        <p> SUBMIT </p>
                    )}
                </div>
            </div>
        </div>
    );
}

export default ResetYourPassword;