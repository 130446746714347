export const FETCH_USER_REPORT = "report/FETCH_USER_REPORT";
export const SET_DOWNLOAD_LINK = "report/SET_DOWNLOAD_LINK";
export const SET_GENERAL_REPORT = "report/SET_GENERAL_REPORT";
export const SET_CYCLE_REPORT = "report/SET_CYCLE_REPORT";
export const SET_READINESS_REPORT = "report/SET_READINESS_REPORT";
export const SET_SYMPTOMS_REPORT = "report/SET_SYMPTOMS_REPORT";
export const SET_LIFESTYLE_REPORT = "report/SET_LIFESTYLE_REPORT";
export const SET_TRAINING_REPORT = "report/SET_TRAINING_REPORT";
export const SET_MORNING_REPORT = "report/SET_MORNING_REPORT";
export const SET_TRAINING_LOAD_REPORT = "report/SET_TRAINING_LOAD_REPORT";
export const SET_TRAINING_SESSIONS_REPORT = "report/SET_TRAINING_SESSIONS_REPORT";
export const SET_LOADED_COUNT = "report/SET_LOADED_COUNT";

export const getUserReport = (userAccess, date, onSuccess, onError) => ({
    type: FETCH_USER_REPORT,
    payload: { userAccess, date, onSuccess, onError },
});

export const setDownloadLink = (payload) => ({
    type: SET_DOWNLOAD_LINK,
    payload
});

export const setGeneralReport = (payload) => ({
    type: SET_GENERAL_REPORT,
    payload,
})

export const setCycleReport = (payload) => ({
    type: SET_CYCLE_REPORT,
    payload,
});

export const setReadinessReport = (payload) => ({
    type: SET_READINESS_REPORT,
    payload,
});

export const setSymptomsReport = (payload) => ({
    type: SET_SYMPTOMS_REPORT,
    payload,
});

export const setLifestyleReport = (payload) => ({
    type: SET_LIFESTYLE_REPORT,
    payload,
});

export const setTrainingReport = (payload) => ({
    type: SET_TRAINING_REPORT,
    payload,
});

export const setMorningReport = (payload) => ({
    type: SET_MORNING_REPORT,
    payload,
});

export const setTrainingLoadReport = (payload) => ({
    type: SET_TRAINING_LOAD_REPORT,
    payload
})

export const setTrainingSessionsReport = (payload) => ({
    type: SET_TRAINING_SESSIONS_REPORT,
    payload
})

export const setLoadedCount = (payload) => ({
    type: SET_LOADED_COUNT,
    payload
})