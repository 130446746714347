import React from "react";
import button_styles from "../styles/buttons.module.css";
import common_style from "../styles/common-styles.module.css";
import main_styles from "../styles/main.module.css";
import WildLogo from "../assets/logo-light.png";

const MarketingBannerCard = () => {
  const tabSource = [
    {
      id: 1,
      tabContent: "Vetted training plans from our expert coaching partners",
    },
    { id: 2, tabContent: "Syncs with your menstrual cycle phase " },
    { id: 3, tabContent: "Accessible through your personal dashboard" },
  ];

  return (
    <div
      id="coach-plans"
      className={`${main_styles.show_for_desktop} flex alignCenter justifyCenter`}
      style={{
        position: "absolute",
        top: -120,
        left: "50%",
        transform: "translate(-50%, 0)",
        background: "#ffffff",
        height: 475,
        minWidth: 939,
        maxWidth: 940,
        borderRadius: 18,
        boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.3)",
        zIndex: 1,
      }}
    >
      <div
        className="flex justifyStart alignStart w_100"
        style={{ height: 475 }}
      >
        <div
          style={{
            position: "relative",
            height: 475,
            width: 368,
            minWidth: 368,
          }}
          className="christmas_bg"
        >
          <img
            width={46.75}
            height={46.75}
            src="https://images.unsplash.com/photo-1532384748853-8f54a8f476e2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2340&q=80"
            alt="wild-marketing-banner"
          />
          <div
            style={{
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <img width={46.75} height={46.75} src={WildLogo} alt="wild logo" />
          </div>
        </div>

        <div
          className="flex column alignStart justifyStart w_100 h_100"
          style={{ padding: "32px 32px 0", position: "relative" }}
        >
          <p style={{ color: "#000", fontSize: 16, lineHeight: "100%" }}>
            Wild.AI Add-on
          </p>
          <h3 style={{ color: "#000", margin: "16px 0 8px" }}>
            Go Wild.AI with our new training plans, created by expert coaches!
          </h3>
          <div className={common_style.gift_bullets_for_life_support}>
            <ul>
              {tabSource.map((item, itemIndex) => (
                <li key={itemIndex + 1}>
                  <div style={{ width: "100%", maxWidth: 39 }}>
                    <img
                      src="/assets/images/icons/dark-tick.svg"
                      alt="tick-dark"
                      height={16}
                      width={20}
                      style={{ marginTop: 6 }}
                    />
                  </div>
                  <div style={{ width: "100%" }}>{item.tabContent}</div>
                </li>
              ))}
            </ul>
          </div>

          <button
            className={button_styles.black}
            style={{ width: 180, height: 46, margin: "16px 0 0" }}
            onClick={() => {
              window.open("https://athlete.wild.ai", "_blank");
            }}
          >
            <p style={{ margin: 0, textTransform: "uppercase" }}>Start Today</p>
          </button>

          <p style={{ color: "#000", margin: "16px 0 8px", fontSize: 16 }}>
            Interested in becoming a training plan partner?
          </p>
          <a
            style={{
              cursor: "pointer",
              textDecoration: "underline 1px solid #000",
            }}
            target="_blank"
            href="https://wildai.typeform.com/to/HWVgx9mX"
            rel="noopener noreferrer"
          >
            <p style={{ color: "#000", fontSize: 16, lineHeight: "140%" }}>
              Apply here
            </p>
          </a>
        </div>
      </div>
    </div>
  );
};

export default MarketingBannerCard;
