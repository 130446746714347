import React from "react";

const FooterDescription = (props) => {
    return props && (
        <div className="flex column justifyStart w-100">
            <p className="common_description">
                {props.replaceStatus ? props.describe.replace(/your/gi, 'her').replace(/you/gi, 'she') : props.describe}
            </p>
        </div>
    );
}

export default FooterDescription;