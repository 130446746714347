import moment from "moment";
import { put, takeEvery, call } from "redux-saga/effects";
import api from "../../services/api/ApiService";
import {
    FETCH_USER_REPORT, setCycleReport,
    setReadinessReport, setSymptomsReport,
    setLifestyleReport, setTrainingReport, setMorningReport,
    setTrainingLoadReport,
    setTrainingSessionsReport,
} from "./actions";

async function trainingSessionsReport(userAccess, date) {
    let response;
    if (userAccess) {
        response = api.get(`user/report/blocks/training_sessions?user_access=${userAccess}&date=${date}`);
    } else {
        response = api.get(`user/report/blocks/training_sessions?date=${date}`);
    }
    return response;
}

async function trainingLoadReport(userAccess, date) {
    let response;
    if (userAccess) {
        response = api.get(`user/report/blocks/training_load?user_access=${userAccess}&date=${date}`);
    } else {
        response = api.get(`user/report/blocks/training_load?date=${date}`);
    }
    return response;
}

async function morningReport(userAccess, date) {
    let response;
    if (userAccess) {
        response = api.get(`user/report/blocks/morning?user_access=${userAccess}&date=${date}`);
    } else {
        response = api.get(`user/report/blocks/morning?date=${date}`);
    }
    return response;
}

async function lifestyleReport(userAccess, date) {
    let response;
    if (userAccess) {
        response = api.get(`user/report/blocks/lifestyle?user_access=${userAccess}&date=${date}`);
    } else {
        response = api.get(`user/report/blocks/lifestyle?date=${date}`);
    }
    return response;
}

async function symptomsReport(userAccess, date) {
    let response;
    if (userAccess) {
        response = api.get(`user/report/blocks/symptoms?user_access=${userAccess}&date=${date}`);
    } else {
        response = api.get(`user/report/blocks/symptoms?date=${date}`);
    }
    return response;
}

async function trainingReport(userAccess, date) {
    let response;
    if (userAccess) {
        response = api.get(`user/report/blocks/training?user_access=${userAccess}&date=${date}`);
    } else {
        response = api.get(`user/report/blocks/training?date=${date}`);
    }
    return response;
}

async function readinessReport(userAccess, date) {
    let response;
    if (userAccess) {
        response = api.get(`user/report/blocks/readiness?user_access=${userAccess}&date=${date}`);
    } else {
        response = api.get(`user/report/blocks/readiness?date=${date}`);
    }
    return response;
}

async function cycleReport(userAccess, date) {
    let response;
    if (userAccess) {
        response = api.get(`user/report/blocks/cycle?user_access=${userAccess}&date=${date}`);
    } else {
        response = api.get(`user/report/blocks/cycle?date=${date}`);
    }
    return response;
}

function* fetchReport({ payload: { userAccess, date, onSuccess, onError } }) {
    date = date || moment().format('DD/MM/YYYY');
    try {
        const [
            { data: cycle_checkins },
            { data: readiness_checkins },
            { data: symptoms_checkins },
            { data: lifestyle_checkins },
            { data: training_checkins },
            { data: morning_checkins },
            { data: training_load_checkins },
            { data: training_sessions_report }
        ] = yield Promise.all([
            cycleReport(userAccess, date),
            readinessReport(userAccess, date),
            symptomsReport(userAccess, date),
            lifestyleReport(userAccess, date),
            trainingReport(userAccess, date),
            morningReport(userAccess, date),
            trainingLoadReport(userAccess, date),
            trainingSessionsReport(userAccess, date)
        ]);
        yield put(setCycleReport(cycle_checkins));
        yield put(setReadinessReport(readiness_checkins));
        yield put(setSymptomsReport(symptoms_checkins));
        yield put(setLifestyleReport(lifestyle_checkins));
        yield put(setTrainingReport(training_checkins));
        yield put(setMorningReport(morning_checkins));
        yield put(setTrainingLoadReport(training_load_checkins));
        yield put(setTrainingSessionsReport(training_sessions_report));
        yield call(onSuccess);
    } catch (err) {
        if (err.response) {
            const { data: { details } } = err.response;
            yield call(onError, details);
        }
    }
}

function* reportSaga() {
    yield takeEvery(FETCH_USER_REPORT, fetchReport);
}

export default reportSaga;
