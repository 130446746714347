import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { chunk } from "../js/chunks";
// Components
import Header from "../components/common/Header";
import Footer from "../components/common/Footer";
import TabLayout from "../components/common/TabLayout";
// Icons
import alerDarkIcon from "../assets/icons/alert-dark.svg";
import reportIcon from "../assets/icons/analytics-light.svg";
import lockIcon from "../assets/icons/lock.svg";
// Actions
import { tabs } from "../js/static";
import { createNotification, fetchAthleteReports, setAthleteReports } from "../store/app/actions";

const Reports = (props) => {
    const { location } = props;
    const dispatch = useDispatch();
    const [athlete_reports, set_athlete_reports] = useState([]);
    let { user, reports } = useSelector((state) => state.app);
    let [username, set_username] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (user) {
            let { first_name } = user;
            set_username(first_name);
        }
    }, [user]);

    const resetFields = () => {
        set_athlete_reports([]);
        dispatch(setAthleteReports([]));
    }
    const onSuccess = () => setLoading(false);
    const onError = (error) => dispatch(createNotification("error-toast", error));

    useEffect(() => {
        if (reports && reports.length > 0) {
            set_athlete_reports(chunk(reports, 4));
        }
    }, [reports]);

    useEffect(() => {
        setLoading(true);
        resetFields();
        window.analytics.page();
        dispatch(fetchAthleteReports(onSuccess, onError));
    }, []);

    return (
        <div className="_container">
            <header className='desktop home_background_banner'>
                <div className='banner_image home_background_banner_image' />
                <div className='_after'>
                    <Header location={location} />
                    <div className="desktop flex justifyCenter alignCenter w-100 h-100" style={{ margin: '96px 0 0' }}>
                        <h1 style={{ color: '#FFF', fontSize: 72 }}>{`Hi, ${username}`}</h1>
                    </div>

                    <div className="mobile flex justifyCenter alignCenter w-100 h-100" style={{ margin: '72px 0 0' }}>
                        <h3 className="mobile" style={{ color: '#FFF', fontSize: 32 }}>{`Hi, ${username}`}</h3>
                    </div>
                </div>
            </header>

            <header className='mobile home_background_banner'>
                <Header location={location} />
                <div className="flex justifyCenter alignCenter w-100 h-100" style={{ margin: '96px 0 0' }}>
                    <h3 style={{ color: '#000', fontSize: 32 }}>{`Hi, ${username}`}</h3>
                </div>
            </header>

            <div className="main_content_area flex justifyCenter alignCenter w-100 h-100">
                <div className="content_area flex column justifyStart alignCenter w-100">

                    {/* tabs */}
                    <TabLayout tabs={tabs} index={3} />

                    {/* loading */}
                    {loading && (
                        <div className="flex column justifyCenter alignCenter" style={{ margin: '64px 0' }}>
                            <div className="custom_loader" />
                            <p style={{ marginTop: 32 }}>
                                {`Loading, please wait…`}
                            </p>
                        </div>
                    )}

                    {/* Athlete Reports (1- desktop, 2- mobile) */}
                    <div className="desktop flex column justifyCenter alignCenter w-100" style={{ margin: "24px 0 0" }}>
                        {athlete_reports.map((report_list, index) => (
                            <div className="flex justifyCenter alignCenter w-100" key={index + 1}
                                style={{ margin: "24px 0 0" }}>
                                {report_list && report_list.length > 0 && report_list.map((report, sub_index) => (
                                    <div className={`flex_container flex column justifyStart alignStart relative ${!report['locked'] && 'pointer'} `} key={sub_index + 1}
                                        onClick={() => {
                                            if (!report['locked']) {
                                                const search_params = report['download_link'].split("?");
                                                const params = JSON.parse('{"' + decodeURI(search_params[1]).replace(/\?/g, '').replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
                                                window.open(`/cycle-report?user_access=${params['user_access']}&date=${params['date']}`, "_blank");
                                            }
                                        }}
                                    >
                                        <img src={report['image_url'] || 'https://images.unsplash.com/photo-1608138404239-d2f557515ecb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80'} alt="plan_image" height={260} width="100%" style={{ borderRadius: 16 }} />
                                        <div className="flex column justifyEnd alignStart absolute absolute_card">
                                            <img className="absolute" style={{ top: 16, right: 16 }} src={reportIcon} alt="report icon" height={32} width={32} />
                                            {report['locked'] && (<img className="absolute" style={{ top: 21, right: 54 }} src={lockIcon} alt="lock icon" height={22} width={18} />)}
                                            <div className="flex column justifyStart" style={{ marginLeft: 12 }}>
                                                <p>{report['title']}</p>
                                                <h4>{report['subtitle']}</h4>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>

                    <div className="mobile flex column justifyStart alignCenter w-100" style={{ marginTop: 24 }}>
                        {reports && reports.length > 0 && reports.map((report, index) => {
                            return (
                                <div className={`flex_container flex column justifyStart alignStart relative ${!report['locked'] && 'pointer'} `} key={index + 1}
                                    onClick={() => {
                                        if (!report['locked']) {
                                            const search_params = report['download_link'].split("?");
                                            const params = JSON.parse('{"' + decodeURI(search_params[1]).replace(/\?/g, '').replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
                                            window.open(`/cycle-report?user_access=${params['user_access']}&date=${params['date']}`, "_blank");
                                        }
                                    }}
                                >
                                    <img src={report['image_url'] || 'https://images.unsplash.com/photo-1608138404239-d2f557515ecb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80'} alt="plan_image" height={220} width="100%" style={{ borderRadius: 24 }} />
                                    <div className="flex column justifyEnd alignStart absolute absolute_card">
                                        <img className="absolute" style={{ top: 16, right: 16 }} src={reportIcon} alt="report icon" height={32} width={32} />
                                        {report['locked'] && (<img className="absolute" style={{ top: 21, right: 54 }} src={lockIcon} alt="lock icon" height={22} width={17} />)}
                                        <div className="flex column justifyStart" style={{ marginLeft: 12 }}>
                                            <p>{report['title']}</p>
                                            <h4>{report['subtitle']}</h4>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>

                    {!loading && reports.length === 0 && athlete_reports.length === 0 && (
                        <div className="flex justifyCenter alignCenter w-100" style={{ margin: "24px 0 0" }}>
                            <img src={alerDarkIcon} alt="dark_alert_icon" height={28} width={28} />
                            <p style={{ color: '#000', marginLeft: 8 }}>No reports available yet</p>
                        </div>
                    )}
                </div>
            </div>
            <Footer />
        </div >
    );
};

export default Reports;
