import React, { useState } from "react";
import button_styles from "../styles/buttons.module.css";
import home_styles from "../styles/home.module.css";
import ChevronRight from "../assets/icons/chevron-right.svg";
import ChevronLeft from "../assets/icons/chevron-left.svg";
import WildLogo from "../assets/logo-light.png";
import GympassLogo from "../assets/horizontal-complete-logo-magenta.png";

const BannerWithMobileFeaturesSlider = ({ content, showFormFor, name }) => {
  const scrollDownToForm = () => window.scrollTo(0, 2840);
  const scrollDownToFormOnMobile = () => window.scrollTo(0, 4100);
  const tabSource = content.sort((a, b) => a.id - b.id);
  const [activeIndex, setActiveIndex] = useState(0);
  const len = tabSource.length - 1;

  return (
    <section className={`${home_styles.tab_screen}`}>
      <div className={home_styles.gympass_banner_content}>
        <div
          className={`${home_styles.show_for_desktop} ${home_styles.tab_content}`}
        >
          <div className={home_styles.image_slider_gympass}>
            <div
              className={home_styles.backward}
              onClick={() => {
                setActiveIndex(activeIndex < 1 ? len : activeIndex - 1);
              }}
            >
              <img width={24} height={24} src={ChevronLeft} alt="left arrow" />
            </div>

            <div className={home_styles.device_frame}>
              <img
                width={296}
                height={560}
                src={
                  tabSource.length > 0
                    ? tabSource[activeIndex].tabImage["url"]
                    : ""
                }
                alt={
                  tabSource.length > 0
                    ? tabSource[activeIndex].tabImage["name"]
                    : ""
                }
              />
            </div>

            <div
              className={home_styles.forward}
              onClick={() => {
                setActiveIndex(activeIndex === len ? 0 : activeIndex + 1);
              }}
            >
              <img
                width={24}
                height={24}
                src={ChevronRight}
                alt="right arrow"
              />
            </div>
          </div>

          <div
            className={`${home_styles.tab_layout} ${home_styles.align_center}`}
          >
            <a href="/" target="_blank" rel="noreferrer">
              <img width={92} height={106.46} src={WildLogo} alt="wild logo" />
            </a>
            <div style={{ marginTop: 24 }}>
              <img
                width={102}
                height={20}
                src={GympassLogo}
                alt="gympass logo"
              />
            </div>
            <p className={home_styles.premium_text}>Premium Access</p>
            <p className={home_styles.welcome_text}>{`Welcome ${name}!`}</p>
            <p className={home_styles.normal_text}>
              As a Wellhub member, you are eligible for a free Wild.AI Premium
              subscription.
            </p>
            <p className={home_styles.normal_text}>
              New to Wild.AI? Get started here:
            </p>
            <div
              className="row"
              style={{
                marginTop: 32,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <button
                className={button_styles.white}
                style={{ width: 336 }}
                onClick={() => {
                  scrollDownToForm();
                  showFormFor(true);
                }}
              >
                <p style={{ margin: 0, textTransform: "uppercase" }}>Sign Up</p>
              </button>
            </div>
            <p className={home_styles.normal_text}>
              {`Existing Wild.AI user?  `}
              <span
                style={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={() => {
                  scrollDownToForm();
                  showFormFor(false);
                }}
              >
                Log in
              </span>
            </p>
          </div>
        </div>

        {/* mobile */}
        <div
          className={`${home_styles.show_for_mobile} ${home_styles.tab_content}`}
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <div
            className={`${home_styles.tab_layout} ${home_styles.align_center}`}
          >
            <a href="/" target="_blank" rel="noreferrer">
              <img width={136} height={157} src={WildLogo} alt="wild logo" />
            </a>
            <div style={{ marginTop: 24 }}>
              <img
                width={102}
                height={20}
                src={GympassLogo}
                alt="gympass logo"
              />
            </div>
            <p className={home_styles.premium_text}>Premium Access</p>
            <p className={home_styles.welcome_text}>{`Welcome ${name}!`}</p>
            <p className={home_styles.normal_text}>
              As a Wellhub member, you are eligible for a free Wild.AI Premium
              subscription.
            </p>
            <p className={home_styles.normal_text}>
              New to Wild.AI? Get started here:
            </p>
            <div
              className="row"
              style={{
                marginTop: 32,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <button
                className={button_styles.white}
                style={{ width: 318 }}
                onClick={() => {
                  scrollDownToFormOnMobile();
                  showFormFor(true);
                }}
              >
                <p style={{ margin: 0, textTransform: "uppercase" }}>Signup</p>
              </button>
            </div>
            <p className={home_styles.normal_text}>
              {`Existing Wild.AI user?  `}
              <span
                style={{ textDecoration: "underline" }}
                onClick={() => {
                  scrollDownToFormOnMobile();
                  showFormFor(false);
                }}
              >
                Log in
              </span>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BannerWithMobileFeaturesSlider;
