import React, { useEffect, useState } from "react";
import ColouredArrowDown from "../../assets/icons/report-arrow-down.svg";
import ColouredArrowUp from "../../assets/icons/report-arrow-up.svg";
import LinePattern from "../../assets/icons/line-pattern.svg"


const GraphMultiLegends = (props) => {
    const [legendList, setlegends] = useState(null);

    const covertDictionaryIntoArray = (dictionary) => {
        let array = [];
        if (Object.keys(dictionary).length > 0) {
            for (var i = 0; i < Object.keys(dictionary).length; i++) {
                array.push(Object.values(dictionary)[i]);
            }
        }
        return array;
    }

    useEffect(() => {
        if (props && props["legend"]) {
            const legendsList = covertDictionaryIntoArray(props["legend"]);
            setlegends(legendsList);
        } else {
            setlegends(null);
        }
    }, [props]);

    return (
        <div className="flex column alignStart flex_wrap w-100" style={{ padding: 16, borderRadius: 8, margin: "32px 0", background: "#F4F4F4" }}>
            {legendList && legendList.map((legends, index) => (
                <div key={index + 1} className="flex alignStart flex_wrap w-100">
                    {legends && legends.map((item, subIndex) => (
                        <div className="flex alignCenter justifyStart" key={subIndex + 1} style={{ minWidth: 100, margin: "0 16px", padding: "8px 0", }}>
                            <>
                                {item.colour === "arrows" ? (
                                    <div className="flex justifyStart">
                                        <img src={ColouredArrowUp} alt="indicator" height={20} width={20} />
                                        <img src={ColouredArrowDown} alt="indicator" height={20} width={20} />
                                    </div>
                                ) : (
                                    <>
                                        {item.colour.toLowerCase() === "#ffffff" ? (
                                            <div style={{ backgroundColor: '#ffffff', height: 27, width: 25, borderRadius: 1.6 }}>
                                                <img src={LinePattern} alt="line-pattern" style={{ margin: "0px 1px", height: 24, width: 23, borderRadius: 1.6 }} />
                                            </div>
                                        ) : (
                                            <div style={{ background: `${item.colour}`, height: 24, width: 24, boxShadow: "0 0 1px rgba(0,0,0,0.3)", borderRadius: 2 }} />
                                        )}
                                    </>
                                )}
                            </>
                            <p style={{ marginLeft: 12, fontSize: 14 }}>{item.text}</p>
                        </div>
                    ))}
                </div>
            ))}
        </div >
    );
}

export default GraphMultiLegends;