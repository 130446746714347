export const menstrual_cycle_report = {
    "cycle": {
        "title": "Cycle overview",
        "subtitle": "Here’s an overview of your past cycle lengths and phases including bleeding levels and predicted (or actual) ovulation days. Your cycle lengths (in days) are indicated on the right along with whether they increased or decreased compared to your historical average.",
        "legend": [
            {
                "colour": "#809E69",
                "text": "Predicted ovulation day"
            },
            {
                "colour": "#C9383F",
                "text": "Heavy bleeding"
            },
            {
                "colour": "#F95151",
                "text": "Medium bleeding"
            },
            {
                "colour": "#E9A6AA",
                "text": "Light bleeding"
            },
            {
                "colour": "#BFBFBF",
                "text": "Phase day"
            },
            {
                "colour": "#FFFFFF",
                "text": "No phase day"
            }
        ],
        "bullets": [
            {
                "value": "32d",
                "trend": "positive",
                "trend_value": "2",
                "arrow_colour": "#202020",
                "text": "Your most recent cycle was 32 days long, which is a difference of 2 days from your historical average."
            },
            {
                "value": "3d",
                "trend": "positive",
                "trend_value": "1",
                "arrow_colour": "#202020",
                "text": "Your period lasted 3 days, which is a difference of 1 day from your historical average."
            },
            {
                "value": "18d",
                "trend": "positive",
                "trend_value": "2",
                "arrow_colour": "#202020",
                "text": "Predicted ovulation occurred around day 18, which is a difference of 2 days from your historical average."
            }
        ],
        "cycle_lengths": {
            "Last": {
                "value": "32d",
                "trend": "positive",
                "trend_value": 2,
                "arrow_colour": "#202020"
            },
            "2nd to last": {
                "value": "31d",
                "trend": "positive",
                "trend_value": 1,
                "arrow_colour": "#202020"
            },
            "3rd to last": {
                "value": "28d",
                "trend": "negative",
                "trend_value": -2,
                "arrow_colour": "#202020"
            },
            "4th to last": {
                "value": "30d",
                "trend": "neutral",
                "trend_value": 0,
                "arrow_colour": "#202020"
            }
        },
        "cycles_data": [
            {
                "max_days": 3,
                "phase": "Period",
                "data": [
                    {
                        "order": 0,
                        "name": "Last",
                        "data": {
                            "1": "#C9383F",
                            "2": "#C9383F",
                            "3": "#C9383F"
                        }
                    },
                    {
                        "order": 1,
                        "name": "2nd to last",
                        "data": {
                            "1": "#C9383F",
                            "2": "#C9383F",
                            "3": "#C9383F"
                        }
                    },
                    {
                        "order": 2,
                        "name": "3rd to last",
                        "data": {
                            "1": "#C9383F",
                            "2": "#C9383F",
                            "3": "#C9383F"
                        }
                    },
                    {
                        "order": 3,
                        "name": "4th to last",
                        "data": {
                            "1": "#C9383F",
                            "2": "#C9383F",
                            "3": "#C9383F"
                        }
                    }
                ]
            },
            {
                "max_days": 13,
                "phase": "Mid Follicular",
                "data": [
                    {
                        "order": 0,
                        "name": "Last",
                        "data": {
                            "1": "#BFBFBF",
                            "2": "#BFBFBF",
                            "3": "#BFBFBF",
                            "4": "#BFBFBF",
                            "5": "#BFBFBF",
                            "6": "#BFBFBF",
                            "7": "#BFBFBF",
                            "8": "#BFBFBF",
                            "9": "#BFBFBF",
                            "10": "#BFBFBF",
                            "11": "#BFBFBF",
                            "12": "#BFBFBF",
                            "13": "#BFBFBF"
                        }
                    },
                    {
                        "order": 1,
                        "name": "2nd to last",
                        "data": {
                            "1": "#BFBFBF",
                            "2": "#BFBFBF",
                            "3": "#BFBFBF",
                            "4": "#BFBFBF",
                            "5": "#BFBFBF",
                            "6": "#BFBFBF",
                            "7": "#BFBFBF",
                            "8": "#BFBFBF",
                            "9": "#BFBFBF",
                            "10": "#BFBFBF",
                            "11": "#BFBFBF",
                            "12": "#BFBFBF"
                        }
                    },
                    {
                        "order": 2,
                        "name": "3rd to last",
                        "data": {
                            "1": "#BFBFBF",
                            "2": "#BFBFBF",
                            "3": "#BFBFBF",
                            "4": "#BFBFBF",
                            "5": "#BFBFBF",
                            "6": "#BFBFBF",
                            "7": "#BFBFBF",
                            "8": "#BFBFBF",
                            "9": "#BFBFBF"
                        }
                    },
                    {
                        "order": 3,
                        "name": "4th to last",
                        "data": {
                            "1": "#BFBFBF",
                            "2": "#BFBFBF",
                            "3": "#BFBFBF",
                            "4": "#BFBFBF",
                            "5": "#BFBFBF",
                            "6": "#BFBFBF",
                            "7": "#BFBFBF",
                            "8": "#BFBFBF",
                            "9": "#BFBFBF",
                            "10": "#BFBFBF",
                            "11": "#BFBFBF"
                        }
                    }
                ]
            },
            {
                "max_days": 4,
                "phase": "Ovulatory",
                "data": [
                    {
                        "order": 0,
                        "name": "Last",
                        "data": {
                            "1": "#BFBFBF",
                            "2": "#809E69",
                            "3": "#BFBFBF",
                            "4": "#BFBFBF"
                        }
                    },
                    {
                        "order": 1,
                        "name": "2nd to last",
                        "data": {
                            "1": "#BFBFBF",
                            "2": "#809E69",
                            "3": "#BFBFBF",
                            "4": "#BFBFBF"
                        }
                    },
                    {
                        "order": 2,
                        "name": "3rd to last",
                        "data": {
                            "1": "#BFBFBF",
                            "2": "#809E69",
                            "3": "#BFBFBF",
                            "4": "#BFBFBF"
                        }
                    },
                    {
                        "order": 3,
                        "name": "4th to last",
                        "data": {
                            "1": "#BFBFBF",
                            "2": "#809E69",
                            "3": "#BFBFBF",
                            "4": "#BFBFBF"
                        }
                    }
                ]
            },
            {
                "max_days": 7,
                "phase": "Mid Luteal",
                "data": [
                    {
                        "order": 0,
                        "name": "Last",
                        "data": {
                            "1": "#BFBFBF",
                            "2": "#BFBFBF",
                            "3": "#BFBFBF",
                            "4": "#BFBFBF",
                            "5": "#BFBFBF",
                            "6": "#BFBFBF",
                            "7": "#BFBFBF"
                        }
                    },
                    {
                        "order": 1,
                        "name": "2nd to last",
                        "data": {
                            "1": "#BFBFBF",
                            "2": "#BFBFBF",
                            "3": "#BFBFBF",
                            "4": "#BFBFBF",
                            "5": "#BFBFBF",
                            "6": "#BFBFBF",
                            "7": "#BFBFBF"
                        }
                    },
                    {
                        "order": 2,
                        "name": "3rd to last",
                        "data": {
                            "1": "#BFBFBF",
                            "2": "#BFBFBF",
                            "3": "#BFBFBF",
                            "4": "#BFBFBF",
                            "5": "#BFBFBF",
                            "6": "#BFBFBF",
                            "7": "#BFBFBF"
                        }
                    },
                    {
                        "order": 3,
                        "name": "4th to last",
                        "data": {
                            "1": "#BFBFBF",
                            "2": "#BFBFBF",
                            "3": "#BFBFBF",
                            "4": "#BFBFBF",
                            "5": "#BFBFBF",
                            "6": "#BFBFBF",
                            "7": "#BFBFBF"
                        }
                    }
                ]
            },
            {
                "max_days": 5,
                "phase": "Late Luteal",
                "data": [
                    {
                        "order": 0,
                        "name": "Last",
                        "data": {
                            "1": "#BFBFBF",
                            "2": "#BFBFBF",
                            "3": "#BFBFBF",
                            "4": "#BFBFBF",
                            "5": "#BFBFBF"
                        }
                    },
                    {
                        "order": 1,
                        "name": "2nd to last",
                        "data": {
                            "1": "#BFBFBF",
                            "2": "#BFBFBF",
                            "3": "#BFBFBF",
                            "4": "#BFBFBF",
                            "5": "#BFBFBF"
                        }
                    },
                    {
                        "order": 2,
                        "name": "3rd to last",
                        "data": {
                            "1": "#BFBFBF",
                            "2": "#BFBFBF",
                            "3": "#BFBFBF",
                            "4": "#BFBFBF",
                            "5": "#BFBFBF"
                        }
                    },
                    {
                        "order": 3,
                        "name": "4th to last",
                        "data": {
                            "1": "#BFBFBF",
                            "2": "#BFBFBF",
                            "3": "#BFBFBF",
                            "4": "#BFBFBF",
                            "5": "#BFBFBF"
                        }
                    }
                ]
            }
        ]
    },
    "readiness": {
        "y_axes": [
            0,
            20,
            40,
            60,
            80,
            100
        ],
        "title": "Readiness Score",
        "subtitle": "Here’s how your readiness scores changed across your last cycle. Each bar represents a day in your last cycle, while the black lines across each phase represent the average of that phase. Your average readiness score in each phase along with whether your average score increased or decreased compared with your historical average in that phase is also displayed below the x axis..",
        "legend": [
            {
                "colour": "#D53D46",
                "text": "Low"
            },
            {
                "colour": "#F5B94B",
                "text": "Medium"
            },
            {
                "colour": "#809E69",
                "text": "High"
            },
            {
                "colour": "#000000",
                "text": "Average readiness"
            },
            {
                "colour": "arrows",
                "text": "Change from historical average"
            }
        ],
        "phase_details": [
            {
                "start_date": "14-04-2022",
                "end_date": "16-04-2022",
                "full_name": "Period",
                "short_name": "EF",
                "duration": 3,
                "stats": {
                    "value": "19",
                    "trend": "positive",
                    "trend_value": "7",
                    "arrow_colour": "#5f9f61"
                }
            },
            {
                "start_date": "17-04-2022",
                "end_date": "29-04-2022",
                "full_name": "Mid Follicular",
                "short_name": "MF",
                "duration": 13,
                "stats": {
                    "value": "14",
                    "trend": "positive",
                    "trend_value": "4",
                    "arrow_colour": "#5f9f61"
                }
            },
            {
                "start_date": "30-04-2022",
                "end_date": "03-05-2022",
                "full_name": "Ovulatory",
                "short_name": "OV",
                "duration": 4,
                "stats": {
                    "value": "11",
                    "trend": "positive",
                    "trend_value": "4",
                    "arrow_colour": "#5f9f61"
                }
            },
            {
                "start_date": "04-05-2022",
                "end_date": "10-05-2022",
                "full_name": "Mid Luteal",
                "short_name": "ML",
                "duration": 7,
                "stats": {
                    "value": "6",
                    "trend": "negative",
                    "trend_value": "-1",
                    "arrow_colour": "#c9383f"
                }
            },
            {
                "start_date": "11-05-2022",
                "end_date": "15-05-2022",
                "full_name": "Late Luteal",
                "short_name": "LL",
                "duration": 5,
                "stats": {
                    "value": "15",
                    "trend": "positive",
                    "trend_value": "3",
                    "arrow_colour": "#5f9f61"
                }
            }
        ],
        "bar_graph": {
            "2022-04-14": {
                "value": 25.36231884057971,
                "colour": "#D53D46"
            },
            "2022-04-15": {
                "value": 25.36231884057971,
                "colour": "#D53D46"
            },
            "2022-04-16": {
                "value": 25.36231884057971,
                "colour": "#D53D46"
            },
            "2022-04-17": {
                "value": 66.66666666667,
                "colour": "#809E69"
            },
            "2022-04-18": {
                "value": 66.66666666667,
                "colour": "#F5B94B"
            },
            "2022-04-19": {
                "value": 66.66666666667,
                "colour": "#D53D46"
            },
            "2022-04-20": {
                "value": 66.66666666667,
                "colour": "#809E69"
            },
            "2022-04-21": {
                "value": 66.66666666667,
                "colour": "#809E69"
            },
            "2022-04-22": {
                "value": 66.66666666667,
                "colour": "#D53D46"
            },
            "2022-04-23": {
                "value": 66.66666666667,
                "colour": "#D53D46"
            },
            "2022-04-24": {
                "value": 66.66666666667,
                "colour": "#F5B94B"
            },
            "2022-04-25": {
                "value": 66.66666666667,
                "colour": "#D53D46"
            },
            "2022-04-26": {
                "value": 66.66666666667,
                "colour": "#D53D46"
            },
            "2022-04-27": {
                "value": 66.66666666667,
                "colour": "#D53D46"
            },
            "2022-04-28": {
                "value": 66.66666666667,
                "colour": "#D53D46"
            },
            "2022-04-29": {
                "value": 66.66666666667,
                "colour": "#D53D46"
            },
            "2022-04-30": {
                "value": 14,
                "colour": "#D53D46"
            },
            "2022-05-01": {
                "value": 14,
                "colour": "#D53D46"
            },
            "2022-05-02": {
                "value": 14,
                "colour": "#D53D46"
            },
            "2022-05-03": {
                "value": 14,
                "colour": "#D53D46"
            },
            "2022-05-04": {
                "value": 60,
                "colour": "#D53D46"
            },
            "2022-05-05": {
                "value": 60,
                "colour": "#D53D46"
            },
            "2022-05-06": {
                "value": 60,
                "colour": "#D53D46"
            },
            "2022-05-07": {
                "value": 60,
                "colour": "#D53D46"
            },
            "2022-05-08": {
                "value": 60,
                "colour": "#D53D46"
            },
            "2022-05-09": {
                "value": 60,
                "colour": "#D53D46"
            },
            "2022-05-10": {
                "value": 60,
                "colour": "#D53D46"
            },
            "2022-05-11": {
                "value": 32.93650793650794,
                "colour": "#D53D46"
            },
            "2022-05-12": {
                "value": 32.93650793650794,
                "colour": "#D53D46"
            },
            "2022-05-13": {
                "value": 32.93650793650794,
                "colour": "#D53D46"
            },
            "2022-05-14": {
                "value": 32.93650793650794,
                "colour": "#D53D46"
            },
            "2022-05-15": {
                "value": 32.93650793650794,
                "colour": "#D53D46"
            }
        },
        "line_graph": {
            "14-04-2022": 19.867697730082323,
            "16-04-2022": 19.867697730082323,
            "17-04-2022": 44.211468206615258,
            "29-04-2022": 44.211468206615258,
            "30-04-2022": 11.535115139404319,
            "03-05-2022": 11.535115139404319,
            "04-05-2022": 36.0935512866813095,
            "10-05-2022": 36.0935512866813095,
            "11-05-2022": 25.652899987023249,
            "15-05-2022": 25.652899987023249
        }
    },
    "morning": {
        "title": "Morning Data",
        "subtitle": "This is how you woke up across your cycle:",
        "legend": [
            {
                "colour": "arrows",
                "text": "Indicates increase/reduction from typical phase average."
            },
            {
                "colour": "joined_dots",
                "text": "Represents daily scores. Grey shading represents average score trend across the cycle."
            }
        ],
        "graphs": [
            {
                "name": "Sleep (hours)",
                "unit": "hours",
                "symbol": "h",
                "y_axes": [
                    0,
                    1,
                    2,
                    3,
                    4,
                    5
                ],
                "phase_details": [
                    {
                        "start_date": "14-04-2022",
                        "end_date": "16-04-2022",
                        "full_name": "Period",
                        "short_name": "EF",
                        "duration": 3,
                        "stats": {
                            "value": "3.7",
                            "trend": "positive",
                            "trend_value": "0.3",
                            "arrow_colour": "#5f9f61"
                        }
                    },
                    {
                        "start_date": "17-04-2022",
                        "end_date": "29-04-2022",
                        "full_name": "Mid Follicular",
                        "short_name": "MF",
                        "duration": 13,
                        "stats": {
                            "value": "1.6",
                            "trend": "negative",
                            "trend_value": "-0.3",
                            "arrow_colour": "#c9383f"
                        }
                    },
                    {
                        "start_date": "30-04-2022",
                        "end_date": "03-05-2022",
                        "full_name": "Ovulatory",
                        "short_name": "OV",
                        "duration": 4,
                        "stats": {
                            "value": "2",
                            "trend": "neutral",
                            "trend_value": "0",
                            "arrow_colour": "#f6b84b"
                        }
                    },
                    {
                        "start_date": "04-05-2022",
                        "end_date": "10-05-2022",
                        "full_name": "Mid Luteal",
                        "short_name": "ML",
                        "duration": 7,
                        "stats": {
                            "value": "1",
                            "trend": "negative",
                            "trend_value": "-1.7",
                            "arrow_colour": "#c9383f"
                        }
                    },
                    {
                        "start_date": "11-05-2022",
                        "end_date": "15-05-2022",
                        "full_name": "Late Luteal",
                        "short_name": "LL",
                        "duration": 5,
                        "stats": {
                            "value": "2.2",
                            "trend": "negative",
                            "trend_value": "-0.6",
                            "arrow_colour": "#c9383f"
                        }
                    }
                ],
                "line_graph": {
                    "2022-04-14": 3,
                    "2022-04-15": 5,
                    "2022-04-16": 3,
                    "2022-04-17": 1,
                    "2022-04-18": 3,
                    "2022-04-19": 0,
                    "2022-04-20": 0,
                    "2022-04-21": 1,
                    "2022-04-22": 1,
                    "2022-04-23": 3,
                    "2022-04-24": 5,
                    "2022-04-25": 5,
                    "2022-04-26": 1,
                    "2022-04-27": 1,
                    "2022-04-28": 0,
                    "2022-04-29": 0,
                    "2022-04-30": 3,
                    "2022-05-02": 1,
                    "2022-05-04": 1,
                    "2022-05-05": 0,
                    "2022-05-06": 0,
                    "2022-05-07": 5,
                    "2022-05-08": 1,
                    "2022-05-09": 0,
                    "2022-05-10": 0,
                    "2022-05-12": 3,
                    "2022-05-13": 0,
                    "2022-05-14": 1,
                    "2022-05-15": 5
                },
                "average_graph": {
                    "14-04-2022": 3.6666666666666665,
                    "16-04-2022": 3.6666666666666665,
                    "17-04-2022": 1.6153846153846154,
                    "29-04-2022": 1.6153846153846154,
                    "30-04-2022": 2,
                    "03-05-2022": 2,
                    "04-05-2022": 1,
                    "10-05-2022": 1,
                    "11-05-2022": 2.25,
                    "15-05-2022": 2.25
                }
            }
        ]
    },
    "training": {
        "title": "Training Check-in Overview",
        "subtitle": "Here’s a summary of your training check-ins across this cycle. Each block represents a day of your last cycle.",
        "top_checkin_var": "Workout readiness",
        "top_severe_var": "Workout readiness",
        "top_checkin": "Across your last cycle you checked-in for \"Workout readiness\" most. You reported it most frequently in the Mid Follicular phase of your cycle.\n\nThe symptom you reported most intensely this cycle was Workout readiness. You reported it most frequently in the Mid Follicular phase of your cycle.",
        "legend": {
            "negative": [
                {
                    "colour": "#C9383F",
                    "text": "Severe"
                },
                {
                    "colour": "#F95151",
                    "text": "Moderate"
                },
                {
                    "colour": "#E9A6AA",
                    "text": "Mild"
                },
                {
                    "colour": "#BFBFBF",
                    "text": "None"
                }
            ],
            "positive": [
                {
                    "colour": "#607854",
                    "text": "Great"
                },
                {
                    "colour": "#8FAF74",
                    "text": "Ok"
                },
                {
                    "colour": "#E9A6AA",
                    "text": "Poor"
                },
                {
                    "colour": "#FFFFFF",
                    "text": "No entry"
                }
            ]
        },
        "data": {
            "Workout readiness": [
                {
                    "max_days": 3,
                    "phase": "Period",
                    "data": {
                        "1": "#8FAF74",
                        "2": "#8FAF74",
                        "3": "#607854"
                    }
                },
                {
                    "max_days": 13,
                    "phase": "Mid Follicular",
                    "data": {
                        "1": "#B1BFA4",
                        "2": "#8FAF74",
                        "3": "#607854",
                        "4": "#8FAF74",
                        "5": "#8FAF74",
                        "6": "#E9A6AA",
                        "7": "#607854",
                        "8": "#E9A6AA",
                        "9": "#E9A6AA",
                        "10": "#E9A6AA",
                        "11": "#8FAF74",
                        "12": "#B1BFA4",
                        "13": "#607854"
                    }
                },
                {
                    "max_days": 4,
                    "phase": "Ovulatory",
                    "data": {
                        "1": "#8FAF74",
                        "2": "#B1BFA4",
                        "3": "#8FAF74",
                        "4": "#8FAF74"
                    }
                },
                {
                    "max_days": 7,
                    "phase": "Mid Luteal",
                    "data": {
                        "1": "#8FAF74",
                        "2": "#8FAF74",
                        "3": "#B1BFA4",
                        "4": "#8FAF74",
                        "5": "#607854",
                        "6": "#B1BFA4",
                        "7": "#8FAF74"
                    }
                },
                {
                    "max_days": 5,
                    "phase": "Late Luteal",
                    "data": {
                        "1": "#8FAF74",
                        "2": "#8FAF74",
                        "3": "#8FAF74",
                        "4": "#B1BFA4",
                        "5": "#607854"
                    }
                }
            ]
        }
    },
    "training_load": {
        "title": "Training Load",
        "subtitle": "On average across this cycle you spent 17 day(s) in an optimal state, with 7 day(s) spent in an over-trained state and 8 day(s) spent in a sub-optimal state.\n\nThis is an estimate of how hard you trained across this cycle, providing an indication of whether your training load is optimal or not.",
        "legend": [
            {
                "colour": "arrows",
                "text": "Indicates increase/reduction in % from typical phase average."
            },
            {
                "colour": "joined_dots",
                "text": "Represents daily scores. Grey shading represents average score trend across the cycle."
            }
        ],
        "data": {
            "name": "Training Load",
            "unit": "",
            "symbol": "",
            "y_axes": {
                "Suboptimal": {
                    "range": [
                        0,
                        1
                    ],
                    "colour": "#FFFFFF"
                },
                "Optimal": {
                    "range": [
                        1,
                        2
                    ],
                    "colour": "#8FAF74"
                },
                "Overtrained": {
                    "range": [
                        2,
                        3
                    ],
                    "colour": "#FFFFFF"
                }
            },
            "phase_details": [
                {
                    "start_date": "14-04-2022",
                    "end_date": "16-04-2022",
                    "full_name": "Period",
                    "short_name": "EF",
                    "duration": 3,
                    "stats": {
                        "value": "0.9",
                        "trend": "positive",
                        "trend_value": "0.4",
                        "arrow_colour": "#5f9f61"
                    }
                },
                {
                    "start_date": "17-04-2022",
                    "end_date": "29-04-2022",
                    "full_name": "Mid Follicular",
                    "short_name": "MF",
                    "duration": 13,
                    "stats": {
                        "value": "1.5",
                        "trend": "positive",
                        "trend_value": "1.1",
                        "arrow_colour": "#5f9f61"
                    }
                },
                {
                    "start_date": "30-04-2022",
                    "end_date": "03-05-2022",
                    "full_name": "Ovulatory",
                    "short_name": "OV",
                    "duration": 4,
                    "stats": {
                        "value": "0.9",
                        "trend": "positive",
                        "trend_value": "0.6",
                        "arrow_colour": "#5f9f61"
                    }
                },
                {
                    "start_date": "04-05-2022",
                    "end_date": "10-05-2022",
                    "full_name": "Mid Luteal",
                    "short_name": "ML",
                    "duration": 7,
                    "stats": {
                        "value": "2.0",
                        "trend": "positive",
                        "trend_value": "1.4",
                        "arrow_colour": "#5f9f61"
                    }
                },
                {
                    "start_date": "11-05-2022",
                    "end_date": "15-05-2022",
                    "full_name": "Late Luteal",
                    "short_name": "LL",
                    "duration": 5,
                    "stats": {
                        "value": "1.9",
                        "trend": "positive",
                        "trend_value": "0.9",
                        "arrow_colour": "#5f9f61"
                    }
                }
            ],
            "line_graph": {
                "2022-04-14": 0.9180722660126154,
                "2022-04-15": 0.8766205386203311,
                "2022-04-16": 0.9091162704280557,
                "2022-04-17": 0.7975694909152399,
                "2022-04-18": 0.6931963544881015,
                "2022-04-19": 1.489413533829751,
                "2022-04-20": 1.629427068689095,
                "2022-04-21": 1.8849701962648955,
                "2022-04-22": 1.8670157566726027,
                "2022-04-23": 1.7409186116245854,
                "2022-04-24": 2.045429949604926,
                "2022-04-25": 1.9135605061971779,
                "2022-04-26": 1.359852525579205,
                "2022-04-27": 2.012839372903634,
                "2022-04-28": 1.5165645336823288,
                "2022-04-29": 1.1369508764907574,
                "2022-04-30": 0.9081537705567041,
                "2022-05-01": 0.7304011415359452,
                "2022-05-02": 0.9805497718088863,
                "2022-05-03": 1.0260781201455174,
                "2022-05-04": 1.1948214575747347,
                "2022-05-05": 1.0429389856813096,
                "2022-05-06": 1.8063842091622302,
                "2022-05-07": 2.08037070506119,
                "2022-05-08": 2.144174317178542,
                "2022-05-09": 2.1179242837028642,
                "2022-05-10": 2.0277311239070843,
                "2022-05-11": 1.8406794117846412,
                "2022-05-12": 1.8735348243799663,
                "2022-05-13": 2.0126607037307425,
                "2022-05-14": 1.987411795250746,
                "2022-05-15": 1.629103459000596
            },
            "average_graph": {
                "14-04-2022": 0.9012696916870008,
                "16-04-2022": 0.9012696916870008,
                "17-04-2022": 1.5414271771709207,
                "29-04-2022": 1.5414271771709207,
                "30-04-2022": 0.908850877248121,
                "03-05-2022": 0.908850877248121,
                "04-05-2022": 2.0011435707303318,
                "10-05-2022": 2.0011435707303318,
                "11-05-2022": 1.879819458112392,
                "15-05-2022": 1.879819458112392
            }
        }
    },
    "training_sessions": {
        "sessions_num": 32,
        "top_sport_type": "Triathlon",
        "title": "Training Session & Load Overview",
        "subtitle": "You performed 32 training session(s) in this cycle. Here’s the break down by phase:",
        "legend": [
            {
                "colour": "#43573E",
                "text": "Running"
            },
            {
                "colour": "#607854",
                "text": "Cycling"
            },
            {
                "colour": "#708B5E",
                "text": "Swimming"
            },
            {
                "colour": "#809E69",
                "text": "Triathlon"
            },
            {
                "colour": "#8FAF74",
                "text": "Walking"
            },
            {
                "colour": "#A0B78B",
                "text": "Alpine Ski"
            },
            {
                "colour": "#B1BFA4",
                "text": "Backcountry Ski"
            },
            {
                "colour": "#C7C9C6",
                "text": "Ice Skate"
            },
            {
                "colour": "#F09136",
                "text": "Snowboard"
            },
            {
                "colour": "#F5B94B",
                "text": "Nordic Ski"
            }
        ],
        "y_axes": [
            0,
            3,
            6,
            9,
            12,
            15
        ],
        "stacked_bars": {
            "EF": {
                "order": 0,
                "phase_details": {
                    "start_date": "14-04-2022",
                    "end_date": "16-04-2022",
                    "full_name": "Period",
                    "short_name": "EF",
                    "duration": 3
                },
                "total": 3,
                "sport_types": {
                    "Alpine Ski": 1,
                    "Ice Skate": 2
                }
            },
            "MF": {
                "order": 1,
                "phase_details": {
                    "start_date": "17-04-2022",
                    "end_date": "29-04-2022",
                    "full_name": "Mid Follicular",
                    "short_name": "MF",
                    "duration": 13
                },
                "total": 13,
                "sport_types": {
                    "Running": 2,
                    "Cycling": 1,
                    "Swimming": 2,
                    "Triathlon": 4,
                    "Alpine Ski": 1,
                    "Snowboard": 2,
                    "Nordic Ski": 1
                }
            },
            "OV": {
                "order": 2,
                "phase_details": {
                    "start_date": "30-04-2022",
                    "end_date": "03-05-2022",
                    "full_name": "Ovulatory",
                    "short_name": "OV",
                    "duration": 4
                },
                "total": 4,
                "sport_types": {
                    "Cycling": 1,
                    "Swimming": 2,
                    "Backcountry Ski": 1
                }
            },
            "ML": {
                "order": 3,
                "phase_details": {
                    "start_date": "04-05-2022",
                    "end_date": "10-05-2022",
                    "full_name": "Mid Luteal",
                    "short_name": "ML",
                    "duration": 7
                },
                "total": 7,
                "sport_types": {
                    "Running": 1,
                    "Cycling": 1,
                    "Triathlon": 3,
                    "Walking": 1,
                    "Alpine Ski": 1
                }
            },
            "LL": {
                "order": 4,
                "phase_details": {
                    "start_date": "11-05-2022",
                    "end_date": "15-05-2022",
                    "full_name": "Late Luteal",
                    "short_name": "LL",
                    "duration": 5
                },
                "total": 5,
                "sport_types": {
                    "Running": 1,
                    "Cycling": 1,
                    "Swimming": 1,
                    "Walking": 1,
                    "Backcountry Ski": 1
                }
            }
        }
    },
    "symptoms": {
        "title": "Symptoms Check-in Overview",
        "subtitle": "Here’s a summary of the symptoms you experienced and their severity across your cycle. Each block represents a day of your last cycle.",
        "top_checkin_var": "Injury",
        "top_severe_var": "Injury",
        "top_checkin": "The symptom you reported most often across this cycle was Injury. You reported it most frequently in the Mid Follicular phase of your cycle.\n\nThe symptom you reported most intensely this cycle was Injury. You reported it most frequently in the Mid Follicular phase of your cycle.",
        "legend": {
            "negative": [
                {
                    "colour": "#C9383F",
                    "text": "Severe"
                },
                {
                    "colour": "#F95151",
                    "text": "Moderate"
                },
                {
                    "colour": "#E9A6AA",
                    "text": "Mild"
                },
                {
                    "colour": "#BFBFBF",
                    "text": "None"
                }
            ],
            "positive": [
                {
                    "colour": "#607854",
                    "text": "Great"
                },
                {
                    "colour": "#8FAF74",
                    "text": "Ok"
                },
                {
                    "colour": "#E9A6AA",
                    "text": "Poor"
                },
                {
                    "colour": "#FFFFFF",
                    "text": "No entry"
                }
            ]
        },
        "data": {
            "Injury": [
                {
                    "max_days": 3,
                    "phase": "Period",
                    "data": {
                        "1": "#E9A6AA",
                        "2": "#F95151",
                        "3": "#F95151"
                    }
                },
                {
                    "max_days": 13,
                    "phase": "Mid Follicular",
                    "data": {
                        "1": "#BFBFBF",
                        "2": "#BFBFBF",
                        "3": "#BFBFBF",
                        "4": "#C9383F",
                        "5": "#C9383F",
                        "6": "#C9383F",
                        "7": "#E9A6AA",
                        "8": "#E9A6AA",
                        "9": "#BFBFBF",
                        "10": "#E9A6AA",
                        "11": "#BFBFBF",
                        "12": "#F95151",
                        "13": "#E9A6AA"
                    }
                },
                {
                    "max_days": 4,
                    "phase": "Ovulatory",
                    "data": {
                        "1": "#C9383F",
                        "2": "#C9383F",
                        "3": "#C9383F",
                        "4": "#E9A6AA"
                    }
                },
                {
                    "max_days": 7,
                    "phase": "Mid Luteal",
                    "data": {
                        "1": "#BFBFBF",
                        "2": "#F95151",
                        "3": "#E9A6AA",
                        "4": "#F95151",
                        "5": "#F95151",
                        "6": "#BFBFBF",
                        "7": "#BFBFBF"
                    }
                },
                {
                    "max_days": 5,
                    "phase": "Late Luteal",
                    "data": {
                        "1": "#C9383F",
                        "2": "#BFBFBF",
                        "3": "#BFBFBF",
                        "4": "#C9383F",
                        "5": "#BFBFBF"
                    }
                }
            ]
        }
    },
    "lifestyle": {
        "title": "Lifestyle Check-in Overview",
        "subtitle": "Here’s a summary of your lifestyle factors across this cycle. Each block represents a day of your last cycle.",
        "top_checkin_var": null,
        "top_severe_var": null,
        "top_checkin": null,
        "legend": {
            "negative": [
                {
                    "colour": "#C9383F",
                    "text": "Severe"
                },
                {
                    "colour": "#F95151",
                    "text": "Moderate"
                },
                {
                    "colour": "#E9A6AA",
                    "text": "Mild"
                },
                {
                    "colour": "#BFBFBF",
                    "text": "None"
                }
            ],
            "positive": [
                {
                    "colour": "#607854",
                    "text": "Great"
                },
                {
                    "colour": "#8FAF74",
                    "text": "Ok"
                },
                {
                    "colour": "#E9A6AA",
                    "text": "Poor"
                },
                {
                    "colour": "#FFFFFF",
                    "text": "No entry"
                }
            ]
        },
        "data": {
            "Running": [
                {
                    "max_days": 3,
                    "phase": "Period",
                    "data": {
                        "1": "#F95151",
                        "2": "#BFBFBF",
                        "3": "#BFBFBF"
                    }
                },
                {
                    "max_days": 13,
                    "phase": "Mid Follicular",
                    "data": {
                        "1": "#E9A6AA",
                        "2": "#BFBFBF",
                        "3": "#BFBFBF",
                        "4": "#C9383F",
                        "5": "#E9A6AA",
                        "6": "#C9383F",
                        "7": "#F95151",
                        "8": "#BFBFBF",
                        "9": "#C9383F",
                        "10": "#BFBFBF",
                        "11": "#BFBFBF",
                        "12": "#BFBFBF",
                        "13": "#E9A6AA"
                    }
                },
                {
                    "max_days": 4,
                    "phase": "Ovulatory",
                    "data": {
                        "1": "#E9A6AA",
                        "2": "#F95151",
                        "3": "#E9A6AA",
                        "4": "#BFBFBF"
                    }
                },
                {
                    "max_days": 7,
                    "phase": "Mid Luteal",
                    "data": {
                        "1": "#F95151",
                        "2": "#C9383F",
                        "3": "#F95151",
                        "4": "#BFBFBF",
                        "5": "#F95151",
                        "6": "#F95151",
                        "7": "#BFBFBF"
                    }
                },
                {
                    "max_days": 5,
                    "phase": "Late Luteal",
                    "data": {
                        "1": "#E9A6AA",
                        "2": "#E9A6AA",
                        "3": "#BFBFBF",
                        "4": "#BFBFBF",
                        "5": "#E9A6AA"
                    }
                }
            ],
        }
    }
}
export const contraceptive_group_report = {
    "cycle": {
        "title": "Contraceptive Cycle Overview",
        "subtitle": "Here’s an overview of your past contraceptive cycle lengths and phases including withdrawal bleeding levels. Your contraceptive cycle length is indicated on the right. Any changes in this may be due to changes in the days you took your active phase or inactive phase pills.",
        "legend": [
            {
                "colour": "#C9383F",
                "text": "Heavy bleeding"
            },
            {
                "colour": "#F95151",
                "text": "Medium bleeding"
            },
            {
                "colour": "#E9A6AA",
                "text": "Light bleeding"
            },
            {
                "colour": "#BFBFBF",
                "text": "Non-bleeding day"
            },
            {
                "colour": "#FFFFFF",
                "text": "No phase day"
            }
        ],
        "bullets": [
            {
                "value": "28d",
                "trend": "neutral",
                "trend_value": "0",
                "arrow_colour": "#202020",
                "text": "Your most recent contraceptive was 28 days long."
            },
            {
                "value": "21d",
                "trend": "positive",
                "trend_value": "2",
                "arrow_colour": "#202020",
                "text": "Your active phase was 21 days long."
            }
        ],
        "cycle_lengths": {
            "Last": {
                "value": "28d",
                "trend": "neutral",
                "trend_value": 0,
                "arrow_colour": "#202020"
            },
            "2nd to last": {
                "value": "28d",
                "trend": "neutral",
                "trend_value": 0,
                "arrow_colour": "#202020"
            },
            "3rd to last": {
                "value": "28d",
                "trend": "neutral",
                "trend_value": 0,
                "arrow_colour": "#202020"
            },
            "4th to last": {
                "value": "28d",
                "trend": "neutral",
                "trend_value": 0,
                "arrow_colour": "#202020"
            }
        },
        "cycles_data": [
            {
                "max_days": 21,
                "phase": "Active Phase",
                "data": [
                    {
                        "order": 0,
                        "name": "Last",
                        "data": {
                            "1": "#BFBFBF",
                            "2": "#BFBFBF",
                            "3": "#BFBFBF",
                            "4": "#BFBFBF",
                            "5": "#BFBFBF",
                            "6": "#BFBFBF",
                            "7": "#BFBFBF",
                            "8": "#BFBFBF",
                            "9": "#BFBFBF",
                            "10": "#BFBFBF",
                            "11": "#BFBFBF",
                            "12": "#BFBFBF",
                            "13": "#BFBFBF",
                            "14": "#BFBFBF",
                            "15": "#BFBFBF",
                            "16": "#BFBFBF",
                            "17": "#BFBFBF",
                            "18": "#BFBFBF",
                            "19": "#BFBFBF",
                            "20": "#BFBFBF",
                            "21": "#BFBFBF"
                        }
                    },
                    {
                        "order": 1,
                        "name": "2nd to last",
                        "data": {
                            "1": "#BFBFBF",
                            "2": "#BFBFBF",
                            "3": "#BFBFBF",
                            "4": "#BFBFBF",
                            "5": "#BFBFBF",
                            "6": "#BFBFBF",
                            "7": "#BFBFBF",
                            "8": "#BFBFBF",
                            "9": "#BFBFBF",
                            "10": "#BFBFBF",
                            "11": "#BFBFBF",
                            "12": "#BFBFBF",
                            "13": "#BFBFBF",
                            "14": "#BFBFBF",
                            "15": "#BFBFBF",
                            "16": "#BFBFBF",
                            "17": "#BFBFBF",
                            "18": "#BFBFBF",
                            "19": "#BFBFBF",
                            "20": "#BFBFBF",
                            "21": "#BFBFBF"
                        }
                    },
                    {
                        "order": 2,
                        "name": "3rd to last",
                        "data": {
                            "1": "#BFBFBF",
                            "2": "#BFBFBF",
                            "3": "#BFBFBF",
                            "4": "#BFBFBF",
                            "5": "#BFBFBF",
                            "6": "#BFBFBF",
                            "7": "#BFBFBF",
                            "8": "#BFBFBF",
                            "9": "#BFBFBF",
                            "10": "#BFBFBF",
                            "11": "#BFBFBF",
                            "12": "#BFBFBF",
                            "13": "#BFBFBF",
                            "14": "#BFBFBF",
                            "15": "#BFBFBF",
                            "16": "#BFBFBF",
                            "17": "#BFBFBF",
                            "18": "#BFBFBF",
                            "19": "#BFBFBF",
                            "20": "#BFBFBF",
                            "21": "#BFBFBF"
                        }
                    },
                    {
                        "order": 3,
                        "name": "4th to last",
                        "data": {
                            "1": "#BFBFBF",
                            "2": "#BFBFBF",
                            "3": "#BFBFBF",
                            "4": "#BFBFBF",
                            "5": "#BFBFBF",
                            "6": "#BFBFBF",
                            "7": "#BFBFBF",
                            "8": "#BFBFBF",
                            "9": "#BFBFBF",
                            "10": "#BFBFBF",
                            "11": "#BFBFBF",
                            "12": "#BFBFBF",
                            "13": "#BFBFBF",
                            "14": "#BFBFBF",
                            "15": "#BFBFBF",
                            "16": "#BFBFBF",
                            "17": "#BFBFBF",
                            "18": "#BFBFBF",
                            "19": "#BFBFBF",
                            "20": "#BFBFBF",
                            "21": "#BFBFBF"
                        }
                    }
                ]
            },
            {
                "max_days": 7,
                "phase": "Inactive Phase",
                "data": [
                    {
                        "order": 0,
                        "name": "Last",
                        "data": {
                            "1": "#BFBFBF",
                            "2": "#BFBFBF",
                            "3": "#BFBFBF",
                            "4": "#BFBFBF",
                            "5": "#BFBFBF",
                            "6": "#BFBFBF",
                            "7": "#BFBFBF"
                        }
                    },
                    {
                        "order": 1,
                        "name": "2nd to last",
                        "data": {
                            "1": "#BFBFBF",
                            "2": "#BFBFBF",
                            "3": "#BFBFBF",
                            "4": "#BFBFBF",
                            "5": "#BFBFBF",
                            "6": "#BFBFBF",
                            "7": "#BFBFBF"
                        }
                    },
                    {
                        "order": 2,
                        "name": "3rd to last",
                        "data": {
                            "1": "#BFBFBF",
                            "2": "#BFBFBF",
                            "3": "#BFBFBF",
                            "4": "#BFBFBF",
                            "5": "#BFBFBF",
                            "6": "#BFBFBF",
                            "7": "#BFBFBF"
                        }
                    },
                    {
                        "order": 3,
                        "name": "4th to last",
                        "data": {
                            "1": "#BFBFBF",
                            "2": "#BFBFBF",
                            "3": "#BFBFBF",
                            "4": "#BFBFBF",
                            "5": "#BFBFBF",
                            "6": "#BFBFBF",
                            "7": "#BFBFBF"
                        }
                    }
                ]
            }
        ]
    },
    "readiness": {
        "y_axes": [
            0,
            12,
            24,
            36,
            48,
            60
        ],
        "title": "Readiness Score",
        "subtitle": "Here’s how your readiness scores changed across your last cycle. Each bar represents a day in your last cycle, while the black lines across each phase represent the average of that phase. Your average readiness score in each phase along with whether your average score increased or decreased compared with your historical average in that phase is also displayed below the x axis..",
        "legend": [
            {
                "colour": "#D53D46",
                "text": "Low"
            },
            {
                "colour": "#F5B94B",
                "text": "Medium"
            },
            {
                "colour": "#809E69",
                "text": "High"
            },
            {
                "colour": "#000000",
                "text": "Average readiness"
            },
            {
                "colour": "arrows",
                "text": "Change from historical average"
            }
        ],
        "phase_details": [
            {
                "start_date": "19-04-2022",
                "end_date": "09-05-2022",
                "full_name": "Active Phase",
                "short_name": "AP",
                "duration": 21,
                "stats": {
                    "value": "14",
                    "trend": "positive",
                    "trend_value": "3",
                    "arrow_colour": "#5f9f61"
                }
            },
            {
                "start_date": "10-05-2022",
                "end_date": "16-05-2022",
                "full_name": "Inactive Phase",
                "short_name": "IP",
                "duration": 7,
                "stats": {
                    "value": "8",
                    "trend": "neutral",
                    "trend_value": "0",
                    "arrow_colour": "#f6b84b"
                }
            }
        ],
        "bar_graph": {
            "2022-04-19": {
                "value": 16.246498599439775,
                "colour": "#D53D46"
            },
            "2022-04-20": {
                "value": 2.3333333333333335,
                "colour": "#D53D46"
            },
            "2022-04-21": {
                "value": 20.69351230425056,
                "colour": "#D53D46"
            },
            "2022-04-22": {
                "value": 2,
                "colour": "#D53D46"
            },
            "2022-04-23": {
                "value": 2,
                "colour": "#D53D46"
            },
            "2022-04-24": {
                "value": 5.508474576271182,
                "colour": "#D53D46"
            },
            "2022-04-25": {
                "value": 1.1666666666666667,
                "colour": "#D53D46"
            },
            "2022-04-26": {
                "value": 41.23931623931624,
                "colour": "#F5B94B"
            },
            "2022-04-27": {
                "value": 2,
                "colour": "#D53D46"
            },
            "2022-04-28": {
                "value": 3.5,
                "colour": "#D53D46"
            },
            "2022-04-29": {
                "value": 2.5865444235254738,
                "colour": "#D53D46"
            },
            "2022-04-30": {
                "value": 44.34543848431287,
                "colour": "#F5B94B"
            },
            "2022-05-01": {
                "value": 8.514460967064096,
                "colour": "#D53D46"
            },
            "2022-05-02": {
                "value": 34.72222222222222,
                "colour": "#F5B94B"
            },
            "2022-05-03": {
                "value": 58.57643585176606,
                "colour": "#F5B94B"
            },
            "2022-05-04": {
                "value": 2,
                "colour": "#D53D46"
            },
            "2022-05-05": {
                "value": 22.22222222222222,
                "colour": "#D53D46"
            },
            "2022-05-06": {
                "value": 1.5,
                "colour": "#D53D46"
            },
            "2022-05-07": {
                "value": 1.75,
                "colour": "#D53D46"
            },
            "2022-05-08": {
                "value": 31.481481481481474,
                "colour": "#D53D46"
            },
            "2022-05-09": {
                "value": 1.666666666666667,
                "colour": "#D53D46"
            },
            "2022-05-10": {
                "value": 1.4,
                "colour": "#D53D46"
            },
            "2022-05-11": {
                "value": 24.188790560471972,
                "colour": "#D53D46"
            },
            "2022-05-12": {
                "value": 1,
                "colour": "#D53D46"
            },
            "2022-05-13": {
                "value": 7.870370370370374,
                "colour": "#D53D46"
            },
            "2022-05-14": {
                "value": 1.75,
                "colour": "#D53D46"
            },
            "2022-05-15": {
                "value": 20.20145812845769,
                "colour": "#D53D46"
            },
            "2022-05-16": {
                "value": 1.25,
                "colour": "#D53D46"
            }
        },
        "line_graph": {
            "19-04-2022": 14.573965430406611,
            "09-05-2022": 14.573965430406611,
            "10-05-2022": 8.237231294185719,
            "16-05-2022": 8.237231294185719
        }
    },
    "morning": {
        "title": "Morning Data",
        "subtitle": "This is how you woke up across your cycle:",
        "legend": [
            {
                "colour": "arrows",
                "text": "Indicates increase/reduction from typical phase average."
            },
            {
                "colour": "joined_dots",
                "text": "Represents daily scores. Grey shading represents average score trend across the cycle."
            }
        ],
        "graphs": [
            {
                "name": "Sleep (hours)",
                "unit": "hours",
                "symbol": "h",
                "y_axes": [
                    0,
                    1,
                    2,
                    3,
                    4,
                    5
                ],
                "phase_details": [
                    {
                        "start_date": "19-04-2022",
                        "end_date": "09-05-2022",
                        "full_name": "Active Phase",
                        "short_name": "AP",
                        "duration": 21,
                        "stats": {
                            "value": "2.2",
                            "trend": "negative",
                            "trend_value": "-1.2",
                            "arrow_colour": "#c9383f"
                        }
                    },
                    {
                        "start_date": "10-05-2022",
                        "end_date": "16-05-2022",
                        "full_name": "Inactive Phase",
                        "short_name": "IP",
                        "duration": 7,
                        "stats": {
                            "value": "2.1",
                            "trend": "neutral",
                            "trend_value": "0",
                            "arrow_colour": "#f6b84b"
                        }
                    }
                ],
                "line_graph": {
                    "2022-04-20": 5,
                    "2022-04-21": 3,
                    "2022-04-22": 0,
                    "2022-04-24": 3,
                    "2022-04-25": 0,
                    "2022-04-26": 3,
                    "2022-04-27": 3,
                    "2022-04-28": 5,
                    "2022-04-29": 0,
                    "2022-05-01": 1,
                    "2022-05-02": 1,
                    "2022-05-04": 5,
                    "2022-05-05": 1,
                    "2022-05-07": 5,
                    "2022-05-08": 0,
                    "2022-05-09": 1,
                    "2022-05-10": 1,
                    "2022-05-11": 3,
                    "2022-05-12": 1,
                    "2022-05-13": 5,
                    "2022-05-14": 0,
                    "2022-05-15": 5,
                    "2022-05-16": 0
                },
                "average_graph": {
                    "19-04-2022": 2.25,
                    "09-05-2022": 2.25,
                    "10-05-2022": 2.142857142857143,
                    "16-05-2022": 2.142857142857143
                }
            }
        ]
    },
    "training": {
        "title": "Training Check-in Overview",
        "subtitle": "Here’s a summary of your training check-ins across this cycle. Each block represents a day of your last cycle.",
        "top_checkin_var": "Workout readiness",
        "top_severe_var": "Workout readiness",
        "top_checkin": "Across your last cycle you checked-in for \"Workout readiness\" most. You reported it most frequently in the Active Phase phase of your cycle.\n\nThe symptom you reported most intensely this cycle was Workout readiness. You reported it most frequently in the Active Phase phase of your cycle.",
        "legend": {
            "negative": [
                {
                    "colour": "#C9383F",
                    "text": "Severe"
                },
                {
                    "colour": "#F95151",
                    "text": "Moderate"
                },
                {
                    "colour": "#E9A6AA",
                    "text": "Mild"
                },
                {
                    "colour": "#BFBFBF",
                    "text": "None"
                }
            ],
            "positive": [
                {
                    "colour": "#607854",
                    "text": "Great"
                },
                {
                    "colour": "#8FAF74",
                    "text": "Ok"
                },
                {
                    "colour": "#E9A6AA",
                    "text": "Poor"
                },
                {
                    "colour": "#FFFFFF",
                    "text": "No entry"
                }
            ]
        },
        "data": {
            "Workout readiness": [
                {
                    "max_days": 21,
                    "phase": "Active Phase",
                    "data": {
                        "1": "#B1BFA4",
                        "2": "#B1BFA4",
                        "3": "#8FAF74",
                        "4": "#8FAF74",
                        "5": "#E9A6AA",
                        "6": "#8FAF74",
                        "7": "#8FAF74",
                        "8": "#B1BFA4",
                        "9": "#E9A6AA",
                        "10": "#E9A6AA",
                        "11": "#607854",
                        "12": "#8FAF74",
                        "13": "#E9A6AA",
                        "14": "#607854",
                        "15": "#E9A6AA",
                        "16": "#8FAF74",
                        "17": "#B1BFA4",
                        "18": "#E9A6AA",
                        "19": "#8FAF74",
                        "20": "#8FAF74",
                        "21": "#8FAF74"
                    }
                },
                {
                    "max_days": 7,
                    "phase": "Inactive Phase",
                    "data": {
                        "1": "#B1BFA4",
                        "2": "#B1BFA4",
                        "3": "#8FAF74",
                        "4": "#B1BFA4",
                        "5": "#E9A6AA",
                        "6": "#8FAF74",
                        "7": "#8FAF74"
                    }
                }
            ]
        }
    },
    "training_load": {
        "title": "Training Load",
        "subtitle": "On average across this cycle you spent 16 day(s) in an optimal state, with 5 day(s) spent in an over-trained state and 7 day(s) spent in a sub-optimal state.\n\nThis is an estimate of how hard you trained across this cycle, providing an indication of whether your training load is optimal or not.",
        "legend": [
            {
                "colour": "arrows",
                "text": "Indicates increase/reduction in % from typical phase average."
            },
            {
                "colour": "joined_dots",
                "text": "Represents daily scores. Grey shading represents average score trend across the cycle."
            }
        ],
        "data": {
            "name": "Training Load",
            "unit": "",
            "symbol": "",
            "y_axes": {
                "Suboptimal": {
                    "range": [
                        0,
                        1
                    ],
                    "colour": "#FFFFFF"
                },
                "Optimal": {
                    "range": [
                        1,
                        2
                    ],
                    "colour": "#8FAF74"
                },
                "Overtrained": {
                    "range": [
                        2,
                        3
                    ],
                    "colour": "#FFFFFF"
                }
            },
            "phase_details": [
                {
                    "start_date": "19-04-2022",
                    "end_date": "09-05-2022",
                    "full_name": "Active Phase",
                    "short_name": "AP",
                    "duration": 21,
                    "stats": {
                        "value": "1.4",
                        "trend": "positive",
                        "trend_value": "0.8",
                        "arrow_colour": "#5f9f61"
                    }
                },
                {
                    "start_date": "10-05-2022",
                    "end_date": "16-05-2022",
                    "full_name": "Inactive Phase",
                    "short_name": "IP",
                    "duration": 7,
                    "stats": {
                        "value": "1.7",
                        "trend": "positive",
                        "trend_value": "1",
                        "arrow_colour": "#5f9f61"
                    }
                }
            ],
            "line_graph": {
                "2022-04-19": 0.9686353392007171,
                "2022-04-20": 1.1323319266148608,
                "2022-04-21": 0.9345371922965137,
                "2022-04-22": 0.6691974699318798,
                "2022-04-23": 0.7265566718230754,
                "2022-04-24": 0.7663467277189256,
                "2022-04-25": 1.0042078066926228,
                "2022-04-26": 0.9151140411556485,
                "2022-04-27": 1.2723385064701989,
                "2022-04-28": 2.0445123573006625,
                "2022-04-29": 2.048093570877429,
                "2022-04-30": 2.0158529270097776,
                "2022-05-01": 1.8904846954577104,
                "2022-05-02": 2.009547558419343,
                "2022-05-03": 1.8181490170190822,
                "2022-05-04": 1.8244188412704978,
                "2022-05-05": 1.4230480554370977,
                "2022-05-06": 1.8816297482177275,
                "2022-05-07": 1.488662338039279,
                "2022-05-08": 1.0997844933529923,
                "2022-05-09": 0.8430978445153184,
                "2022-05-10": 1.3970914492308888,
                "2022-05-11": 1.745069526189206,
                "2022-05-12": 1.5692550999361305,
                "2022-05-13": 1.8423073898623796,
                "2022-05-14": 2.001254519516413,
                "2022-05-15": 1.563267025317189,
                "2022-05-16": 1.8351945429965526
            },
            "average_graph": {
                "19-04-2022": 1.3614222152703972,
                "09-05-2022": 1.3614222152703972,
                "10-05-2022": 1.7084227769887108,
                "16-05-2022": 1.7084227769887108
            }
        }
    },
    "training_sessions": {
        "sessions_num": 28,
        "top_sport_type": "Hiking",
        "title": "Training Session & Load Overview",
        "subtitle": "You performed 28 training session(s) in this cycle. Here’s the break down by phase:",
        "legend": [
            {
                "colour": "#43573E",
                "text": "Running"
            },
            {
                "colour": "#607854",
                "text": "Cycling"
            },
            {
                "colour": "#708B5E",
                "text": "Swimming"
            },
            {
                "colour": "#809E69",
                "text": "Triathlon"
            },
            {
                "colour": "#8FAF74",
                "text": "Hiking"
            },
            {
                "colour": "#A0B78B",
                "text": "Walking"
            },
            {
                "colour": "#B1BFA4",
                "text": "Alpine Ski"
            },
            {
                "colour": "#C7C9C6",
                "text": "Backcountry Ski"
            },
            {
                "colour": "#F09136",
                "text": "Ice Skate"
            },
            {
                "colour": "#F5B94B",
                "text": "Snowboard"
            },
            {
                "colour": "#F9D055",
                "text": "Nordic Ski"
            }
        ],
        "y_axes": [
            0,
            5,
            10,
            15,
            20,
            25
        ],
        "stacked_bars": {
            "AP": {
                "order": 0,
                "phase_details": {
                    "start_date": "19-04-2022",
                    "end_date": "09-05-2022",
                    "full_name": "Active Phase",
                    "short_name": "AP",
                    "duration": 21
                },
                "total": 21,
                "sport_types": {
                    "Cycling": 1,
                    "Swimming": 3,
                    "Triathlon": 2,
                    "Hiking": 4,
                    "Walking": 3,
                    "Alpine Ski": 1,
                    "Backcountry Ski": 2,
                    "Snowboard": 4,
                    "Nordic Ski": 1
                }
            },
            "IP": {
                "order": 1,
                "phase_details": {
                    "start_date": "10-05-2022",
                    "end_date": "16-05-2022",
                    "full_name": "Inactive Phase",
                    "short_name": "IP",
                    "duration": 7
                },
                "total": 7,
                "sport_types": {
                    "Running": 2,
                    "Walking": 1,
                    "Alpine Ski": 1,
                    "Backcountry Ski": 2,
                    "Ice Skate": 1
                }
            }
        }
    },
    "symptoms": {
        "title": "Symptoms Check-in Overview",
        "subtitle": "Here’s a summary of the symptoms you experienced and their severity across your cycle. Each block represents a day of your last cycle.",
        "top_checkin_var": "Injury",
        "top_severe_var": "Injury",
        "top_checkin": "The symptom you reported most often across this cycle was Injury. You reported it most frequently in the Active Phase phase of your cycle.\n\nThe symptom you reported most intensely this cycle was Injury. You reported it most frequently in the Active Phase phase of your cycle.",
        "legend": {
            "negative": [
                {
                    "colour": "#C9383F",
                    "text": "Severe"
                },
                {
                    "colour": "#F95151",
                    "text": "Moderate"
                },
                {
                    "colour": "#E9A6AA",
                    "text": "Mild"
                },
                {
                    "colour": "#BFBFBF",
                    "text": "None"
                }
            ],
            "positive": [
                {
                    "colour": "#607854",
                    "text": "Great"
                },
                {
                    "colour": "#8FAF74",
                    "text": "Ok"
                },
                {
                    "colour": "#E9A6AA",
                    "text": "Poor"
                },
                {
                    "colour": "#FFFFFF",
                    "text": "No entry"
                }
            ]
        },
        "data": {
            "Injury": [
                {
                    "max_days": 21,
                    "phase": "Active Phase",
                    "data": {
                        "1": "#C9383F",
                        "2": "#E9A6AA",
                        "3": "#E9A6AA",
                        "4": "#BFBFBF",
                        "5": "#BFBFBF",
                        "6": "#BFBFBF",
                        "7": "#C9383F",
                        "8": "#F95151",
                        "9": "#BFBFBF",
                        "10": "#E9A6AA",
                        "11": "#C9383F",
                        "12": "#E9A6AA",
                        "13": "#BFBFBF",
                        "14": "#E9A6AA",
                        "15": "#BFBFBF",
                        "16": "#E9A6AA",
                        "17": "#E9A6AA",
                        "18": "#BFBFBF",
                        "19": "#C9383F",
                        "20": "#BFBFBF",
                        "21": "#F95151"
                    }
                },
                {
                    "max_days": 7,
                    "phase": "Inactive Phase",
                    "data": {
                        "1": "#C9383F",
                        "2": "#E9A6AA",
                        "3": "#F95151",
                        "4": "#BFBFBF",
                        "5": "#E9A6AA",
                        "6": "#BFBFBF",
                        "7": "#F95151"
                    }
                }
            ]
        }
    },
    "lifestyle": {
        "title": "Symptoms Check-in Overview",
        "subtitle": "Here’s a summary of the symptoms you experienced and their severity across your cycle. Each block represents a day of your last cycle.",
        "top_checkin_var": "Injury",
        "top_severe_var": "Injury",
        "top_checkin": "The symptom you reported most often across this cycle was Injury. You reported it most frequently in the Active Phase phase of your cycle.\n\nThe symptom you reported most intensely this cycle was Injury. You reported it most frequently in the Active Phase phase of your cycle.",
        "legend": {
            "negative": [
                {
                    "colour": "#C9383F",
                    "text": "Severe"
                },
                {
                    "colour": "#F95151",
                    "text": "Moderate"
                },
                {
                    "colour": "#E9A6AA",
                    "text": "Mild"
                },
                {
                    "colour": "#BFBFBF",
                    "text": "None"
                }
            ],
            "positive": [
                {
                    "colour": "#607854",
                    "text": "Great"
                },
                {
                    "colour": "#8FAF74",
                    "text": "Ok"
                },
                {
                    "colour": "#E9A6AA",
                    "text": "Poor"
                },
                {
                    "colour": "#FFFFFF",
                    "text": "No entry"
                }
            ]
        },
        "data": {
            "Running": [
                {
                    "max_days": 21,
                    "phase": "Active Phase",
                    "data": {
                        "1": "#E9A6AA",
                        "2": "#BFBFBF",
                        "3": "#BFBFBF",
                        "4": "#C9383F",
                        "5": "#F95151",
                        "6": "#BFBFBF",
                        "7": "#F95151",
                        "8": "#BFBFBF",
                        "9": "#C9383F",
                        "10": "#C9383F",
                        "11": "#BFBFBF",
                        "12": "#E9A6AA",
                        "13": "#F95151",
                        "14": "#BFBFBF",
                        "15": "#BFBFBF",
                        "16": "#E9A6AA",
                        "17": "#BFBFBF",
                        "18": "#E9A6AA",
                        "19": "#BFBFBF",
                        "20": "#E9A6AA",
                        "21": "#E9A6AA"
                    }
                },
                {
                    "max_days": 7,
                    "phase": "Inactive Phase",
                    "data": {
                        "1": "#E9A6AA",
                        "2": "#BFBFBF",
                        "3": "#BFBFBF",
                        "4": "#C9383F",
                        "5": "#F95151",
                        "6": "#BFBFBF",
                        "7": "#BFBFBF"
                    }
                }
            ],
        }
    }
}
export const monthly_report = {
    "cycle": null,
    "readiness": {
        "y_axes": [
            0,
            12,
            24,
            36,
            48,
            60
        ],
        "title": "Readiness Score",
        "subtitle": "Here’s how your readiness scores changed in May. Each bar represents a day in the last month, while the black line represents the average over the month.",
        "legend": [
            {
                "colour": "#D53D46",
                "text": "Low"
            },
            {
                "colour": "#F5B94B",
                "text": "Medium"
            },
            {
                "colour": "#809E69",
                "text": "High"
            },
            {
                "colour": "#000000",
                "text": "Average readiness"
            }
        ],
        "phase_details": [
            {
                "start_date": "01-05-2022",
                "end_date": "31-05-2022",
                "full_name": "Menopause",
                "short_name": "ME",
                "duration": 31,
                "stats": {
                    "value": null,
                    "trend": null,
                    "trend_value": null,
                    "arrow_colour": null
                }
            }
        ],
        "bar_graph": {
            "2022-05-01": {
                "value": 58.82352941176471,
                "colour": "#F5B94B"
            },
            "2022-05-02": {
                "value": 1.1666666666666667,
                "colour": "#D53D46"
            },
            "2022-05-03": {
                "value": 1.5,
                "colour": "#D53D46"
            },
            "2022-05-04": {
                "value": 1.333333333333333,
                "colour": "#D53D46"
            },
            "2022-05-05": {
                "value": 21.864111498257827,
                "colour": "#D53D46"
            },
            "2022-05-06": {
                "value": 2,
                "colour": "#D53D46"
            },
            "2022-05-07": {
                "value": 43.18734793187349,
                "colour": "#F5B94B"
            },
            "2022-05-08": {
                "value": 15.846994535519123,
                "colour": "#D53D46"
            },
            "2022-05-09": {
                "value": 1.2,
                "colour": "#D53D46"
            },
            "2022-05-10": {
                "value": 1.5,
                "colour": "#D53D46"
            },
            "2022-05-11": {
                "value": 2,
                "colour": "#D53D46"
            },
            "2022-05-12": {
                "value": 16.666666666666664,
                "colour": "#D53D46"
            },
            "2022-05-13": {
                "value": 18.306010928961747,
                "colour": "#D53D46"
            },
            "2022-05-14": {
                "value": 2,
                "colour": "#D53D46"
            },
            "2022-05-15": {
                "value": 13.114754098360656,
                "colour": "#D53D46"
            },
            "2022-05-16": {
                "value": 59.16030534351145,
                "colour": "#F5B94B"
            },
            "2022-05-17": {
                "value": 1.3107846190272614,
                "colour": "#D53D46"
            },
            "2022-05-18": {
                "value": 1.4351908480621836,
                "colour": "#D53D46"
            },
            "2022-05-19": {
                "value": 37.95518207282913,
                "colour": "#F5B94B"
            },
            "2022-05-20": {
                "value": 14.837398373983739,
                "colour": "#D53D46"
            },
            "2022-05-21": {
                "value": 1.666666666666667,
                "colour": "#D53D46"
            },
            "2022-05-22": {
                "value": 2,
                "colour": "#D53D46"
            },
            "2022-05-23": {
                "value": 19.347826086956523,
                "colour": "#D53D46"
            },
            "2022-05-24": {
                "value": 2.3333333333333335,
                "colour": "#D53D46"
            },
            "2022-05-25": {
                "value": 33.72093023255813,
                "colour": "#F5B94B"
            },
            "2022-05-26": {
                "value": 36.72316384180792,
                "colour": "#F5B94B"
            },
            "2022-05-27": {
                "value": 2.387739967748105,
                "colour": "#D53D46"
            },
            "2022-05-28": {
                "value": 1.4969164230745824,
                "colour": "#D53D46"
            },
            "2022-05-29": {
                "value": 1.666666666666667,
                "colour": "#D53D46"
            },
            "2022-05-30": {
                "value": 1.6,
                "colour": "#D53D46"
            },
            "2022-05-31": {
                "value": 12.704918032786876,
                "colour": "#D53D46"
            }
        },
        "line_graph": {
            "01-05-2022": 13.898594760658606,
            "31-05-2022": 13.898594760658606
        }
    },
    "morning": {
        "title": "Morning Data",
        "subtitle": "This is how you woke up in May:",
        "legend": [
            {
                "colour": "joined_dots",
                "text": "Represents daily scores. Grey shading represents average score trend across the last month."
            }
        ],
        "graphs": [
            {
                "name": "Sleep (hours)",
                "unit": "hours",
                "symbol": "h",
                "y_axes": [
                    0,
                    1,
                    2,
                    3,
                    4,
                    5
                ],
                "phase_details": [
                    {
                        "start_date": "01-05-2022",
                        "end_date": "31-05-2022",
                        "full_name": "Menopause",
                        "short_name": "ME",
                        "duration": 31,
                        "stats": {
                            "value": null,
                            "trend": null,
                            "trend_value": null,
                            "arrow_colour": null
                        }
                    }
                ],
                "line_graph": {
                    "2022-05-01": 3,
                    "2022-05-02": 0,
                    "2022-05-04": 3,
                    "2022-05-05": 1,
                    "2022-05-07": 3,
                    "2022-05-09": 0,
                    "2022-05-10": 1,
                    "2022-05-11": 0,
                    "2022-05-12": 0,
                    "2022-05-13": 5,
                    "2022-05-14": 5,
                    "2022-05-15": 5,
                    "2022-05-16": 5,
                    "2022-05-17": 3,
                    "2022-05-18": 3,
                    "2022-05-19": 5,
                    "2022-05-20": 3,
                    "2022-05-22": 0,
                    "2022-05-24": 0,
                    "2022-05-25": 3,
                    "2022-05-26": 5,
                    "2022-05-27": 1,
                    "2022-05-28": 1,
                    "2022-05-29": 0,
                    "2022-05-30": 1,
                    "2022-05-31": 3
                },
                "average_graph": {
                    "01-05-2022": 2.269230769230769,
                    "31-05-2022": 2.269230769230769
                }
            }
        ]
    },
    "training": {
        "title": "Training Check-in Overview",
        "subtitle": "Here’s a summary of your training check-ins over the last month. Each block represents a day of month.",
        "top_checkin_var": "Workout readiness",
        "top_severe_var": "Workout readiness",
        "top_checkin": "Across last month you checked-in for \"Workout readiness\" most.\n\nThe symptom you reported most intensely this month was Workout readiness.",
        "legend": {
            "negative": [
                {
                    "colour": "#C9383F",
                    "text": "Severe"
                },
                {
                    "colour": "#F95151",
                    "text": "Moderate"
                },
                {
                    "colour": "#E9A6AA",
                    "text": "Mild"
                },
                {
                    "colour": "#BFBFBF",
                    "text": "None"
                }
            ],
            "positive": [
                {
                    "colour": "#607854",
                    "text": "Great"
                },
                {
                    "colour": "#8FAF74",
                    "text": "Ok"
                },
                {
                    "colour": "#E9A6AA",
                    "text": "Poor"
                },
                {
                    "colour": "#FFFFFF",
                    "text": "No entry"
                }
            ]
        },
        "data": {
            "Workout readiness": [
                {
                    "max_days": 31,
                    "phase": "Menopause",
                    "data": {
                        "1": "#B1BFA4",
                        "2": "#607854",
                        "3": "#8FAF74",
                        "4": "#E9A6AA",
                        "5": "#B1BFA4",
                        "6": "#8FAF74",
                        "7": "#B1BFA4",
                        "8": "#607854",
                        "9": "#8FAF74",
                        "10": "#E9A6AA",
                        "11": "#607854",
                        "12": "#607854",
                        "13": "#607854",
                        "14": "#8FAF74",
                        "15": "#8FAF74",
                        "16": "#8FAF74",
                        "17": "#8FAF74",
                        "18": "#607854",
                        "19": "#8FAF74",
                        "20": "#607854",
                        "21": "#607854",
                        "22": "#8FAF74",
                        "23": "#E9A6AA",
                        "24": "#607854",
                        "25": "#B1BFA4",
                        "26": "#8FAF74",
                        "27": "#E9A6AA",
                        "28": "#607854",
                        "29": "#8FAF74",
                        "30": "#B1BFA4",
                        "31": "#FFFFFF"
                    }
                }
            ]
        }
    },
    "training_load": {
        "title": "Training Load",
        "subtitle": "On average across the last month you spent 18 day(s) in an optimal state, with 4 day(s) spent in an over-trained state and 9 day(s) spent in a sub-optimal state.\n\nThis is an estimate of how hard you trained across the last month, providing an indication of whether your training load is optimal or not.",
        "legend": [
            {
                "colour": "joined_dots",
                "text": "Represents daily scores. Grey shading represents average score trend across the last month."
            }
        ],
        "data": {
            "name": "Training Load",
            "unit": "",
            "symbol": "",
            "y_axes": {
                "Suboptimal": {
                    "range": [
                        0,
                        1
                    ],
                    "colour": "#FFFFFF"
                },
                "Optimal": {
                    "range": [
                        1,
                        2
                    ],
                    "colour": "#8FAF74"
                },
                "Overtrained": {
                    "range": [
                        2,
                        3
                    ],
                    "colour": "#FFFFFF"
                }
            },
            "phase_details": [
                {
                    "start_date": "01-05-2022",
                    "end_date": "31-05-2022",
                    "full_name": "Menopause",
                    "short_name": "ME",
                    "duration": 31,
                    "stats": {
                        "value": null,
                        "trend": null,
                        "trend_value": null,
                        "arrow_colour": null
                    }
                }
            ],
            "line_graph": {
                "2022-05-01": 0.9914183138434015,
                "2022-05-02": 0.8466853279802392,
                "2022-05-03": 1.576640889211268,
                "2022-05-04": 1.0625275359354207,
                "2022-05-05": 0.8552212653547432,
                "2022-05-06": 0.88548125402397,
                "2022-05-07": 0.7509435731607319,
                "2022-05-08": 0.6420041633437499,
                "2022-05-09": 0.5686268928568772,
                "2022-05-10": 0.4934059405223576,
                "2022-05-11": 1.0899930877568984,
                "2022-05-12": 1.0233970891590383,
                "2022-05-13": 0.9605118507149973,
                "2022-05-14": 1.8199819363131966,
                "2022-05-15": 1.6858682422786844,
                "2022-05-16": 2.0250541270067464,
                "2022-05-17": 2.06400903895093,
                "2022-05-18": 1.7621256542083144,
                "2022-05-19": 1.3862189206319517,
                "2022-05-20": 1.1186704932362288,
                "2022-05-21": 1.116639539155363,
                "2022-05-22": 1.5469809343821113,
                "2022-05-23": 1.2597600737625105,
                "2022-05-24": 1.2644010535774606,
                "2022-05-25": 1.8307249785906667,
                "2022-05-26": 2.066524463556678,
                "2022-05-27": 2.0030455080744862,
                "2022-05-28": 1.715997349233517,
                "2022-05-29": 1.9031770654973719,
                "2022-05-30": 1.5719998384040816,
                "2022-05-31": 1.3694926102277412
            },
            "average_graph": {
                "01-05-2022": 1.3308880326113461,
                "31-05-2022": 1.3308880326113461
            }
        }
    },
    "training_sessions": {
        "sessions_num": 31,
        "top_sport_type": "Ice Skate",
        "title": "Training Session & Load Overview",
        "subtitle": "You performed 31 training session(s) last month. Here’s the break down:",
        "legend": [
            {
                "colour": "#43573E",
                "text": "Running"
            },
            {
                "colour": "#607854",
                "text": "Cycling"
            },
            {
                "colour": "#708B5E",
                "text": "Swimming"
            },
            {
                "colour": "#809E69",
                "text": "Triathlon"
            },
            {
                "colour": "#8FAF74",
                "text": "Hiking"
            },
            {
                "colour": "#A0B78B",
                "text": "Walking"
            },
            {
                "colour": "#B1BFA4",
                "text": "Alpine Ski"
            },
            {
                "colour": "#C7C9C6",
                "text": "Backcountry Ski"
            },
            {
                "colour": "#F09136",
                "text": "Ice Skate"
            },
            {
                "colour": "#F5B94B",
                "text": "Snowboard"
            },
            {
                "colour": "#F9D055",
                "text": "Nordic Ski"
            }
        ],
        "y_axes": [
            0,
            2,
            4,
            6,
            8,
            10
        ],
        "stacked_bars": {
            "ME": {
                "order": 0,
                "phase_details": {
                    "start_date": "01-05-2022",
                    "end_date": "31-05-2022",
                    "full_name": "Menopause",
                    "short_name": "ME",
                    "duration": 31
                },
                "total": 31,
                "sport_types": {
                    "Running": 1,
                    "Cycling": 3,
                    "Swimming": 1,
                    "Triathlon": 4,
                    "Hiking": 1,
                    "Walking": 3,
                    "Alpine Ski": 4,
                    "Backcountry Ski": 2,
                    "Ice Skate": 6,
                    "Snowboard": 3,
                    "Nordic Ski": 3
                }
            }
        }
    },
    "symptoms": {
        "title": "Symptoms Check-in Overview",
        "subtitle": "Here’s a summary of the symptoms you experienced and their severity over the last month. Each block represents a day of the month.",
        "top_checkin_var": "Illness",
        "top_severe_var": "Illness",
        "top_checkin": "The symptom you reported most often across this cycle was Illness.\n\nThe symptom you reported most intensely this month was Illness.",
        "legend": {
            "negative": [
                {
                    "colour": "#C9383F",
                    "text": "Severe"
                },
                {
                    "colour": "#F95151",
                    "text": "Moderate"
                },
                {
                    "colour": "#E9A6AA",
                    "text": "Mild"
                },
                {
                    "colour": "#BFBFBF",
                    "text": "None"
                }
            ],
            "positive": [
                {
                    "colour": "#607854",
                    "text": "Great"
                },
                {
                    "colour": "#8FAF74",
                    "text": "Ok"
                },
                {
                    "colour": "#E9A6AA",
                    "text": "Poor"
                },
                {
                    "colour": "#FFFFFF",
                    "text": "No entry"
                }
            ]
        },
        "data": {
            "Illness": [
                {
                    "max_days": 31,
                    "phase": "Menopause",
                    "data": {
                        "1": "#E9A6AA",
                        "2": "#F95151",
                        "3": "#C9383F",
                        "4": "#BFBFBF",
                        "5": "#BFBFBF",
                        "6": "#C9383F",
                        "7": "#BFBFBF",
                        "8": "#F95151",
                        "9": "#BFBFBF",
                        "10": "#F95151",
                        "11": "#E9A6AA",
                        "12": "#E9A6AA",
                        "13": "#F95151",
                        "14": "#E9A6AA",
                        "15": "#BFBFBF",
                        "16": "#BFBFBF",
                        "17": "#F95151",
                        "18": "#E9A6AA",
                        "19": "#BFBFBF",
                        "20": "#E9A6AA",
                        "21": "#E9A6AA",
                        "22": "#BFBFBF",
                        "23": "#E9A6AA",
                        "24": "#E9A6AA",
                        "25": "#BFBFBF",
                        "26": "#BFBFBF",
                        "27": "#BFBFBF",
                        "28": "#BFBFBF",
                        "29": "#C9383F",
                        "30": "#C9383F",
                        "31": "#BFBFBF"
                    }
                }
            ],
        }
    },
    "lifestyle": {
        "title": "Lifestyle Check-in Overview",
        "subtitle": "Here’s a summary of your lifestyle factors over the last month. Each block represents a day of the month.",
        "top_checkin": null,
        "top_checkin_var": null,
        "top_severe_var": null,
        "legend": {
            "negative": [
                {
                    "colour": "#C9383F",
                    "text": "Severe"
                },
                {
                    "colour": "#F95151",
                    "text": "Moderate"
                },
                {
                    "colour": "#E9A6AA",
                    "text": "Mild"
                },
                {
                    "colour": "#BFBFBF",
                    "text": "None"
                }
            ],
            "positive": [
                {
                    "colour": "#607854",
                    "text": "Great"
                },
                {
                    "colour": "#8FAF74",
                    "text": "Ok"
                },
                {
                    "colour": "#E9A6AA",
                    "text": "Poor"
                },
                {
                    "colour": "#FFFFFF",
                    "text": "No entry"
                }
            ]
        },
        "data": {
            "Running": [
                {
                    "max_days": 31,
                    "phase": "Menopause",
                    "data": {
                        "1": "#E9A6AA",
                        "2": "#F95151",
                        "3": "#C9383F",
                        "4": "#BFBFBF",
                        "5": "#BFBFBF",
                        "6": "#C9383F",
                        "7": "#BFBFBF",
                        "8": "#F95151",
                        "9": "#BFBFBF",
                        "10": "#F95151",
                        "11": "#E9A6AA",
                        "12": "#E9A6AA",
                        "13": "#F95151",
                        "14": "#E9A6AA",
                        "15": "#BFBFBF",
                        "16": "#BFBFBF",
                        "17": "#F95151",
                        "18": "#E9A6AA",
                        "19": "#BFBFBF",
                        "20": "#E9A6AA",
                        "21": "#E9A6AA",
                        "22": "#BFBFBF",
                        "23": "#E9A6AA",
                        "24": "#E9A6AA",
                        "25": "#BFBFBF",
                        "26": "#BFBFBF",
                        "27": "#BFBFBF",
                        "28": "#BFBFBF",
                        "29": "#C9383F",
                        "30": "#C9383F",
                        "31": "#BFBFBF"
                    }
                }
            ],
        }
    },
}