import React, { useState, useEffect } from "react";
import CommonReportHeader from "../../common/CommonReportHeader";
import GraphLegends from "../../common/GraphLegends";
import EmptyGraph from "../../common/EmptyGraph";
import { monthly_report } from "../../../js/static_report";
import moment from "moment";



const TrainingSession = (props) => {
    let [y_axes, setYaxes] = useState(null);
    let [legends, setLegends] = useState(null);
    let [content, setContent] = useState(null);
    let [graph_width, set_graph_width] = useState([]);
    let [graph, setGraph] = useState([]);
    let [stack_bars, set_stack_bars] = useState([]);
    let [showSportType, setShowSportType] = useState(-1);
    let [phaseLength, setPhaseLength] = useState(0);
    let [show_empty_graph, set_show_empty_graph] = useState(false);


    const reverseyAxesArray = (y_axes) => {
        y_axes.splice(0, 1);
        y_axes = y_axes.reverse();
        return y_axes;
    }

    const getDaysDiffForIndexing = (start_date, end_date) => {
        const date1 = new Date(start_date);
        const date2 = new Date(end_date);
        function getDifferenceInDays(date1, date2) {
            const diffInMs = Math.abs(date2 - date1);
            return diffInMs / (1000 * 60 * 60 * 24);
        }
        return getDifferenceInDays(date1, date2) + 1;
    }

    const covertCycleDataDictionaryIntoArray = (dictionary) => {
        let array = [];
        if (Object.keys(dictionary).length > 0) {
            for (var i = 1; i <= Object.keys(dictionary).length; i++) {
                array[i - 1] = Object.values(dictionary)[i - 1];
            }
        }
        return array;
    }

    useEffect(() => {
        if (props.trainingSessions) {
            let { training_sessions: { legend, title, subtitle, y_axes, stacked_bars, sessions_num } } = props.trainingSessions;
            if (sessions_num === 0 && !props.dashboard) {
                set_show_empty_graph(true);
                let { legend, title, subtitle, y_axes, stacked_bars } = monthly_report['training_sessions'];
                setLegends(legend);
                setYaxes(reverseyAxesArray(y_axes));
                setContent({
                    title: title || null,
                    subtitle: subtitle || null
                });
                setPhaseLength(Object.keys(stacked_bars).length);
                setGraph(covertCycleDataDictionaryIntoArray(stacked_bars));
            } else if (sessions_num === 0 && props.dashboard) {
                setLegends(legend);
                setYaxes(reverseyAxesArray(y_axes));
                setContent({
                    title: title || null,
                    subtitle: subtitle || null
                });
                setPhaseLength(Object.keys(stacked_bars).length);
                setGraph(covertCycleDataDictionaryIntoArray(stacked_bars));
            } else {
                setLegends(legend);
                setYaxes(reverseyAxesArray(y_axes));
                setContent({
                    title: title || null,
                    subtitle: subtitle || null
                });
                setPhaseLength(Object.keys(stacked_bars).length);
                setGraph(covertCycleDataDictionaryIntoArray(stacked_bars));
            }
        }
    }, [props.trainingSessions]);


    const findColourFromLegend = (type) => {
        const color = legends.find((legend) => legend.text === type);
        return color.colour;
    }

    const calculateSumOfValues = (arr) => {
        let sum = 0;
        for (let i in arr) {
            sum += arr[i]
        }
        return sum;
    }

    const calculateHeight = (val) => {
        if (y_axes) {
            let base_height = (y_axes.length * 60) / y_axes[0];
            return val * base_height;
        }
    }

    useEffect(() => {
        if (graph && graph.length > 0) {
            let start_date = moment(graph[0].phase_details.start_date, 'DD-MM-YYYY').format('YYYY-MM-DD');
            let end_date = moment(graph[graph.length - 1].phase_details.end_date, 'DD-MM-YYYY').format('YYYY-MM-DD');
            const days = getDaysDiffForIndexing(start_date, end_date);
            set_graph_width(days);

            let stackBars = graph.map((item) => {
                start_date = moment(item.phase_details.start_date, 'DD-MM-YYYY').format('YYYY-MM-DD');
                end_date = moment(item.phase_details.end_date, 'DD-MM-YYYY').format('YYYY-MM-DD');

                let sportTypes = Object.keys(item.sport_types).reverse();
                let sportValues = Object.values(item.sport_types).reverse();
                let heights = sportValues.map((type_value) => calculateHeight(type_value));
                let colors = sportTypes.map((type_value) => findColourFromLegend(type_value));
                return {
                    width: 120,
                    heights,
                    colors,
                    sportTypes,
                    values: sportValues,
                    phase_name: item.phase_details.full_name
                }
            });

            set_stack_bars(stackBars);
        }
    }, [graph]);

    return !props ? (
        <div className="flex column justifyStart alignStart w-100" style={{ margin: "40px 0 0" }}>
            <CommonReportHeader title="Training Sessions" subtitle={null} />
        </div>
    ) : legends && (
        <div className="flex column justifyStart alignStart relative w-100" style={{ margin: "40px 0 0" }}>
            {content && (<CommonReportHeader title={content.title} subtitle={content.subtitle} isSubtitleUnder={true} />)}
            <EmptyGraph show={show_empty_graph} message="No data to show! Complete your check-ins in Wild AI to see your data." />

            <div className="flex column justifyStart alignStart w-100 scrollable" style={{ paddingBottom: 24 }}>

                <div className="flex column justifyStart alignStart w-100" style={{ position: "relative", margin: "24px 0", minWidth: 120 * 5 }}>
                    {/* axes */}
                    {y_axes && y_axes.map((axe, index) => (
                        <div key={index + 1} className="flex justifyStart alignStart w-100" style={{ borderBottom: "1px solid #737373", borderTop: index === 0 ? "1px solid #737373" : 0, minWidth: graph_width * 28 + 120, height: 60 }}>
                            <p style={{ color: "#737373" }}>{`${axe}`}</p>
                        </div>
                    ))}
                    {/* bar with line and circle */}
                    <div className="flex alignEnd" style={{ position: "absolute", bottom: 0 }}>
                        {stack_bars && stack_bars.length > 1 && stack_bars.map((startbar, sIndex) => (
                            <div className="flex column alignEnd" style={{ position: "relative", marginLeft: sIndex === 0 ? 96 : 48 }}>
                                {startbar['heights'].length > 0 ? startbar['heights'].map((barHeight, hcIndex) => {
                                    return (
                                        <div className="flex column alignCenter justifyCenter" key={`bar_${hcIndex + 1}`}
                                            style={{
                                                position: "relative", textAlign: 'center',
                                                background: startbar['colors'][hcIndex],
                                                minWidth: startbar.width,
                                                height: barHeight,
                                                maxWidth: startbar.width,
                                                transition: `all .2s ease-in-out`,
                                                transform: showSportType === `bar_${sIndex + 1}_${startbar['sportTypes'][hcIndex]}` ? `scale(1.1)` : `scale(1)`,
                                            }}
                                            onMouseEnter={() => setShowSportType(`bar_${sIndex + 1}_${startbar['sportTypes'][hcIndex]}`)}
                                            onMouseLeave={() => setShowSportType(-1)}
                                        >
                                            {showSportType === `bar_${sIndex + 1}_${startbar['sportTypes'][hcIndex]}` ? (
                                                <p style={{ fontSize: 8, color: "#ffffff" }}>{`${startbar['sportTypes'][hcIndex]}`}</p>
                                            ) : (
                                                <p style={{ fontSize: 8, color: "#ffffff" }}>{`${startbar['values'][hcIndex]}`}</p>
                                            )}
                                        </div>
                                    )
                                }) : (
                                    <div style={{ position: "relative", background: '#ffffff', minWidth: startbar.width }} />
                                )}
                            </div>
                        ))}


                        {stack_bars && stack_bars.length === 1 && stack_bars.map((startbar, sIndex) => (
                            <div className="flex alignEnd" style={{ position: "relative", marginLeft: 32 }}>
                                {startbar['heights'].length > 0 ? startbar['heights'].map((barHeight, hcIndex) => {
                                    return (
                                        <div className="flex column alignCenter justifyCenter" key={`bar_${hcIndex + 1}`}
                                            style={{
                                                position: "relative", textAlign: 'center',
                                                background: startbar['colors'][hcIndex],
                                                minWidth: 72,
                                                marginLeft: 16,
                                                height: barHeight,
                                                maxWidth: 72,
                                                transition: `all .2s ease-in-out`,
                                                transform: showSportType === `bar_${sIndex + 1}_${startbar['sportTypes'][hcIndex]}` ? `scale(1.1)` : `scale(1)`,
                                            }}
                                            onMouseEnter={() => setShowSportType(`bar_${sIndex + 1}_${startbar['sportTypes'][hcIndex]}`)}
                                            onMouseLeave={() => setShowSportType(-1)}
                                        >
                                            {showSportType === `bar_${sIndex + 1}_${startbar['sportTypes'][hcIndex]}` ? (
                                                <p style={{ fontSize: 8, color: "#ffffff" }}>{`${startbar['sportTypes'][hcIndex]}`}</p>
                                            ) : (
                                                <p style={{ fontSize: 8, color: "#ffffff" }}>{`${startbar['values'][hcIndex]}`}</p>
                                            )}
                                        </div>
                                    )
                                }) : (
                                    <div style={{ position: "relative", background: '#ffffff', minWidth: 72 }} />
                                )}
                            </div>
                        ))}
                    </div>

                    {/* Phase Name */}
                    {phaseLength > 1 && (
                        <div className="flex alignEnd" style={{ position: "absolute", bottom: -48, }}>
                            {stack_bars && stack_bars.map((startbar, sIndex) => (
                                <div className="flex column alignCenter justifyCenter" key={`phase_${sIndex + 1}`}
                                    style={{
                                        position: "relative",
                                        textAlign: 'center',
                                        minWidth: startbar.width,
                                        marginLeft: sIndex === 0 ? 96 : 48

                                    }}>
                                    <p style={{ color: "#000000", fontSize: 12 }}> <span style={{ color: "#607854", marginRight: 4, fontWeight: 600 }}>{calculateSumOfValues(startbar.values)}</span>{`${calculateSumOfValues(startbar.values) > 1 ? `Sessions` : `Session`}`}</p>
                                    <div className="flex justifyStart alignCenter w-100" style={{ marginTop: 4 }} >
                                        <p style={{ fontSize: 12, margin: "auto" }}>{startbar.phase_name}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>

            <GraphLegends legend={legends} />
        </div >
    )
}


export default TrainingSession;