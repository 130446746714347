import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import AnalyticsLines from "../assets/icons/analytics-line.svg";
import ReportHeader from "../components/common/ReportHeader";
import { getUserReport } from "../store/report/actions";
import { history } from "../index";
import MetaTags from 'react-meta-tags';
import moment from "moment";
import { saveAs } from "file-saver";

// Desktop Graphs
import DESKTOP_CYCLE_CHECKIN from "../components/Graphs/Desktop/CycleCheckIn";
import DESKTOP_READINESSS from "../components/Graphs/Desktop/ReadinessScore";
import DESKTOP_SYMPTOMS from "../components/Graphs/Desktop/SymptomsOverview";
import DESKTOP_LIFESTYLE from "../components/Graphs/Desktop/LifestyleOverview";
import DESKTOP_TRAINING_OVERVIEW from "../components/Graphs/Desktop/TrainingOverview";
import DESKTOP_MORNING from "../components/Graphs/Desktop/MorningCheckin";
import DESKTOP_TRAINING_LOAD from "../components/Graphs/Desktop/TrainingLoad";
import DESKTOP_TRAINING_SESSION from "../components/Graphs/Desktop/TrainingSession";
// Mobile Graphs
import MOBILE_CYCLE_CHECKIN from "../components/Graphs/Mobile/CycleCheckIn";
import MOBILE_READINESSS from "../components/Graphs/Mobile/ReadinessScore";
import MOBILE_SYMPTOMS from "../components/Graphs/Mobile/SymptomsOverview";
import MOBILE_LIFESTYLE from "../components/Graphs/Mobile/LifestyleOverview";
import MOBILE_TRAINING_OVERVIEW from "../components/Graphs/Mobile/TrainingOverview";
import MOBILE_MORNING from "../components/Graphs/Mobile/MorningCheckin";
import MOBILE_TRAINING_LOAD from "../components/Graphs/Mobile/TrainingLoad";
import MOBILE_TRAINING_SESSION from "../components/Graphs/Mobile/TrainingSession";
import Axios from "axios";


const Report = () => {
    let [URLparams, setURLParams] = useState(null);
    const { location: { search } } = history;
    const [loading, setLoading] = useState(false);
    let [reportLoaded, setReportLoaded] = useState(false);
    let [birthControlReport, setBirthControlReport] = useState(0);
    let [headers, setHeaders] = useState(null);
    let [cycle, setCycles] = useState(null);
    let [readiness, setReadiness] = useState(null);
    let [symptoms, setSymptoms] = useState(null);
    let [lifestyle, setLifestyle] = useState(null);
    let [training, setTraining] = useState(null);
    let [morning, setMorning] = useState(null);
    let [traingLoad, setTrainingLoad] = useState(null);
    let [trainingSessions, setTrainingSession] = useState(null);
    let [monthly_report, set_monthly_report] = useState(false);
    let [screen_size, set_screen_size] = useState(0);
    let [show_download_screen, set_download_screen] = useState(false);
    const [stopScrolling, setStopScrolling] = useState(false);
    let { cycle_report, readiness_report, symptoms_report, lifestyle_report, training_report, morning_report, training_load, training_sessions } = useSelector((state) => state.report);
    let [general_report, set_general_report] = useState(null);
    let [download_link, set_download_link] = useState(null);
    const dispatch = useDispatch();
    const onError = () => setLoading(false);
    const onSuccess = () => { setLoading(false); setReportLoaded(true); }

    async function downloadPDF() {
        if (headers && download_link) {
            let { title, cycle_start } = headers;
            const filename = `Wild AI ${title} ${cycle_start}.pdf`;
            saveAs(download_link, filename);
        }
    }

    useEffect(async () => {
        setLoading(true);
        set_screen_size(window.screen.width);
        const params = JSON.parse('{"' + decodeURI(search).replace(/\?/g, '').replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
        setURLParams(params);
        const { user_access, date } = params;
        dispatch(getUserReport(user_access, date || moment().format('DD/MM/YYYY'), onSuccess, onError));
        async function reportDownloadLink(userAccess, date) {
            let response = Axios.get(`${process.env.REACT_APP_BASE_URL}/user/report/blocks/pdf_download_link,general?user_access=${userAccess}&date=${date}&live=${true}`);
            return response;
        }
        const { data } = await reportDownloadLink(user_access, date || moment().format('DD/MM/YYYY'));
        set_general_report(data);
    }, []);

    useEffect(() => {
        set_download_link(general_report ? general_report['pdf_download_link'] : null);
        set_monthly_report(general_report ? general_report['general'].monthly : false);
        setHeaders(general_report && general_report['general']);
        setBirthControlReport(general_report ? general_report['general'].contraceptive_group : 0)
        setCycles(cycle_report && Object.keys(cycle_report.cycle).length > 0 ? cycle_report : null);
        setReadiness(readiness_report && Object.keys(readiness_report.readiness).length > 0 ? readiness_report : null);
        setSymptoms(symptoms_report && Object.keys(symptoms_report.symptoms).length > 0 ? symptoms_report : null);
        setLifestyle(lifestyle_report && Object.keys(lifestyle_report.lifestyle).length > 0 ? lifestyle_report : null);
        setTraining(training_report && Object.keys(training_report.training).length > 0 ? training_report : null);
        setMorning(morning_report && Object.keys(morning_report.morning).length > 0 ? morning_report : null);
        setTrainingLoad(training_load && Object.keys(training_load.training_load).length > 0 ? training_load : null);
        setTrainingSession(training_sessions && Object.keys(training_sessions.training_sessions).length > 0 ? training_sessions : null);
    }, [general_report, cycle_report, readiness_report, symptoms_report, lifestyle_report, training_report, morning_report, training_load, training_sessions]);

    useEffect(() => {
        if (reportLoaded) {
            if (URLparams["pdf"] !== undefined) {
                if (URLparams["pdf"] === "true") {
                    if (screen_size <= 768) {
                        setStopScrolling(true);
                        set_download_screen(true);
                        downloadPDF();
                    } else {
                        downloadPDF();
                    }
                }
            }
        }
    }, [reportLoaded, URLparams]);

    return (
        <div className="flex column alignCenter justifyCenter w-100 h-100">
            <MetaTags>
                <title>Menstrual Cycle Report </title>
                <meta property="og:title" content="Menstrual Cycle Report" />
                <meta name="description" content="Your latest cycle insights to help you perform & train with your physiology in mind." />
                <meta property='og:description' content="Your latest cycle insights to help you perform & train with your physiology in mind." />
            </MetaTags>

            {loading && screen_size > 768 && (
                <div className="flex column justifyCenter alignCenter reports_loading_container" style={{ marginTop: 164 }}>
                    <div className="flex column justifyCenter alignCenter" style={{ marginTop: -284 }}>
                        <div className="progress_loader">
                            <div className="loaderBar"></div>
                        </div>
                        <p style={{ marginTop: 32 }}>
                            {`Loading sections, please wait…`}
                        </p>
                    </div>
                </div>
            )}

            {loading && screen_size <= 768 && (
                <div className="flex column justifyCenter alignCenter pdf_downloading_container">
                    <p style={{ fontSize: 24 }}>Generating Report</p>

                    <div className="flex column justifyCenter alignCenter" style={{ margin: "44px 0" }}>
                        <img src={AnalyticsLines} alt="analytics_lines" height={32} width={32} style={{ margin: "16px 0" }} />
                        {headers && (<h1>{moment(headers.cycle_start).format("MMMM Do")}</h1>)}
                        {headers && (<p style={{ marginTop: 16 }}>{`to ${moment(headers.cycle_end).format("MMMM Do YYYY")}`}</p>)}
                    </div>

                    <p style={{ maxWidth: 240, fontSize: 24, lineHeight: 1.5, textAlign: 'center' }}>Please wait until your report has been generated:</p>
                    <div className="flex column justifyCenter alignCenter" style={{ margin: "28px 0" }}>
                        <div className="custom_loader" />
                        <p style={{ marginTop: 32 }}>
                            {`Loading ...`}
                        </p>
                    </div>
                </div>
            )}

            {!loading && headers === null && (
                <div className="flex column justifyCenter alignCenter reports_loading_container">
                    <div className="black_alert_icon">
                        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.145 11.9558L8.77109 1.67522C8.34711 0.989883 7.65271 0.999533 7.22868 1.68486L0.854716 11.9512C0.430744 12.6366 0.747576 13.166 1.55886 13.166H14.4403C15.2521 13.166 15.569 12.64 15.145 11.9558ZM7.99475 3.95064C8.40267 3.95064 8.71894 4.39676 8.69775 4.94207L8.56827 8.22765C8.54764 8.7724 8.28809 9.21851 7.99303 9.21851C7.6974 9.21851 7.43784 8.7724 7.41722 8.22765L7.28946 4.94207C7.26883 4.39676 7.58566 3.95064 7.99475 3.95064ZM7.99475 11.6933C7.47968 11.6933 7.12617 11.3209 7.13705 10.8118C7.13705 10.2915 7.49113 9.93038 7.99475 9.93038C8.51956 9.93038 8.85129 10.292 8.86276 10.8118C8.86218 11.3209 8.51957 11.6933 7.99475 11.6933Z" fill="#010101" />
                        </svg>
                    </div>
                    <p style={{ marginTop: 16 }}>
                        {`There is an error. Please try again later!`}
                    </p>
                </div>
            )}

            {!loading && headers === null && (
                <div className="flex column justifyCenter alignCenter reports_loading_container">
                    <div className="black_alert_icon">
                        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.145 11.9558L8.77109 1.67522C8.34711 0.989883 7.65271 0.999533 7.22868 1.68486L0.854716 11.9512C0.430744 12.6366 0.747576 13.166 1.55886 13.166H14.4403C15.2521 13.166 15.569 12.64 15.145 11.9558ZM7.99475 3.95064C8.40267 3.95064 8.71894 4.39676 8.69775 4.94207L8.56827 8.22765C8.54764 8.7724 8.28809 9.21851 7.99303 9.21851C7.6974 9.21851 7.43784 8.7724 7.41722 8.22765L7.28946 4.94207C7.26883 4.39676 7.58566 3.95064 7.99475 3.95064ZM7.99475 11.6933C7.47968 11.6933 7.12617 11.3209 7.13705 10.8118C7.13705 10.2915 7.49113 9.93038 7.99475 9.93038C8.51956 9.93038 8.85129 10.292 8.86276 10.8118C8.86218 11.3209 8.51957 11.6933 7.99475 11.6933Z" fill="#010101" />
                        </svg>
                    </div>
                    <p style={{ marginTop: 16 }}>
                        {`There is an error. Please try again later!`}
                    </p>
                </div>
            )}

            {show_download_screen && (
                <div className="flex column justifyCenter alignCenter pdf_downloading_container">
                    <p style={{ fontSize: 24 }}>Generating Report</p>

                    <div className="flex column justifyCenter alignCenter" style={{ margin: "56px 0" }}>
                        <img src={AnalyticsLines} alt="analytics_lines" height={32} width={32} style={{ margin: "16px 0" }} />
                        {headers && (<h1>{moment(headers.cycle_start).format("MMMM Do")}</h1>)}
                        {headers && (<p style={{ marginTop: 16 }}>{`to ${moment(headers.cycle_end).format("MMMM Do YYYY")}`}</p>)}
                    </div>

                    <p style={{ maxWidth: 240, fontSize: 24, lineHeight: 1.5, textAlign: 'center' }}>
                        Your report is ready:
                    </p>
                    <div className="flex column justifyCenter alignCenter" style={{ margin: "32px 0" }}>
                        <p className="flex column justifyCenter alignCenter" style={{
                            marginTop: 24,
                            fontWeight: 700,
                            background: "#000000",
                            height: 56,
                            borderRadius: 60,
                            minWidth: 265,
                            color: "#ffffff",
                            textAlign: "center"
                        }}
                            onClick={() => downloadPDF()}>
                            {`Download PDF`}
                        </p>
                    </div>

                </div>
            )}

            {screen_size >= 1200 ? (
                <div className="main_container" id="print_report_desktop">
                    <ReportHeader header={headers} print={() => downloadPDF()} params={URLparams} />
                    {cycle && <DESKTOP_CYCLE_CHECKIN dashboard={false} checkins={cycle} monthly_status={monthly_report} />}
                    {readiness && <DESKTOP_READINESSS dashboard={false} score={readiness} monthly_status={monthly_report} />}
                    {morning && <DESKTOP_MORNING dashboard={false} morning={morning} monthly_status={monthly_report} />}
                    {training && <DESKTOP_TRAINING_OVERVIEW dashboard={false} training={training} monthly_status={monthly_report} />}
                    {traingLoad && <DESKTOP_TRAINING_LOAD dashboard={false} traingLoad={traingLoad} monthly_status={monthly_report} birthControlReport={birthControlReport} />}
                    {trainingSessions && <DESKTOP_TRAINING_SESSION dashboard={false} trainingSessions={trainingSessions} />}
                    {symptoms && <DESKTOP_SYMPTOMS dashboard={false} symptoms={symptoms} monthly_status={monthly_report} />}
                    {lifestyle && <DESKTOP_LIFESTYLE dashboard={false} lifestyle={lifestyle} monthly_status={monthly_report} />}
                </div>
            ) : (
                <div className="main_container" id="print_report_mobile"
                    style={{
                        position: stopScrolling && "fixed",
                        width: stopScrolling && "100vw",
                    }}
                >
                    <ReportHeader header={headers} print={() => downloadPDF()} params={URLparams} />
                    {cycle && <MOBILE_CYCLE_CHECKIN dashboard={false} checkins={cycle} monthly_status={monthly_report} />}
                    {readiness && <MOBILE_READINESSS dashboard={false} score={readiness} monthly_status={monthly_report} />}
                    {morning && <MOBILE_MORNING dashboard={false} morning={morning} monthly_status={monthly_report} />}
                    {training && <MOBILE_TRAINING_OVERVIEW dashboard={false} training={training} monthly_status={monthly_report} />}
                    {traingLoad && <MOBILE_TRAINING_LOAD dashboard={false} traingLoad={traingLoad} monthly_status={monthly_report} birthControlReport={birthControlReport} />}
                    {trainingSessions && <MOBILE_TRAINING_SESSION dashboard={false} trainingSessions={trainingSessions} />}
                    {symptoms && <MOBILE_SYMPTOMS dashboard={false} symptoms={symptoms} monthly_status={monthly_report} />}
                    {lifestyle && <MOBILE_LIFESTYLE dashboard={false} lifestyle={lifestyle} monthly_status={monthly_report} />}
                </div>
            )}
        </div>
    );
}

export default Report;