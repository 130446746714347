import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { useDispatch, useSelector } from "react-redux";
// Components
import { Widget } from '@typeform/embed-react';
import Footer from "../components/common/Footer";
import Header from "../components/common/Header";
// Common icons
import CloseDarkIcon from "../assets/icons/cross-dark.svg";
import CloseIcon from "../assets/icons/cross-light.svg";
import DiscountSticker from "../assets/icons/discount-applied-sticker.svg";
import ForwardArrow from "../assets/icons/forward-arrow-dark.svg";
import ForwardArrowLight from "../assets/icons/forward-arrow-light.svg";
import DownArrowIcon from "../assets/icons/gray-down-arrow.svg";
import UpArrowIcon from "../assets/icons/gray-up-arrow.svg";
import GreenTickIcon from "../assets/icons/green-tick.svg";
import WildAILogoDark from "../assets/logo-dark.png";
// Training icons
import CalendarIcon from "../assets/icons/calendar.svg";
import SportTypeIcon from "../assets/icons/muscle.svg";
import TimerIcon from "../assets/icons/timer-gray.svg";
import TrainingLevelIcon from "../assets/icons/training-level.svg";
import WeightIcon from "../assets/icons/weight.svg";
// Action
import { isEqual } from "lodash";
import CustomHeader from "../components/common/CustomHeader";
import { getShortDescription } from "../js/chunks";
import { fetchProPlan, setProPlan, validatePromoCode } from "../store/app/actions";

const OpenSessionDetail = (props) => {
    const { location } = props;
    const dispatch = useDispatch();
    let { user, plan } = useSelector((state) => state.app);
    let [username, set_username] = useState('');
    let [error_message, set_error_message] = useState('');
    const [loading, setLoading] = useState(true);
    const [openPopup, setPopupOpening] = useState(false);
    const [isProUser, setProUserStatus] = useState(false);
    let [show_preview, set_preview] = useState(true);
    let [sessions_list, setSessionList] = useState([]);
    let [opened_session, setOpenedSession] = useState(null);
    let [showFullText, setShowFullText] = useState({
        length: 0,
        view_more: false,
        show_full: false,
    });
    let screen_width = window.screen.width;
    let currency_list = [
        { prefix: "USD", symbol: '$' },
        { prefix: "EUR", symbol: '€' },
        { prefix: "JPY", symbol: '¥' },
        { prefix: "GBP", symbol: '£' },
    ];
    let [opened_category, setOpenedCategory] = useState({
        index: 0,
        name: ""
    });
    let [followers_list, setFollowersList] = useState([]);
    let [exercises, setExercisesList] = useState([]);
    let [nextItemIndex, setNextIndex] = useState(0);
    let [timesUpPopup, showTimesUpPopup] = useState(false);

    // find currency symbol
    const findSymbol = (name) => {
        const returning_obj = currency_list.find((item) => item['prefix'] === name && item);
        return returning_obj['symbol'];
    }

    const onSuccessProUser = (response) => setProUserStatus(response['is_user_pro']);
    useEffect(() => {
        if (plan && Object.keys(plan).length > 0) {
            setFlagsForSessions(plan['plan_details'][0]['sessions']);
            setShowFullText({
                length: plan['description'].length,
                view_more: plan['description'].length > 200 ? true : false,
                show_full: plan['description'].length > 200 ? false : true,
            });
        }
    }, [plan]);

    function setBlocksBasedOnCategories(followers) {
        followers = followers.filter(n => n)
        let list_of_exercises = ['A', 'B', "C", 'D', 'E', 'F', 'G', 'H'].reduce((acc, category) => {
            if (followers.length) {
                let _followers = followers.filter(follower => follower.category.startsWith(category));
                _followers = _followers.map((item) => { return { ...item, video_loaded: false, show_full_content: true } });
                return [...acc, { category: category, followers: _followers, show_off: false }]
            } else {
                return [...acc, { category: category, followers: [], show_off: false }]
            }
        }, []);
        return list_of_exercises;
    }

    // Set opening and closing flags for sessions list on init
    const setFlagsForSessions = (sessions_list) => {
        if (sessions_list && sessions_list.length > 0) {
            let flagList = sessions_list.filter((item, index) => (index === 0 || index === 1) && item);
            flagList = flagList.map((item, index) => {
                return { ...item, open_list: index === 0 ? true : false }
            });
            let exercises_blocks = flagList.map((item) => setBlocksBasedOnCategories(item['description']));
            exercises_blocks = exercises_blocks.map((item) => {
                return item.filter(item => item['followers'].length !== 0);
            });
            exercises_blocks = exercises_blocks.filter(n => n.length !== 0);
            exercises_blocks = exercises_blocks.map((item) => {
                return item.map((item, index) => {
                    return {
                        ...item,
                        show_off: index === 0 ? true : false,
                        followers: item['followers'].map((follower_item) => {
                            return {
                                ...follower_item,
                                show_full_content: index === 0 ? true : false
                            }
                        })
                    }
                });
            });

            let final_list = [];
            for (var i = 0; i < flagList.length; i++) {
                final_list.push({ ...flagList[i], exercises: exercises_blocks[i] });
            }
            setOpenedCategory({ ...opened_category, index: 0, name: (exercises_blocks[0].filter(item => item['show_off']))[0]['category'] });
            setSessionList(final_list);
        }
    }
    // Open session list for specific index and close the other one
    const setFlagForListBasedOnIndex = (opening_index) => {
        let flagList = sessions_list.map((item, index) => {
            return { ...item, open_list: index === opening_index ? !item['open_list'] : !item['open_list'] }
        });
        setOpenedSession(flagList);
        setSessionList(flagList);
        flagList = flagList.filter((item) => item.open_list === true)[0];
        setFollowersList(flagList['exercises']);
        setExercisesList(flagList['exercises'].filter((item) => item.show_off === true)[0]['followers']);
        setOpenedCategory({ ...opened_category, index: flagList['day_of_week'], name: flagList['exercises'].filter((item) => item.show_off === true)[0]['category'] });
    }
    const showExerciseFromList = (session, category) => {
        let updatedListCategory = { index: session['day_of_week'], name: category };
        if (!(isEqual(updatedListCategory, opened_category))) {
            let updatedFlagExercises = session['exercises'].map(item => {
                return { ...item, show_off: item['category'] === category ? true : false }
            });
            setFollowersList(updatedFlagExercises);
            setExercisesList(updatedFlagExercises.filter((item) => item.show_off === true)[0]['followers']);
            setOpenedSession({ ...session, exercises: updatedFlagExercises });
            setOpenedCategory({ ...opened_category, index: session['day_of_week'], name: updatedFlagExercises.filter((item) => item.show_off === true)[0]['category'] });
        }
    }

    // set user onload
    useEffect(() => {
        if (user) {
            let { first_name } = user;
            set_username(first_name);
        }
    }, [user]);

    // set opened session list
    useEffect(() => {
        if (sessions_list && sessions_list.length > 0) {
            let list = sessions_list.filter((item) => item.open_list === true)[0]
            setOpenedSession(list);
            setFollowersList(list['exercises']);
            const currentIndex = list['exercises'].indexOf(list['exercises'].filter((item) => item.show_off === true)[0]);
            const nextIndex = (currentIndex + 1) % list['exercises'].length;
            setNextIndex(list['exercises'].length >= nextIndex ? nextIndex : 0);
            setExercisesList(list['exercises'].filter((item) => item.show_off === true)[0]['followers']);
        }
    }, [sessions_list]);

    const getNextSupersetCategory = (currentItem) => {
        const currentIndex = followers_list.indexOf(currentItem);
        const nextIndex = (currentIndex + 1) % followers_list.length;
        setNextIndex(nextIndex);
    }

    const moveInSuperset = (nextIndex) => {
        let moved_exercise = followers_list.filter((item, indx) => indx === nextIndex)[0];
        const currentIndex = followers_list.indexOf(moved_exercise);
        const next_index = (currentIndex + 1) % followers_list.length;
        setNextIndex(next_index);
        setExercisesList(moved_exercise['followers']);
    }

    const setFlagsForVideoPreviewOnceLoaded = (list, index) => {
        list = list.map((follower, idx) => { return { ...follower, video_loaded: index === idx ? true : follower['video_loaded'] } });
        setExercisesList(list);
    }

    const setFlagsForVideoPreviewOnceLoadedMobile = (followes_list, follower_index, follower_item_index) => {
        followes_list = followes_list.map((follower_item, idx) => {
            return {
                ...follower_item,
                followers: idx === follower_index ?
                    follower_item['followers'].map((item, index) => {
                        return { ...item, video_loaded: index === follower_item_index ? true : item['video_loaded'] }
                    })
                    : follower_item['followers']
            }
        });
        setFollowersList(followes_list);
    }

    const setTrueFlagsForShowFullContent = (followes_list, follower_index, follower_item_index) => {
        followes_list = followes_list.map((follower_item, idx) => {
            return {
                ...follower_item,
                followers: idx === follower_index ?
                    follower_item['followers'].map((item, index) => {
                        return { ...item, show_full_content: index === follower_item_index ? true : item['show_full_content'] }
                    })
                    : follower_item['followers']
            }
        });
        setFollowersList(followes_list);
    }

    const setFalseFlagForShowFullContent = (followes_list, follower_index, follower_item_index) => {
        followes_list = followes_list.map((follower_item, idx) => {
            return {
                ...follower_item,
                followers: idx === follower_index ?
                    follower_item['followers'].map((item, index) => {
                        return { ...item, show_full_content: index === follower_item_index ? false : item['show_full_content'] }
                    })
                    : follower_item['followers']
            }
        });
        setFollowersList(followes_list);
    }

    const findSessionsPerWeek = (sessions_list) => sessions_list.length;
    const onSuccess = () => setLoading(false);
    const onError = (error) => { setLoading(false); set_error_message(error); }
    useEffect(() => {
        if (location) {
            window.analytics.page();
            dispatch(setProPlan({}));
            dispatch(validatePromoCode(null, onSuccessProUser, onError));
            const { pathname } = location;
            var id = pathname.substring(pathname.lastIndexOf('/') + 1);
            dispatch(fetchProPlan(id, false, onSuccess, onError));
        }
    }, []);



    return (
        <div className="_container"
            style={{
                position: (openPopup || timesUpPopup) ? 'fixed' : 'relative',
                overflow: (openPopup || timesUpPopup) ? 'hidden' : 'auto'
            }}
        >
            {/* Desktop header */}
            <header className='desktop home_background_banner'>
                <div className='banner_image home_background_banner_image' />
                <div className='_after'>
                    <Header location={location} />
                    <div className="desktop flex justifyCenter alignCenter w-100 h-100" style={{ margin: '96px 0 0' }}>
                        <h1 style={{ color: '#FFF', fontSize: 72 }}>{`Hi, ${username}`}</h1>
                    </div>

                    <div className="mobile flex justifyCenter alignCenter w-100 h-100" style={{ margin: '72px 0 0' }}>
                        <h3 className="mobile" style={{ color: '#FFF', fontSize: 32 }}>{`Hi, ${username}`}</h3>
                    </div>
                </div>
            </header>

            {/* Mobile header */}
            <header className='mobile w-100'>
                <CustomHeader location={location} />
            </header>

            <div className="main_content_area flex justifyCenter alignCenter w-100 h-100">
                <div className="content_area flex column justifyStart alignCenter w-100 h-100">
                    {/* loading */}
                    {loading ? (
                        <div className="flex column justifyCenter alignCenter" style={{ margin: '64px 0' }}>
                            <div className="custom_loader" />
                            <p style={{ marginTop: 32 }}>
                                {`Loading, please wait…`}
                            </p>
                        </div>
                    ) : null}

                    {/* if dound error, throw error message */}
                    {error_message !== '' ? (
                        <div className="flex column justifyCenter alignCenter" style={{ margin: '64px 8px', textAlign: 'center' }}>
                            <h2>{`Sorry, we couldn’t find anything`}</h2>
                            <p style={{ margin: "16px 0 0" }}>  Try using another search term or contact support at support@wild.ai.  </p>
                        </div>
                    ) : null}

                    {plan && Object.keys(plan).length > 0 && (
                        <div className="flex justifyCenter alignCenter w-100 h-100 relative">
                            {/* Desktop version */}
                            <div className="flex justifyBetween desktop h-100" style={{ margin: "32px 0 0" }}>
                                {/* left */}
                                <div id="side_content" className='flex column alignStart' style={{ width: 330 }}>
                                    {/* Plan name + Author */}
                                    <div className="flex column justifyStart w-100">
                                        {plan['user_pricing']['user_bought'] && (
                                            <div className="flex" style={{ marginBottom: 16 }}>
                                                <img src={GreenTickIcon} alt="green_tick_icon" height={24} width={24} />
                                                <p style={{ color: '#000000', marginLeft: 8 }}>Purchased</p>
                                            </div>
                                        )}
                                        <h1>{plan['name']}</h1>
                                        <p style={{ color: '#8F8F8F', margin: "8px 0 0", paddingBottom: 8, borderBottom: '1px solid #000' }}>{`by ${plan['author']}`}</p>
                                    </div>

                                    {/* Icon with labels */}
                                    <div className="flex column alignCenter justifyCenter" style={{ margin: "24px 0 24px 24px", width: '70%', }}>
                                        {/* first row */}
                                        <div className="flex justifyBetween alignCenter w-100">
                                            {/* sport_type */}
                                            <div className="flex column alignCenter" style={{ width: 93 }}>
                                                <img src={SportTypeIcon} alt="sport_type" height={24} width={24} />
                                                <p style={{ color: '#8F8F8F', textAlign: 'center', margin: '8px 0 0' }}>{plan['sport_type']}</p>
                                            </div>

                                            {/* Place */}
                                            <div className="flex column alignCenter" style={{ width: 49 }}>
                                                <img src={WeightIcon} alt="sport_type" height={24} width={24} />
                                                <p style={{ color: '#8F8F8F', textAlign: 'center', margin: '8px 0 0' }}>{`Needs\n Gym`}</p>
                                            </div>
                                        </div>

                                        {/* Second row */}
                                        <div className="flex justifyBetween alignCenter w-100">
                                            {/* Level */}
                                            <div className="flex column alignCenter" style={{ width: 99, margin: "16px 0" }}>
                                                <img src={TrainingLevelIcon} alt="sport_type" height={24} width={24} />
                                                <p style={{ color: '#8F8F8F', textAlign: 'center', margin: '8px 0 0' }}>{`Level ${plan['level']}`}</p>
                                            </div>
                                            {/* Duration */}
                                            <div className="flex column alignCenter" style={{ width: 62 }}>
                                                <img src={TimerIcon} alt="sport_type" height={24} width={24} />
                                                <p style={{ color: '#8F8F8F', textAlign: 'center', margin: '8px 0 0' }}>{`Duration ${plan['plan_length']}`}</p>
                                            </div>
                                        </div>

                                        {/* Sessions per week */}
                                        <div className="flex column alignCenter" style={{ width: 71 }}>
                                            <img src={CalendarIcon} alt="sport_type" height={24} width={24} />
                                            <p style={{ color: '#8F8F8F', textAlign: 'center', margin: '8px 0 0' }}>{`${findSessionsPerWeek(plan['plan_details'][0]['sessions'])} Sessions per week`}</p>
                                        </div>

                                    </div>

                                    {/* Plan description */}
                                    {showFullText['show_full'] ? (
                                        <p style={{ margin: "24px 0" }}>{plan['description']}
                                            <span onClick={() => setShowFullText({ ...showFullText, show_full: false })}
                                                className="view_less_text">{`View Less`}
                                            </span>
                                        </p>
                                    ) : (
                                        <p style={{ margin: "24px 0" }}>{getShortDescription(plan['description'], 150)}
                                            {showFullText['view_more'] ?
                                                <span onClick={() => setShowFullText({ ...showFullText, show_full: true })}
                                                    className="view_more_text"  >{`Read More`}</span>
                                                : null}
                                        </p>
                                    )}

                                    {/* Video preview */}
                                    {show_preview ? (
                                        <div className="flex justifyCenter alignCenter relative w-100" style={{ height: 180, borderRadius: 12 }}>
                                            <img src={plan['image_url']} alt="plan_image" height={180} width={330} style={{ borderRadius: 12 }} />
                                            <div className="flex column justifyCenter alignCenter h-100 absolute w-100">
                                                <div className="custom_loader" />
                                            </div>
                                        </div>
                                    ) : null}

                                    {/* Loaded video */}
                                    <ReactPlayer
                                        width={330}
                                        height={180}
                                        url={plan['promo_video']}
                                        controls={true}
                                        style={{ display: show_preview ? 'none' : 'block' }}
                                        onReady={() => set_preview(false)}
                                        onLoad={() => set_preview(false)}
                                    />

                                    {/* sessions list */}
                                    <p style={{ color: '#666668', fontWeight: 700, margin: "24px 0 0" }}>{plan['plan_details'][0]['name']}</p>
                                    <div className="flex column justifyCenter alignCenter w-100">
                                        {sessions_list && sessions_list.length > 0 && sessions_list.map((list_item, index) => (
                                            <div className="flex column justifyStart alignStart w-100" key={index + 1}>
                                                <div className="flex alignCenter justifyStart w-100 pointer" style={{
                                                    background: "#F8F8F8",
                                                    padding: list_item['open_list'] ? "16px 10px 0" : "16px 10px",
                                                    borderTopLeftRadius: 12,
                                                    borderTopRightRadius: 12,
                                                    margin: "24px 0 0",
                                                    borderBottomLeftRadius: list_item['open_list'] ? 0 : 12,
                                                    borderBottomRightRadius: list_item['open_list'] ? 0 : 12,
                                                }}
                                                    onClick={() => setFlagForListBasedOnIndex(index)}
                                                >
                                                    <img src={list_item['image_url']} alt="session_image" height={60} width={64} style={{ borderRadius: 6, minWidth: 60 }} />
                                                    <div className="flex alignStart justifyBetween w-100">
                                                        <div className="flex column justifyCenter" style={{ margin: "0 12px" }}>
                                                            <p style={{ color: '#666668', fontWeight: 700, textTransform: 'capitalize' }}>{`Session ${index + 1}`}</p>
                                                            <div className="flex" style={{ marginTop: 3 }}>
                                                                <p style={{ color: '#B0B0AD' }}>{`${list_item['session_intensity']} intensity`}</p>
                                                                <span style={{ margin: "0 8px", color: '#B0B0AD' }}>{`-`}</span>
                                                                <p style={{ color: '#B0B0AD' }}>{list_item['duration_minutes'] > 1 ? `${list_item['duration_minutes']} mins` : `${list_item['duration_minutes']} min`}</p>
                                                            </div>
                                                        </div>
                                                        <img className="pointer" src={list_item['open_list'] ? UpArrowIcon : DownArrowIcon} alt={list_item['open_list'] ? "up arrow icon" : "down arrow icon"} height={28} width={28} />
                                                    </div>
                                                </div>
                                                {list_item['open_list'] && list_item['exercises'] && list_item['exercises'].length > 0 && list_item['exercises'].map((session, sub_index) => (
                                                    <div className="flex column justifyStart alignCenter w-100" key={sub_index + 1}
                                                        style={{
                                                            margin: sub_index === 0 ? 0 : "12px 0 0",
                                                            borderRadius: sub_index === 0 ? 0 : 12,
                                                            borderBottomLeftRadius: 12,
                                                            borderBottomRightRadius: 12,
                                                            background: "#F8F8F8",
                                                        }}
                                                    >
                                                        {session['followers'] && session['followers'].map((item, exercise_index) => (
                                                            <div className="flex alignCenter justifyStart w-100 pointer"
                                                                onClick={() => {
                                                                    getNextSupersetCategory(session);
                                                                    showExerciseFromList(list_item, session['category'])
                                                                }}
                                                                style={{

                                                                    padding: exercise_index + 1 === session['followers'].length ? "16px 10px" : "16px 10px 0",
                                                                    borderBottomLeftRadius: exercise_index + 1 === session['followers'].length ? 12 : 0,
                                                                    borderBottomRightRadius: exercise_index + 1 === session['followers'].length ? 12 : 0
                                                                }}>
                                                                <img src={list_item['image_url']} alt="session_image" height={60} width={64} style={{ borderRadius: 6, minWidth: 60 }} />
                                                                <div className="flex column justifyCenter" style={{ margin: "0 12px" }}>
                                                                    <p style={{ color: '#666668', fontWeight: 700 }}>{`${item['movement']}`}</p>
                                                                    <p style={{ color: '#B0B0AD', marginTop: 4, textTransform: 'capitalize' }}>{item['category']}</p>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                ))}
                                            </div>
                                        ))}
                                    </div>

                                    {/* Show liked Demo text */}
                                    <p className="w-100" onClick={() => showTimesUpPopup(true)} style={{ color: '#000000', textAlign: 'center', letterSpacing: 1, marginTop: 48, fontWeight: 700 }}>{`LIKED DEMO?`}</p>

                                    {/*  View in app & Buy Plan buttons */}
                                    <div className="flex justifyCenter alignCenter w-100 pointer"
                                        onClick={() => window.location.href = plan['user_pricing']['user_buy_url']}
                                        style={{ background: '#000', height: 56, margin: "24px 0", textAlign: 'center', borderRadius: 60 }}
                                    >

                                        {isProUser ? (
                                            <p style={{ color: '#FFF', letterSpacing: 1 }}>
                                                <span style={{ textDecoration: 'line-through' }}> {`${findSymbol(plan['user_pricing']['currency'])}${plan['user_pricing']['price']}`}</span>
                                                <span style={{ margin: "0 8px" }}> {`${findSymbol(plan['user_pricing']['currency'])}${plan['user_pricing']['user_price']}`}</span>
                                                {` BUY PLAN`}
                                            </p>
                                        ) : (
                                            <p style={{ color: '#FFF', letterSpacing: 1 }}>
                                                <span style={{ margin: "0 8px" }}> {`${findSymbol(plan['user_pricing']['currency'])}${plan['user_pricing']['user_price']}`}</span>
                                                {` BUY PLAN`}
                                            </p>
                                        )}

                                        {/* <p style={{ color: '#FFF', letterSpacing: 1 }}>
                                            <span style={{ textDecoration: 'line-through' }}> {`${findSymbol(plan['user_pricing']['currency'])}${plan['user_pricing']['price']}`}</span>
                                            <span style={{ margin: "0 8px" }}> {`${findSymbol(plan['user_pricing']['currency'])}${plan['user_pricing']['user_price']}`}</span>
                                            {` BUY PLAN`}
                                        </p> */}
                                    </div>

                                    {/* Feedback container which opens typeform */}
                                    <div className="flex column justifyCenter alignCenter w-100"
                                        style={{ background: '#F1F0F0', textAlign: 'center', borderRadius: 12, padding: "24px 0 16px" }}
                                    >
                                        <p style={{ color: '#000', fontSize: 16, width: 180, fontWeight: 500 }}>Didn’t find what you were looking for?</p>
                                        <p className="pointer" style={{ color: '#000', textDecoration: 'underline', fontWeight: 600, marginTop: 16, textTransform: 'uppercase' }} onClick={() => setPopupOpening(true)}>Tell us why</p>
                                    </div>
                                </div>

                                {/* border */}
                                <div style={{ width: 2, margin: "0 60px", background: 'rgba(0,0,0, 0.4)', height: '100%' }} />

                                {/* right */}
                                {opened_session ?
                                    <div className="flex column alignStart w-100" style={{ maxWidth: 576 }}>
                                        <p style={{ color: '#8F8F8F', }}>{`Session ${opened_session['priority_no']}`}</p>
                                        <div className="flex justifyBetween alignCenter w-100">
                                            <h1 style={{ textTransform: 'capitalize' }}>{opened_session['name']}</h1>
                                            {/* duration */}
                                            <div className="flex column alignCenter" style={{ width: 72 }}>
                                                <img src={TimerIcon} alt="timer icon" height={24} width={24} />
                                                <p style={{ color: '#8F8F8F', textAlign: 'center', margin: '8px 0 0' }}>{`Duration ${opened_session['duration_minutes']} min`}</p>
                                            </div>
                                        </div>
                                        <p style={{ color: '#8F8F8F', marginTop: 8, paddingBottom: 8, borderBottom: '1px solid #000', width: 280 }}>{`${opened_session['session_intensity']} Intensity`}</p>
                                        <p style={{ margin: "16px 0 24px", fontSize: 27 }}> Training</p>
                                        <p style={{ fontSize: 22, fontWeight: 500 }}>{opened_session['name']}</p>
                                        <p style={{ margin: "8px 0 24px" }}>{opened_session['notes']}</p>
                                        {/* Exercises list */}
                                        {exercises && exercises.length > 0 && exercises.map((exercise, index) => (
                                            <div className="flex column alignCenter justifyStart w-100" key={index + 1}
                                                style={{ background: "#F6F6F6", padding: 16 }}
                                            >
                                                {/* video */}
                                                {!exercise['video_loaded'] ?
                                                    <div className="flex justifyCenter alignCenter relative w-100" style={{ height: 260, borderRadius: 12, margin: index === 0 ? 0 : "24px 0 0" }}>
                                                        <img src={plan['image_url']} alt="plan_image" height={260} width={545} style={{ borderRadius: 12 }} />
                                                        <div className="flex column justifyCenter alignCenter h-100 absolute w-100">
                                                            <div className="custom_loader" />
                                                        </div>
                                                    </div> : null}

                                                <ReactPlayer
                                                    width={545}
                                                    height={260}
                                                    url={exercise['video']}
                                                    controls={true}
                                                    style={{ display: exercise['video_loaded'] ? 'block' : 'none' }}
                                                    onReady={() => setFlagsForVideoPreviewOnceLoaded(exercises, index)}
                                                    onLoad={() => setFlagsForVideoPreviewOnceLoaded(exercises, index)}
                                                />

                                                <p className="w-100" style={{ textAlign: 'left', fontSize: 16, margin: "20px 0 12px", fontWeight: 500 }}>{exercise['movement']}</p>
                                                <p className="w-100" style={{ textAlign: 'left', fontSize: 16, color: 'rgba(0,0,0,0.5)' }}>{exercise['category']}</p>
                                                <ul className="w-100" style={{ textAlign: 'left', margin: "16px 0 24px" }}>
                                                    {exercise['description'].indexOf('-') > -1 ?
                                                        exercise['description'].split('-').map((desc, idx) => {
                                                            return desc !== "" && (<li key={idx + 1} style={{ margin: "4px 0 0 16px" }}>{desc}</li>)
                                                        })
                                                        : exercise['description'].split(/^\s*(?:[\dA-Z]+\.|[a-z]\)|•)\s+/gm).map((desc, idx) => {
                                                            return desc !== "" && (<li key={idx + 1} style={{ margin: "4px 0 0 16px" }}>{desc}</li>)
                                                        })}
                                                </ul>
                                                <div className="flex justifyBetween alignCenter w-100"
                                                    style={{ background: '#FFF', padding: 16, borderTopLeftRadius: 10, borderTopRightRadius: 10 }}>
                                                    <p>Sets</p>
                                                    <p>{exercise['sets']}</p>
                                                </div>
                                                <div className="flex justifyBetween alignCenter w-100"
                                                    style={{ background: '#FFF', padding: 16, margin: "2px 0" }}>
                                                    <p>Reps</p>
                                                    <p>{exercise['reps']}</p>
                                                </div>
                                                <div className="flex justifyBetween alignCenter w-100"
                                                    style={{ background: '#FFF', padding: 16, }}>
                                                    <p>Load</p>
                                                    <p>{exercise['load']}</p>
                                                </div>
                                                <div className="flex justifyBetween alignCenter w-100"
                                                    style={{ background: '#FFF', padding: 16, margin: "2px 0" }}>
                                                    <p>Temp</p>
                                                    <p>{exercise['tempo']}</p>
                                                </div>
                                                <div className="flex justifyBetween alignCenter w-100"
                                                    style={{ background: '#FFF', padding: 16, margin: "0 0 2px" }}>
                                                    <p>Focus</p>
                                                    <p>{exercise['focus']}</p>
                                                </div>
                                                <div className="flex justifyBetween alignCenter w-100"
                                                    style={{ background: '#FFF', padding: 16, borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}>
                                                    <p>Exercise Importance</p>
                                                    <p>{exercise['key']}</p>
                                                </div>
                                            </div>
                                        ))}

                                        {/* Move to next superset */}
                                        {nextItemIndex ?
                                            <div className="flex alignCenter justifyEnd w-100" style={{ margin: "24px 0 0" }}>
                                                <p className="pointer" onClick={() => moveInSuperset(nextItemIndex)}>{`Superset ${nextItemIndex + 1}`}</p>
                                                <img className="pointer" src={ForwardArrow} alt="forward arrow icon" height={24} width={24} style={{ marginLeft: 16 }} />
                                            </div>
                                            : nextItemIndex === 0 && opened_category['index'] === 0 ?
                                                <div className="flex alignCenter justifyEnd w-100" style={{ margin: "24px 0 0" }}>
                                                    <p className="pointer" onClick={() => setFlagForListBasedOnIndex(1)}>Session 2</p>
                                                    <img className="pointer" src={ForwardArrow} alt="forward arrow icon" height={24} width={24} style={{ marginLeft: 16 }} />
                                                </div>
                                                : <div className="flex alignCenter justifyEnd w-100" style={{ margin: "24px 0 0" }}>
                                                    <p className="pointer" onClick={() => showTimesUpPopup(true)}>Next</p>
                                                    <img className="pointer" src={ForwardArrow} alt="forward arrow icon" height={24} width={24} style={{ marginLeft: 16 }} />
                                                </div>
                                        }
                                    </div>
                                    : <div className="flex column alignStart w-100" style={{ maxWidth: 576 }} />
                                }
                            </div>

                            {/* Mobile version */}
                            <div className="mobile flex column alignStart w-100 relative" style={{ maxWidth: 576, marginTop: 16 }}>

                                {opened_session ?
                                    <div className="flex column alignCenter w-100">
                                        <p style={{ color: '#8F8F8F', }}>{`Session ${opened_session['priority_no']}`}</p>
                                        {/* Session name */}
                                        <h1 style={{ textTransform: 'capitalize', textAlign: 'center' }}>{opened_session['name']}</h1>
                                        {/* Intensity level */}
                                        <p style={{ color: '#8F8F8F', textAlign: 'center', marginTop: 8, paddingBottom: 8, borderBottom: '1px solid #000', width: 280 }}>{`${opened_session['session_intensity']} Intensity`}</p>
                                        {/* duration */}
                                        <div className="flex column alignCenter" style={{ width: 72, margin: "16px 0 0" }}>
                                            <img src={TimerIcon} alt="timer icon" height={24} width={24} />
                                            <p style={{ color: '#8F8F8F', textAlign: 'center', margin: '8px 0 0' }}>{`Duration ${opened_session['duration_minutes']} min`}</p>
                                        </div>
                                        <p className="w-100" style={{ margin: "16px 0", fontSize: 27, textAlign: 'left' }}> Training</p>
                                        <p className="w-100" style={{ fontSize: 22, fontWeight: 500, textAlign: 'left' }}>{opened_session['name']}</p>
                                        <p style={{ margin: "8px 0 24px", textAlign: 'left' }}>{opened_session['notes']}</p>
                                        {/*  View in app & Buy Plan buttons */}
                                        <div className="flex justifyCenter alignCenter w-100 pointer"
                                            onClick={() => window.location.href = plan['user_pricing']['user_buy_url']}
                                            style={{ background: '#000', height: 56, textAlign: 'center', marginBottom: 16, borderRadius: 60 }}
                                        >
                                            {isProUser ? (
                                                <p style={{ color: '#FFF', letterSpacing: 1 }}>
                                                    <span style={{ textDecoration: 'line-through' }}> {`${findSymbol(plan['user_pricing']['currency'])}${plan['user_pricing']['price']}`}</span>
                                                    <span style={{ margin: "0 8px" }}> {`${findSymbol(plan['user_pricing']['currency'])}${plan['user_pricing']['user_price']}`}</span>
                                                    {` BUY PLAN`}
                                                </p>
                                            ) : (
                                                <p style={{ color: '#FFF', letterSpacing: 1 }}>
                                                    <span style={{ margin: "0 8px" }}> {`${findSymbol(plan['user_pricing']['currency'])}${plan['user_pricing']['user_price']}`}</span>
                                                    {` BUY PLAN`}
                                                </p>
                                            )}
                                            {/* <p style={{ color: '#FFF', letterSpacing: 1 }}>
                                                <span style={{ textDecoration: 'line-through' }}> {`${findSymbol(plan['user_pricing']['currency'])}${plan['user_pricing']['price']}`}</span>
                                                <span style={{ margin: "0 8px" }}> {`${findSymbol(plan['user_pricing']['currency'])}${plan['user_pricing']['user_price']}`}</span>
                                                {` BUY PLAN`}
                                            </p> */}
                                        </div>
                                        {/* Sessions list */}
                                        <div className="flex column justifyCenter alignCenter w-100">
                                            {sessions_list && sessions_list.length > 0 && sessions_list.map((list_item, index) => (
                                                <div className="flex column justifyStart alignStart w-100" key={index + 1}>
                                                    {list_item && list_item['open_list'] && (
                                                        <div className="flex column justifyStart alignCenter w-100">
                                                            {followers_list && followers_list.map((follower_item, follower_index) => (
                                                                <div className="flex column alignCenter justifyStart w-100" key={follower_index + 1} style={{ margin: "8px 0", background: "#F8F8F8", borderRadius: 12 }}>
                                                                    {follower_item['followers'] && follower_item['followers'].map((follower_item, follower_item_index) => (
                                                                        <div key={follower_item_index + 1} className="flex column alignCenter justifyStart w-100" style={{ padding: 8 }}>
                                                                            {/* Opened state & closed state */}
                                                                            {follower_item['show_full_content'] ?
                                                                                <div className="flex column alignCenter justifyStart w-100" style={{ padding: 8 }}>
                                                                                    {/* video */}
                                                                                    {!follower_item['video_loaded'] ?
                                                                                        <div className="flex justifyCenter alignCenter relative w-100" style={{ height: 260, borderRadius: 12, margin: index === 0 ? 0 : "24px 0 0" }}>
                                                                                            <img src={plan['image_url']} alt="plan_image" height={260} width={545} style={{ borderRadius: 12 }} />
                                                                                            <div className="flex column justifyCenter alignCenter h-100 absolute w-100">
                                                                                                <div className="custom_loader" />
                                                                                            </div>
                                                                                        </div> : null}

                                                                                    <ReactPlayer
                                                                                        width={window.screen.width * 0.92}
                                                                                        height={260}
                                                                                        url={follower_item['video']}
                                                                                        controls={true}
                                                                                        style={{ display: follower_item['video_loaded'] ? 'block' : 'none' }}
                                                                                        onReady={() => setFlagsForVideoPreviewOnceLoadedMobile(followers_list, follower_index, follower_item_index)}
                                                                                        onLoad={() => setFlagsForVideoPreviewOnceLoadedMobile(followers_list, follower_index, follower_item_index)}
                                                                                    />

                                                                                    <div className="flex justifyBetween alignCenter w-100">
                                                                                        <p style={{ textAlign: 'left', fontSize: 16, margin: "20px 0 12px", fontWeight: 500, width: '80%' }}>{follower_item['movement']}</p>
                                                                                        {follower_item['show_full_content'] ?
                                                                                            <img src={UpArrowIcon} alt="arrow up icon" height={24} width={24} onClick={() => setFalseFlagForShowFullContent(followers_list, follower_index, follower_item_index)} />
                                                                                            : null
                                                                                        }
                                                                                    </div>
                                                                                    <p className="w-100" style={{ textAlign: 'left', fontSize: 16, color: 'rgba(0,0,0,0.5)' }}>{follower_item['category']}</p>
                                                                                    <ul className="w-100" style={{ textAlign: 'left', margin: "16px 0 24px" }}>
                                                                                        {follower_item['description'].indexOf('-') > -1 ?
                                                                                            follower_item['description'].split('-').map((desc, idx) => {
                                                                                                return desc !== "" && (<li key={idx + 1} style={{ margin: "4px 0 0 16px" }}>{desc}</li>)
                                                                                            })
                                                                                            : follower_item['description'].split(/^\s*(?:[\dA-Z]+\.|[a-z]\)|•)\s+/gm).map((desc, idx) => {
                                                                                                return desc !== "" && (<li key={idx + 1} style={{ margin: "4px 0 0 16px" }}>{desc}</li>)
                                                                                            })}
                                                                                    </ul>
                                                                                    <div className="flex justifyBetween alignCenter w-100"
                                                                                        style={{ background: '#FFF', padding: 16, borderTopLeftRadius: 10, borderTopRightRadius: 10 }}>
                                                                                        <p>Sets</p>
                                                                                        <p>{follower_item['sets']}</p>
                                                                                    </div>
                                                                                    <div className="flex justifyBetween alignCenter w-100"
                                                                                        style={{ background: '#FFF', padding: 16, margin: "2px 0" }}>
                                                                                        <p>Reps</p>
                                                                                        <p>{follower_item['reps']}</p>
                                                                                    </div>
                                                                                    <div className="flex justifyBetween alignCenter w-100"
                                                                                        style={{ background: '#FFF', padding: 16, }}>
                                                                                        <p>Load</p>
                                                                                        <p>{follower_item['load']}</p>
                                                                                    </div>
                                                                                    <div className="flex justifyBetween alignCenter w-100"
                                                                                        style={{ background: '#FFF', padding: 16, margin: "2px 0" }}>
                                                                                        <p>Temp</p>
                                                                                        <p>{follower_item['tempo']}</p>
                                                                                    </div>
                                                                                    <div className="flex justifyBetween alignCenter w-100"
                                                                                        style={{ background: '#FFF', padding: 16, margin: "0 0 2px" }}>
                                                                                        <p>Focus</p>
                                                                                        <p>{follower_item['focus']}</p>
                                                                                    </div>
                                                                                    <div className="flex justifyBetween alignCenter w-100"
                                                                                        style={{ background: '#FFF', padding: 16, borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}>
                                                                                        <p>Exercise Importance</p>
                                                                                        <p>{follower_item['key']}</p>
                                                                                    </div>
                                                                                </div>
                                                                                :
                                                                                <div className="flex alignCenter justifyStart w-100 pointer">
                                                                                    <img src={list_item['image_url']} alt="session_image" height={60} width={64} style={{ borderRadius: 6, minWidth: 60 }} />
                                                                                    <div className="flex alignStart justifyBetween w-100">
                                                                                        <div className="flex column justifyCenter" style={{ margin: "0 12px" }}>
                                                                                            <p style={{ color: '#666668', fontWeight: 700, }}>{`${follower_item['movement']}`}</p>
                                                                                            <p style={{ color: '#B0B0AD', marginTop: 4, textTransform: 'capitalize' }}>{follower_item['category']}</p>
                                                                                        </div>
                                                                                        {!follower_item['show_full_content'] ?
                                                                                            <img src={DownArrowIcon} alt="arrow down icon" height={24} width={24} onClick={() => setTrueFlagsForShowFullContent(followers_list, follower_index, follower_item_index)} />
                                                                                            : null
                                                                                        }
                                                                                    </div>
                                                                                </div>}
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                            {/* Session 1 */}
                                            {opened_category['index'] === 1 ?
                                                <div className="flex justifyBetween alignCenter w-100">
                                                    <div className="flex justifyStart alignCenter w-100" onClick={() => setFlagForListBasedOnIndex(0)} style={{ margin: "16px 0" }}>
                                                        <img src={ForwardArrow} alt="back arrow icon" height={24} width={24} style={{ transform: 'rotate(180deg)' }} />
                                                        <p className="w-100" style={{ color: '#666668', marginLeft: 12, fontWeight: 700, textTransform: 'capitalize' }}> {`Session 1`}</p>
                                                    </div>
                                                    <div className="flex justifyEnd alignCenter w-100" onClick={() => showTimesUpPopup(true)} style={{ margin: "16px 0" }}>
                                                        <div className="flex alignCenter">
                                                            <p className="w-100" style={{ color: '#666668', marginRight: 12, fontWeight: 700, textTransform: 'capitalize' }}> Next</p>
                                                            <img src={ForwardArrow} alt="back arrow icon" height={24} width={24} />
                                                        </div>
                                                    </div>
                                                </div>
                                                : null}
                                            {/* Session 2 */}
                                            {opened_category['index'] === 0 ?
                                                <div className="flex justifyEnd alignCenter w-100" onClick={() => setFlagForListBasedOnIndex(1)} style={{ margin: "16px 0" }}>
                                                    <div className="flex alignCenter">
                                                        <p className="w-100" style={{ color: '#666668', marginRight: 12, fontWeight: 700, textTransform: 'capitalize' }}> {`Session 2`}</p>
                                                        <img src={ForwardArrow} alt="back arrow icon" height={24} width={24} />
                                                    </div>
                                                </div>
                                                : null}
                                        </div>
                                    </div>
                                    : null}
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <Footer />

            {/* Typeform popup */}
            {
                openPopup ? (
                    <div className="flex column justifyCenter alignCenter h-100 w-100 typeform_model_popup">
                        <div className="flex column justifyCenter alignCenter popup_card">
                            <div className="flex alignCenter justifyEnd w-100 pointer"
                                onClick={() => setPopupOpening(false)}
                                style={{ position: 'absolute', top: 32, right: 32 }}
                            >
                                <img src={CloseIcon} alt="close icon" height={24} width={24} />
                            </div>
                            <Widget
                                id="https://wildai.typeform.com/to/E6ahUxo5"
                                height={screen_width <= 576 ? "90%" : 490}
                                width={screen_width <= 576 ? "96%" : 576}
                                hidden={{
                                    email: user && user['email'],
                                }}
                                onSubmit={() => {
                                    setTimeout(() => setPopupOpening(false), 1500);
                                    window.localStorage.setItem("survey", true);
                                }}
                            />
                        </div>
                    </div>
                ) : null
            }

            {timesUpPopup ? (
                <div className="flex column justifyCenter alignCenter h-100 w-100 model_popup">
                    {screen_width <= 768 ?
                        <div className="flex column justifyStart alignCenter relative popup_card">
                            <div className="flex alignCenter justifyEnd w-100 pointer"
                                onClick={() => showTimesUpPopup(false)}
                                style={{ position: 'absolute', top: 20, right: 20 }}
                            >
                                <img src={CloseDarkIcon} alt="close dark icon" height={24} width={24} />
                            </div>

                            <div className="flex column justifyCenter alignCenter w-100" style={{ margin: "0 auto" }}>
                                <img src={WildAILogoDark} alt="Wild.AI logo" height={106.53} width={92} />
                                <p style={{ fontSize: 27 }}>Wild.AI</p>
                                <p style={{ fontSize: 27, margin: '24px 0 0' }}>{`Hi ${user ? user['first_name'] : null}!`}</p>
                                <img src={DiscountSticker} alt="discount sticker" height={180} width={180} style={{ margin: "-10px 0" }} />
                                <p style={{ fontSize: 14, textAlign: 'center', width: '90%' }}>Happy to hear you’re enjoying your experience. The first two sessions of this plan were on us!</p>
                                <p style={{ fontSize: 14, textAlign: 'center', margin: "8px 0" }}>Purchasing this full plan gives you lifetime access to all its sessions</p>
                                <p style={{ fontSize: 14, textAlign: 'center', width: '90%' }}>On top of that, you’ll be supporting one of our expert coaches, which is also pretty rewarding!</p>

                                <div onClick={() => window.location.href = plan['user_pricing']['user_buy_url']} className="black-button" style={{ minHeight: 48, margin: "32px 0 0" }} >
                                    <img src={ForwardArrowLight} alt="forward arrow icon" height={24} width={24} style={{ marginRight: 24 }} />
                                    <p> Buy Plan </p>
                                </div>
                                <p className="flex justifyCenter alignCenter w-100 pointer" onClick={() => showTimesUpPopup(false)} style={{ textTransform: 'uppercase', margin: "24px 0 16px" }}>No, Thank You</p>
                            </div>
                        </div>

                        :
                        <div className="flex column justifyStart alignCenter relative popup_card">

                            <div className="flex alignCenter justifyEnd w-100 pointer"
                                onClick={() => showTimesUpPopup(false)}
                                style={{ position: 'absolute', top: 20, right: 20 }}
                            >
                                <img src={CloseDarkIcon} alt="close dark icon" height={24} width={24} />
                            </div>

                            <img src={DiscountSticker} alt="discount sticker" height={180} width={180}
                                style={{ position: 'absolute', top: 64, right: 32 }} />
                            <div className="flex column justifyCenter alignCenter w-100" style={{ background: "#fff", width: 468, height: 468, padding: 24, borderRadius: 8, margin: "0 auto" }}>
                                <img src={WildAILogoDark} alt="Wild.AI logo" height={106.53} width={92} />
                                <p style={{ fontSize: 27 }}>Wild.AI</p>
                                <p style={{ fontSize: 27, margin: '24px 0' }}>{`Hi ${user ? user['first_name'] : null}!`}</p>
                                <p style={{ fontSize: 14, textAlign: 'center', width: 380 }}>Happy to hear you’re enjoying your experience. The first two sessions of this plan were on us!</p>
                                <p style={{ fontSize: 14, textAlign: 'center', margin: "8px 0" }}>Purchasing this full plan gives you lifetime access to all its sessions</p>
                                <p style={{ fontSize: 14, textAlign: 'center', width: 380 }}>On top of that, you’ll be supporting one of our expert coaches, which is also pretty rewarding!</p>

                                <div onClick={() => window.location.href = plan['user_pricing']['user_buy_url']} className="black-button" style={{ minHeight: 48, margin: "32px 0 0" }} >
                                    <img src={ForwardArrowLight} alt="forward arrow icon" height={24} width={24} style={{ marginRight: 24 }} />
                                    <p> Buy Plan </p>
                                </div>
                                <p className="flex justifyCenter alignCenter w-100 pointer" onClick={() => showTimesUpPopup(false)} style={{ textTransform: 'uppercase', margin: "24px 0 16px" }}>No, Thank You</p>
                            </div>
                        </div>
                    }
                </div>
            ) : null
            }

        </div >
    );
};

export default OpenSessionDetail;
