import React from "react";
import WildLogo from "../assets/logo-light.png";

const LogoFooter = ({ screenName }) => {
  return (
    <footer
      style={{
        width: "100%",
        height: "100%",
        padding: "80px 0",
        background: "#010101",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <a href="/">
        <img src={WildLogo} height={141.17} width={122} alt="wild logo" />
      </a>
    </footer>
  );
};

export default LogoFooter;
