import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// Components
import Header from "../components/common/Header";
import Footer from "../components/common/Footer";
// Logo
import WildLightLogo from "../assets/logo-light.png";
// Icons
import GrayTickIcon from "../assets/icons/gray-tick.svg";
import StarIcon from "../assets/icons/star.svg"
// Actions
import { validatePromoCode, createNotification } from "../store/app/actions";
import { history } from "../index";

const WildPro = (props) => {
    let { location } = props;
    const dispatch = useDispatch();
    let { user } = useSelector((state) => state.app);
    let [username, set_username] = useState('');
    let [loading, setLoading] = useState(false);
    let [result, setResult] = useState(null);
    const features_list = [
        "Calculated readiness scores",
        "Wearable device integrations",
        "Receive pre- and post-training recomendations",
        "Data reports and trends up to 2 cycles or 60 days",
        "Unlimited Data Visualisation",
        "Training Plans",
        "Connection to a Coach",
        "Premium training, nutrition and recovery content"
    ]

    useEffect(() => {
        if (user) {
            let { first_name } = user;
            set_username(first_name);
        }
    }, [user]);

    const onSuccess = (response) => {
        setLoading(false);
        if (response['is_user_pro']) {
            history.push("/subscribed");
        } else {
            setResult(response);
        }
    }
    const onError = (error) => dispatch(createNotification("error-toast", error));

    useEffect(() => {
        let { pathname } = location;
        var promo_code = pathname.substring(pathname.lastIndexOf('/') + 1);
        if (promo_code !== "") {
            setLoading(true);
            dispatch(validatePromoCode(promo_code, onSuccess, onError));
        }
    }, []);

    return (loading && result == null) ? (
        <div className="flex column justifyCenter alignCenter h-100 w-100">
            <div className="custom_loader" />
            <p style={{ marginTop: 32 }}>
                {`Loading, please wait…`}
            </p>
        </div>
    ) : (
        <div className="_container">
            <header className='desktop home_background_banner' style={{ minHeight: 300, maxHeight: 301 }}>
                <div className='_after'>
                    <Header location={location} />
                    <div className="desktop flex justifyCenter alignCenter w-100 h-100">
                        <p style={{ color: '#FFF', fontSize: 72, marginTop: 140 }}>{`Hi, ${username}`}</p>
                    </div>
                </div>
            </header>

            {/* Mobile design */}
            <header className='mobile relative w-100'>
                <img src="https://media.wild.ai/assets/images/lifting.jpeg"
                    alt="Banner boxing"
                    height={890}
                    width='100%'
                />
                <div className="flex column justifyCenter alignCenter w-100" style={{ position: 'absolute', top: 0 }}>
                    <a href="https://wild.ai" target="_blank" rel="noreferrer" style={{ marginTop: 24 }}>
                        <img src={WildLightLogo} alt="wild.ai logo" height={106.53} width={92} />
                    </a>

                    <div className="flex column justifyCenter alignCenter w-100 h-100" style={{
                        background: "#fff",
                        margin: "96px auto 0",
                        borderTopLeftRadius: 24,
                        height: 664,
                        padding: "16px 24px", minHeight: '100%', borderTopRightRadius: 24
                    }}>
                        <div style={{ width: 40, height: 5, borderRadius: 8, background: '#B0B0AD', }} />
                        <br />
                        <div className="flex column alignCenter justifyCenter relative w-100 h-100">
                            {result && result['discount_percentage'] && (
                                <div className="absolute" style={{ top: -88, right: 0 }}>
                                    <div className="relative">
                                        <img src={StarIcon} alt="start icon" height={110} width={110} />
                                        <div className="absolute" style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                            <p style={{ fontSize: 36, rotate: '-24deg', marginTop: -8, marginLeft: -6 }}>{`-${result && result['discount_percentage']}`}</p>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <p className="w-100" style={{ fontSize: 32, textAlign: 'left' }}>Pro Features</p>
                            <div className="flex column alignStart">
                                {features_list.map((feature, index) => (
                                    <div className="flex justifyCenter alignCenter" key={index + 1} style={{ margin: "16px 0 0" }}>
                                        <img src={GrayTickIcon} alt="tick_mark" height={18} width={18} style={{ marginRight: 16 }} />
                                        <p style={{ color: '#565656', fontSize: 16 }}>{feature}</p>
                                    </div>
                                ))}
                            </div>
                            <br />
                            <div onClick={() => window.location.replace(`${result['buy_endpoint']}`)} id="continue_to_dashboard_button" className="black-button" style={{ maxWidth: '90%' }} >
                                <p> Let's Go! </p>
                            </div>
                            <p onClick={() => history.push("/plans")} className="flex justifyCenter alignCenter w-100" style={{ textTransform: 'uppercase', margin: "24px 0 16px" }}>No, Thank You</p>
                            <div style={{ width: 140, height: 5, borderRadius: 8, background: '#000', }} />
                        </div>
                    </div>
                </div>
            </header>

            {/* Desktop design */}
            <div className="relative desktop flex justifyBetween alignCenter w-100 h-100" style={{ minHeight: result && result['discount_percentage'] ? 990 : 850, maxHeight: result && result['discount_percentage'] ? 992 : 850 }}>
                <div className="flex column justifyCenter alignCenter h-100" style={{ width: '50%', paddingRight: 80 }}>
                    {result && result['discount_percentage'] && (
                        <div className="flex JustifyCenter alignStart w-100" style={{ marginBottom: 32 }}>
                            <div className="relative" style={{ marginLeft: '12%' }}>
                                <img src={StarIcon} alt="start icon" height={200} width={200} />
                                <div className="absolute" style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                    <p style={{ fontSize: 64, rotate: '-24deg', marginTop: -12, marginLeft: -12 }}>{`-${result && result['discount_percentage']}`}</p>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="flex column justifyStart alignStart" style={{ width: 300 }}>
                        <p style={{ fontSize: 45, textAlign: 'left' }}>Pro Features</p>
                        <div className="flex column alignStart">
                            {features_list.map((feature, index) => (
                                <div className="flex justifyCenter alignCenter" key={index + 1} style={{ margin: "16px 0 0" }}>
                                    <img src={GrayTickIcon} alt="tick_mark" height={18} width={18} style={{ marginRight: 12 }} />
                                    <p style={{ color: '#565656', fontSize: 16 }}>{feature}</p>
                                </div>
                            ))}
                        </div>
                        <div onClick={() => window.location.replace(`${result['buy_endpoint']}`)} id="continue_to_dashboard_button" className="black-button" style={{ margin: "24px 0 16px" }} >
                            <p> Get Wild.AI Pro </p>
                        </div>
                        <p onClick={() => history.push("/plans")} className="white-button-with-border">No, Thank You</p>
                    </div>
                </div>

                <div className="absolute" style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 10 }}>
                    <img src="https://wild-blog-strapi.s3.amazonaws.com/dashboard_min_986e54e6a8.png"
                        alt="Wild.AI mobile app"
                        height={626}
                        width={309}
                    />
                </div>
                <div className="flex colum justifyCenter alignCenter h-100" style={{ width: '50%', backgroundImage: 'url(https://media.wild.ai/assets/images/lifting.jpeg)', backgroundPositionX: 'center', backgroundPositionY: 'top' }} />
            </div>
            <Footer />
        </div>
    );
};

export default WildPro;
