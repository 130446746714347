import React, { useState, useEffect } from "react";
import home_styles from "../styles/home.module.css";
import shapes from "../styles/shapes.module.css";
import MarketingBannerCard from "./marketing-banner-card";
import Cross from "../assets/icons/cross-dark.svg";

const even = (number) => {
  if (number % 2 === 0) return true;
  else return false;
};

const LifeStageSupport = ({ content, screenName }) => {
  const [stages, setStages] = useState([]);

  const getData = () => {
    for (var i = 0; i < content.length; i++) {
      content[i].show = false;
    }
    return content;
  };

  const updateStages = (index, whichvalue, newvalue) => {
    let g = stages[index];
    g[whichvalue] = newvalue;
    if (index === -1) {
    } else
      setStages([...stages.slice(0, index), g, ...stages.slice(index + 1)]);
  };

  const openDialog = (indexOf) => updateStages(indexOf, "show", true);

  const closeDialog = (indexOf) => updateStages(indexOf, "show", false);

  useEffect(() => {
    if (content) {
      const data = getData(content);
      const sorted = data.sort((a, b) => a.id - b.id);
      setStages(sorted);
    }
  }, [content]);

  return (
    <section className={home_styles.life_stage_support}>
      <div className={shapes.support_border_top} />
      <div className={home_styles.support_overlay}>
        <img
          src="https://wild-blog-strapi.s3.amazonaws.com/support_0a43147a1e.jpg?12335003.7"
          alt="life stage support background"
          className={home_styles.support_bg}
        />
        {screenName === "landing" && <MarketingBannerCard />}
        <div
          className={home_styles.support_overlay_content}
          style={{ paddingTop: 260 }}
        >
          <div className={home_styles.support_content}>
            <h2
              className={home_styles.show_for_desktop}
              style={{
                color: "#ffffff",
                marginBottom: 127.5,
                marginTop: screenName === "landing" ? 222.5 : 0,
                textTransform: "capitalize",
                textAlign: "center",
              }}
            >
              Life stages we support
            </h2>

            <h2
              className={home_styles.show_for_mobile}
              style={{
                color: "#ffffff",
                marginBottom: 127.5,
                textTransform: "capitalize",
                textAlign: "center",
              }}
            >
              Life stages we support
            </h2>

            {stages &&
              stages.map((stage, index) => (
                <span key={index + 1}>
                  <div className={home_styles.vertical_row}>
                    <div className={home_styles.vertical_row_left} />
                    <div className={home_styles.vertical_row_center}>
                      <div className={home_styles.vertical_line} />
                      <div className={home_styles.vertical_line} />
                      <div className={home_styles.vertical_line} />
                      <div className={home_styles.vertical_half_line_top} />
                    </div>
                    <div className={home_styles.vertical_row_right} />
                  </div>

                  <div className={home_styles.vertical_row}>
                    {even(index + 1) ? (
                      <div className={home_styles.vertical_row_left}>
                        {stage.support ? (
                          <div
                            className={home_styles.vertical_row_left_content}
                          >
                            <p className={home_styles.timeline}>
                              {stage.timeline}
                            </p>
                            <p className={home_styles.description}>
                              {stage.title}
                            </p>
                          </div>
                        ) : (
                          <div
                            className={home_styles.vertical_row_left_content}
                          >
                            <p className={home_styles.timeline_fade}>
                              {stage.timeline}
                            </p>
                            <p className={home_styles.description_fade}>
                              {stage.title}
                            </p>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className={home_styles.vertical_row_left} />
                    )}
                    <div className={home_styles.vertical_row_center}>
                      {stage.src === null ? (
                        <div className={home_styles.support_icon_empty} />
                      ) : (
                        <div className={home_styles.support_icon_box}>
                          <div
                            className={home_styles.support_icon}
                            onClick={() => {
                              openDialog(index);
                              for (var i = 0; i < stages.length; i++) {
                                if (i === index) {
                                } else {
                                  closeDialog(i);
                                }
                              }
                            }}
                          >
                            {stage && (
                              <img
                                width={52}
                                height={52}
                                src={stage && stage["icon"].url}
                                alt={stage && stage["icon"].name}
                              />
                            )}
                          </div>

                          {stage.show && (
                            <div
                              className={home_styles.support_icon_details_box}
                            >
                              <div className={home_styles.support_icon_details}>
                                <div
                                  className={home_styles.close_dialog}
                                  onClick={() => closeDialog(index)}
                                >
                                  <img
                                    width={24}
                                    height={24}
                                    src={Cross}
                                    alt="cross"
                                  />
                                </div>
                                <h3>{stage.title}</h3>
                                <p>{stage.description}</p>
                                <ul>
                                  {stage.bullets.map((bullets, subIndex) => (
                                    <li key={subIndex + 1}>
                                      <div className={home_styles.dot} />
                                      <p style={{ marginLeft: 8 }}>
                                        {bullets["bullet"]}
                                      </p>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                              <div className={shapes.white_tiled} />
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    {!even(index + 1) ? (
                      <div className={home_styles.vertical_row_right}>
                        {stage.support ? (
                          <div
                            className={home_styles.vertical_row_right_content}
                          >
                            <p className={home_styles.timeline}>
                              {stage.timeline}
                            </p>
                            <p className={home_styles.description}>
                              {stage.title}
                            </p>
                          </div>
                        ) : (
                          <div
                            className={home_styles.vertical_row_right_content}
                          >
                            <p className={home_styles.timeline_fade}>
                              {stage.timeline}
                            </p>
                            <p className={home_styles.description_fade}>
                              {stage.title}
                            </p>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className={home_styles.vertical_row_right} />
                    )}
                  </div>

                  <div className={home_styles.vertical_row}>
                    <div className={home_styles.vertical_row_left} />
                    <div className={home_styles.vertical_row_center}>
                      <div className={home_styles.vertical_half_line_bottom} />
                      <div className={home_styles.vertical_line} />
                      <div className={home_styles.vertical_line} />
                      <div className={home_styles.vertical_line} />
                    </div>
                    <div className={home_styles.vertical_row_right} />
                  </div>
                </span>
              ))}
          </div>
        </div>
      </div>

      <div
        className={`${home_styles.show_for_desktop} ${shapes.support_border_bottom}`}
      />
    </section>
  );
};

export default LifeStageSupport;
