import React, { useState, useEffect } from "react";
import CommonReportHeader from "../../common/CommonReportHeader";
import GraphLegends from "../../common/GraphLegends";
import EmptyGraph from "../../common/EmptyGraph";
import { menstrual_cycle_report, contraceptive_group_report, monthly_report } from "../../../js/static_report";
import moment from "moment";


const ReadinessScore = (props) => {
    let [y_axes, setYaxes] = useState(null);
    let [legends, setLegends] = useState(null);
    let [phaseDetails, setPhaseDetails] = useState(null);
    let [barValues, setBarValues] = useState(null);
    let [content, setContent] = useState(null);
    let [showTooltip, setShowTooltip] = useState(-1);
    let [axes_difference, set_axes_difference] = useState(0);
    let [axes_base, set_axes_base] = useState(0);
    let [cycle_data_length, set_cycle_data_length] = useState(0);
    let [max_days, set_max_days] = useState(0);
    let [show_empty_graph, set_show_empty_graph] = useState(false);

    const removeElement = (array, arrayElement) => {
        for (var i = 0; i < array.length; i++) {
            if (array[i] === arrayElement) {
                array.splice(i, 1);
            }
        }
        return array;
    }

    const reverseyAxesArray = (y_axes) => {
        if (y_axes) {
            y_axes = removeElement(y_axes, 0);
            y_axes = y_axes.reverse();
            set_axes_difference(y_axes[0] - y_axes[1]);
            const base = y_axes[y_axes.length - 1] - (y_axes[0] - y_axes[1])
            set_axes_base(base);
            return y_axes;
        }
    }

    const changeDateFormate = (arr) => {
        let temp = [];
        for (var i = 0; i < arr.length; i++) {
            temp[i] = {
                start_date: moment(arr[i].start_date, 'DD-MM-YYYY').format('YYYY-MM-DD'),
                end_date: moment(arr[i].end_date, 'DD-MM-YYYY').format('YYYY-MM-DD'),
                full_name: arr[i].full_name,
                short_name: arr[i].short_name,
                duration: arr[i].duration,
                value: arr[i].stats ? arr[i].stats["value"] : null,
                trend: arr[i].stats ? arr[i].stats["trend"] : null,
                trend_value: arr[i].stats ? arr[i].stats["trend_value"] : null,
                arrow_colour: arr[i].stats ? arr[i].stats['arrow_colour'] : null
            };
        }
        return temp;
    }

    const sortLinesByKeys = (o) => {
        var orderedDates = [];
        Object.keys(o).sort(function (a, b) {
            return moment(a, 'DD-MM-yyyy').toDate() - moment(b, 'DD-MM-yyyy').toDate();
        }).forEach(function (key) {
            var date = moment(key, 'DD-MM-YYYY')
            orderedDates[date.format('YYYY-MM-DD')] = o[key];
        })
        return orderedDates;
    }

    const setLineValueInBar = (bar_graph, phase_details, line_graph) => {
        var bar_values = [];
        var phase_values = [];
        for (var i = 0; i < phase_details.length; i++) {
            //  eslint-disable-next-line
            Object.keys(bar_graph).forEach(function (bar_key) {
                if (moment(bar_key).isSame(phase_details[i].start_date)) {
                    bar_values[bar_key] = { ...bar_graph[bar_key], line_value: line_graph[bar_key], cycle_start: true, cycle_end: false }
                } else if (moment(bar_key).isBetween(phase_details[i].start_date, phase_details[i].end_date)) {
                    bar_values[bar_key] = { ...bar_graph[bar_key], line_value: line_graph[phase_details[i].start_date], cycle_start: false, cycle_end: false }
                } else if (moment(bar_key).isSame(phase_details[i].end_date)) {
                    bar_values[bar_key] = { ...bar_graph[bar_key], line_value: line_graph[bar_key], cycle_start: false, cycle_end: true }
                }
            });
            phase_values[i] = {
                name: phase_details[i].full_name,
                short_name: phase_details[i].short_name,
                days: phase_details[i].duration,
                value: phase_details[i].value,
                trend: phase_details[i].trend,
                trend_value: phase_details[i].trend_value,
                arrow_colour: phase_details[i].arrow_colour
            }

        }
        setPhaseDetails(phase_values);
        return bar_values;
    }

    const allEqualToNull = (arr) => arr.every(v => v === -1);

    useEffect(() => {
        if (props.score) {
            let { readiness: { legend, phase_details, bar_graph, line_graph, title, subtitle, y_axes } } = props.score;
            if (Object.values(bar_graph) && Object.values(bar_graph).length > 0) {
                let is_empty = false;
                let phase_count = phase_details.length;
                let value_collector = [];
                const bar_values = Object.values(bar_graph);
                for (let i in bar_values) {
                    value_collector[i] = bar_values[i].value;
                }

                if (value_collector.length > 0) {
                    if (allEqualToNull(value_collector)) {
                        is_empty = true;
                    } else {
                        is_empty = false;
                    }
                }

                if (is_empty && !props.dashboard) {
                    set_show_empty_graph(true);
                    if (phase_details.length > 2) {
                        let { legend, phase_details, bar_graph, line_graph, title, subtitle, y_axes } = menstrual_cycle_report['readiness'];
                        setYaxes(reverseyAxesArray(y_axes));
                        setLegends(legend);
                        set_cycle_data_length(phase_details.length);
                        set_max_days(calculateMaxDays(phase_details));
                        setBarValues(setLineValueInBar(bar_graph, changeDateFormate(phase_details), sortLinesByKeys(line_graph)));
                        setContent({
                            title: title || null,
                            subtitle: subtitle || null
                        });
                    } else if (phase_details.length === 2) {
                        let { legend, phase_details, bar_graph, line_graph, title, subtitle, y_axes } = contraceptive_group_report['readiness'];
                        setYaxes(reverseyAxesArray(y_axes));
                        setLegends(legend);
                        set_cycle_data_length(phase_details.length);
                        set_max_days(calculateMaxDays(phase_details));
                        setBarValues(setLineValueInBar(bar_graph, changeDateFormate(phase_details), sortLinesByKeys(line_graph)));
                        setContent({
                            title: title || null,
                            subtitle: subtitle || null
                        });
                    } else if (phase_details.length === 1) {
                        let { legend, phase_details, bar_graph, line_graph, title, subtitle, y_axes } = monthly_report['readiness'];
                        setYaxes(reverseyAxesArray(y_axes));
                        setLegends(legend);
                        set_cycle_data_length(phase_details.length);
                        set_max_days(calculateMaxDays(phase_details));
                        setBarValues(setLineValueInBar(bar_graph, changeDateFormate(phase_details), sortLinesByKeys(line_graph)));
                        setContent({
                            title: title || null,
                            subtitle: subtitle || null
                        });
                    }
                } else if (!is_empty && phase_count === 0 && !props.dashboard) {
                    // special case
                    setYaxes(reverseyAxesArray(y_axes));
                    setLegends(legend);
                    setContent({
                        title: title || null,
                        subtitle: subtitle || null
                    });
                    set_cycle_data_length(1);
                    const phase = [{
                        full_name: "Menopause",
                        short_name: "ME",
                        duration: bar_values.length,
                        end_date: Object.keys(bar_graph)[bar_values.length - 1],
                        start_date: Object.keys(bar_graph)[0],
                        value: null,
                        trend: null,
                        trend_value: null,
                        arrow_colour: null
                    }]
                    const line = {
                        [Object.keys(bar_graph)[0]]: 0,
                        [Object.keys(bar_graph)[bar_values.length - 1]]: 0
                    }
                    set_max_days(bar_values.length);
                    setBarValues(setLineValueInBar(bar_graph, phase, line));
                } else if (is_empty && !props.dashboard) {
                    setYaxes(reverseyAxesArray(y_axes));
                    setLegends(legend);
                    set_cycle_data_length(phase_details.length);
                    set_max_days(calculateMaxDays(phase_details));
                    setBarValues(setLineValueInBar(bar_graph, changeDateFormate(phase_details), sortLinesByKeys(line_graph)));
                    setContent({
                        title: title || null,
                        subtitle: subtitle || null
                    });
                } else {
                    setYaxes(reverseyAxesArray(y_axes));
                    setLegends(legend);
                    set_cycle_data_length(phase_details.length);
                    set_max_days(calculateMaxDays(phase_details));
                    setBarValues(setLineValueInBar(bar_graph, changeDateFormate(phase_details), sortLinesByKeys(line_graph)));
                    setContent({
                        title: title || null,
                        subtitle: subtitle || null
                    });
                }
            }
        }
    }, [props.score]);

    const calculateMaxDays = (phases) => {
        let maxDays = 0;
        for (var i = 0; i < phases.length; i++) {
            maxDays += phases[i].duration;
        }
        return maxDays;
    }

    const calculateBarHeight = (value, base, diff) => {
        let val_diff = 0;
        if (base < 0 || base === 0) {
            val_diff = (value / diff) * 60;
            if (val_diff < 0) {
                return 0;
            }
            return val_diff;
        } else {
            val_diff = value - base;
            val_diff = (val_diff / diff) * 60;
            return val_diff;
        }
    }

    const calcHeightWidth = () => {
        let boxes_width = document.getElementById('readiness_bar_container');
        if (boxes_width) {
            boxes_width = boxes_width.offsetWidth;
            const calc = (boxes_width - (16 * cycle_data_length)) / max_days;
            return calc;
        }
    }


    return !props.score ? (
        <div className="flex column justifyStart alignStart w-100" style={{ margin: "32px 0 0" }}>
            <CommonReportHeader label="Readiness Score" />
        </div>
    ) : legends && (
        <div className="flex column justifyStart alignStart relative w-100" style={{ margin: "32px 0 0" }}>
            {content && (<CommonReportHeader header={props.headers || null} title={content.title} subtitle={content.subtitle} replaceStatus={props.dashboard} isSubtitleUnder={true} />)}

            <EmptyGraph show={show_empty_graph} message="No data to show! Complete your check-ins in Wild AI to see your data." />

            <div className="flex column justifyStart alignStart w-100  scrollable" style={{ paddingBottom: 24 }}>
                <div className="flex column w-100" style={{ position: "relative", margin: "64px 0 16px", minWidth: barValues && Object.keys(barValues).length * 28 + 80, borderTop: "1px solid #737373" }}>
                    {/* axes */}
                    {y_axes && y_axes.map((axe, index) => (
                        <div key={index + 1} className="flex justifyStart alignStart w-100" style={{
                            borderBottom: "1px solid #737373",
                            height: 60
                        }}>
                            <p style={{ color: "#737373" }}>{`${axe}`}</p>
                        </div>
                    ))}

                    {/* bar with line and circle */}
                    <div className="flex" id="readiness_bar_container" style={{
                        width: 'calc(100% - 48px)',
                        position: "absolute",
                        bottom: 1, left: 48
                    }}>
                        {barValues && Object.keys(barValues).map((bar, index) => {
                            return (
                                <div key={index + 1} className="flex alignEnd" style={{
                                    position: "relative",
                                    marginRight: barValues[bar]["cycle_end"] ? 16 : 0
                                }}>
                                    {/* bar */}
                                    <div style={{
                                        position: "relative",
                                        background: barValues[bar]["colour"] || 'transparent',
                                        width: calcHeightWidth() - 8,
                                        margin: "0 4px",
                                        height: calculateBarHeight(barValues[bar]["value"], axes_base, axes_difference),
                                        maxHeight: calculateBarHeight(barValues[bar]["value"], axes_base, axes_difference)
                                    }}
                                        onMouseEnter={() => setShowTooltip(`bar_${index + 1}_hover_${barValues[bar]["value"]}`)}
                                        onMouseLeave={() => setShowTooltip(-1)}
                                    />
                                    {/* Tooltip */}
                                    {showTooltip === `bar_${index + 1}_hover_${barValues[bar]["value"]}` && (
                                        <div className="tooltip" style={{
                                            background: "#FFFFFF",
                                            boxShadow: "0 0 2px rgba(0,0,0,0.3)",
                                            marginBottom: calculateBarHeight(barValues[bar]["value"], axes_base, axes_difference) + 20,
                                            border: "0.5px solid rgba(0,0,0,0.5)",
                                            width: 108,
                                            marginLeft: -40,
                                            zIndex: 1000,
                                            padding: 4,
                                            height: "auto"
                                        }}>
                                            {`${moment(bar).format("DD MMM YY")}: ${barValues[bar]["value"].toFixed(1)}`}
                                        </div>
                                    )}
                                    {/* Date */}
                                    {phaseDetails.length === 1 && (
                                        <div className="flex column alignCenter" style={{
                                            position: "absolute", bottom: -23,
                                            width: calcHeightWidth() - 8, margin: "0 4px",
                                        }}>
                                            <div style={{ width: 1, height: 8, background: '#737373' }} />
                                            <p style={{ fontSize: 8, marginTop: 4 }}>{moment(bar).format("DD")}</p>
                                        </div>
                                    )}

                                    {/* start circle */}
                                    {barValues[bar]["cycle_start"] && (
                                        <>
                                            <div
                                                onMouseEnter={() => setShowTooltip(index)}
                                                onMouseLeave={() => setShowTooltip(-1)}
                                                style={{
                                                    position: "absolute",
                                                    left: 0,
                                                    bottom: 0,
                                                    zIndex: 100,
                                                    background: barValues[bar]["line_value"] ? "#FFFFFF" : "transparent",
                                                    width: 20,
                                                    height: 20,
                                                    marginBottom: barValues[bar]["line_value"] ? calculateBarHeight(barValues[bar]["line_value"], axes_base, axes_difference) - 8 : 0,
                                                    border: barValues[bar]["line_value"] ? "2px solid #000000" : 0,
                                                    borderRadius: "50%"
                                                }} />

                                            {barValues[bar]["line_value"] !== 0 && showTooltip === index && (
                                                <div className="tooltip" style={{
                                                    background: barValues[bar]["line_value"] === undefined ? "transparent" : "#FFFFFF",
                                                    boxShadow: barValues[bar]["line_value"] ? "0 0 2px rgba(0,0,0,0.3)" : "none",
                                                    marginBottom: barValues[bar]["line_value"] ? calculateBarHeight(barValues[bar]["line_value"], axes_base, axes_difference) + 20 : 0,
                                                    border: barValues[bar]["line_value"] ? "0.5px solid rgba(0,0,0,0.5)" : 0,
                                                    width: 108,
                                                    marginLeft: -40,
                                                }}>
                                                    {`${moment(bar).format("DD MMM YY")}: ${(barValues[bar]["line_value"]).toFixed(0)}`}
                                                </div>
                                            )}
                                        </>
                                    )}

                                    {/* line */}
                                    <div style={{
                                        position: "absolute",
                                        left: 0,
                                        bottom: 0,
                                        zIndex: 10,
                                        background: barValues[bar]["line_value"] ? "#000000" : "transparent",
                                        height: 3,
                                        width: calcHeightWidth(),
                                        // width: barValues[bar]["cycle_end"] ? 18 : props.monthly_status ? 35 : 34,
                                        // marginLeft: 4,
                                        marginBottom: barValues[bar]["line_value"] ? calculateBarHeight(barValues[bar]["line_value"], axes_base, axes_difference) : 0
                                    }} />

                                    {/* end circle */}
                                    {barValues[bar]["cycle_end"] && (
                                        <>
                                            <div onMouseEnter={() => setShowTooltip(index)}
                                                onMouseLeave={() => setShowTooltip(-1)}
                                                style={{
                                                    position: "absolute",
                                                    right: 0,
                                                    bottom: 0,
                                                    zIndex: 100,
                                                    background: barValues[bar]["line_value"] ? "#FFFFFF" : "transparent",
                                                    width: 20,
                                                    height: 20,
                                                    // marginLeft: props.monthly_status ? 12 : 4,
                                                    marginBottom: barValues[bar]["line_value"] ? calculateBarHeight(barValues[bar]["line_value"], axes_base, axes_difference) - 8 : 0,
                                                    border: barValues[bar]["line_value"] ? "2px solid #000000" : 0,
                                                    borderRadius: "50%"
                                                }}
                                            />

                                            {barValues[bar]["line_value"] !== 0 && showTooltip === index && (
                                                <div className="tooltip" style={{
                                                    width: 108,
                                                    marginLeft: -40,
                                                    background: barValues[bar]["line_value"] === undefined ? "transparent" : "#FFFFFF", boxShadow: barValues[bar]["line_value"] ? "0 0 2px rgba(0,0,0,0.3)" : "none", marginBottom: barValues[bar]["line_value"] ? calculateBarHeight(barValues[bar]["line_value"], axes_base, axes_difference) + 20 : 0, border: barValues[bar]["line_value"] ? "0.5px solid rgba(0,0,0,0.5)" : 0
                                                }}>
                                                    {`${moment(bar).format("DD MMM YY")}: ${(barValues[bar]["line_value"]).toFixed(0)}`}
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                            )
                        })}
                    </div>
                </div>

                <div className="flex justifyStart alignStart">
                    {phaseDetails && phaseDetails.length > 1 && phaseDetails.map((phase, index) => (
                        <div key={index + 1}
                            className="flex justifyCenter alignCenter flex_wrap"
                            style={{
                                marginLeft: index === 0 ? 48 : 16,
                                minWidth: calcHeightWidth() * phase.days,
                                lineHeight: 3,
                                marginTop: phaseDetails.length === 1 ? 16 : 0,
                            }}
                        >
                            <p style={{ color: "#000000", fontSize: 11 }}>{phase.name}</p>
                            <h5 style={{ fontSize: 12, marginLeft: 4 }}>{phase.value}</h5>
                            <div className="flex alignCenter">
                                {phase.trend === "positive" && (
                                    <>
                                        <div className="positive_arrow">
                                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="24" height="24" viewBox="0 0 24 24">
                                                <path d="M14,20H10V11L6.5,14.5L4.08,12.08L12,4.16L19.92,12.08L17.5,14.5L14,11V20Z" fill={phase.arrow_colour || '#5f9f61'} />
                                            </svg>
                                        </div>
                                        <p className="trend_value" style={{ marginLeft: 0, fontSize: 12 }}>{`+${phase.trend_value}`}</p>
                                    </>
                                )}
                                {phase.trend === "negative" && (
                                    <>
                                        <div className="negative_arrow">
                                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="24" height="24" viewBox="0 0 24 24">
                                                <path d="M10,4H14V13L17.5,9.5L19.92,11.92L12,19.84L4.08,11.92L6.5,9.5L10,13V4Z" fill={phase.arrow_colour || "#DD3A29"} />
                                            </svg>
                                        </div>
                                        <p className="trend_value" style={{ marginLeft: 0, fontSize: 12 }}>{`${phase.trend_value}`}</p>
                                    </>
                                )}
                                {phase.trend === "neutral" && (
                                    <>
                                        <div style={{ marginLeft: 4, width: 14, height: 2, backgroundColor: "#000000" }} />
                                        <p className="trend_value" style={{ marginLeft: 0, fontSize: 12 }}>{`${phase.trend_value}`}</p>
                                    </>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>


            <GraphLegends legend={legends} />
        </div>
    )
}


export default ReadinessScore;