import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
// Components
import Header from "../components/common/Header";
import Footer from "../components/common/Footer";
// Icons
import WildLightLogo from "../assets/logo-light.png";
import ForwardArrow from "../assets/icons/forward-arrow-light.svg";
import WildAILogoDark from "../assets/logo-dark.png";
// Actions
import { history } from "../index";

const AlreadySubscribed = (props) => {
    const { location } = props;
    let { user } = useSelector((state) => state.app);
    let [username, set_username] = useState('');

    useEffect(() => {
        if (user) {
            let { first_name } = user;
            set_username(first_name);
        }
    }, [user]);

    useEffect(() => {
        window.analytics.page();
    }, []);

    // Already subscribed screen

    return (
        <div className="_container">
            {/* Desktop */}
            <header className='desktop background_banner' style={{ minHeight: 992 }}>
                <video autoPlay loop muted preload="auto" poster="https://media.wild.ai/assets/images/landing-video-thumbnail.png">
                    <source src="https://media.wild.ai/assets/videos/wild_teaser.mp4" type="video/mp4" />
                </video>
                <div className='_after'>
                    <Header location={location} />
                    <div className="flex column justifyCenter alignCenter w-100 h-100">
                        <div className="flex justifyCenter alignCenter w-100" style={{ margin: '0 0 64px' }}>
                            <p style={{ color: '#FFF', fontSize: 72 }}>{`Hi, ${username}`}</p>
                        </div>
                        <div className="flex column justifyCenter alignCenter w-100" style={{ background: "#fff", width: 468, height: 468, padding: 24, borderRadius: 8, margin: "0 auto" }}>
                            <img src={WildAILogoDark} alt="Wild.AI logo" height={106.53} width={92} />
                            <p style={{ fontSize: 27 }}>Wild.AI</p>
                            <p style={{ fontSize: 27, margin: '40px 0 8px' }}>You look familiar!</p>
                            <p style={{ fontSize: 17, textAlign: 'center', width: 220 }}>You are already a pro member</p>
                            <div onClick={() => history.push("/plans")} id="continue_to_dashboard_button" className="black-button" style={{ maxWidth: 320, margin: "32px 0 0" }} >
                                <img src={ForwardArrow} alt="Wild.AI logo" height={24} width={24} style={{ marginRight: 24 }} />
                                <p> Continue to Dashboard </p>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            {/* Mobile */}
            <header className='mobile w-100'>
                <video autoPlay loop muted preload="auto" poster="https://media.wild.ai/assets/images/landing-video-thumbnail.png" style={{ height: 768 }}>
                    <source src="https://media.wild.ai/assets/videos/wild_teaser.mp4" type="video/mp4" />
                </video>

                <div className="flex column justifyCenter alignCenter w-100" style={{ position: 'absolute', top: 0 }}>
                    <a href="https://wild.ai" target="_blank" rel="noreferrer" style={{ marginTop: 24 }}>
                        <img src={WildLightLogo} alt="wild.ai logo" height={106.53} width={92} />
                    </a>
                    <div className="flex justifyCenter alignCenter w-100" style={{ margin: '24px 0 0' }}>
                        <p style={{ color: '#fff', fontSize: 32 }}>{`Hi, ${username}`}</p>
                    </div>
                    <div className="flex column justifyCenter alignCenter w-100" style={{ background: "#fff", width: '96%', padding: "2%", height: 438, margin: "56px auto 0", borderRadius: 8 }}>
                        <img src={WildAILogoDark} alt="Wild.AI logo" height={106.53} width={92} />
                        <p style={{ fontSize: 27 }}>Wild.AI</p>
                        <p style={{ fontSize: 27, margin: '40px 0 8px' }}>You look familiar!</p>
                        <p style={{ fontSize: 17, textAlign: 'center', width: 220 }}>You are already a pro member</p>
                        <div onClick={() => history.push("/plans")} id="continue_to_dashboard_button" className="black-button" style={{ maxWidth: '90%', margin: "32px 0 0" }} >
                            <img src={ForwardArrow} alt="Wild.AI logo" height={24} width={24} style={{ marginRight: 16 }} />
                            <p> Continue to Dashboard </p>
                        </div>
                    </div>
                </div>
            </header>
            <Footer />
        </div>
    );
};

export default AlreadySubscribed;
