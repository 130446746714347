import initialState from "../initialState";
import {
  INITIALIZED,
  SET_USER,
  SET_REDIRECT_TO,
  SET_RECAPTCHA_STATUS,
  CREATE_NOTIFICATION,
  REMOVE_NOTIFICATION,
  SET_PRO_PLANS,
  SET_PLAN_DETAIL,
  SET_WILD_COACHES,
  SET_WILD_COACH,
  SET_ATHLETE_REPORTS

} from "./actions";

export default function appReducer(
  state = initialState.app,
  { type, payload }
) {
  switch (type) {
    case SET_USER: {
      return {
        ...state,
        user: payload.user,
      };
    }
    case SET_RECAPTCHA_STATUS: {
      const { status } = payload;
      return { ...state, captchaStatus: status };
    }
    case CREATE_NOTIFICATION: {
      return { ...state, notification: payload };
    }
    case SET_PRO_PLANS: {
      return { ...state, plans: payload };
    }
    case SET_PLAN_DETAIL: {
      return { ...state, plan: payload };
    }
    case SET_WILD_COACHES: {
      return { ...state, coaches: payload };
    }
    case SET_WILD_COACH: {
      return { ...state, coach: payload };
    }
    case SET_ATHLETE_REPORTS: {
      return { ...state, reports: payload };
    }
    case REMOVE_NOTIFICATION: {
      return { ...state, notification: { open: false } };
    }
    case INITIALIZED: {
      return { ...state, isInitialized: payload.isInitialized };
    }
    case SET_REDIRECT_TO: {
      return { ...state, redirectTo: payload.to };
    }
    default:
      return state;
  }
}
