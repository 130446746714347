import React, { useEffect } from "react";

const Notification = ({ open, onClose, type, message, timeout }) => {
  useEffect(() => {
    if (open && timeout > 0) {
      setTimeout(onClose, timeout);
    }
  }, [open]);

  return (
    open && (
      <div
        style={{
          display: "flex",
          height: "60px",
          justifyContent: "center",
          alignItems: "center",
          position: "fixed",
          bottom: 0,
          left: 0,
          minWidth: "100vw",
          background: type === "success-toast" ? "#27AE60" : "#ED5252",
          zIndex: 10,
        }}
      >
        {/* <ImageComponent
          imgSrc={
            type === "success-toast"
              ? `/assets/images/icons/tick.svg`
              : `/assets/images/icons/alert.svg`
          }
          imgAlt="notification icon"
          height={24}
          width={24}
        /> */}
        <p
          style={{
            textAlign: "center",
            fontSize: "16px",
            lineHeight: "20px",
            color: "#ffffff",
            margin: "0px 24px",
          }}
        >
          {message}
        </p>
      </div>
    )
  );
};

export default Notification;
