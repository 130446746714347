import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { chunk } from "../js/chunks";
// Components
import Header from "../components/common/Header";
import Footer from "../components/common/Footer";
import TabLayout from "../components/common/TabLayout";
import { Widget } from '@typeform/embed-react';
// Icons
import greenTickIcon from "../assets/icons/green-tick.svg"
import timerIcon from "../assets/icons/timer-light.svg";
import alerDarkIcon from "../assets/icons/alert-dark.svg";
import CloseIcon from "../assets/icons/cross-light.svg";
// Actions
import { history } from "../index";
import { tabs } from "../js/static";
import { createNotification, fetchProPlans, setProPlans, validatePromoCode } from "../store/app/actions";

const Plans = (props) => {
    const { location } = props;
    const dispatch = useDispatch();
    const [openPopup, setPopupOpening] = useState(false);
    const [pro_plans, set_pro_plans] = useState([]);
    let { user, plans } = useSelector((state) => state.app);
    let [username, set_username] = useState('');
    const [loading, setLoading] = useState(false);
    const [isProUser, setProUserStatus] = useState(false);
    let screen_width = window.screen.width;

    let currency_list = [
        { prefix: "USD", symbol: '$' },
        { prefix: "EUR", symbol: '€' },
        { prefix: "JPY", symbol: '¥' },
        { prefix: "GBP", symbol: '£' },
    ];

    const findSymbol = (name) => {
        const returning_obj = currency_list.find((item) => item['prefix'] === name && item);
        return returning_obj['symbol'];
    }
    useEffect(() => {
        if (user) {
            let { first_name } = user;
            set_username(first_name);
        }
    }, [user]);

    const resetFields = () => {
        set_pro_plans([]);
        dispatch(setProPlans([]));
        dispatch(validatePromoCode(null, onSuccessProUser, onError));
        dispatch(fetchProPlans(onSuccess, onError));
    }
    const onSuccess = () => setLoading(false);
    const onError = (error) => dispatch(createNotification("error-toast", error));
    const onSuccessProUser = (response) => setProUserStatus(response['is_user_pro']);

    const triggerForm = () => setPopupOpening(true);

    useEffect(() => {
        if (plans && plans.length > 0) {
            set_pro_plans(chunk(plans, 4));
            let isSurveyDone = window.localStorage.hasOwnProperty("survey");
            let survey_value = window.localStorage.getItem("survey");
            if (isSurveyDone) {
                if (survey_value === 'false') {
                    setTimeout(() => triggerForm(), 25000);
                }
            } else {
                setTimeout(() => triggerForm(), 25000);
            }
        }
    }, [plans]);

    useEffect(() => {
        setLoading(true);
        resetFields();
        window.analytics.page();
    }, []);

    return (
        <div className="_container"
            style={{
                position: openPopup ? 'fixed' : 'relative',
                overflow: openPopup ? 'hidden' : 'auto'
            }}
        >
            {/* Desktop header */}
            <header className='desktop home_background_banner'>
                <div className='banner_image home_background_banner_image' />
                <div className='_after'>
                    <Header location={location} />
                    <div className="desktop flex justifyCenter alignCenter w-100 h-100" style={{ margin: '96px 0 0' }}>
                        <h1 style={{ color: '#FFF', fontSize: 72 }}>{`Hi, ${username}`}</h1>
                    </div>

                    <div className="mobile flex justifyCenter alignCenter w-100 h-100" style={{ margin: '72px 0 0' }}>
                        <h3 className="mobile" style={{ color: '#FFF', fontSize: 32 }}>{`Hi, ${username}`}</h3>
                    </div>
                </div>
            </header>

            {/* Mobile header */}
            <header className='mobile home_background_banner'>
                <Header location={location} />
                <div className="flex justifyCenter alignCenter w-100 h-100" style={{ margin: '96px 0 0' }}>
                    <h3 style={{ color: '#000', fontSize: 32 }}>{`Hi, ${username}`}</h3>
                </div>
            </header>

            <div className="main_content_area flex justifyCenter alignCenter w-100 h-100"
                style={{ background: screen_width <= 992 ? '#f1ebeb40' : null }}
            >
                <div className="content_area flex column justifyStart alignCenter w-100">

                    {/* tabs */}
                    <TabLayout tabs={tabs} index={0} />

                    {/* loading */}
                    {loading ? (
                        <div className="flex column justifyCenter alignCenter" style={{ margin: '64px 0' }}>
                            <div className="custom_loader" />
                            <p style={{ marginTop: 32 }}>
                                {`Loading, please wait…`}
                            </p>
                        </div>
                    ) : null}

                    {/* Desktop version */}
                    <div className="desktop flex column justifyCenter alignCenter w-100" style={{ margin: "24px 0 0" }}>
                        {pro_plans.map((plan_list, index) => (
                            <div className="flex justifyCenter alignCenter w-100" key={index + 1}
                                style={{ margin: "24px 0 0" }}>
                                {plan_list && plan_list.length > 0 &&
                                    plan_list.map((plan, sub_index) => (
                                        <div className="flex_container flex column justifyStart alignStart relative pointer" key={sub_index + 1}
                                            onClick={() => history.push(`/plan?id=${plan['pk']}`)}
                                        >
                                            <img src={plan['plan_image'] || 'https://images.unsplash.com/photo-1608138404239-d2f557515ecb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80'} alt="plan_image" height={250} width="100%" style={{ borderRadius: 16, boxShadow: '0 0 3px rgb(0 0 0 / 35%)' }} />
                                            {plan['user_pricing']['user_bought'] ? (
                                                <div className="flex absolute" style={{ top: 20, right: 12, zIndex: 100 }}>
                                                    <p style={{ color: '#FFF', marginRight: 8 }}>Purchased</p>
                                                    <img src={greenTickIcon} alt="green_tick_icon" height={22} width={22} />
                                                </div>
                                            ) : null}

                                            <div className="flex column justifyEnd alignStart absolute absolute_card">
                                                <h4>{plan.name}</h4>
                                                <div className="flex justifyStart" style={{ margin: "4px 0 12px" }}>
                                                    {isProUser ? (<p style={{ fontSize: 14, letterSpacing: 1 }}>
                                                        <span style={{ marginRight: 8 }}> {`${findSymbol(plan['user_pricing']['currency'])}${plan['user_pricing']['user_price']}`}</span>
                                                        <span style={{ textDecoration: 'line-through' }}> {`${findSymbol(plan['user_pricing']['currency'])}${plan['user_pricing']['price']}`}</span>
                                                    </p>) : (
                                                        <p style={{ fontSize: 14, letterSpacing: 1 }}>
                                                            <span style={{ marginRight: 8 }}> {`${findSymbol(plan['user_pricing']['currency'])}${plan['user_pricing']['user_price']}`}</span>
                                                        </p>
                                                    )}
                                                    <div className="flex alignCenter" style={{ marginLeft: 16 }}>
                                                        <img src={timerIcon} alt="timer_icon" height={18} width={18} />
                                                        <span style={{ marginLeft: 6 }}><p style={{ fontSize: 14 }}>{`${plan.plan_length > 1 ? `${plan.plan_length} weeks` : `${plan.plan_length} week`}`}</p></span>
                                                    </div>
                                                </div>
                                                <div className="flex justifyCenter">
                                                    <img src={plan['coach']['profile_img_url']} alt="coach_profile_image" height={36} width={36} style={{ borderRadius: '50%', border: "1px solid #fff" }} />
                                                    <div className="flex column justifyStart" style={{ marginLeft: 12 }}>
                                                        <p style={{ fontSize: 12 }}>{plan['coach']['name']}</p>
                                                        <p style={{ fontSize: 12 }}>{plan['coach']['short_title']}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        ))}
                    </div>

                    {/* Mobile version */}
                    <div className="mobile flex column justifyStart alignCenter w-100" style={{ marginTop: 24 }}>
                        {plans && plans.length > 0 && plans.map((plan, index) => (
                            <div className="flex_container flex column justifyStart alignStart relative pointer" key={index + 1}
                                onClick={() => history.push(`/plan?id=${plan['pk']}`)}>
                                <img src={plan['plan_image'] || 'https://images.unsplash.com/photo-1608138404239-d2f557515ecb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80'} alt="plan_image" height={220} width="100%" style={{ borderRadius: 24 }} />
                                {plan['user_pricing']['user_bought'] ? (
                                    <div className="flex absolute" style={{ top: 20, right: 12, zIndex: 100 }}>
                                        <p style={{ color: '#FFF', marginRight: 8 }}>Purchased</p>
                                        <img src={greenTickIcon} alt="green_tick_icon" height={22} width={22} />
                                    </div>
                                ) : null}
                                <div className="flex column justifyEnd alignStart absolute absolute_card">
                                    <h4>{plan.name}</h4>
                                    <div className="flex justifyStart" style={{ margin: "4px 0 12px" }}>
                                        {isProUser ? (<p style={{ fontSize: 14, letterSpacing: 1 }}>
                                            <span style={{ marginRight: 8 }}> {`${findSymbol(plan['user_pricing']['currency'])}${plan['user_pricing']['user_price']}`}</span>
                                            <span style={{ textDecoration: 'line-through' }}> {`${findSymbol(plan['user_pricing']['currency'])}${plan['user_pricing']['price']}`}</span>
                                        </p>) : (
                                            <p style={{ fontSize: 14, letterSpacing: 1 }}>
                                                <span style={{ marginRight: 8 }}> {`${findSymbol(plan['user_pricing']['currency'])}${plan['user_pricing']['user_price']}`}</span>
                                            </p>
                                        )}
                                        <div className="flex alignCenter" style={{ marginLeft: 16 }}>
                                            <img src={timerIcon} alt="timer_icon" height={18} width={18} />
                                            <span style={{ marginLeft: 6 }}><p style={{ fontSize: 14 }}>{`${plan.plan_length > 1 ? `${plan.plan_length} weeks` : `${plan.plan_length} week`}`}</p></span>
                                        </div>
                                    </div>
                                    <div className="flex justifyCenter">
                                        <img src={plan['coach']['profile_img_url']} alt="coach_profile_image" height={36} width={36} style={{ borderRadius: '50%', border: "1px solid #fff" }} />
                                        <div className="flex column justifyStart" style={{ marginLeft: 12 }}>
                                            <p style={{ fontSize: 12 }}>{plan['coach']['name']}</p>
                                            <p style={{ fontSize: 12 }}>{plan['coach']['short_title']}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    {/* No plans available card */}
                    {!loading && plans.length === 0 && pro_plans.length === 0 && (
                        <div className="flex justifyCenter alignCenter w-100" style={{ margin: "24px 0 0" }}>
                            <img src={alerDarkIcon} alt="dark_alert_icon" height={22} width={22} />
                            <p style={{ color: '#000', marginLeft: 8 }}>No plans available yet</p>
                        </div>
                    )}
                </div>
            </div>
            <Footer />

            {/* Typeform popup */}
            {openPopup ? (
                <div className="flex column justifyCenter alignCenter h-100 w-100 typeform_model_popup">
                    <div className="flex column justifyCenter alignCenter popup_card">
                        <div className="flex alignCenter justifyEnd w-100 pointer"
                            onClick={() => setPopupOpening(false)}
                            style={{ position: 'absolute', top: 32, right: 32 }}
                        >
                            <img src={CloseIcon} alt="close icon" height={24} width={24} />
                        </div>
                        <Widget
                            id="https://wildai.typeform.com/to/E6ahUxo5"
                            height={screen_width <= 576 ? "90%" : 490}
                            width={screen_width <= 576 ? "96%" : 576}
                            hidden={{
                                email: user && user['email'],
                            }}
                            onSubmit={() => {
                                setTimeout(() => setPopupOpening(false), 1500);
                                window.localStorage.setItem("survey", true);
                            }}
                        />
                    </div>
                </div>
            ) : null}
        </div >
    );
};

export default Plans;
