import initialState from "../initialState";
import {
    SET_DOWNLOAD_LINK,
    SET_GENERAL_REPORT,
    SET_CYCLE_REPORT, SET_READINESS_REPORT, SET_SYMPTOMS_REPORT, SET_LIFESTYLE_REPORT,
    SET_TRAINING_REPORT, SET_MORNING_REPORT,
    SET_TRAINING_LOAD_REPORT,
    SET_TRAINING_SESSIONS_REPORT,
    SET_LOADED_COUNT,
} from "./actions";

export default function overviewReducer(
    state = initialState.app,
    { type, payload }
) {
    switch (type) {
        case SET_DOWNLOAD_LINK: {
            return { ...state, download_link: payload }
        }
        case SET_GENERAL_REPORT: {
            return { ...state, general_report: payload }
        }
        case SET_CYCLE_REPORT: {
            return { ...state, cycle_report: payload };
        }
        case SET_READINESS_REPORT: {
            return { ...state, readiness_report: payload };
        }
        case SET_SYMPTOMS_REPORT: {
            return { ...state, symptoms_report: payload };
        }
        case SET_LIFESTYLE_REPORT: {
            return { ...state, lifestyle_report: payload };
        }
        case SET_TRAINING_REPORT: {
            return { ...state, training_report: payload };
        }
        case SET_MORNING_REPORT: {
            return { ...state, morning_report: payload };
        }
        case SET_TRAINING_LOAD_REPORT: {
            return { ...state, training_load: payload }
        }
        case SET_TRAINING_SESSIONS_REPORT: {
            return { ...state, training_sessions: payload }
        }
        case SET_LOADED_COUNT: {
            return { ...state, loadedCount: payload }
        }
        default:
            return state;
    }
}
