export default {
  app: {
    isInitialized: false,
    user: null,
    captchaStatus: false,
    notification: { open: false },
    plans: [],
    plan: {},
    coaches: [],
    coach: {},
    reports: [],
    cycle_report: null,
    readiness_report: null,
    symptoms_report: null,
    lifestyle_report: null,
    training_report: null,
    morning_report: null,
    training_load: null,
    training_sessions: null,
    loadedCount: 0,
  },
  loading: {},
};
