import React, { useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { hot } from "react-hot-loader/root";
import { useSelector, useDispatch } from "react-redux";
import { history } from "./index";
// Components
import Login from "./containers/SignIn";
import Loading from "./components/common/Loading";
import PlansPage from "./containers/Plans";
import CoachesPage from "./containers/Coaches";
import Shop from "./containers/Shop";
import ReportsPage from "./containers/Reports";
import PlanDetailPage from "./containers/Plan";
import OpenSessionDetailPage from "./containers/Session";
import CoachDetailPage from "./containers/Coach";
import Product from "./containers/Product";
import ReportDetailPage from "./containers/Report";
import WildPro from "./containers/WildPro";
import AlreadySubscribed from "./containers/AlreadySubscribed";
import ThankYou from "./containers/ThankYou";
import JoinGympass from "./containers/JoinGympass";
import PageNotFound from "./components/404";
// actions
import { initialize, loginAuto, createNotification } from "./store/app/actions";

const App = () => {
  const isInitialized = useSelector((state) => state.app.isInitialized);
  const user = useSelector((state) => state.app.user);
  const location = useSelector((state) => state.router.location);
  const dispatch = useDispatch();

  const onError = (error) => {
    dispatch(createNotification("error-toast", error));
    history.push("/login");
    return null;
  };
  useEffect(() => {
    dispatch(initialize());
  }, []);

  const MainView = React.memo(() => (
    <ConnectedRouter history={history}>
      <Layout />
    </ConnectedRouter>
  ));

  const PrivateRoute = ({ component: Component, ...rest }) =>
    user ? (
      <Route
        location={location}
        render={(props) => <Component {...props} />}
        {...rest}
      />
    ) : (
      <Route
        {...rest}
        render={(props) => (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )}
      />
    );

  const Layout = () => {
    const { pathname, search } = location;

    if (!user && !search && pathname === "/") {
      return <Redirect to="/login" />;
    }

    if (
      !user &&
      search &&
      (pathname === "/" ||
        pathname === "/plans" ||
        pathname === "/coaches" ||
        pathname === "/plan" ||
        pathname === "/coach" ||
        pathname === "/reports")
    ) {
      const params = JSON.parse(
        '{"' +
          decodeURI(search)
            .replace(/\?/g, "")
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"') +
          '"}'
      );
      if (params["id"]) {
        dispatch(
          loginAuto(params, onError, true, `${pathname}?id=${params["id"]}`)
        );
      } else {
        dispatch(loginAuto(params, onError, true, `${pathname}`));
      }
      return (
        <div className="App">
          <Loading />
        </div>
      );
    }

    if (!user && pathname.startsWith("/wildpro")) {
      var promo_code = pathname.substring(pathname.lastIndexOf("/") + 1);
      window.localStorage.setItem("location", promo_code);
      return <Redirect to="/login" />;
    }

    if ((user && pathname === "/") || (user && pathname === "/login")) {
      return <Redirect to="/plans" />;
    }

    return (
      <Switch>
        <PrivateRoute
          user={user}
          exact
          path="/plans"
          component={(props) => <PlansPage {...props} />}
        />
        <PrivateRoute
          user={user}
          exact
          path="/coaches"
          component={(props) => <CoachesPage {...props} />}
        />
        <PrivateRoute
          user={user}
          exact
          path="/shop"
          component={(props) => <Shop {...props} />}
        />
        <PrivateRoute
          user={user}
          exact
          path="/shop/:id"
          component={(props) => <Product {...props} />}
        />
        <PrivateRoute
          user={user}
          exact
          path="/reports"
          component={(props) => <ReportsPage {...props} />}
        />
        <PrivateRoute
          user={user}
          exact
          path="/plan"
          component={(props) => <PlanDetailPage {...props} />}
        />
        <PrivateRoute
          user={user}
          exact
          path="/plan/session/:id"
          component={(props) => <OpenSessionDetailPage {...props} />}
        />
        <PrivateRoute
          user={user}
          exact
          path="/coach"
          component={(props) => <CoachDetailPage {...props} />}
        />
        <PrivateRoute
          user={user}
          exact
          path="/wildpro/:id"
          component={(props) => <WildPro {...props} />}
        />
        <PrivateRoute
          user={user}
          exact
          path="/wildpro"
          component={(props) => <WildPro {...props} />}
        />
        <PrivateRoute
          user={user}
          exact
          path="/subscribed"
          component={(props) => <AlreadySubscribed {...props} />}
        />
        <PrivateRoute
          user={user}
          exact
          path="/thankyou"
          component={(props) => <ThankYou {...props} />}
        />
        <Route
          exact
          path="/cycle-report"
          component={(props) => <ReportDetailPage {...props} />}
        />
        <Route
          exact
          path="/login"
          location={location}
          component={(props) => <Login {...props} />}
        />
        <Route
          exact
          path="/gympass/join/:gympass?/:first_name?/:last_name?"
          location={location}
          component={(props) => <JoinGympass {...props} />}
        />
        <Route to="/404" component={() => <PageNotFound />} />
        <Route component={() => <PageNotFound />} />
      </Switch>
    );
  };

  if (!isInitialized) {
    return (
      <div className="App">
        <Loading />
      </div>
    );
  }

  return (
    <div className="App">
      <MainView />
    </div>
  );
};

export default process.env.REACT_APP_ENV === "development" ? hot(App) : App;
