import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// Components
import Footer from "../components/common/Footer";
import Header from "../components/common/Header";
import TabLayout from "../components/common/TabLayout";
// Actions
import { history } from "../index";
import { tabs } from "../js/static";

const Shop = (props) => {
    const { location } = props;
    let { user } = useSelector((state) => state.app);
    let [username, set_username] = useState('');
    let screen_width = window.screen.width;
    let [products_arr, setProductsArr] = useState(null);

    useEffect(() => {
        if (user) {
            let { first_name } = user;
            set_username(first_name);
        }
    }, [user]);

    useEffect(() => {
        window.analytics.page();
        setProductsArr([
            {
                id: 1,
                name: "Garmin",
                front_image_url: "https://wild-blog-strapi.s3.amazonaws.com/Garmin_0d9d72de66.svg",
                white_logo_url: "https://wild-blog-strapi.s3.amazonaws.com/Garmin_Logo_White_fc0387676d.svg",
            },
            {
                id: 2,
                name: "Oura",
                front_image_url: "https://wild-blog-strapi.s3.amazonaws.com/Oura_f630718025.svg",
                white_logo_url: "https://wild-blog-strapi.s3.amazonaws.com/Oura_Logo_White_553419b6a3.svg",
            },
            // {
            //     id: 3,
            //     name: "Hexxee",
            //     front_image_url: "https://wild-blog-strapi.s3.amazonaws.com/Hexxee_66ef79eddf.svg",
            //     white_logo_url: "https://wild-blog-strapi.s3.amazonaws.com/Hexxee_Logo_White_9bf150f294.svg",
            // }
        ]);
    }, []);

    return (
        <div className="_container">
            {/* Desktop header */}
            <header className='desktop home_background_banner'>
                <div className='banner_image home_background_banner_image' />
                <div className='_after'>
                    <Header location={location} />
                    <div className="desktop flex justifyCenter alignCenter w-100 h-100" style={{ margin: '96px 0 0' }}>
                        <h1 style={{ color: '#FFF', fontSize: 72 }}>{`Hi, ${username}`}</h1>
                    </div>

                    <div className="mobile flex justifyCenter alignCenter w-100 h-100" style={{ margin: '72px 0 0' }}>
                        <h3 className="mobile" style={{ color: '#FFF', fontSize: 32 }}>{`Hi, ${username}`}</h3>
                    </div>
                </div>
            </header>

            {/* Mobile header */}
            <header className='mobile home_background_banner'>
                <Header location={location} />
                <div className="flex justifyCenter alignCenter w-100 h-100" style={{ margin: '96px 0 0' }}>
                    <h3 style={{ color: '#000', fontSize: 32 }}>{`Hi, ${username}`}</h3>
                </div>
            </header>

            <div className="main_content_area flex justifyCenter alignCenter w-100 h-100"
                style={{ background: screen_width <= 768 ? '#f1ebeb40' : null }}
            >
                <div className="content_area flex column justifyStart alignCenter w-100">
                    {/* tabs */}
                    <TabLayout tabs={tabs} index={2} />
                    <div className="flex justifyCenter alignCenter w-100" style={{
                        flexDirection: screen_width > 992 ? 'row' : 'column',
                        margin: screen_width > 992 ? "32px 0 0" : 0
                    }}>
                        {products_arr ? products_arr.map((product, index) =>
                            <div className="product_card" key={index + 1}
                                style={{ margin: screen_width > 992 ? index === 1 ? "0 24px" : 0 : index === 1 ? "24px 0" : 0 }}
                                onClick={() => history.push(`/shop/${product['name']}`)}
                            >
                                <img src={product['front_image_url']} alt={product['name']} height={screen_width <= 992 ? 263 : 363} width={screen_width <= 992 ? 263 : 363} />
                                <img src={product['white_logo_url']} alt={`${product['name']} logo`} height={screen_width <= 992 ? 83 : 103} width={screen_width <= 992 ? 240 : 300} />
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
            <Footer />

        </div>
    );
};

export default Shop;
