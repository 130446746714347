import React from "react";
import moment from "moment";
import WildLightLogo from "../../assets/logo-light.png";

// Icons
import Facebook from "../../assets/icons/facebook.svg";
import Instagram from "../../assets/icons/instagram.svg";
import LinkedIn from "../../assets/icons/linkedin.svg";
// import Medium from "../../assets/icons/medium.svg";
// import Twitter from "../../assets/icons/twitter.svg";
// import Youtube from "../../assets/icons/youtube.svg";


const Footer = () => {
    const socialMediaLinks = [
        {
            label: "facebook",
            url: "https://www.facebook.com/wildaiapp/",
            icon: Facebook
        },
        {
            label: "instagram",
            url: "https://www.instagram.com/wildaiapp/",
            icon: Instagram
        },
        {
            label: "linkedin",
            url: "https://www.linkedin.com/company/wildaiapp/",
            icon: LinkedIn
        },
        // {
        //     label: "medium",
        //     url: "https://medium.com/wild-push-your-limits-and-live-all-extremes",
        //     icon: Medium
        // },
        // {
        //     label: "twitter",
        //     url: "https://twitter.com/wild_ai_",
        //     icon: Twitter
        // },
        // {
        //     label: "youtube",
        //     url: "https://www.youtube.com/channel/UCMyN82gtAZlzuNfUzUfJktA",
        //     icon: Youtube
        // }
    ];

    return (
        <footer className='footer'>
            {/* Desktop version */}
            <div className="footer_row desktop">
                {/* Left links */}
                <div className="flex column alignStart">
                    <a href="https://wild.ai/faqs">FAQs</a>
                    <a style={{ margin: "10px 0" }} href="https://wild.ai/team">Team</a>
                    <a href="https://wild.ai/careers">Jobs</a>
                </div>
                {/* Center */}
                <div className="flex column justifyCenter alignCenter">
                    <a href="/" className="footer_logo">
                        <img src={WildLightLogo} alt="wild.ai logo" height={58} width={50} />
                    </a>
                    <div className="icons">
                        {socialMediaLinks.map((media, index) => (
                            <a href={media.url} target="_blank" rel="noreferrer" key={index + 1}>
                                <img src={media.icon} alt={media.label} height={24} width={24} />
                            </a>
                        ))}
                    </div>
                </div>
                {/* Right */}
                <div className="flex column alignEnd">
                    <a href="https://wild.ai/privacy-policy">Privacy Policy</a>
                    <a href="https://wild.ai/terms-of-services" style={{ marginTop: 16 }}>Terms of Service</a>
                </div>
            </div>

            {/* Mobile version */}
            <div className="flex column justifyCenter alignCenter w-100 mobile" style={{ padding: "64px 0 0" }}>
                <a href="https://wild.ai/">
                    <img src={WildLightLogo} alt="wild.ai logo" height={78} width={70} />
                </a>
                <div className="icons">
                    {socialMediaLinks.map((media, index) => (
                        <a href={media.url} target="_blank" rel="noreferrer" key={index + 1}>
                            <img src={media.icon} alt={media.label} height={24} width={24} />
                        </a>
                    ))}
                </div>

                <div className="flex column alignCenter" style={{ margin: "32px 0 0" }}>
                    <a href="https://wild.ai/faqs">FAQs</a>
                    <a style={{ margin: "16px 0" }} href="https://wild.ai/team">Team</a>
                    <a href="https://wild.ai/careers">Jobs</a>
                    <a style={{ margin: "16px 0" }} href="https://wild.ai/privacy-policy">Privacy Policy</a>
                    <a href="https://wild.ai/terms-of-services">Terms of Service</a>
                </div>

            </div>

            {/* Copyright desktop view */}
            <div className='copyrights desktop' style={{ marginLeft: - 56 }}>
                <p>{`© Copyright ${moment().format("YYYY")} WILD Technologies AI | All rights reserved`}</p>
            </div>

            {/* Copyright mobile view */}
            <div className='copyrights mobile'>
                <p>{`© Copyright ${moment().format("YYYY")} WILD Technologies AI | All rights reserved`}</p>
            </div>
        </footer>
    );
};

export default Footer;
