import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
// Components
import Header from "../components/common/Header";
import Footer from "../components/common/Footer";
// Logo
import WildLightLogo from "../assets/logo-light.png";
// Icons
import QRAppStore from "../assets/QR-app-store.png";
import QRPlayStore from "../assets/QR-google-play.png";
import AppStore from "../assets/icons/app-store.svg"
// Actions
import { history } from "../index";
import { loadAppUrl, getOS } from "../js/get-wild";

const ThankYou = (props) => {
    const { location } = props;
    let { user } = useSelector((state) => state.app);
    let [username, set_username] = useState('');


    const getQRLink = () => {
        let OS = getOS();
        if (OS === "Windows" || OS === "Android" || OS === "Linux") {
            return QRPlayStore;
        } else if (OS === "Mac OS" || OS === "iOS") {
            return QRAppStore;
        }
    }

    useEffect(() => {
        if (user) {
            let { first_name } = user;
            set_username(first_name);
        }
    }, [user]);

    useEffect(() => {
        window.analytics.page();
    }, []);


    return (
        <div className="_container">
            <header className='desktop home_background_banner' style={{ minHeight: 220 }}>
                <div className='_after'>
                    <Header location={location} />
                </div>
            </header>

            {/* Mobile design */}
            <header className='mobile relative w-100'>
                <div style={{ marginTop: -180 }}>
                    <img src="https://media.wild.ai/assets/images/boxing.jpeg"
                        alt="Banner boxing"
                        height={1090}
                        width='100%'
                    />
                </div>

                <div className="flex column justifyCenter alignCenter w-100" style={{ position: 'absolute', top: 0 }}>
                    <a href="https://wild.ai" target="_blank" rel="noreferrer" style={{ marginTop: 24 }}>
                        <img src={WildLightLogo} alt="wild.ai logo" height={106.53} width={92} />
                    </a>
                    <div className="flex column justifyCenter alignCenter w-100 h-100"
                        style={{
                            background: "#fff",
                            margin: "96px auto 0",
                            borderTopLeftRadius: 24,
                            padding: "16px 24px",
                            minHeight: '100%',
                            borderTopRightRadius: 24
                        }}>
                        <div style={{ width: 40, height: 5, borderRadius: 8, background: '#B0B0AD', }} />
                        <br />
                        <div className="flex column alignCenter justifyCenter w-100 h-100">
                            <div className="flex column justifyStart alignStart">
                                <p style={{ fontSize: 32, lineHeight: 1.3 }}>{`Welcome, ${username}`}</p>
                                <p style={{ fontSize: 17, margin: "16px 0" }}>This is the beginning of an incredible journey together!</p>
                                <p style={{ fontSize: 17, color: 'rgba(1, 1, 1, 0.4)' }}>Download the app now</p>
                            </div>
                            <img src={getQRLink()} alt="QR Code" height={226} width={226} style={{ margin: "16px 0" }} />
                            <img src={AppStore} alt="app store icon" height={41} width={121} onClick={() => loadAppUrl()} />
                            <div onClick={() => history.push("/plans")} className="black-button" style={{ maxWidth: '94%', margin: "32px 0 16px" }} >
                                <p> Explore Training Plans </p>
                            </div>
                            <div onClick={() => history.push("/plans")} className="white-button-with-border" style={{ maxWidth: '94%', marginBottom: 32 }}>
                                <p >Continue to Dashboard</p>
                            </div>
                            <div style={{ width: 140, height: 5, borderRadius: 8, background: '#000', }} />
                        </div>
                    </div>
                </div>
            </header>

            {/* Desktop design */}
            <div className="relative desktop flex justifyBetween alignCenter w-100 h-100" style={{ minHeight: 850, maxHeight: 850 }}>
                <div className="flex colum justifyCenter alignCenter h-100" style={{ width: '40%', backgroundImage: 'url(https://media.wild.ai/assets/images/boxing.jpeg)', backgroundPosition: 'center' }} />
                <div className="absolute" style={{ top: '50%', left: '40%', transform: 'translate(-50%, -50%)', zIndex: 10 }}>
                    <img src="https://wild-blog-strapi.s3.amazonaws.com/dashboard_min_986e54e6a8.png"
                        alt="Wild.AI mobile app"
                        height={626}
                        width={309}
                    />
                </div>
                <div className="flex column justifyCenter alignCenter h-100" style={{ width: '60%', paddingLeft: 110 }}>
                    <div className="flex column justifyStart alignStart" style={{ width: 425 }}>
                        <p style={{ fontSize: 45, lineHeight: 1.3 }}>{`Welcome, ${username}`}</p>
                        <p style={{ fontSize: 28, lineHeight: 1.3, margin: "16px 0" }}>This is the beginning of an incredible journey together!</p>
                        <p style={{ color: 'rgba(1, 1, 1, 0.4)' }}>Download the app now</p>
                        <img src={getQRLink()} alt="QR Code" height={226} width={226} style={{ margin: "16px 0 16px -11px" }} />
                        <img src={AppStore} onClick={() => loadAppUrl()} alt="app store icon" height={41} width={121} style={{ cursor: 'pointer' }} />
                        <div onClick={() => history.push("/plans")} className="black-button" style={{ maxWidth: 300, margin: "72px 0 16px" }} >
                            <p> Explore Training Plans </p>
                        </div>
                        <div onClick={() => history.push("/plans")} className="white-button-with-border" style={{ maxWidth: 300, marginBottom: 32 }}>
                            <p >Continue to Dashboard</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mobile w-100" style={{ paddingTop: 60 }}>
                <Footer />
            </div>

            <div className="desktop w-100">
                <Footer />
            </div>
        </div>
    );
};

export default ThankYou;
