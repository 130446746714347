import React, { useState, useEffect } from "react";
import CommonReportHeader from "../../common/CommonReportHeader";
import JoinedDots from "../../../assets/icons/joined_dots.svg";
import Arrows from "../../../assets/icons/gray_ arrows.svg";
import EmptyGraph from "../../common/EmptyGraph";
import { menstrual_cycle_report, contraceptive_group_report, monthly_report } from "../../../js/static_report";
import moment from "moment";


const TrainingLoad = (props) => {
    let [y_axes, setY_Axes] = useState([]);
    let [legends, setLegends] = useState(null);
    let [content, setContent] = useState(null);
    let [graph, setGraph] = useState(null);
    let [phaseDetails, setPhaseDetails] = useState([]);
    let [line_graph_values, set_line_graph_value] = useState(null);
    let [lines_list, set_lines_axes] = useState([]);
    let [line_point_graph, set_line_point_graph] = useState([]);
    let [showTooltip, setShowTooltip] = useState(-1);
    let [max_days, set_max_days] = useState(0);
    let [show_empty_graph, set_show_empty_graph] = useState(false);


    function createLine(x1, y1, x2, y2) {
        let distance = Math.sqrt((x2 - x1) * (x2 - x1) + (y2 - y1) * (y2 - y1));
        let x_mid = (x1 + x2) / 2;
        let y_mid = (y1 + y2) / 2;
        let angel_in_radian = Math.atan((y2 - y1) / (x2 - x1));
        let angel_in_degree = (angel_in_radian * 180) / Math.PI;
        return [`${distance}px`, `${x_mid - (distance / 2)}px`, `${y_mid}px`, "rotate(" + (-1 * angel_in_degree) + "deg)"]
    }

    const getDaysDiffForIndexing = (start_date, end_date) => {
        const date1 = new Date(start_date);
        const date2 = new Date(end_date);
        function getDifferenceInDays(date1, date2) {
            const diffInMs = Math.abs(date2 - date1);
            return diffInMs / (1000 * 60 * 60 * 24);
        }
        return getDifferenceInDays(date1, date2) + 1;
    }

    const handleWithPhaseDate = (start_date, end_date, average_graph) => {
        let average_value = 0;
        Object.keys(average_graph).forEach(function (average_key) {
            if (moment(average_key, "YYYY-MM-DD").isSame(moment(start_date, "YYYY-MM-DD")) ||
                moment(average_key, "YYYY-MM-DD").isSame(moment(end_date, "YYYY-MM-DD"))
            ) {
                average_value = average_graph[average_key];
            }
        });
        return average_value;
    }

    const calculatePointDistance = (index, days) => {
        let distance = 0;
        if (index === 0) {
            distance = props.monthly_status ? 34 * days : 28 * days;
        } else if (index === 1) {
            distance = props.monthly_status ? 34 * days : 28 * days + 16;
        } else if (index === 2) {
            distance = props.monthly_status ? 34 * days : 28 * days + 32;
        } else if (index === 3) {
            distance = props.monthly_status ? 34 * days : 28 * days + 48;
        } else if (index === 4) {
            distance = props.monthly_status ? 34 * days : 28 * days + 64;
        }
        return distance;
    }

    const handleWithLineKeys = (start_date, end_date, line_key, index) => {
        let line_values = [];
        start_date = moment(start_date, 'DD-MM-YYYY').format('YYYY-MM-DD');
        end_date = moment(end_date, 'DD-MM-YYYY').format('YYYY-MM-DD');
        // start date check
        if (moment(line_key).isSame(start_date)) {
            line_values.push({
                id: index,
                day: getDaysDiffForIndexing(start_date, line_key),
            });
        }
        // between date check
        if (moment(line_key).isBetween(start_date, end_date)) {
            line_values.push({
                id: index,
                day: getDaysDiffForIndexing(start_date, line_key),
            });
        }
        // end date check
        if (moment(line_key).isSame(end_date)) {
            line_values.push({
                id: index,
                day: getDaysDiffForIndexing(start_date, line_key),
            });
        }
        return line_values;
    }

    const setPhases = (phase_details, average_graph) => {
        var phase_values = [];
        for (var i = 0; i < phase_details.length; i++) {
            phase_values[i] = {
                days: phase_details[i].duration,
                average_value: handleWithPhaseDate(phase_details[i].start_date, phase_details[i].end_date, average_graph),
                start_date: phase_details[i].start_date,
                end_date: phase_details[i].end_date,
                name: phase_details[i].full_name,
                short_name: phase_details[i].short_name,
                value: phase_details[i].stats ? phase_details[i].stats["value"] : null,
                trend: phase_details[i].stats ? phase_details[i].stats["trend"] : null,
                trend_value: phase_details[i].stats ? phase_details[i].stats["trend_value"] : null
            }
        }
        setPhaseDetails(phase_values);
    }

    const setAxes = (axes) => {
        let labels = Object.keys(axes);
        let ranges = Object.values(axes).map((axe) => axe.range);
        let colors = Object.values(axes).map((axe) => axe.colour);
        var merged = [].concat.apply([], ranges);
        let unique = [...new Set(merged)];
        let y_axis = [];
        for (var index = 0; index < unique.length - 1; index++) {
            y_axis[index] = {
                label: labels[index],
                color: colors[index],
                value: unique[index + 1],
            }
        }
        setY_Axes(y_axis);
    }

    const setLinePoints = (line_graph, phase_details) => {
        let start_date = moment(phase_details[0].start_date, 'DD-MM-YYYY').format('YYYY-MM-DD');
        //  Set Lines Axes
        let linesArr = [];
        // eslint-disable-next-line
        Object.keys(line_graph).map((key, index) => {
            let days_from_start_date_to_line_date = getDaysDiffForIndexing(start_date, key);
            let value_of_index_from_phase_against_line_date;

            let line_value = Object.values(line_graph)[index];

            for (var i = 0; i < phase_details.length; i++) {
                const values = handleWithLineKeys(phase_details[i].start_date, phase_details[i].end_date, key, i);
                if (values && values.length > 0) {
                    value_of_index_from_phase_against_line_date = values[0].id;
                    break;
                }
            }

            return (
                linesArr[index] = {
                    x: calculatePointDistance(value_of_index_from_phase_against_line_date, days_from_start_date_to_line_date),
                    y: line_graph[key] * 100,
                    line_value,
                    date: key
                }
            )
        });

        set_line_point_graph(linesArr);

        var concatenated_lines = [];
        for (var l = 0; l < linesArr.length - 1; l++) {
            concatenated_lines[l] = {
                x1: linesArr[l].x,
                y1: linesArr[l].y,
                x2: linesArr[l + 1].x,
                y2: linesArr[l + 1].y,
            }
        }

        lines_list = concatenated_lines;
        set_lines_axes(concatenated_lines);
    }

    const calculateMaxDays = (trainingsData) => {
        let maxDays = 0;
        for (var i = 0; i < trainingsData.length; i++) {
            maxDays += trainingsData[i].duration;
        }
        return maxDays;
    }

    useEffect(() => {
        if (graph && Object.values(graph).length > 0) {
            setAxes(graph.y_axes);
            if (Object.values(graph.phase_details).length > 0) {
                setPhases(graph.phase_details, graph.average_graph);
                set_max_days(calculateMaxDays(graph.phase_details));
            }
        }
    }, [graph]);

    useEffect(() => {
        if (y_axes && y_axes.length > 0 && graph) {
            let line_graph = {};
            let line_graph_DB = Object.keys(graph.line_graph);
            let line_graph_DB_values = Object.values(graph.line_graph);
            for (var idx in line_graph_DB) {
                let key = line_graph_DB[idx];
                line_graph[key] = line_graph_DB_values[idx]
            }
            set_line_graph_value(line_graph);
        }
    }, [y_axes]);

    const getDates = (startDate, endDate) => {
        var dateArray = [];
        var currentDate = moment(startDate);
        var stopDate = moment(endDate);
        while (currentDate <= stopDate) {
            dateArray.push(moment(currentDate).format('YYYY-MM-DD'))
            currentDate = moment(currentDate).add(1, 'days');
        }
        return dateArray;
    }

    const allEqualToNull = (arr) => arr.every(v => v === 0);

    useEffect(() => {
        if (props.traingLoad) {
            let { training_load: { title, subtitle, legend, data } } = props.traingLoad;
            let is_empty = false;
            let phase_count = 0;
            let value_collector = [];
            if (Object.values(data) && Object.values(data).length > 0) {
                if (Object.values(data['line_graph']) && Object.values(data['line_graph']).length > 0) {
                    const line_values = Object.values(data['line_graph']);
                    phase_count = data['phase_details'].length;
                    for (let i in line_values) {
                        value_collector[i] = line_values[i];
                    }

                    if (value_collector.length > 0) {
                        if (allEqualToNull(value_collector)) {
                            is_empty = true;
                        } else {
                            is_empty = false;
                        }
                    }


                    if (is_empty && !props.dashboard) {
                        set_show_empty_graph(true);
                        if (phase_count > 2) {
                            let { title, subtitle, legend, data } = menstrual_cycle_report['training_load'];
                            setContent({
                                title: title || null,
                                subtitle: subtitle || null
                            });
                            setLegends(legend);
                            setGraph(data);
                        } else if (phase_count === 2) {
                            let { title, subtitle, legend, data } = contraceptive_group_report['training_load'];
                            setContent({
                                title: title || null,
                                subtitle: subtitle || null
                            });
                            setLegends(legend);
                            setGraph(data);
                        } else if (phase_count === 1 || phase_count === 0) {
                            let { title, subtitle, legend, data } = monthly_report['training_load'];
                            setContent({
                                title: title || null,
                                subtitle: subtitle || null
                            });
                            setLegends(legend);
                            setGraph(data);
                        }
                    } else if (is_empty && props.dashboard) {
                        setContent({
                            title: title || null,
                            subtitle: subtitle || null
                        });
                        setLegends(legend);
                        setGraph(data);
                    } else {
                        setContent({
                            title: title || null,
                            subtitle: subtitle || null
                        });
                        setLegends(legend);
                        setGraph(data);
                    }
                }
            }
        }
    }, [props.traingLoad]);


    useEffect(() => {
        if (line_graph_values && phaseDetails.length > 0) {
            setLinePoints(line_graph_values, phaseDetails);
        }
    }, [line_graph_values, phaseDetails]);

    return !props ? (
        <div className="flex column justifyStart alignStart w-100" style={{ margin: "32px 0 0" }}>
            <CommonReportHeader label="Training Load Overview" />
        </div>
    ) : legends && (
        <div className="flex column justifyStart alignStart relative w-100" style={{ margin: "32px 0 0" }}>
            {content && (<CommonReportHeader title={content.title} subtitle={content.subtitle} isSubtitleUnder={true} />)}
            <EmptyGraph show={show_empty_graph} message="No data to show! Complete your check-ins in Wild AI to see your data." />
            {/* Graph */}
            <div className="flex column justifyStart alignStart w-100  scrollable" style={{ paddingBottom: 24 }}>
                <div className="flex w-100" style={{
                    position: "relative", margin: "56px 0 24px",
                    minWidth: (props.monthly_status ? max_days * 34 : max_days * 28) + 80,
                    flexDirection: "column-reverse"
                }}>
                    {/* axes */}
                    {y_axes && y_axes.map((axe, axeIndex) => {
                        return (
                            <div key={axeIndex + 1} className="flex justifyStart alignCenter w-100" style={{
                                borderBottom: "1px solid #737373", borderTop: y_axes.length === axeIndex + 1 ? "1px solid #737373" : 0,
                                minWidth: (props.monthly_status ? max_days * 34 : max_days * 28) + 80,
                                height: 100, background: axe ? axe.color : '#ffffff'
                            }}>
                                <p style={{ color: axe.color.toLowerCase() !== '#ffffff' ? '#ffffff' : "#737373", transform: 'rotate3d(0, 0, 1, 270deg)', marginLeft: axe.color.toLowerCase() !== '#ffffff' ? -8 : -20, fontSize: 12 }}>{`${axe.label}`}</p>
                            </div>
                        )
                    })}

                    <div className="flex" style={{ height: 300, position: "absolute", bottom: 0, left: 48 }}>

                        {props.birthControlReport !== 1 && (
                            <div className="flex" style={{ position: "absolute", bottom: 0 }}>
                                {phaseDetails && phaseDetails.map((bar, bIndex) => {
                                    let average_start_date = moment(bar.start_date, 'DD-MM-YYYY').format('YYYY-MM-DD');
                                    let average_end_date = moment(bar.end_date, 'DD-MM-YYYY').format('YYYY-MM-DD');
                                    var daylist = getDates(average_start_date, average_end_date);
                                    return (
                                        <div key={bIndex + 1} className="flex alignEnd"
                                            style={{ position: "relative", marginLeft: bIndex === 0 ? 0 : 16 }}>
                                            {/* bar */}
                                            <div style={{
                                                position: "relative", background: "rgba(191, 191, 191, 0.5)",
                                                width: props.monthly_status ? 34 * bar.days : 28 * bar.days,
                                                borderTopLeftRadius: 4,
                                                borderTopRightRadius: 4,
                                                height: bar.average_value * 100
                                            }}
                                            />

                                            {phaseDetails.length === 1 && (
                                                <div className="flex " style={{ position: "absolute", bottom: -23, }}>
                                                    {daylist.map((item, itemIndex) => (
                                                        <div className="flex column alignEnd" style={{ width: props.monthly_status ? 34 : 28 }}>
                                                            <div key={itemIndex + 1} style={{ width: 1, height: 8, background: '#737373' }} />
                                                            <p style={{ fontSize: 8, marginTop: 4, }}>{moment(item).format("DD")}</p>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}

                                        </div>
                                    )
                                })}
                            </div>
                        )}

                        {/* Circle */}
                        {line_point_graph && line_point_graph.length > 0 && (
                            <div className="flex" style={{ position: "relative" }}>
                                {line_point_graph.map((line_axes, line_axes_index) => {
                                    return (
                                        <div className="flex alignCenter" key={`circle_${line_axes_index + 1}`}>
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    left: line_axes.x - 8,
                                                    zIndex: 1000,
                                                    background: "#FFFFFF",
                                                    width: 16,
                                                    height: 16,
                                                    bottom: line_axes.y - 8,
                                                    border: "2px solid #000000",
                                                    borderRadius: "50%"
                                                }}
                                                onMouseEnter={() => setShowTooltip(`graph_index_${1}_point_index_${line_axes_index}`)}
                                                onMouseLeave={() => setShowTooltip(-1)}
                                            />
                                            {showTooltip === `graph_index_${1}_point_index_${line_axes_index}` && (
                                                <div className="tooltip" style={{
                                                    left: line_axes.x - 42,
                                                    background: "#FFFFFF",
                                                    boxShadow: "0 0 2px rgba(0,0,0,0.3)",
                                                    marginBottom: line_axes.y + 24,
                                                    border: "0.5px solid rgba(0,0,0,0.5)",
                                                    zIndex: 10000,
                                                    width: 108,
                                                }}>
                                                    {`${moment(line_axes.date).format("DD MMM")}: ${Number.isInteger(line_axes.line_value) ? line_axes.line_value : line_axes.line_value.toFixed(1)}`}
                                                </div>
                                            )}
                                        </div>
                                    )
                                })}
                            </div>
                        )}

                        {lines_list && lines_list.length > 0 && (
                            <div className="flex" style={{ position: "relative" }}>
                                {lines_list.map((line_axes, line_axes_index) => {
                                    return (
                                        <div key={`line_${line_axes_index + 1}`} id={`graph_${1}_line_${line_axes_index}`}
                                            style={{
                                                background: "#000000",
                                                height: 2,
                                                position: "absolute",
                                                width: createLine(line_axes.x1, line_axes.y1, line_axes.x2, line_axes.y2)[0],
                                                left: createLine(line_axes.x1, line_axes.y1, line_axes.x2, line_axes.y2)[1],
                                                bottom: createLine(line_axes.x1, line_axes.y1, line_axes.x2, line_axes.y2)[2],
                                                transform: createLine(line_axes.x1, line_axes.y1, line_axes.x2, line_axes.y2)[3]
                                            }} />
                                    )
                                })}
                            </div>
                        )}
                    </div>
                </div>


                <div className="flex justifyStart alignStart w-100">
                    {phaseDetails && phaseDetails.length > 1 && phaseDetails.map((phase, pIndex) => (
                        <div
                            key={pIndex + 1}
                            className="flex justifyCenter alignCenter flex_wrap"
                            style={{
                                marginLeft: pIndex === 0 ? (phaseDetails.length === 2 ? 48 : 34) : 16,
                                minWidth: props.monthly_status ? 34 * phase.days : 28 * phase.days,
                                marginTop: 16,
                                lineHeight: 2.5
                            }}
                        >
                            <p style={{ color: "#000000", fontSize: 12 }}>{phase.name}</p>

                            <div className="flex justifyCenter alignCenter">
                                {/* <h5 style={{ fontSize: 12, marginLeft: 6 }}>{phase.value}</h5> */}
                                <div className="flex alignCenter">
                                    {phase.trend === "positive" && (
                                        <>
                                            <div className="positive_arrow">
                                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="24" height="24" viewBox="0 0 24 24">
                                                    <path d="M14,20H10V11L6.5,14.5L4.08,12.08L12,4.16L19.92,12.08L17.5,14.5L14,11V20Z" fill={phase.arrow_colour || '#5f9f61'} />
                                                </svg>
                                            </div>
                                            <p className="trend_value" style={{ marginLeft: 0, fontSize: 12 }}>{`+${phase.trend_value}%`}</p>
                                        </>
                                    )}
                                    {phase.trend === "negative" && (
                                        <>

                                            <div className="negative_arrow">
                                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="24" height="24" viewBox="0 0 24 24">
                                                    <path d="M10,4H14V13L17.5,9.5L19.92,11.92L12,19.84L4.08,11.92L6.5,9.5L10,13V4Z" fill={phase.arrow_colour || "#DD3A29"} />
                                                </svg>
                                            </div>
                                            <p className="trend_value" style={{ marginLeft: 0, fontSize: 12 }}>{`${phase.trend_value}%`}</p>
                                        </>
                                    )}
                                    {phase.trend === "neutral" && (
                                        <>
                                            <div style={{ marginLeft: 4, width: 12, height: 2, backgroundColor: "#000000" }} />
                                            <p className="trend_value" style={{ marginLeft: 0, fontSize: 12 }}>{`${phase.trend_value}%`}</p>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {/* Legends */}
            <div className="flex column justifyCenter alignStart w-100 grey_box mtb_32">
                {legends && legends.map((legend, index) => (
                    <div className="flex justifyBetween alignCenter" key={index + 1} style={{ margin: "8px 0 0" }}>
                        {legend.colour === "arrows" && <img src={Arrows} alt="joined_dots" height={24} />}
                        {legend.colour === "joined_dots" && <img src={JoinedDots} alt="joined_dots" height={24} />}
                        <p className="common_subtitle_without_underline" style={{ marginLeft: 12, padding: 0, fontSize: 14, lineHeight: 1.4 }}>{legend.text}</p>
                    </div>
                ))}
            </div>
        </div>
    )



}

export default TrainingLoad;