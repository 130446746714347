import React, { useEffect, useState } from "react";
import ColouredArrowDown from "../../assets/icons/report-arrow-down.svg";
import ColouredArrowUp from "../../assets/icons/report-arrow-up.svg";
import LinePattern from "../../assets/icons/line-pattern.svg"
import JoinedDots from "../../assets/icons/joined_dots.svg";


const GraphLegends = (props) => {
    const [legends, setlegends] = useState([]);
    useEffect(() => {
        if (props && props["legend"]) {
            setlegends(props["legend"] || []);
        } else {
            setlegends([]);
        }
    }, [props]);

    return legends && legends.length > 0 && (
        <div className="flex alignStart flex_wrap w-100" style={{ padding: 16, borderRadius: 8, margin: "32px 0", background: "#F4F4F4" }}>
            {legends && legends.map((item, index) => (
                <div className="flex alignCenter justifyStart" key={index + 1} style={{ minWidth: 100, margin: "0 16px", padding: "8px 0", }}>
                    <>
                        {item.colour === "arrows" ? (
                            <div className="flex justifyStart">
                                <img src={ColouredArrowUp} alt="indicator" height={20} width={20} />
                                <img src={ColouredArrowDown} alt="indicator" height={20} width={20} />
                            </div>
                        ) : item.colour === "" ? (
                            <div className="flex justifyStart">
                                <img src={JoinedDots} alt="joined_dots" height={24} />
                            </div>
                        ) : (
                            <>
                                {item.colour.toLowerCase() === "#ffffff" ? (
                                    <div style={{ backgroundColor: '#ffffff', height: 27, width: 25, borderRadius: 1.6 }}>
                                        <img src={LinePattern} alt="line-pattern" style={{ margin: "0px 1px", height: 24, width: 23, borderRadius: 1.6 }} />
                                    </div>
                                ) : (
                                    <div style={{ background: `${item.colour}`, height: 24, width: 24, borderRadius: 2 }} />
                                )}
                            </>
                        )}
                    </>
                    <p style={{ marginLeft: 12, fontSize: 14 }}>{item.text}</p>
                </div>
            ))}
        </div >
    );
}

export default GraphLegends;