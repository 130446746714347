import React from "react";
import form_styles from "../styles/form.module.css";
import LoginForm from "./login-form";
import JoinForm from "./signup-form";

const AccessFormCard = ({ isSignUp, showFormFor, gympass, firstName }) => {
  return (
    <div className={form_styles.access_form_card}>
      <h2>{isSignUp ? `Sign up` : `Log in`}</h2>

      {isSignUp ? (
        <JoinForm
          gympass={gympass}
          firstname={firstName}
          changeFormState={(state) => showFormFor(state)}
        />
      ) : (
        <LoginForm
          gympass={gympass}
          changeFormState={(state) => showFormFor(state)}
        />
      )}
    </div>
  );
};

export default AccessFormCard;
