import { all, fork } from "redux-saga/effects";
import appSagas from "./app/sagas";
import reportSaga from "./report/sagas";

export default function* rootSaga(services = {}) {
  yield all([
    fork(appSagas, services),
    fork(reportSaga, services),
  ]);
}
