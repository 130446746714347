import React, { useState, useEffect } from "react";
import CommonReportHeader from "../../common/CommonReportHeader";
import GraphMultiLegends from "../../common/GraphMultiLegends";
import FooterDescription from "../../common/FooterDescription";
import LinePattern from "../../../assets/icons/line-pattern.svg";
import EmptyGraph from "../../common/EmptyGraph";
import { menstrual_cycle_report, contraceptive_group_report, monthly_report } from "../../../js/static_report";


const SymptomsOverview = (props) => {
    let [legends, setLegends] = useState(null);
    let [content, setContent] = useState(null);
    let [symptoms, setSymptoms] = useState(null);
    let [symptomsData, setSymptomsData] = useState(null);
    let [checkinDesc, setCheckinDesc] = useState(null);
    let [cycle_data_length, set_cycle_data_length] = useState(0);
    let [max_days, set_max_days] = useState(0);
    let [empty_phases_count, set_empty_phases_count] = useState(0);
    let [show_empty_graph, set_show_empty_graph] = useState(false);

    const allEqualToWhite = (arr) => arr.every(v => v === '#FFFFFF');
    const allEqualToGrey = (arr) => arr.every(v => v === '#BFBFBF');

    useEffect(() => {
        if (props.symptoms) {
            let { symptoms: { legend, title, subtitle, data, top_checkin } } = props.symptoms;
            if (data && Object.keys(data).length !== 0) {
                let data_values = Object.values(data);
                let is_empty = false;
                let phase_count = 0;
                let empty_phase_count = 0;
                let merge_arrays = [];
                if (data_values && data_values.length > 0) {
                    for (let i in data_values) {
                        let data_list = data_values[i];
                        phase_count = data_values[i].length;
                        if (data_list && data_list.length > 0) {
                            for (let j in data_list) {
                                let colors_list = Object.values(data_list[j]['data']);
                                if (colors_list.length === 0) {
                                    empty_phase_count += 1;
                                }
                                merge_arrays = [...merge_arrays, ...colors_list];
                            }
                        }
                    }

                    if (merge_arrays && merge_arrays.length > 0) {
                        if (allEqualToWhite(merge_arrays)) {
                            is_empty = true;
                        } else if (allEqualToGrey(merge_arrays)) {
                            is_empty = true;
                        } else {
                            is_empty = false;
                        }
                    }

                    if (is_empty && !props.dashboard) {
                        set_show_empty_graph(true);
                        if (phase_count > 2) {
                            let { legend, title, subtitle, data, top_checkin } = menstrual_cycle_report['symptoms'];
                            setLegends(legend);
                            setContent({
                                title: title || null,
                                subtitle: subtitle || null
                            });
                            setSymptoms(Object.keys(data));
                            setSymptomsData(Object.values(data));
                            setCheckinDesc(top_checkin || null);
                        } else if (phase_count === 2) {
                            let { legend, title, subtitle, data, top_checkin } = contraceptive_group_report['symptoms'];
                            setLegends(legend);
                            setContent({
                                title: title || null,
                                subtitle: subtitle || null
                            });
                            setSymptoms(Object.keys(data));
                            setSymptomsData(Object.values(data));
                            setCheckinDesc(top_checkin || null);
                        } else if (phase_count === 1) {
                            let { legend, title, subtitle, data, top_checkin } = monthly_report['symptoms'];
                            setLegends(legend);
                            setContent({
                                title: title || null,
                                subtitle: subtitle || null
                            });
                            setSymptoms(Object.keys(data));
                            setSymptomsData(Object.values(data));
                            setCheckinDesc(top_checkin || null);
                        }
                    } else if (is_empty && props.dashboard) {
                        setLegends(legend);
                        setContent({
                            title: title || null,
                            subtitle: subtitle || null
                        });
                        setSymptoms(Object.keys(data));
                        setSymptomsData(Object.values(data));
                        setCheckinDesc(top_checkin || null);
                    } else {
                        setLegends(legend);
                        setContent({
                            title: title || null,
                            subtitle: subtitle || null
                        });
                        setSymptoms(Object.keys(data));
                        setSymptomsData(Object.values(data));
                        setCheckinDesc(top_checkin || null);
                    }
                    set_empty_phases_count(empty_phase_count / data_values.length);
                } else {
                    set_show_empty_graph(true);
                    let { legend, title, subtitle, data, top_checkin } = monthly_report['symptoms'];
                    setLegends(legend);
                    setContent({
                        title: title || null,
                        subtitle: subtitle || null
                    });
                    setSymptoms(Object.keys(data));
                    setSymptomsData(Object.values(data));
                    setCheckinDesc(top_checkin || null);
                }
            }
        }
    }, [props.symptoms]);

    const covertCycleDataDictionaryIntoArray = (dictionary, maxDays) => {
        let array = [];
        if (Object.keys(dictionary).length > 0) {
            for (var i = 1; i <= maxDays; i++) {
                array[i - 1] = dictionary[i] || "#ffffff";
            }
        } else {
            for (var j = 1; j <= maxDays; j++) {
                array[j - 1] = "#ffffff";
            }
        }
        return array;
    }

    const calculateMaxDays = (symptomsData) => {
        let maxDays = 0;
        for (var i = 0; i < symptomsData.length; i++) {
            let max_days = Object.values(symptomsData[i]['data']);
            maxDays += max_days.length;
        }
        return maxDays;
    }

    useEffect(() => {
        if (symptomsData && symptomsData.length > 0) {
            const resp = calculateMaxDays(symptomsData[0]);
            set_cycle_data_length(symptomsData[0].length);
            set_max_days(resp);
        }
    }, [symptomsData]);

    const calcHeightWidth = () => {
        let boxes_width = document.getElementById('symptoms_rectangles_container');
        if (boxes_width) {
            boxes_width = boxes_width.offsetWidth - (100 * empty_phases_count);
            const calc = (boxes_width - (16 * cycle_data_length)) / max_days;
            return calc;
        }
    }

    return !props.symptoms ? (
        <div className="flex column justifyStart alignStart w-100" style={{ margin: "40px 0 0" }}>
            <CommonReportHeader label="Symptoms Check-in Overview" />
        </div>
    ) : legends && (
        <div className="flex column justifyStart alignStart relative w-100" style={{ margin: "40px 0 0" }}>
            {content && (<CommonReportHeader header={props.headers || null} title={content.title} subtitle={content.subtitle} replaceStatus={props.dashboard} isSubtitleUnder={true} />)}

            {checkinDesc && <FooterDescription header={props.headers || null} replaceStatus={props.dashboard} describe={checkinDesc} />}

            <EmptyGraph show={show_empty_graph} message="You're not tracking this yet. Add this check-in Wild AI to start tracking this metric!" />

            <div className="flex column justifyStart alignStart w-100 scrollable" style={{ paddingBottom: 24 }}>
                <div className="flex column justifyStart alignStart w-100">
                    {symptoms && symptoms.map((item, index) => {
                        return (
                            <div className="flex justifyStart alignCenter w-100" key={index + 1} style={{ marginTop: index === 0 ? 32 : 16, }}>
                                <p style={{
                                    fontSize: 12, fontWeight: 700,
                                    minWidth: 84, maxWidth: 84,
                                    textAlign: 'left'
                                }}>{item}</p>

                                <div className="flex justifyStart alignStart" id="symptoms_rectangles_container" style={{ width: 'calc(100% - 84px)' }}>
                                    {symptomsData[index] && symptomsData[index].map((symptomPhaseDetail, subIndex) => {
                                        let max_days = Object.values((symptomPhaseDetail["data"])).length;
                                        let map_list = covertCycleDataDictionaryIntoArray(symptomPhaseDetail["data"], max_days);
                                        return (
                                            <div className="flex justifyStart" key={subIndex + 1} style={{ marginLeft: 16 }}>
                                                {map_list && map_list.map((color, colorIndex) => {
                                                    return (
                                                        <div key={colorIndex + 1}>
                                                            {color.toLowerCase() === "#ffffff" ? (
                                                                <img src={LinePattern} alt="line-pattern" style={{
                                                                    margin: "4px 1.5px",
                                                                    height: calcHeightWidth() - 3,
                                                                    width: calcHeightWidth() - 3,
                                                                    borderRadius: 1.6,
                                                                    maxHeight: 48,
                                                                    maxWidth: 48
                                                                }} />
                                                            ) : (
                                                                <div style={{
                                                                    margin: "4px 1.5px",
                                                                    height: calcHeightWidth() - 3,
                                                                    width: calcHeightWidth() - 3,
                                                                    background: color,
                                                                    borderRadius: 1.6,
                                                                    maxHeight: 48,
                                                                    maxWidth: 48
                                                                }} />
                                                            )}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )
                    })}

                    {symptoms && symptoms.map((item, index) => {
                        return index + 1 === 1 && (
                            <div className="flex justifyStart alignStart w-100" key={index + 1} style={{ marginTop: 16 }}>
                                <p style={{
                                    fontSize: 12, minWidth: 84, maxWidth: 84, textAlign: 'left',
                                    marginTop: symptomsData[index].length === 1 || symptomsData[index].length === 2 ? 4 : 8
                                }}>
                                    {symptomsData[index].length > 1 ? `Day of Phase` : `Day of month`}
                                </p>
                                <div className="flex justifyStart alignStart">
                                    {symptomsData[index] && symptomsData[index].map((symptomPhaseDetail, subIndex) => {
                                        let max_days = Object.values((symptomPhaseDetail["data"])).length;
                                        let map_list = covertCycleDataDictionaryIntoArray(symptomPhaseDetail["data"], max_days);
                                        return (
                                            <div className="flex column justifyStart" key={subIndex + 1} style={{ marginLeft: 16 }}>
                                                <div className="flex justifyStart alignCenter">
                                                    {map_list && map_list.map((color, colorIndex) => {
                                                        return (
                                                            <p key={colorIndex + 1} style={{
                                                                fontSize: symptomsData[index].length === 1 || symptomsData[index].length === 2 ? 10 : 14,
                                                                width: calcHeightWidth() - 3,
                                                                maxHeight: 48,
                                                                maxWidth: 48,
                                                                margin: "4px 1.5px",
                                                                textAlign: "center"
                                                            }}>{colorIndex + 1}</p>
                                                        );
                                                    })}
                                                </div>
                                                {symptomsData[index].length > 1 && (
                                                    <div className="flex justifyStart alignCenter w-100" style={{ marginTop: 16 }} >
                                                        <p style={{ fontSize: 11, fontWeight: 700, margin: "auto" }}>{symptomPhaseDetail.phase}</p>
                                                    </div>
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
            <GraphMultiLegends legend={legends} />
        </div >
    )
}


export default SymptomsOverview;