import React from "react";

const Loading = () => {
    return (
        <div className="flex column justifyCenter alignCenter reports_loading_container">
            <div className="custom_loader" />
            <p style={{ marginTop: 32 }}>
                {`Loading, please wait…`}
            </p>
        </div>
    );
}

export default Loading;
